import { blue, blueGrey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const getEcStyles = () => ({
  "& .ecContainer": {
    "& .ecRulesColumns": {
      background: "#f4f1ff",
      boxShadow: "0px 3px 6px #00000029",

      "& .ecRules": {
        "& .rulesTitle": {
          background: "linear-gradient(91deg,  #6580e0 40%, #9cc3f4 100%)",
          opacity: 0.9,
          color: "white",
          padding: "0.55rem",
        },

        "& .rulesList": {
          width: "100%",
          paddingTop: "0px",
          maxHeight: "75vh",
          overflowY: "scroll",
        },

        "& .dataType": {
          padding: "15px 0.5rem",
          marginTop: "4px",
          background: "#f2f2f2",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        },
      },
    },

    "& .ecTitleHead": {
      fontSize: 13,
      fontWeight: 550,
      padding: "7px",
      color: "#54577d",
      textAlign: "center",
      backgroundColor: "#d0c2ff",
      borderRight: "1px solid #d0c2ff",
    },

    "& .ecRuleCheckCard": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "wrap",
      background: "#eee",
      padding: "4px 6px",
      width: "100%",
      height: "100%",
      position: "relative",
      gap: "4px",
      margin: "auto",
      borderRadius: "6px",
      cursor: "pointer",
    },
  },
  "& .dragItemsList": {
    boxSizing: "border-box",
    display: "grid",
    width: "100%",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  "& .testHeader": {
    color: "white",
    opacity: 0.9,
    background: "linear-gradient(91deg,  #6580e0 45%, #9cc3f4 100%)",
  },
  "& .addRow": {
    backgroundColor: "#6580e0",
    ":hover": { backgroundColor: "#6580e0" },
  },
  "& .ecContainers": {
    maxHeight: "calc(75vh + 30px)",
    overflowY: "auto",
    boxSizing: "border-box",
  },
  "& .scrollbarStyle": {
    "::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
      borderRadius: "0.5rem",
    },
    "::-webkit-scrollbar-track": {
      background: "#e0e0e0",
      borderRadius: "0.5rem",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#aba9a9",
      borderRadius: "0.5rem",
    },
    "::-webkit-scrollbar-track :hover": {
      background: "#888",
      borderRadius: "0.5rem",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#888",
      borderRadius: "0.5rem",
    },
  },
  "& .switchStyle": {
    width: "35px",
    height: "20px",
    padding: "5px",
    "& .MuiSwitch-switchBase": {
      padding: "3px 2px",
    },
    // '& .MuiSwitch-thumb': { backgroundColor: '#6580e0' },
    // '& .MuiSwitch-track': { backgroundColor: 'grey' },
    ".MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#6580e0",
    },
    ".MuiSwitch-colorPrimary.Mui-checked .MuiSwitch-thumb ": {
      color: "#6580e0",
    },
    "& .MuiSwitch-thumb": {
      color: "grey",
      width: "10px",
      height: "10px",
      marginTop: "1.75px"
    },
    "& .MuiSwitch-track": { backgroundColor: "grey" },
  },
});

const getHeaderStyles = () => ({
  "& .headerCus": {
    width: "100%",
    borderRadius: "0 !important", // Set border-radius to 0 with !important
    boxShadow: "none !important", // Remove box-shadow with !important
    "& .MuiToolbar-root": {
      minHeight: "52px",
      color: "#096eb6",
    },
    "& .MuiButtonBase-root": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "& .MuiTypography-h5": {
      background: "linear-gradient(to right, #2c65f4, #e73323)",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
    "& .headerIcons": {
      flexGrow: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "1rem",
    },
  },
  "& .notItems": {
    "& .MuiMenu-paper": {
      // overflow: "auto",
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      marginTop: "12px",
      width: "460px",
      maxWidth: "460px",
      padding: "8px 16px",
      "& .MuiAvatar-root": {
        width: "32px",
        height: "32px",
        marginRight: "8px",
        fontSize: "16px",
      },
      "&:before": {
        // content: '" "',
        display: "block",
        position: "absolute",
        top: "0",
        right: "14px",
        width: "10px",
        height: "10px",
        backgroundColor: "#fff",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
      "& ul": {
        "& li": {
          padding: "8px 0px",
          whiteSpace: "normal",
          "& p": {
            paddingRight: "35px",
            "& span": {
              color: "#ccc",
              fontWeight: "200",
              fontSize: "10px",
            },
          },
        },
      },
      "& .MuiTabs-root": {
        minHeight: "30px",
      },
      "& .MuiTabs-flexContainer": {
        borderBottom: "2px solid #ddd",
        "& .MuiTab-root": {
          padding: "0px 16px 0px 0px",
          minWidth: "auto",
          minHeight: "30px",
        },
      },
      "& .listBox": {
        paddingTop: "16px",
        "& .timeNot": {
          color: "#ccc",
          textTransform: "uppercase",
        },
      },
    },
    "& .notOnline": {
      position: "absolute",
      right: "8px",
      "& .MuiCheckbox-root": {
        padding: "5px",
        "& .MuiSvgIcon-root": {
          fontSize: "10px",
        },
        "& .MuiSvgIcon-root[data-testid='RadioButtonUncheckedIcon']": {
          color: "#fff",
        },
      },
    },
    "&.statusItems": {
      "& .MuiPaper-root": {
        width: "auto",
        "& ul": {
          "& li": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            padding: "8px",
            marginBottom: "2px",
          },
        },
      },
      "& .webSerStatus": {
        paddingTop: "8px",
        "& .MuiAvatar-root": {
          width: "15px",
          height: "15px",
        },
        "& svg": {
          fontSize: "6px",
        },

        "& .statusOnline": {
          backgroundColor: "rgb(0 128 0 / 19%)",
          "& .MuiAvatar-root": {
            backgroundColor: "green",
          },
          "& span": {
            color: "green",
          },
        },
      },
    },
  },
});

const getSidemenuStyles = () => ({
  "& .sidemenuCus": {
    borderRight: 0,
    zIndex: 2,
    "& .MuiDrawer-paper": {
      zIndex: 2,
      backgroundImage:
        "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
    },

    "& a": {
      // color: darktext,
      "& span.MuiTypography-root": {
        fontWeight: "300",
        color: "#333",
      },
      "& svg": {
        fontSize: "20px",
      },
    },
    "& .MuiListItem-button": {
      "& .MuiListItemIcon-root": {
        minWidth: "46px",
      },
    },

    "& a.active": {
      color: "#F9F9FF",
      "& .MuiTypography-root": {
        fontSize: "14px",
        color: "#fff",
      },
      "& .MuiListItem-button": {
        background:
          "transparent linear-gradient(91deg,  #611EB6 25%, #66A2EE 100%)",
      },
      "& svg": {
        color: "#F9F9FF",
      },
    },
  },
});

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1291,
      xl: 1776,
    },
  },
  typography: {
    allVariants: {
      fontSize: 12,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
    },
    h1: {
      fontSize: 22,
      fontWeight: 400,
    },
    h2: {
      fontSize: 20,
    },
    bold: {
      fontWeight: 600,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...getEcStyles(),
          "& .flex": {
            display: "flex",
          },
          "& .column": {
            flexDirection: "column",
          },
          "& .row": {
            flexDirection: "row",
          },
          // flex-end
          "& .flex-end": {
            display: "flex",
            alignItems: "flex-end",
          },

          // inline display
          "& .display-inline": {
            display: "inline-flex",
          },

          /* Vertical and Horizontal Center */
          "& .center": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },

          /* Vertical Center */
          "& .v-center": {
            display: "flex",
            alignItems: "center",
          },

          /* Horizontal Center */
          "& .h-center ": {
            display: "flex",
            justifyContent: "center",
          },
          /* Space Between */
          "& .space-between": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },

          /* Space Around */
          "& .space-around": {
            display: "flex",
            justifyContent: "space-around",
          },

          // Paper UI

          // Paper UI End

          // Common
          "& .labels": {
            backgroundColor: "#5b9df0",
            display: "inline-block",
            padding: "4px 6px",
            borderRadius: "4px",
            fontSize: "8px",
            color: "#fff",
            background: "transparent linear-gradient(91deg, #611EB6 25%, #66A2EE 100%)",
          },
          "& .innerHeader": {
            height: "58px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            zIndex: 2,
            boxShadow: "0px 4px 8px 0px rgba(46, 45, 125, 0.16)",
            // background: `url(${InnerHeader})`,
            background: "transparent linear-gradient(91deg,  #611EB6 25%, #66A2EE 100%)",
            backgroundSize: "cover",
            color: "#F9F9FF",
            padding: "12px 16px 12px 16px",
            borderRadius: "0",
            boxSizing: "border-box",
          },
          "& .innerHeaderContainer": {
            position: "fixed",
            zIndex: 2,
            marginLeft: "-12px",
            marginRight: "-12px",
            borderRadius: 0,
            transition: "width 0.3s ease-in-out, max-width 0.3s ease-in-out",
          },
          "& .pt74": {
            paddingTop: "74px",
            width: "100%",
          },
          // outlet
          "& .outlet": {
            padding: "0px 16px 16px",
            borderRadius: "16px 16px 0 0",
            width: "100%",
          },

          ...getSidemenuStyles(),
          ...getHeaderStyles(),

          "& .testSuiteNote": {
            backgroundColor: "#d0c2ff",
            color: "#54577d",
            width: "100%",
            borderRadius: "4px",
            padding: "2%",
            marginTop: "2%",
          },
          "& .Notes": {
            bottom: -25,
            padding: "15px 5px 5px 5px",
            position: "sticky",
            background: "#f4f1ff",
          },
          "& .apiKeyNote": {
            backgroundColor: "#BBC7F2",
            width: "100%",
            color: "#54577d",
            borderRadius: "4px",
            padding: "4px 12px",
            marginTop: "12px",
          },
          "& .apiKeyNote2": {
            backgroundColor: "#BBC7F2",
            // width: "90.3%",
            color: "#54577d",
            borderRadius: "4px",
            padding: "1%",
            marginBottom: "1%",
          },
          "& .apiKeyNote3": {
            backgroundColor: "#ffadba",
            color: "#54577d",
            width: "100%",
            borderRadius: "4px",
            padding: "1.5%",
            marginTop: "2%",
          },
          color: "#406887",
          fontFamily: "Poppins, Helvetica, 'sans-serif'",
          backgroundColor: "#F4F1FF",
          "& strong , b": {
            fontWeight: 600,
          },
          // Paper UI
          "& .MuiPaper-root": {
            boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
            color: "#406887",
          },
          "& .MuiPaper-root.MuiPaper-elevation0": {
            boxShadow: "none",
          },
          // svg icon css
          "& .MuiSvgIcon-root": {
            fontSize: "inherit",
          },
          "& .createBtn": {
            float: "right",
            padding: "8px 0",

            "& .MuiToolbar-root": {
              backgroundColor: "transparent",
              padding: 0,
            },

            "& .MuiIconButton-root": {
              borderRadius: "4px",
              backgroundColor: "#fff",
              color: "#808b9a",
              border: "1px solid #808b9a",
              marginLeft: "4px",
              "&:hover": {
                color: "#296df5",
              },
            },
          },
          "& .refreshBtn": {
            float: "left",
            padding: "8px 0",
            "& .MuiButtonBase-root": {
              border: "1px solid #bdbdbd",
              borderRadius: "4px",
            },
          },
          "& .pvTestSch": {
            "& .refreshBtn": {
              paddingLeft: 16,
            },
            "& .createBtn": {
              paddingRight: 16,
            },
          },
          "& .custom-ag-grid": {
            height: "100%",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column"
          },
          "& .ag-theme-balham": {
            height: "100%",
            width: "100%",
            border: 0,
            overflow: "hidden",
            paddingBottom: "10px",
            "& .highlight-header": {
              color: "green",
            },
            "& .error-header": {
              color: "#ef6c00",
            },
            "& .ag-root-wrapper-body": {
              height: "auto !important",
            },
            "& .ag-overlay": {
              position: "static",
            },
            "& .ag-row": {
              height: "41px",
              justifyContent: "center",
              alignItems: "center",
              "&:nth-of-type(odd)": {
                backgroundColor: "#f6f2fa",
              },
              "&:nth-of-type(even)": {
                backgroundColor: "#ede8ff",
              },
              border: "1px solid #f6f2fa",
            },
            "& .ag-cell": {
              justifyContent: "flex-start",
              alignItems: "center",
              display: "flex",
              color: "#54577d",
              fontSize: 12.2,
            },
            "& .ag-header": {
              backgroundColor: "#d0c2ff",
              border: "1px solid #d0c2ff",
            },
            "& .ag-header-cell ": {
              color: "#54577d",
              fontWeight: 550,
              padding: "7px",
              fontSize: 13,
            },
            "& .ag-header-cell::after": {
              display: "none",
            },
            "& .ag-header-icon": {
              opacity: 1,
              color: "#54577d",
            },
            "& .rowsDisplay": {
              color: "red",
              position: "absolute",
              bottom: "4px",
              width: "80px",
              left: "14px",
              "& .MuiSelect-select": {
                padding: "3.5px 14px",
              },
            },
          },
          "& .logoSec": {
            display: "flex",
            alignItems: "center",
            "& .menuBtn": {
              marginLeft: "0px",
            },
            "& .MuiBox-root": {
              marginRight: "8px",
            },
          },
          "& .MuiToolbar-root": {
            padding: "0px 16px",
          },
          // "& .innerHeader": {
          //   backgroundColor: "#E89D45",
          //   color: "#fff",
          //   padding: "2px 12px",
          //   // marginLeft: "-24px",
          //   // marginRight: "-24px",
          //   // marginTop: "1.4rem",
          //   // marginBottom: "0.8rem",
          //   // boxShadow: "0 0 10px 0 rgb(9 110 182 / 25%)",
          //   boxSizing: "border-box",
          //   "& .MuiTypography-h6": {
          //     color: "#fff",
          //     fontSize: 16,
          //   },
          // },
          "& .selectVal": {
            marginTop: 16,
            "& .selValBody": {
              padding: "16px",
            },
          },
          "& .selColVal": {
            minHeight: "calc(100% - 0px)",
            backgroundColor: "#eaedf7",
          },
          // new ui changes
          "& .drawerShortHead": {
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            padding: "0.25rem 0.8rem",
            borderRadius: 0,
            color: "#E89D45",
            boxShadow: "0px -3px 4px #00000029",
            opacity: 1,
          },

          "& .col-start": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          },

          "& .flex-col-center": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          },

          "& .alignCenter": {
            gap: 2,
            display: "flex",
            justifyContent: "center",
          },
          "& .justifyContent-align-center": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          "& .flex-col": {
            display: "flex",
            flexDirection: "column",
          },

          "& .accordionArrow": {
            height: "20px",
            width: "20px",
            background: "#6580e0",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "3px",
            cursor: "pointer",
          },
          "& .backButton": {
            background: "#AAAAAA",
            margin: "0.6rem 0rem",
            boxShadow: "0px 3px 2px #00000029",
            opacity: 1,
            borderRadius: 0.5,
          },
          "& .autListIcons": {
            display: "flex",
            gap: "4px",
            justifyContent: "center",
          },

          "& .accordianActionIcon": {
            background: "#6580e0",
            border: "1px solid #6580e0",
            color: "white",
            borderRadius: "4px",
            // padding: "2px",
            "&:hover": {
              cursor: "pointer",
              background: "#e7e0ff",
              color: "#6580e0",
            },
          },
          "& .accordianActionIcon2": {
            background: "#cf2b2b",
            border: "1px solid #cf2b2b",
            color: "white",
            borderRadius: "4px",
            marginLeft: "120px",
            marginTop: "-10px",
            marginRight: "-30px",
            "&:hover": {
              cursor: "pointer",
              background: "#e7e0ff",
              color: "#cf2b2b",
            },
          },
          "& .bg-white&box-shadow": {
            boxShadow: "0px 3px 6px #00000029  !important",
            background: "#FFFFFF",
            opacity: 1,
            borderTopLeftRadius: "0px !important",
            "& .MuiTypography-bold": {
              color: "#096eb6",
            },
          },
          "& .DSSelection": {
            width: "250px",
            border: "none",
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
            borderRadius: 0,

            "& .MuiListItemIcon-root": {
              "& img": {
                width: "25px",
                height: "auto",
              },
            },
            "#my-input": {
              display: "flex",
              alignItems: "center",
              padding: "0px",
              gap: "0.5rem",
              height: "40px",
              borderRadius: 0,
            },
          },
          "& .menuItem": {
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            boxSizing: "border-box",
            height: "40px",
            width: "250px",
            padding: 0,
          },
          "& .listItemIcon": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "40px !important",
            "& img": {
              margin: "auto",
            },
          },

          "& .custom-link": {
            display: "inline-block !important",
            lineHeight: 1,
            textDecoration: "none",
            padding: 0,
            margin: 0,
          },
          "& .loader-button": {
            position: "relative",
            "& .MuiLoadingButton-startIconLoadingStart": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
            "& .MuiLoadingButton-loadingIndicator": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          },

          "& .DBresult": {
            marginLeft: "-8px",
            marginRight: "-8px",
            "& .DBResultList": {
              "& .MuiDivider-root": {
                width: "50px",
                marginBottom: "8px",
              },

              "& img": {
                height: "70px",
              },
            },
          },

          "& .DSResultcard": {
            backgroundColor: "#fff",
            color: "#424242",
            border: "1px solid",
            borderColor: "#e0e0e0",
            padding: "8px 16px",
            margin: "8px",
            borderRadius: "4px",
            fontSize: "0.875rem",
            position: "relative",
            transition: "transform 0.5s ease , box-shadow 0.5s ease",
            cursor: "pointer",
            "&:hover": {
              border: "1px solid #096eb6",
              boxShadow: "0 0.5em 0.5em -0.4em #096eb6",
              transform: "translateY(-0.25em)",
            },
            "&.active": {
              border: "1px solid #096eb6",
              boxShadow: "0 0.5em 0.5em -0.4em #096eb6",
              transform: "translateY(-0.25em)",
            },
          },
          "& .card-selected": {
            border: "1px solid #096eb6",
            boxShadow: "0 0.5em 0.5em -0.4em #096eb6",
            transform: "translateY(-0.25em)",
            backgroundColor: "#e6e6fa",
          },

          // End New UI
          // Radio Button
          "& .MuiRadio-root": {
            "& svg": {
              fontSize: "18px",
            },
          },
          // Radio Button
          // Table UI
          "& table.MuiTable-root": {
            border: "1px solid rgb(189 195 199 / 50%)",
            "& tr": {
              "& th": {
                padding: "4px 10px",
                borderLeft: "1px solid rgb(189 195 199 / 50%)",
                backgroundColor: "#7A86A1",
                color: "#ffffff",
              },
              "& td": {
                padding: "4px 10px",
                borderLeft: "1px solid rgb(189 195 199 / 50%)",
                "& .MuiTablePaginationUnstyled-root": {
                  border: "0px",
                },
                "& .MuiIconButton-root": {
                  padding: "2px",
                  fontSize: "1rem",
                  "& svg": {
                    fontSize: "14px",
                  },
                },
              },
              "& .MuiSvgIcon-root": {
                fontSize: "20px",
              },
            },
            "& tr.active": {
              backgroundColor: "#e5f6fd",
            },
          },
          // End Table UI

          "& .MuiAccordionSummary-root": {
            "& .MuiAccordionSummary-content": {
              margin: "10px 0",
            },
          },
          "& .validations": {
            "& th, & td": {
              borderLeft: 0,
              padding: "3px 10px",
            },
          },
          "& .MuiAccordion-root": {
            "& .MuiInputBase-input": {
              padding: "3px 10px",
              fontSize: "10px",
            },
            "& .MuiInputLabel-root": {
              top: "-6px",
              fontSize: "10px",
            },
            "& .MuiFormLabel-filled , .MuiInputLabel-shrink": {
              top: "2px",
            },
            "& .MuiButton-contained": {
              padding: "3px 16px",
              lineHeight: "13px",
              fontSize: "10px",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "1rem",
            },
          },
          "& .dialogCus": {
            "& .MuiDialogActions-spacing": {
              // padding: "0px 0px 15px",
              justifyContent: "center",
            },
          },
          // eslint-disable-next-line no-dupe-keys
          "& .MuiAccordion-root": {
            "& .expRow": {
              "& .MuiInputBase-input": {
                padding: "3px 10px",
                fontSize: "10px",
              },
              "& .MuiInputLabel-root": {
                top: "-6px",
                fontSize: "10px",
              },
              "& .MuiFormLabel-filled , .MuiInputLabel-shrink": {
                top: "2px",
              },
            },

            "& .MuiButton-contained.expBtn": {
              padding: "3px 16px",
              lineHeight: "13px",
              fontSize: "10px",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "1rem",
            },
          },

          // Ag Grid

          "& .ag-root-wrapper": {
            position: "static",
          },

          "& .ag-checkbox-input-wrapper": {
            fontSize: "12px",
            width: "12px",
            height: "12px",
            lineHeight: "12px",
          },

          // Ag Grid End

          "& .MuiStepConnector-line": {
            borderColor: "#0077fe",
            borderTopStyle: "dashed",
            borderTopWidth: "1.5px",
          },
          // PDF
          "& .PDF": {
            "& .MuiCard-root": {
              boxShadow: "none",
              borderRadius: 0,
            },
          },

          // PDF End

          "& .passfailBtn": {
            color: "#333",
            fontSize: "12px!important",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            "& span": {
              "&:nth-of-type(1)": {
                color: "green",
                marginRight: 4,
              },
              "&:nth-of-type(2)": {
                color: "#d32f2f",
                marginLeft: 4,
              },
            },
          },
          "& .expRow": {
            backgroundColor: "#fff",
          },
          // Single file
          "& .fileTextbox": {
            border: " 1px solid #ccc",
            position: "relative",
            padding: "5.4px",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "block",
            textOverflow: "ellipsis",
            paddingRight: "25px",
            "& p": {
              position: "absolute",
              right: "5px",
              top: "5px",
              alignItems: "center",
              display: "flex",
            },
            "& svg.sucIcon": {
              position: "absolute",
              right: "5px",
              top: "4px",
              color: "green",
              fontSize: "20px",
            },
            "& svg.errIcon": {
              fontSize: "13px",
              ml: 0.4,
            },
          },
          "& .drawerFile": {
            "& .fileUpGrid1": {
              flexBasis: "100%",
              maxWidth: "100%",
            },
            "& .gridCusmd": {
              flexBasis: "50%",
              maxWidth: "50%",
            },
            "& .MuiGrid-container": {
              "& .MuiGrid-grid-xs-8": {
                flexBasis: "50%",
                maxWidth: "50%",
              },
              "& .MuiGrid-grid-xs-2": {
                flexBasis: "25%",
                maxWidth: "25%",
              },
            },
          },
          // Single file End

          // Checkbox
          "& .MuiCheckbox-root": {
            padding: "0 4px 0 0",
            "& .MuiSvgIcon-root": {
              fontSize: "20px",
              color: "#6580e0",
            },
          },
          // Checkbox End
          // Stepper
          "& .MuiStepper-horizontal": {
            "& .MuiStepLabel-iconContainer": {
              "& svg": {
                fontSize: "20px",
              },
            },
          },

          // Stepper End

          // Expanded Row
          "& td.expandableRow": {
            padding: "8px 8px !important",
            background: "#fff",
            "& .MuiTabs-root": {
              minHeight: "auto",
              marginBottom: 8,
              "& .MuiButtonBase-root": {
                padding: "5px 16px",
                minHeight: "auto",
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiButtonBase-root.Mui-selected": {
                backgroundColor: "#1976d2",
                color: "#fff",
              },
            },
          },
          "& .passfailStatus": {
            border: "0.5px solid #3a7d33",
            padding: "5px",
            color: "#3a7d33",
            textAlign: "center",
          },
          // Expanded Row End

          // Data Profiling Page
          "& .DPMain": {
            "& .DPDropDown": {
              marginBottom: 16,
              border: "1px dashed #1976d2",
              padding: "16px",
              "& .MuiGrid-grid-lg-12": {
                padding: 0,
              },
            },
          },
          "& .DPFilter": {
            marginBottom: 8,
            "& .MuiButton-root": {
              marginLeft: 4,
            },
          },
          "& .DPConName": {
            display: "flex",
            alignItems: "center",
            "& img": {
              height: 40,
              marginRight: 8,
            },
          },
          "& .colName": {
            textAlign: "center",
            "& h6": {
              color: "#096eb6",
            },
          },

          "& .DPToolTip": {
            position: "relative",
            "& .MuiButtonBase-root": {
              position: "absolute",
              top: "0",
              right: "4px",
            },
          },
          "& .DPGraphHead": {
            padding: "8px",
            borderBottom: "1px solid #ccc",
            position: "relative",
            "& .MuiBox-root": {
              textAlign: "center",
              border: "1px solid #e0e0e0",
              backgroundColor: "#fff",
              borderRadius: "4px",
              "& h6": {
                color: "#096eb6",
              },
            },
            "& .MuiIconButton-root": {
              position: "absolute",
              top: "-16px",
              right: "-8px",
            },
          },

          "& .pieChart": {
            "& svg": {
              margin: "auto",
            },
          },

          "& .DPGraphFooter": {
            backgroundColor: "#e7ebf0",
            textAlign: "center",
            padding: "3px 4px",
            "& p": {
              fontSize: "10px",
            },
          },
          "& .DPOuptlabel": {
            backgroundColor: "#e0f3ff",
            textAlign: "center",
            padding: "8px 0px",
          },

          // Data Profiling Page End

          "& .inputNumber": {
            "& .MuiInputBase-inputSizeSmall": {
              padding: "6.7px 14px",
            },
          },

          "& .commonHead": {
            padding: "4px 16px",
            backgroundColor: "#e5f6fd",
          },
          "& .conValHead": {
            backgroundColor: "#fff",
            padding: "8px 12px",
            // border: "1px solid #ccc",
            marginBottom: "16px",
            borderRadius: "0px",
            // boxShadow: "0px 3px 6px #00000029",
            "& .MuiTypography-bold": {
              color: "#096eb6",
            },
          },
          "& .conValSel": {
            marginBottom: "16px",
            "& .MuiButton-root": {
              marginLeft: "8px",
            },
          },

          "& .innerSubHead": {
            borderBottom: "1px solid #eee",
            padding: "4px 16px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            "& .createBtn": {
              padding: 0,
            },
            "& .MuiTypography-bold": {
              color: "#096eb6",
            },
          },

          "& .innerSubRight": {
            textAlign: "right",
          },

          // Chip UI
          "& .MuiFormControl-root": {
            "& .MuiChip-root": {
              fontSize: 12,
              "& .MuiChip-label": {
                height: 20,
              },
              "& svg": {
                fontSize: 14,
              },
            },
          },

          // Chip UI End

          // Error Message
          "& .errorText": {
            color: "#d32f2f",
            display: "flex",
            alignItems: "center",
            "& svg": {
              marginRight: "4px",
            },
          },
          "& .columnName.MuiTypography-root": {
            backgroundColor: "#e0f3ff",
            padding: "3px 8px",
            marginBottom: "4px",
            marginTop: "4px",
            borderRadius: "4px",
          },
          // Error Message End

          // Accordion

          "& .MuiAccordion-root.MuiPaper-root": {
            // padding: "6px 0px",
            boxShadow: "none",

            // "& .MuiAccordion-root": {
            //   boxShadow: "none",
            // },
          },

          // Button UI
          "& button.MuiButtonBase-root": {
            textTransform: "none",
          },
          // Button UI

          // Comparative Result
          "& .compDSHead": {
            textAlign: "center",
            backgroundColor: "#eee",
            borderRight: "1px solid #fff",

            "& h6": {
              backgroundColor: "#1976d2",
              padding: "0px 8px",
            },
            "& .MuiTypography-bold": {
              color: "#096eb6",
            },
          },
          "& .comValMain": {
            maxHeight: "calc(100vh - 192px)",
            overflow: "auto",
          },
          "& .compValBody": {
            border: "1px solid #eee",
            padding: 8,
            "& .compDSHead": {
              textAlign: "left",
              "& p": {
                backgroundColor: "#e0f3ff",
                padding: "4px 8px",
              },
            },
          },
          "& .compHead1": {
            padding: "2px 16px",
            backgroundColor: blue[200],
            textAlign: "center",
          },
          "& .compTiles": {
            padding: "8px 0px",

            "& .tile.MuiPaper-root": {
              padding: 8,
              alignItems: "center",
              display: "flex",
              "& .MuiSvgIcon-root": {
                color: blue[500],
                fontSize: "18px",
                opacity: 0.8,
              },
              "& .MuiTypography-root": {
                marginLeft: 8,
                paddingLeft: 8,
                borderLeft: "1px solid #ccc",
                fontSize: "11px",
                "& span": {
                  opacity: 0.5,
                },
              },
              "& .MuiIconButton-root": {
                marginLeft: "auto",
              },
              "&:hover": {
                cursor: "pointer",
                backgroundColor: blue[50],
              },
            },
            "& .MuiPaper-root.TableOne": {
              "& .MuiSvgIcon-root": {
                color: "#5cb85c",
              },
            },
            "& .MuiPaper-root.TableTwo": {
              "&  .MuiSvgIcon-root": {
                color: "#f2b561",
              },
            },
          },
          // Comparativee Result End

          // Data Sources
          "& .DBListMain": {
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            width: "100%",
            margin: "-8px -6px",
            "& .DBList": {
              "& .MuiDivider-root": {
                width: "50px",
                marginBottom: "8px",
              },
              "& .DBLeft": {
                cursor: "pointer",
                "&:hover": {
                  cursor: "pointer",
                },
              },

              "& img": {
                height: "70px",
              },
            },
          },

          "& .DisabledDSListItem": {
            color: "#424242",
            border: "1px solid",
            borderColor: "#e0e0e0",
            padding: "8px 16px",
            margin: "8px",
            borderRadius: "4px",
            fontSize: "0.875rem",
            position: "relative",
            transition: "transform 0.5s ease , box-shadow 0.5s ease",
            cursor: "not-allowed",
            opacity: 0.6,
            "& .DBAdd": {
              cursor: "not-allowed",
              // marginTop: "-1rem",
              "&:hover": {
                boxShadow: "none",
              },
            },
            "&:hover": {
              border: "1px solid #ede8ff",
              boxShadow: "none",
              transform: "none",
              cursor: "not-allowed",
            },
          },
          "& .DSListItem": {
            backgroundColor: "#ede8ff",
            border: "1px solid",
            borderColor: "#ede8ff",
            padding: "8px 12px",
            margin: "12px 6px",
            fontSize: "0.875rem",
            position: "relative",
            transition: "all 0.3s ease , box-shadow 0.3s ease",
            cursor: "pointer",
            borderLeft: "6px solid #c8b8ff",
            "&:hover": {
              boxShadow: "0 0.5em 0.5em -0.4em #611EB6",
              transform: "translateY(-0.25em)",
            },
            "&.active": {
              boxShadow: "0 0.5em 0.5em -0.4em #611EB6",
              transform: "translateY(-0.25em)",
            },
            "& .DBAdd": {
              cursor: "pointer",
              color: "white",
              backgroundColor: "#6580e0",
              border: "1px solid #6580e0",
              textAlign: "center",
              borderRadius: "3px",
              opacity: 1,
              height: "18px",
              width: "18px",
              marginLeft: "auto",
              marginTop: "-1.1rem",
              transition: "all 0.3s ease , box-shadow 0.3s ease",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#6580e0",
                boxShadow:
                  "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
              },
            },
            "& .imported": {
              backgroundColor: "#8BCA67",
              display: "inline-block",
              padding: "4px 6px",
              borderRadius: "4px",
              fontSize: "8px",
              color: "#fff",
            },
            "& .privacy": {
              backgroundColor: "#5b9df0",
              display: "inline-block",
              padding: "4px 6px",
              borderRadius: "4px",
              fontSize: "8px",
              color: "#fff",
            },
            "&.public": {
              "& .privacy": {
                background: "#611eb6",
              },
            },
            "& a:active": {
              color: "#406887",
            },
          },

          "& .DSListItem2": {
            backgroundColor: "#eaeff3",
            color: "#611EB6",
            padding: "8px 16px",
            marginRight: "18px",
            borderRadius: "7px",
            fontSize: "1rem",
            position: "relative",
            transition: "all 0.3s ease , box-shadow 0.3s ease",
            cursor: "pointer",
            borderLeft: "6px solid #611EB6",
            marginTop: "10px",
            height: "150%",
            "&:hover": {
              boxShadow: "0 0.5em 0.5em -0.4em #096eb6",
              transform: "translateY(-0.25em)",
            },
            "&.active": {
              boxShadow: "0 0.5em 0.5em -0.4em #096eb6",
              transform: "translateY(-0.25em)",
            },
            "& .DBAdd": {
              cursor: "pointer",
              color: "#6580e0",
              border: "1px solid #6580e0",
              textAlign: "center",
              borderRadius: "3px",
              opacity: 1,
              height: "20px",
              width: "18px",
              marginLeft: "auto",
              marginTop: "-1.1rem",
              transition: "all 0.3s ease , box-shadow 0.3s ease",
              "&:hover": {
                backgroundColor: "#6580e0",
                color: "#fff",
                boxShadow:
                  "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
              },
            },
            "& .privacy": {
              backgroundColor: "#7a86a1",
              display: "inline-block",
              padding: "4px 6px",
              borderRadius: "4px",
              fontSize: "8px",
              color: "#fff",
              marginBottom: "4px",
            },
            "&.public": {
              borderLeftColor: "#E89D45",
              "& .privacy": {
                backgroundColor: "#E89D45",
              },
            },
          },

          "& .filterBtns": {
            // padding: "16px 0px",
            // textAlign: "center",
            display: "flex",
            justifyContent: "flex-start",
            color: "#7A86A1",
            // ".css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) , .css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type)":
            //   {
            //     borderColor: "#e0e0e0",
            //     borderRadius: "20px",
            //   },

            "& .MuiToggleButton-root": {
              // borderColor: "#e0e0e0",
              // marginRight: 5,
              // borderRadius: "20px",
              border: "0px",
              color: "inherit",
              // padding: "2px 8px",
              fontSize: 12,
              p: 0,
              boxShadow: "transparent !important",
              borderColor: "transparent !important",
              backgroundColor: "transparent",
              "&:hover": {
                // backgroundColor: "#e0f2ff",
                boxShadow: "transparent !important",
                borderColor: "transparent !important",
                backgroundColor: "transparent",
                color: "#096eb6",
                fontWeight: 800,
              },
              "&.Mui-selected": {
                // boxShadow: "0 0 6px rgb(0 0 0 / 18%)",
                // borderColor: "rgb(9 110 182 / 45%) !important",
                boxShadow: "transparent !important",
                borderColor: "transparent !important",
                backgroundColor: "transparent",
                color: "#096eb6",
                fontWeight: 800,
              },
            },
          },

          // Data Sources End

          // Text Field UI
          "& .MuiInputBase-root": {
            backgroundColor: "#fff",
          },

          // Text Field UI End

          // Image Upload
          "& .imageComparison": {
            width: "100%",
          },
          "& .headerImg": {
            "& .MuiFormControlLabel-root": {
              marginRight: "0px",
              "& .MuiRadio-root": {
                padding: "0 4px 0",
              },
            },
          },

          "& .corCount": {
            backgroundColor: "rgb(255 0 0 / 56%)",
            border: "1px solid red",
            fontSize: "8px",
            width: "18px",
            height: "18px",
            color: "#fff",
            borderRadius: "50%",
            lineHeight: "16.5px",
            textAlign: "center",
            position: "absolute",
            top: "-18px",
          },
          "& .ignoreCordinates": {
            backgroundColor: "#eaeff3",
            minHeight: "389px",
            borderRadius: "4px",
            "& .innerSubHead": {
              borderColor: "#ccc",
              "& h6": {
                fontSize: "12px",
              },
            },
          },
          "& .cordinatesBody": {
            padding: "8px 16px",
            height: "250px",
            overflow: "auto",
            "& .cordinatesSec": {
              "& .MuiBox-root": {
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
                "& .MuiCheckbox-root": {
                  padding: 0,
                  marginRight: 2,
                },
                "& p": {
                  fontSize: "10px",
                  "& span": {
                    marginLeft: 6,
                    "&:nth-of-type(1)": {
                      marginLeft: 0,
                    },
                  },
                },
              },
            },
          },
          "& .imageCordinates": {
            padding: "8px 16px",
          },

          "& .imageUpload": {
            "& .kiHXWb": {
              width: "100%",
              minWidth: "auto",
              maxWidth: "100%",
              display: "block",
              textAlign: "center",
              height: "auto",
              border: 0,
              "& .fVfcRr": {
                display: "block",
                "& span": {
                  display: "block",
                  margin: "auto",
                },
              },
            },
            "& p": {
              color: "rgb(5 87 194 / 70%)",
              marginTop: 0,
            },
          },
          "& .filterFields": {
            marginTop: "24px",
          },
          "& .imgComOutput": {
            marginTop: "30px",
            padding: "0px 16px 16px",
            "& .imgOutput": {
              marginTop: "16px",
              height: "330px",
              backgroundColor: "#eaeff3",
              display: "flex",
              alignItems: "center",
              padding: 8,
              borderRadius: "4px",
              "& canvas": {
                width: "auto",
                height: "auto",
                maxWidth: "100%",
                maxHeight: "330px",
                margin: "auto",
              },
            },
            "& .ignoreCordinates": {
              minHeight: "330px",
              "& .cordinatesBody": {
                height: "202px",
                overflow: "auto",
              },
            },
          },
          "& .imgUpMain": {
            border: "1.5px dashed #0658c2",
            borderRadius: "4px",
            textAlign: "center",
            backgroundColor: "#eaeff3",
            "& .imgDisplay": {
              height: "250px",
              backgroundColor: "#fff",
              position: "relative",
              "& .imgBox": {
                textAlign: "center",
                backgroundColor: "#f9fbfa",
                display: "flex",
                height: "250px",
                alignItems: "center",

                "& img": {
                  width: "auto",
                  height: "auto",
                  maxWidth: "100%",
                  maxHeight: "250px",
                  margin: "auto",
                },
                "& canvas": {
                  width: "auto",
                  height: "auto",
                  maxWidth: "100%",
                  maxHeight: "250px",
                  margin: "auto",
                },
              },
              "& .openCorBtn": {
                position: "absolute",
                width: "100%",
                background: "transparent",
                top: 0,
                left: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                "& p": {
                  display: "none",
                  margin: "auto",
                  color: "#fff",
                  border: "1px solid #fff",
                  padding: "2px 8px",
                },
                "&:hover": {
                  backgroundColor: "rgb(0 0 0 / 28%)",
                  "& p": {
                    display: "block",
                  },
                },
              },
            },
          },

          // Image Upload End

          // Chart Validation
          "& .chartOutput": {
            maxHeight: "358px",
            overflow: "auto",
          },
          "& .CDAOutputBox": {
            padding: "8px",
            borderLeft: "5px solid",
            borderRadius: "4px",
            backgroundColor: "#fff",
            boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
            "& .colCode": {
              width: "18px",
              height: "18px",
              lineHeight: "18px",
              textAlign: "center",
              float: "right",
              borderRadius: "50%",
              color: "#fff",
            },
          },
          // Chart Validation End

          // API Form
          "& .authFields": {
            color: "#54577d",
            padding: "16px 16px",
            border: "1px solid #c8b8ff",
            marginTop: "16px",
            borderRadius: "4px",
            "& p": {
              marginBottom: "8px",
            },
          },
          "& .formTabs": {
            "& .MuiTabs-root": {
              minHeight: "30px",
            },
            "& .MuiTabs-flexContainer": {
              border: "1px solid #ccc",
              display: "inline-block",
              borderRadius: "30px",
            },
            "& .MuiTabs-indicator": {
              height: "30px",
              zIndex: "-1",
              top: 1,
              borderRadius: "30px",
            },
            "& .MuiTab-root": {
              minHeight: "30px",
              height: "auto",
              padding: "0 16px",
              lineHeight: "30px",
            },
            "& .MuiTab-root.Mui-selected": {
              color: "#fff",
              borderRadius: "50px",
              backgroundColor: "rgb(25, 118, 210)",
            },
            "& .authFields": {
              marginTop: 8,
            },
          },

          // API Form End

          // Small Button
          "& .MuiButton-root.exSmall": {
            padding: "0 8px",
            minWidth: "auto",
            fontSize: "10px",
          },
          // Small Button End

          "& .DBsec": {
            boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
            position: "relative",
            background: "#fff",
            borderRadius: 4,
            marginBottom: "20px",
            padding: 10,

            "& .MuiSvgIcon-root": {
              fontSize: "2rem",
              marginRight: "5px",
              color: "#006dba",
            },

            "& .MuiTypography-root": {
              color: "#333",
            },
            "& .MuiTypography-h6": {
              fontSize: 16,
            },
            "& a": {
              textDecoration: "none",
            },
            "& .linkCus": {
              color: "#009df6",
            },
            "&:hover": {
              border: "1px solid #096eb6",
              boxShadow: "0 0.5em 0.5em -0.4em #096eb6",
              transform: "translateY(-0.25em)",
            },
          },
          "& .PassFail": {
            "& .MuiBox-root , a": {
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            },
            "& .MuiTypography-root": {
              fontSize: 10,
            },
            "& .passed": {
              "& .MuiSvgIcon-root , .MuiTypography-root": {
                color: "#00b104",
              },
            },
            "& .failed": {
              "& .MuiSvgIcon-root , .MuiTypography-root": {
                color: "#ff0b0e",
              },
            },
            "& .MuiSvgIcon-root": {
              fontSize: "0.7rem",
              marginRight: "2px",
            },
          },
          "& .themeSwitch ": {
            ".MuiSwitch-root": {
              right: "8px",
            },
          },
          ".selTabHead": {
            padding: "0px 8px",
            background: "#eee",
            borderRadius: "4px",
          },
          ".selTabCol": {
            padding: "5px 8px",
            "& .MuiTypography-root": {
              "&:hover": {
                background: "#eee",
                cursor: "pointer",
                paddingLeft: "8px",
                borderRadius: "4px",
              },
            },
          },
          ".noTests": {
            border: "1px solid #096eb6",
            display: "inline-block",
            padding: "2px 4px",
            lineHeight: "14px",
            borderRadius: " 4px",
            color: "#096eb6",
            fontWeight: "600",
            minWidth: "20px",
            textAlign: "center",
            cursor: "pointer",
          },
          ".previewDrawHead": {
            textAlign: "center",
            border: "1px solid #ccc",
            marginBottom: "10px",
            ".MuiGrid-container .MuiGrid-root": {
              backgroundColor: "#e5f6fd",
              border: "1px solid #fff",
            },
          },
          ".noTestsDetails": {
            marginTop: "16px",
          },
          ".comMain": {
            ".comBgcolor": {
              boxShadow: "0px 3px 6px #00000029",
              opacity: 1,

              backgroundColor: "#fff",
            },
          },
          ".comSql": {
            textAlign: "center",
            backgroundColor: "#ebebeb",
            borderRadius: "4px",
          },

          "& .preview-highlight": {
            boxSizing: "border-box !important",
            background: "#eee !important",
            zIndex: "1 !important",
            border: "1px solid #096eb6 !important",
            marginLeft: "-1px",
            marginRight: "-1px",
          },
          "& .VTPreview": {
            padding: "8px 10px",
            ".VT-Left": {
              ".MuiBox-root": {
                backgroundColor: "#f6f6f6",
                padding: "8px",
                position: "relative",
                cursor: "pointer",
                textAlign: "center",
                p: {
                  display: "none",
                },
                "&:hover": {
                  p: {
                    position: "absolute",
                    backgroundColor: "rgb(0 0 0 / 24%)",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    transition: "background-color 0.5s ease",
                    svg: {
                      margin: "auto",
                      color: "#fff",
                      fontSize: "30px",
                    },
                  },
                },

                img: {
                  width: "auto",
                  maxWidth: "100%",
                  height: "300px",
                },
              },
            },
            ".VT-Right": {
              ".MuiBox-root": {
                backgroundColor: "#f6f6f6",
                padding: "8px",
                textAlign: "center",
                ".MuiTypography-bold": {
                  color: "#096eb6",
                },
              },
            },
          },
          "& .item-selected": {
            border: "1px solid #096eb6",
            boxShadow: "0 0.5em 0.5em -0.4em #096eb6",
            transform: "translateY(-0.25em)",
            backgroundColor: "#e6e6fa",
          },
          "& .DS-Acc": {
            "&.MuiAccordion-root.MuiPaper-root": {
              padding: 0,
              backgroundColor: "transparent !important",
              boxShadow: "none",
            },

            ".MuiAccordionSummary-content": {
              margin: 0,
            },
            "& .MuiAccordionDetails-root": {
              padding: 0,
            },
            "& .MuiAccordionSummary-root": {
              backgroundColor: "#F2F2F2",

              "&:hover": {
                cursor: "default",
              },
              "& .MuiAccordionSummary-expandIconWrapper": {
                cursor: "pointer",
                textDecoration: "underline",
                color: "#096eb6",
                "&.Mui-expanded": {
                  transform: "none",
                },
              },
            },
            "& .MuiCollapse-wrapperInner.MuiCollapse-vertical": {
              transition: "height 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            },
          },
          "& .active-ds": {
            display: "flex",
            p: {
              marginTop: "8px",
              paddingLeft: "4px",
              paddingRight: "3px",
            },
          },
          "& .ValueClassChecked": {
            "& .MuiFormControlLabel-labelPlacementEnd:nth-of-type(1)": {
              width: "40%",
            },
          },
          "& .ValueClass": {
            // border: "1px solid #ccc",
            borderLeft: "4px solid #b2d1ff",
            padding: "4px 12px",
            backgroundColor: " #F2F1F1",
            boxShadow: "0.47px 4px 10px #7777771A",
            opacity: 1,
            margin: "0px 10px 10px 0px",
            display: "flow-root",
            boxSizing: "border-box",

            "& .MuiFormControlLabel-labelPlacementEnd:nth-of-type(1)": {
              "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                fontWeight: "600",
              },
            },

            "& .MuiFormControlLabel-labelPlacementEnd:nth-of-type(2)": {
              display: "inline-block",
              float: "right",
              marginRight: 8,
            },
            ".MuiFormControlLabel-labelPlacementEnd:nth-of-type(3)": {
              display: "inline-block",
              float: "right",
              marginRight: 16,
            },
            "& .MuiFormControl-root": {
              width: "58%",
              marginRight: 0,
              marginLeft: 0,
            },
          },

          "& .MatchClass": {
            borderLeft: "4px solid #b2d1ff",
            padding: "4px 12px",
            backgroundColor: " #F2F1F1",
            boxShadow: "0.47px 4px 10px #7777771A",
            opacity: 1,
            margin: "0px 10px 10px 0px",
            display: "flow-root",
            boxSizing: "border-box",
            "& .MuiFormControlLabel-labelPlacementEnd:nth-of-type(1)": {
              width: "45%",
            },
            "& .MuiFormControlLabel-labelPlacementEnd:nth-of-type(2)": {
              display: "inline-block",
              width: "50%",
              paddingLeft: "28px",
            },
            ".MuiFormControlLabel-labelPlacementEnd:nth-of-type(3)": {
              display: "inline-block",
              width: "45%",
            },
            "& .MuiFormControlLabel-label": {
              fontSize: "14px",
              fontWeight: "600",
            },
          },
          ".response-popup": {
            maxHeight: "78vh",
            overflowY: "auto",
          },
          "& ul": {
            backgroundColor: "transparent !important",
          },
          ".logoSec": {
            img: {
              padding: "0px 8px",
            },
            "img:nth-of-type(1)": {
              borderRight: "1px solid #ccc",
              borderLeft: 0,
            },
          },
          "& .notificationMain": {
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          },
          "& .notItems": {
            "& .MuiMenu-paper": {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              marginTop: "12px",
              width: "460px",
              maxWidth: "460px",
              padding: "8px 16px",
              "& .MuiAvatar-root": {
                width: "32px",
                height: "32px",
                marginRight: "8px",
                fontSize: "16px",
              },
              "&:before": {
                content: "\"\"",
                display: "block",
                position: "absolute",
                top: "0",
                right: "14px",
                width: "10px",
                height: "10px",
                backgroundColor: "#fff",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: "0px",
              },
              "& ul": {
                "& li": {
                  padding: "8px 0px",
                  whiteSpace: "normal",
                  "& p": {
                    paddingRight: "35px",
                    "& span": {
                      color: "#ccc",
                      fontWeight: "200",
                      fontSize: "10px",
                    },
                  },
                },
              },
              "& .MuiTabs-root": {
                minHeight: "30px",
              },
              "& .MuiTabs-flexContainer": {
                borderBottom: "2px solid #ddd",
                "& .MuiTab-root": {
                  padding: "0px 16px 0px 0px",
                  minWidth: "auto",
                  minHeight: "30px",
                },
              },
              "& .listBox": {
                paddingTop: "16px",
                "& .timeNot": {
                  color: "#ccc",
                  textTransform: "uppercase",
                },
              },
            },
            "& .notOnline": {
              position: "absolute",
              right: "8px",
              "& .MuiCheckbox-root": {
                padding: "5px",
                "& .MuiSvgIcon-root": {
                  fontSize: "10px",
                },
                "& .MuiSvgIcon-root[data-testid='RadioButtonUncheckedIcon']": {
                  color: "#fff",
                },
              },
            },
            "&.statusItems": {
              "& .MuiPaper-root": {
                width: "auto",
                "& ul": {
                  "& li": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    padding: "8px",
                    marginBottom: "2px",
                  },
                },
              },
              "& .webSerStatus": {
                paddingTop: "8px",
                "& .MuiAvatar-root": {
                  width: "15px",
                  height: "15px",
                },
                "& svg": {
                  fontSize: "6px",
                },

                "& .statusOnline": {
                  backgroundColor: "rgb(0 128 0 / 19%)",
                  "& .MuiAvatar-root": {
                    backgroundColor: "green",
                  },
                  "& span": {
                    color: "green",
                  },
                },
              },
            },
          },
          "& .exePop": {
            padding: "0px 16px 16px",
            cursor: "move",
            position: "fixed",
            right: "0px",
            top: "68px",
            zIndex: "1202",
          },
          "& .bg-color": {
            backgroundColor: "#fff",
            boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
            padding: "0px 16px 16px",
            borderRadius: "8px",
          },
          "& .exList": {
            width: "400px",
            overflow: "visible",
            marginTop: "8px",
            padding: "8px",
            "& .MuiAvatar-root": {
              width: "32px",
              height: "32px",
              marginLeft: "-4px",
              marginRight: "8px",
            },

            "& .MuiLinearProgress-root": {
              height: "14px",
              borderRadius: "8px",
              "& .MuiLinearProgress-bar": {
                background:
                  "linear-gradient(0deg, rgba(25,118,210,1) 0%, rgba(97,173,250,1) 100%)",
              },
            },
            "& .percentage": {
              position: "absolute",
              top: "-1px",
              width: "100%",
              textAlign: "center",
              color: "#fff",
            },
            "&.completed": {
              "& .MuiLinearProgress-root": {
                "& .MuiLinearProgress-bar": {
                  background: "linear-gradient(0deg, #128300 0%, #20c905 100%)",
                },
              },
            },
            "&.aborted": {
              "& .MuiLinearProgress-root": {
                background: "rgb(255 215 178)",
                "& .MuiLinearProgress-bar": {
                  background:
                    "linear-gradient(0deg, #ee720d 0%, rgb(255 178 87) 100%)",
                },
              },
            },
          },
          "& .exeHeader": {
            "& .MuiIconButton-root": {
              backgroundColor: "#fff",
              boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
              "& span.MuiBox-root": {
                position: "absolute",
                top: 0,
                backgroundColor: "#e89d45",
                color: "#fff",
                height: "20px",
                width: "20px",
                fontSize: "14px",
                lineHeight: "20px",
                borderRadius: "50%",
                right: 0,
              },
              svg: {
                animation: "cog 3s infinite",
                animationTimingFunction: "linear",
              },
              "@keyframes cog": {
                "100%": {
                  transform: "rotate(360deg)",
                },
              },
            },
          },
          "& .disabled": {
            pointerEvents: "none",
            opacity: "0.6",
          },
          // API Testing -------
          "& .autActions": {
            display: "flex",
            alignItems: "center",
            position: "absolute",
            right: "12px",
            bottom: "5px",
            gap: "4px",
          },
          "& .autActionsList": {
            display: "flex",
            alignItems: "center",
            position: "absolute",
            right: "45px",
            marginTop: "-10px",
            gap: "4px",
          },
          "& .tsHeader": {
            margin: "12px 0px",
            width: "100%",
          },
          "& .btnIcon": {
            "& svg": {
              marginRight: "8px",
              fontSize: "16px",
            },
          },
          "& .btnIcon2": {
            marginTop: "11px",
            backgroundColor: "#6580e0",
            "& svg": {
              marginRight: "8px",
              fontSize: "16px",
            },
            ":hover": { backgroundColor: "#6580e0" },
          },
          "& .timeTT": {
            position: "absolute",
            right: "12px",
            opacity: "0.6",
            fontSize: "0.67rem",
          },
          "& .drawerHead .MuiSwitch-root": {
            marginLeft: "2px",
          },

          "& .apiList": {
            backgroundColor: "#ede8ff",
            ".MuiPaper-root": {
              backgroundColor: "#ede8ff",
              borderRadius: 0,
              borderBottomRightRadius: "0px",
              borderTopRightRadius: "0",
              height: "calc(100vh - 145px)",
              color: "#54577d",
              zIndex: -1
            },
            "& .apiName": {
              padding: "3px 12px 3px 12px",
              alignItems: "center",
            },
            "& .MuiListItem-root": {
              "& .MuiIconButton-root": {
                "& svg": {
                  color: "#e89d45",
                  transition: "0.2s",
                  transform: "translateX(0) rotate(0)",
                },
                "&:hover, &:focus": {
                  backgroundColor: "unset",
                  "& svg:first-of-type": {
                    transform: "translateX(-4px) rotate(-20deg)",
                  },
                  "& svg:last-of-type": {
                    right: 0,
                    opacity: 1,
                  },
                },
                "&:after": {
                  content: "\"\"",
                  position: "absolute",
                  height: "80%",
                  display: "block",
                  left: 0,
                  width: "1px",
                  backgroundColor: "#ccc",
                },
              },
            },
            "& .apiActions": {
              backgroundColor: "#edeaf8",
              "& .MuiListItemButton-root": {
                color: "#54577d",
              },
            },
            "& .listItem": {
              "& .MuiListItemButton-root": {
                paddingLeft: "12px",
                paddingRight: "12px",
              },
            },
          },
          "& .apiNameSelect": {},
          "& .createRequest": {
            height: "calc(100vh - 272px)",
            padding: "0px 12px",
            borderLeft: "0",
            borderBottomRightRadius: "4px",
            borderTopRightRadius: "4px",
            "& .MuiCheckbox-root": {
              padding: 0,
            },

            "& .radioSel1": {
              marginLeft: "0",
            },
          },
          "& .apiUrl": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "100%",
            margin: "12px 0px",
            "& .apiDetailsContainer1": {
              width: "80%",
              "& .MuiInputBase-root": {
                borderRadius: "0",
              },
              "& .save-button": {
                width: "50%",
              },
            },
            "& .urlInput": {
              div: {
                height: "100%",
                borderRadius: 0,
              },
              marginTop: "-0.3px",
              input: {
                fontSize: "1rem !important",
              },
              width: "100%",
              borderRadius: "0px 4px 4px 0px !important",
              border: "none !important",
              outline: "none !important",
              paddingRight: "0px",
            },
            "& .selectMehod": {
              display: "flex",
              borderRadius: "4px 0px 0px 4px !important",
              border: "none !important",
              outline: "none !important",
              boxSizing: "border-box",
              paddingRight: "2px",
              width: "108px",
            },
            "& .MuiButton-root": {
              minHeight: "34px",
            },
          },
          "& .customTabs": {
            "&.viewVar": {
              position: "absolute",
              top: "40px",
              right: "0px",
              zIndex: "2",
              width: "100%",
              padding: "10px 10px 0px",
              backgroundColor: "#f5f5f5",
              boxShadow: "0 0 10px rgb(0 0 0 / 21%)",
              maxHeight: "calc(100vh - 210px)",
              overflow: "auto",
              "& .refreshBtn": {
                display: "none",
              },
            },

            "& .MuiTabs-root": {
              minHeight: "30px",
            },
            "& .MuiTabs-flexContainer": {
              "& .MuiTab-root": {
                padding: "0",
                marginRight: "16px",
                minHeight: "30px",
                minWidth: "auto",
              },
            },
            "& .MuiAccordionDetails-root": {
              padding: 0,
            },
            " & .MuiAccordionSummary-root": {
              minHeight: "auto",
              padding: "0 14px",
              "& .MuiAccordionSummary-content": {
                margin: "4px 0px",
              },
              "&.Mui-expanded": {
                minHeight: "auto",
              },
            },
          },

          "& .dropdown": {
            display: "flex",
            backgroundColor: "#eee",
            position: "absolute",
            zIndex: 2,
            boxShadow: "10px 10px 15px #ccc",
            borderRadius: "10px",
            bottom: -145,
            "& .itemList": {
              height: "150px",
              overflowY: "auto",
              borderRight: "1px solid #ccc",
              listStyle: "none",
              padding: "0px",
              margin: 0,
              "& li": {
                padding: "8px 8px",
                cursor: "pointer",
                borderBottom: "1px solid #ccc",
                "&:hover": {
                  backgroundColor: "#e1e1e1",
                  fontWeight: "600",
                },
              },
            },
            "& .varInfo": {
              padding: "8px",
              // width: "80%",
              "& p": {
                fontSize: "10.5px",
              },
            },
            "& a": {
              display: "block",
              textDecoration: "none",
              padding: "2px 8px",
              borderBottom: "1px solid #ccc",
              fontWeight: "600",
              color: "#406887",
              fontSize: "10px",
              "&:hover": {
                backgroundColor: "#ccc",
              },
            },
          },

          // Updated Start

          // AUT > List > index.js
          "&. gridIcon": {
            height: "27px",
            width: "27px",
            cursor: "pointer",
            float: "right",
          },
          "& .snackbar2": {
            marginTop: "1.7%",
          },
          "& .linkS": {
            textDecoration: "none",
            color: "#455a64",
          },
          "& .emptyAuts": {
            marginTop: "10%",
            color: "#54577d",
          },
          "& .viewIcons": {
            display: "flex",
            justifyContent: "right",
            marginLeft: "15px",
          },
          "& .dialogAct": {
            paddingBottom: "22px",
          },
          "& .unsavedChanges1": {
            color: "#54577d",
          },
          "& .unsavedChanges2": {
            fontSize: 13,
            color: "#54577d",
          },
          "& .unsavedButton": {
            backgroundColor: "#eb5160",
            "&:hover": {
              backgroundColor: "#eb5160",
            },
            right: "2.5%",
          },
          "& .unsavedButton2": {
            backgroundColor: "#eb5160",
            "&:hover": {
              backgroundColor: "#eb5160",
            },
            right: "58%",
          },
          "& .tsForm": {
            marginTop: 2,
            textAlign: "center",
            "& .MuiButton-root": {
              "&:nth-of-type(1)": {
                mr: 1,
              },
            },
          },

          // AUT > List > AutTiles
          "& .autTiles": {
            width: "100%",
            "& a": { textDecoration: "none", color: "#406887" },
          },
          // AUT > List > AutList.js
          "& .emptyListType": {
            fontSize: "14px",
            fontWeight: 300,
            color: "#c8b8ff",
            mt: "1%",
          },

          // AUT > List > CreateAutForm.js
          "& .AutFormButtons": {
            marginTop: "2%",
            textAlign: "center",
            "& .MuiButton-root": {
              "&:nth-of-type(1)": {
                mr: 1,
              },
            },
          },

          // AUT > TestSuite > TestList
          "& .customized_table": {
            minWidth: 700,
          },

          "& .tsAuthForm": {
            marginTop: "1%",
          },

          "& .autocomplete": {
            width: "40%",
          },
          "& .tsList": {
            borderRight: "1px solid #ccc",
          },
          // Add Request > index.js
          "& .baseUrl": {
            div: {
              height: "100%",
              borderRadius: 0,
              width: "24rem",
            },
            input: {
              fontSize: "1rem !important",
            },
          },
          "& .resLogo": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eee",
            padding: "8px 12px",
          },
          "& .snackbar": {
            marginTop: "10.7%",
          },
          "& .resDrawer": {
            width: 550,
            height: "200%",
            margin: "-30px",
            padding: "25px",
            backgroundColor: "#f1edff",
          },
          // Add Request > SideApiList

          "& .moreIcon": {
            marginRight: -1,
            transition: "0.2s",
          },
          "& .moreActionList": {
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: 32,
          },
          "& .listItem": {
            borderBottom: "1px solid #ccc",
          },
          "& .listItemButn": {
            height: 30,
            paddingBottom: "20px",
            paddingTop: "19px",
          },
          "& .addAPIIcon": {
            transform: "scale(1.5)",
            cursor: "pointer",
            borderLeft: "1px solid #ccc",
            marginRight: "13px",
            paddingLeft: "5px",
          },
          // Add Request > ApiListItem
          "& .unsavedIndicator": {
            width: "10px",
            marginRight: "5px",
            marginTop: "2px",
          },
          // Add Request > Tabs
          "& .reqTabs": {
            marginRight: "-10px",
            marginLeft: "-10px",
            marginBottom: "-6%",
          },
          "& .reqTabs1": {
            borderBottom: "1px solid #ccc",
          },
          "& .reqTabs11": {
            marginRight: "-10px",
            marginBottom: "-6%",
          },

          // Add Request > Response
          "& .resTabs": {
            color: "#54577d",
            borderBottom: "1px solid #ccc",
            marginTop: "-3%",
          },
          "& .resTab0": {
            minWidth: "10%",
            marginBottom: "-5%",
          },
          "& .resTab": {
            minWidth: "10%",
            marginLeft: "-16px",
            marginBottom: "-5%",
          },
          "& .resTab1": {
            minWidth: "10%",
            marginLeft: "-16px",
            paddingTop: "5%",
            marginBottom: "-2%",
          },

          // Request > TabsList > Params
          "& .addIcon3": {
            // marginTop: "5px",
            // marginBottom: "4px",
            backgroundColor: "#6580e0",
            color: "#fff",
            // marginLeft: "-20px",
            cursor: "pointer",
            borderRadius: "4px",
            ":hover": {
              backgroundColor: "#6580e0",
            },
          },
          "& .addIcon2": {
            height: "20px",
            width: "20px",
            cursor: "pointer",
          },
          "& .checkbox": {
            transform: "scale(1.1)",
          },
          "& .delIcon": {
            transform: "scale(1.7)",
            cursor: "pointer",
          },
          "& .delIcon2": {
            transform: "scale(1.4)",
            cursor: "pointer",
            marginTop: "17px",
            marginLeft: "5px",
          },
          "& .Scrollbar": {
            height: "calc(100vh - 500px)",
            overflowX: "hidden",
            overflowY: "scroll",
          },
          "& .Scrollbar1": {
            height: "calc(100vh - 340px)",
            overflowX: "hidden",
            overflowY: "scroll",
          },
          "& .pathVars": {
            marginTop: "-34px",
            marginBottom: "-10px",
            marginLeft: "98%",
          },
          "& .emptyParams": {
            padding: "5% 33%",
            // paddingBottom:"8%"
          },

          // Add Request > TabsList > Auth
          "& .authBox2": {
            marginTop: "-29.3%",
          },

          "& .authBox": {
            padding: "12px 0px 0px 12px",
          },

          "& .authTypo": {
            position: "absolute",
            marginTop: "10%",
            left: "63%",
          },
          "& .authTextField": {
            marginLeft: "-30%",
          },
          "& .authTextField2": {
            marginLeft: "-30%",
            marginTop: "5%",
          },
          "& .authIcons": {
            position: "absolute",
            right: "-40px",
            color: "#EED202",
          },
          "& .authIcons2": {
            marginTop: "7%",
            color: "#000000",
          },

          // Add Request > TabsList > Header
          "& .infoIcon": {
            marginBottom: "-10px",
          },
          // Add Request > TabsList > Request
          "& .radioSel1": {
            marginRight: "-8px",
            marginLeft: "12px",
          },
          "& .radioSel2": {
            marginRight: "-8px",
          },
          "& .rawtype": {
            marginBottom: "5px",
            marginTop: "-13px",
          },
          "& .addFormData": {
            marginTop: "-40px",
            marginLeft: "96.5%",
          },
          "& .emptyForm": {
            padding: "1% 34%",
          },

          "& .editor": {
            counterReset: "line",
            border: "1px solid #ced4da",
          },

          "& .editor #codeArea": {
            outline: "none",
            paddingLeft: "60px !important",
          },

          "& .editor pre": {
            paddingLeft: "60px !important",
          },

          "& .editor .editorLineNumber": {
            position: "absolute",
            left: "0px",
            color: "#cccccc",
            textAlign: "right",
            width: "40px",
            fontWeight: 100,
          },

          // Add Request > Tabs (ScrollBar)
          "& .custom-scrollbars": {
            marginLeft: "30px",
            float: "left",
            height: "300px",
            width: "65px",
            overflowY: "scroll",
            marginBottom: "25px",
            background: "#f5f5f5",
          },

          "& .custom-scrollbars::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: "10px",
          },
          "& .custom-scrollbars::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "#555",
          },
          "& .custom-scrollbars::-webkit-scrollbar": {
            width: "12px",
            backgroundColor: "#f5f5f5",
          },

          // AUT > Response > Body
          "& .responseTab": {
            marginTop: "10px",
          },
          "& .rawTypeRes1": {
            borderRadius: "1px",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          },
          "& .rawTypeRes2": {
            borderRadius: "1px",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
          },

          "& .status": {
            color: "green",
          },
          "& .viewRes": {
            marginRight: "-0.5%",
            top: 7
          },

          // AUT > Response > Cookie
          "& .cookie": {
            padding: "10%",
          },

          "& .testresults": {
            padding: "20%",
          },

          // AUT > Response > TestResult

          "& .chipTR": {
            padding: "15px 5px",
            marginTop: "3%",
            backgroundColor: "#ffffff",
            color: "#444f57",
            borderRadius: "4px",
            border: "1px solid #ccc",
            borderBottomColor: "#ffffff",
          },
          "& .expandIcon": {
            color: "black",
            marginRight: "-10px",
          },

          "& .assertions": {
            backgroundColor: "white",
            padding: "2%",
            border: "1px solid #ccc",
          },

          "& .errReason": {
            color: "#66687d",
          },

          "& .statement": {
            color: "#54577d",
            fontSize: "0.82rem",
          },
          // AUT > Test
          "& .createTestButn": {
            textAlign: "right",
            marginLeft: "35%",
            marginRight: "-2.5%",
            // marginTop: "-20px"
          },
          "& .drawerHead": {
            color: "#611EB6",
          },
          // Variables
          "& .addRequestBoxStyle": {
            backgroundColor: "#eaeff4",
            padding: "1.5%",
            height: "calc(100vh - 255px)",
            overflowY: "scroll",
          },
          // "&. tsVarTable": {
          //   marginTop: "20px"
          // },
          // "&. varTableField": {
          //   minWidth: "182px",
          //   "& .MuiInputBase-root": {
          //     height: "30px",
          //     backgroundColor: "#eaeff4",
          //   }
          // },
          // Body End
          "& .fullBox": {
            width: "100%",
          },
          "& .fullBox2": {
            flexGrow: 1,
          },
          "&. stdColor": {
            color: "#03aeed",
          },

          // Updated End
          "& .runOrder": {
            backgroundColor: "#ede8ff",
            padding: "12px",
            height: "calc(100vh - 130px)",
            overflowY: "auto",
          },
          "& .OL": {
            "& .MuiAccordion-root": {
              backgroundColor: "transparent",
              border: 0,
              width: "100%",
              "& .MuiCheckbox-root": {
                padding: 0,
              },
              "& .MuiCollapse-vertical": {
                backgroundColor: "#fff",
              },
            },
            "& .MuiButtonBase-root.MuiAccordionSummary-root": {
              padding: 0,
              backgroundColor: "transparent",
              minHeight: "auto",
            },
            "& .responseRes": {
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              opacity: "0.5",
              gap: "12px",
            },
            "&.singleExe": {
              "& .tcAssertions": {
                borderLeft: "3px solid",
                margin: "10px 22px 12px",
                padding: "8px 15px 0px",
                display: "flex",
              },
              "& .tcPass , .tcFail": {
                color: "green",
                fontWeight: "600",
                fontSize: "14px",
                marginRight: "12px",
              },
              "& .tcFail": {
                color: "red",
              },
            },
          },
          "& .createConfig": {
            backgroundColor: "#ede8ff",
            padding: "12px 12px 0px",
            "& .MuiTextField-root": { marginBottom: "12px" },
          },

          "& .configMain": {
            marginTop: "12px",
            position: "relative",
            "& .apiActions": {
              "& .MuiListItemButton-root": {
                padding: "0 12px 0 10px",
                gap: "4px",
                borderBottom: "1px solid #eee",
              },
            },
            "& .MuiCheckbox-root": {
              padding: 0,
            },
            "& .apiList": {
              "& .MuiFormControlLabel-root": {
                marginRight: 0,
                gap: "4px",
              },
            },
            "& .exeDetails": {
              padding: "1.5px 12px",
              backgroundColor: "#eaeff4",
            },
          },
          "& .ConfigPreview": {
            "& .runOrder": {
              height: "auto",
              backgroundColor: "transparent",
              padding: "0",
              "& .OL": {
                marginBottom: "12px",
                backgroundColor: "#ede8ff",
                padding: "4px 12px",
                "& .MuiCollapse-vertical": {
                  backgroundColor: "transparent",
                },
              },
            },
            "& .DSListItem": {
              backgroundColor: "#fff",
              borderLeft: "1px solid #e0e0e0",
              cursor: "initial",
              "&:hover": {
                boxShadow: "none",
                transform: "none",
              },
              "& .configTC": {
                backgroundColor: "#eaeff4",
                padding: "4px",
                marginBottom: "4px",
              },
            },
            "& .MuiDrawer-paperAnchorRight": {
              top: 0,
              transform: "none !important",
            },
            "& .customTabs": {
              height: "calc(100vh - 128px)",
              overflowY: "auto",
            },
          },
          "& .configTiles": {
            padding: "0px 12px",
            marginTop: "12px",
            "& .DSListItem": {
              borderLeft: "1px solid #e0e0e0",
            },
          },
          "& .closeDrawerIcon": {
            position: "fixed", // Fixed position
            top: 0, // Align to the top
            right: 0, // Align to the right
            margin: "8px", // Small margin for better positioning
            zIndex: 1, // Ensure it stays above other content
          },
          "& .editConfig": {
            "& .runOrder": {
              minHeight: "calc(100% - 0px)",
              height: "auto",
            },
          },
          "& .scroll-api": {
            height: "calc(100vh - 165px)",
            overflowY: "auto",
          },
          "& .cron_builder .well": {
            display: "flex",
            alignItems: "center",
            padding: "15px",
            marginBottom: "15px",
            "&.row": {
              alignItems: "start",
            },
          },
          "& .well-small input": {
            maxWidth: "100px",
          },
          "& .cron_builder_bordering": {
            border: 0,
            padding: "15px 0px",
          },
          "& .cron_builder": {
            padding: "15px",
            backgroundColor: "white",
            borderRadius: "8px",

            "& .nav.nav-tabs": {
              borderBottom: "1px solid #ccc",
            },
            "& .nav li a": {
              padding: "3px",
              color: "#9b9b9b",
            },
            "& .cron-builder-bg": {
              backgroundColor: "#E89D45",
              padding: "4px 0px",
            },
          },
          "& .cron_builder .nav-tabs .nav-item.show .nav-link, .cron_builder .nav-tabs .nav-link.active":
          {
            color: "#1976d2",
            borderBottom: "2px solid #1976d2",
            fontWeight: "600",
          },
          "& .actionIcon": {
            backgroundColor: "#7034BD",
            color: "#fff",
            padding: "5px",
            fontSize: "18px",
            borderRadius: "4px",
            "&:hover": {
              cursor: "pointer",
              background: "#e7e0ff",
            },
          },
          //------------------------------------------------------------------------

          // Dark Theme Start

          "&.dark": {
            backgroundColor: "#1a202c",
            color: "#fff",
            // "& .headerCus": {
            //   backgroundColor: "#1a202c",
            //   "& .MuiButtonBase-root": {
            //     backgroundColor: "rgb(255 255 255 / 35%)",
            //     color: "#d0d2d6",
            //   },
            //   "& .css-1t29gy6-MuiToolbar-root": {
            //     backgroundColor: "#1a202c",
            //   },
            // },
            // "& .sidemenuCus": {
            //   "& .MuiDrawer-paper": {
            //     backgroundColor: "#283046",
            //     boxShadow: "none",
            //     borderRight: "1px solid #3b4253",
            //   },
            // },
            "& .innerHeader": {
              // backgroundColor: "#283046",
              boxShadow: "none",
            },
            "& .DSListItem": {
              backgroundColor: "#1a202c",
              color: "#54577d",
            },
            "& .DBListMain": {
              "& .DBList": {
                "& .MuiDivider-root": {
                  borderColor: "rgb(204 204 204 / 52%)",
                },
              },
            },

            "& .MuiPaper-root": {
              backgroundColor: "#283046",
              color: "#d0d2d6",
            },
            "& .DQhead , .overallDQ": {
              borderColor: "#3b4253",
              "& .MuiGrid-item": {
                "& .DQ ": {
                  borderColor: "#3b4253",
                },
              },
            },
            "& .compSec": {
              "& .compRight": {
                borderColor: "#3b4253",
              },
            },
            "& .DS": {
              "& .MuiGrid-item:nth-of-type(1)": {
                borderColor: "#3b4253",
              },
            },
            "& .bBox": {
              "& p": {
                borderColor: "#3b4253",
                color: "#d0d2d6",
              },
            },
            "& .rBox": {
              backgroundColor: "transparent",
              borderRight: "1px solid #3b4253",
              "& p": {
                borderColor: "#3b4253",
                color: "#d0d2d6",
              },
            },

            "& .MuiInputLabel-root , .MuiFormLabel-root": {
              color: "#b4b7bd",
            },
            "& .MuiInputBase-root": {
              backgroundColor: "#283046",
              color: "#b4b7bd",

              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#b4b7bd",
                color: "#b4b7bd",
              },
              "& .MuiIconButton-root": {
                color: "#b4b7bd",
              },
              "& svg": {
                fontSize: "20px",
                color: "#b4b7bd",
              },
            },

            "& .graphHead": {
              "& .MuiPaper-root": {
                "& p": {
                  "& b": {
                    backgroundColor: "transparent",
                    border: "1px solid",
                  },
                },
              },
            },
            "& .graphSec": {
              "& hr": {
                borderColor: "#3b4253",
              },
            },
            "& .wBox": {
              background: "transparent",
              "& a": {
                background: "#283046",
                "& p": {
                  color: "#d0d2d6",
                },
              },
            },
            "& .DBsec": {
              backgroundColor: "#343d55",
              "& .MuiTypography-root": {
                color: "#d0d2d6",
              },
            },
            // eslint-disable-next-line no-dupe-keys
            // "& .sidemenuCus": {
            //   "& .MuiDrawer-paper": {
            //     boxShadow: "none",
            //     borderRight: "1px solid #3b4253",
            //   },
            //   "& a": {
            //     color: "#d0d2d6",
            //   },
            // },
            "& .MuiStepper-root": {
              "& .MuiStepLabel-label": {
                color: "#d0d2d6",
              },
            },
            "& .MuiButtonBase-root.Mui-disabled": {
              color: "rgb(255 255 255 / 26%)",
              backgroundColor: "rgb(255 255 255 / 12%)",
            },
            // Table header color
            "& .ag-theme-balham .ag-header , .ag-header-row, table.MuiTable-root tr th , .MuiAccordion-root.MuiPaper-root , .commonHead , .columnName.MuiTypography-root, .conValHead, .MuiListSubheader-root, .MuiFormControl-root .MuiChip-root, .imgUpMain .imgDisplay , .ignoreCordinates, .CDAOutputBox, .innerSubHead, .MuiCardHeader-root":
            {
              backgroundColor: "#343d55",
              color: "#ede8ff",
            },
            "& .ag-theme-balham .ag-row , .ag-paging-panel , table.MuiTable-root tr td , .DS-Acc .MuiAccordionSummary-root ":
            {
              backgroundColor: "#283046",
              color: "#ede8ff",
            },
            "& .createBtn .MuiIconButton-root , .imgUpMain , .filterBtns button:hover , .DS-Acc .MuiAccordionDetails-root":
            {
              backgroundColor: "transparent",
            },
            "& .DPGraphHead .MuiBox-root , .expRow, .ag-theme-balham .ag-checkbox-input-wrapper, textarea":
            {
              backgroundColor: "transparent",
            },
            "& .DPOuptlabel , .comSql": {
              backgroundColor: "#343d55",
            },
            "& .DPGraphFooter": {
              backgroundColor: "#1a202c",
            },
            "& .MuiAccordionDetails-root": {
              backgroundColor: "#283046",
            },
            // Dark blue color
            "& .selColVal , .selTabHead , .VTPreview .VT-Left .MuiBox-root, .VTPreview .VT-Right .MuiBox-root":
            {
              backgroundColor: "#1a202c",
            },
            // Font color
            ".MuiCheckbox-root , .MuiFormHelperText-root , .MuiRadio-root , .MuiTableContainer-root tr>td table>thead>tr>th.MuiTableCell-root , .MuiTableContainer-root .MuiChip-root , form.MuiPaper-root svg":
            {
              color: "#d0d2d6",
            },
            ".MuiRadio-root.Mui-checked , .MuiCheckbox-root.Mui-checked , .ag-theme-balham .ag-checkbox-input-wrapper.ag-checked::after":
            {
              color: "#1976d2",
            },
            "& .MuiListSubheader-root": {
              lineHeight: "30px",
            },
            ".css-catpii-MuiSwitch-root .MuiSwitch-thumb": {
              backgroundColor: "#096eb6",
            },
            ".selTabCol .MuiTypography-root": {
              "&:hover": {
                backgroundColor: "#343d55",
              },
            },
            // Border Color
            ".innerSubHead": {
              borderColor: "#3b4253",
            },
            ".previewDrawHead .MuiGrid-container .MuiGrid-root": {
              backgroundColor: "transparent",
            },
            ".comMain": {
              ".comBgcolor": {
                "&:nth-of-type(1)": {
                  backgroundColor: "#343d55",
                },
              },
            },

            "& .ValueClass , .MatchClass ": {
              backgroundColor: "transparent",
            },

          },

          // Dark Theme End
          // -------------------------------------------------------------------------
        },
      },
    },
  },
});

export const useStyles = makeStyles({
  createconnection: {
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },
  TooltipTop: {
    "& .MuiTooltip-tooltip": {
      backgroundColor: "red",
    },
  },
  title: {
    marginBottom: "16px",
  },
  PaperCus: {
    boxShadow: "0 0px 12px rgb(0 0 0 / 8%)!important",
    position: "relative",
    borderRadius: "4px!important",
    "& .linkCus": {
      color: "#009df6",
      fontSize: "12px",
    },
  },

  headSec: {
    padding: 8,
    paddingBottom: 30,
    background: "linear-gradient(30deg, #92bbc3, #e3efe0, #84b4bd)",
    // background: blueGrey[100],
    borderRadius: 10,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    "& .MuiTypography-h6": {
      color: blueGrey[800],
    },
  },

  fileSec: {
    position: "relative",
    background: "#f1faff",
    borderRadius: 10,
    marginBottom: "20px",
    padding: 10,
    paddingBottom: 35,
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },

    "& .MuiTypography-h6": {
      color: "#009df6",
      fontSize: 16,
    },
    "& a": {
      background: "#009df6",
      textDecoration: "none",
    },
    "& .linkCus": {
      background: "none",
    },
  },
  TimeCir: {
    display: "flex",
    alignItems: "center",
  },

  upcomingTests: {
    "& .dx-visibility-change-handler": {
      "& .dxc.dxc-chart": {
        width: "100%",
      },
    },
    "& .wBox": {
      background: "#fff",
      marginTop: "8px",
      marginBottom: "8px",
      "& a": {
        background: "#f3f4f6",
        display: "block",
        padding: "4px 8px",
        marginBottom: "1px",
        borderRadius: "4px",
        "& h6": {
          lineHeight: "16px",
        },
      },

      "& p": {
        "&:last-child": {
          border: 0,
        },
      },
    },

    "& .rBox": {
      backgroundColor: "#fff",
      borderRadius: "4px",
      maxHeight: "117px",
      minHeight: "117px",
      overflow: "auto",
      "& p": {
        padding: "4px 8px",
        borderBottom: "1px solid #eee",
        "& a": {
          fontSize: "12px",
        },
      },
    },
    "& .graphHead": {
      display: "flex",
      "& .MuiPaper-root": {
        textAlign: "center",
        padding: "8px",
        marginRight: "8px",
      },
      // eslint-disable-next-line no-dupe-keys
      "& .MuiPaper-root": {
        "& p": {
          marginRight: "8px",
          "& b": {
            padding: "4px 8px",
            background: "#f3f4f6",
            borderRadius: "4px",
          },
        },
      },
    },

    "& .colorInfo": {
      display: "flex",
      alignItems: "center",
      marginTop: "4px",
      "& .MuiBox-root": {
        display: "flex",
        margin: "auto",
      },
      "& p": {
        display: "flex",
        alignItems: "center",
        marginRight: "16px",
        fontWeight: "500",
        color: "#ffc107",
        "& svg": {
          fontSize: "14px",
          marginRight: "4px",
        },
        "&:nth-of-type(1)": {
          color: "#0074ef",
        },
        "&:last-child": {
          color: "#ff0c00",
        },
      },
    },
    "& .DQhead": {
      padding: "5px 16px",
      borderBottom: "1px solid #eee",
    },
    "& .overallDQ": {
      border: "1px solid #efeff7",
      borderRadius: "4px",

      "& .MuiGrid-item": {
        "& .DQ": {
          padding: "16px",
          minHeight: "79px",
          borderRight: "1px solid #eee",
        },
        "&:last-child": {
          "& .DQ": {
            border: 0,
          },
        },
      },
    },
    "& .graphSec": {
      marginBottom: 16,
      padding: "16px",
      "& .MuiDivider-root": {
        marginBottom: 16,
      },
      "& .card": {
        "& .dx-c-bs4-container": {
          paddingRight: 0,
          paddingLeft: 0,
        },
      },
      "& #bottom-container": {
        position: "absolute",
        top: "-30px",
        right: "0px",
        "& .list-group": {
          listStyle: "none",
          display: "flex",
          padding: "0",
          "& li": {
            paddingLeft: "8px",
          },
        },
      },
      "& .passed , .failed": {
        float: "right",
      },
    },
    "& .compSec": {
      padding: "0 16px",
      "& .compLeft , .compRight": {
        paddingTop: "16px",
      },
      "& .compRight": {
        borderLeft: "1px solid #eee",
        paddingLeft: "16px",
      },
    },
    "& .DS": {
      "& .MuiGrid-item": {
        "&:nth-of-type(1)": {
          borderRight: "1px solid #eee",
        },
      },
    },
  },
  progress: {
    display: "flex",
    border: "2px solid #efeff7",
    borderRadius: "10px",
    position: "relative",
    "& .MuiBox-root": {
      textAlign: "center",
      color: "#fff",
      cursor: "pointer",
      margin: "auto",
      "&:nth-of-type(1)": {
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
      },
      "&:last-child": {
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
      },
    },
    "& .noData": {
      width: "100%",
      background: "#fff",
      borderRadius: "10px",
    },
  },
  STresult: {
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },
    "& p.MuiTypography-root": {
      color: "#333",
    },
    "& p": {
      "& a": {
        fontWeight: "500",
      },
    },
    "&  a": {
      color: "#71787e",
      textDecoration: "none",
      "& h5": {
        color: "#006dba",
        fontSize: "12px",
        "& b": {
          fontSize: "14px",
          marginLeft: "5px",
        },
      },
    },
    "& .Rsec": {
      textAlign: "center",
      display: "inline-block",
      marginBottom: "16px",
      "& h5": {
        display: "flex",
        alignItems: "center",
      },
    },
    "& .bBox": {
      width: "100%",
      "& p": {
        display: "inline-block",
        width: "20%",
        border: "1px solid #eee",
        textAlign: "center",
        minHeight: "51px",
        float: "left",
        padding: "5px 0",
        "&:last-child": {
          lineHeight: "38px",
          "& a": {
            fontWeight: "normal",
          },
        },
        "& a": {
          display: "block",
        },
      },
    },
  },
});

export const tableStyles = makeStyles({
  tableCus: {
    "& .popoverTable": {
      "& td": {
        verticalAlign: "top",
      },
    },
    "& .accCus": {
      marginBottom: 16,
      "& .MuiAccordionSummary-root": {
        minHeight: "auto",
      },
    },
    "& .delSec": {
      "& .MuiTypography-root": {
        backgroundColor: "rgba(25, 118, 210, 0.12)",
        display: "inline-block",
        paddingLeft: 8,
      },
    },
  },
});

export const sideUseStyles = makeStyles({
  sidemenuUI: {
    "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
    {
      minWidth: "148px",
      backgroundColor: "#207398",
      "& .MuiMenu-list>li>a": {
        textDecoration: "none",
        color: "#fff",
      },
    },
  },
});
