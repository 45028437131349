/* eslint-disable react/prop-types */
import { useContext, useEffect, useState } from "react"
import {
  Box,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  TextField, Typography
} from "@mui/material"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"

import rule from "data/test"
import { handleDragOver } from "hooks"
import { resource } from "data/resource"
import UtilsContext from "contexts/Utils/UtilsContext"
import TestCaseContext from "contexts/TestCase/TestCaseContext"

const ReusableChip = ({ label, onDelete }) => (
  <Chip
    size="small"
    label={label}
    sx={{ ml: 0.5, color: "#707070", backgroundColor: "#fbfaff", mt: 0.3 }}
    onDelete={onDelete}
  />
)

export default function Security() {
  const utilsContext = useContext(UtilsContext)
  const testCaseContext = useContext(TestCaseContext)

  const { changes, setChanges, showAlert } = utilsContext
  const { testcaseData, setTestcaseData } = testCaseContext

  const [curr, setCurr] = useState("")

  useEffect(() => {
    const obj = { ...testcaseData?.securityAssertions }
    !testcaseData?.securityAssertions?.cookies?.rules?.length && delete obj.cookies
    setTestcaseData({ ...testcaseData, securityAssertions: { ...obj } })
  }, [])

  const onChangeHandler = (e) => {
    !changes && setChanges(true)
    setTestcaseData({
      ...testcaseData,
      securityAssertions: {
        ...testcaseData.securityAssertions,
        [e.target.name]: {
          ...testcaseData.securityAssertions[e.target.name],
          expectedValue: e.target.value,
        },
      },
    })
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const stringData = event.dataTransfer.getData("ruleData")
    const draggedItem = stringData && JSON?.parse?.(stringData)
    if (draggedItem) {
      const isItemAlreadyPresent = testcaseData.securityAssertions[draggedItem.operation]
      if (!isItemAlreadyPresent) {
        !changes && setChanges(true)
        setTestcaseData({
          ...testcaseData,
          securityAssertions: {
            ...testcaseData.securityAssertions,
            [draggedItem.operation]: draggedItem[draggedItem.operation]
          }
        })
      }
      else {
        showAlert({
          msg: "Rule Check already exists",
          type: "warning",
        })
      }
    }
  }

  const deleteRule = (item) => {
    !changes && setChanges(true)
    const newSecurityAssertion = { ...testcaseData.securityAssertions }
    delete newSecurityAssertion[item]
    setTestcaseData({
      ...testcaseData,
      securityAssertions: {
        ...newSecurityAssertion
      }
    })
  }

  const handleDelete = (index) => {
    const arr = [...testcaseData.securityAssertions.cookies.rules]
    arr.splice(index, 1)
    setTestcaseData({
      ...testcaseData,
      securityAssertions: {
        ...testcaseData.securityAssertions,
        cookies: {
          ...testcaseData.securityAssertions.cookies,
          rules: arr,
        },
      },
    })
  }

  const handleKeyUp = (e) => {
    e.key === "Enter" && e.preventDefault()
    if (!curr.length) return
    if (e.keyCode === 32 || e.keyCode === 13) {
      !changes && setChanges(true)
      setTestcaseData({
        ...testcaseData,
        securityAssertions: {
          ...testcaseData.securityAssertions,
          cookies: {
            ...testcaseData.securityAssertions.cookies,
            rules: [...testcaseData.securityAssertions.cookies.rules, { name: curr }],
          },
        },
      })
      setCurr("")
    }
  }

  return (
    <Box sx={{ px: 1 }}>
      <Grid
        container
        className="ecContainers"
      >
        <Grid item md={12}>
          <Typography
            className="ecTitleHead"
            sx={{ borderTopRightRadius: "12px", borderTopLeftRadius: "12px", }}
          >
            {resource.TC.RULE}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        md={12}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        overflow={"auto"}
        border={"1px solid #cfcfcf"}
      >
        {Object?.entries(testcaseData?.securityAssertions)?.map((each, i) =>
          each[0] !== "_id" &&
          <Box sx={{ height: "100%" }} key={i}>
            <Box
              sx={{
                color: "#54577d",
                margin: "10px",
                padding: "10px",
                borderRadius: "4px",
                position: "relative",
                alignItems: "center",
                background: "#ede8ff",
              }}>
              <IconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  "& svg": { fontSize: "16px" },
                }}
                size="small"
                color="error"
                onClick={() => deleteRule(each[0])}
              >
                <CancelOutlinedIcon />
              </IconButton>
              <Grid container>
                <Grid item sm={4}>
                  <Typography variant="h3" sx={{ fontSize: "15px" }}>{rule.securityData[each[0]]}</Typography>
                </Grid>
                <Grid item sm={8}>
                  {each[0] !== "cookies" ?
                    <TextField
                      select
                      size="small"
                      autoComplete='off'
                      variant="outlined"
                      name={each[0]}
                      value={testcaseData.securityAssertions[each[0]]?.expectedValue || 200}
                      onChange={onChangeHandler}
                    >
                      {each[0] === "statusCode" ?
                        rule.statusCode.map(e => <MenuItem key={e.name} value={e.name}>{e.value}</MenuItem>) :
                        rule.contentType.map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)
                      }
                    </TextField>
                    :
                    <>
                      {each[1]?.rules?.length ? each[1].rules.map((item, index) =>
                        <ReusableChip
                          key={index}
                          label={item.name}
                          onDelete={() => handleDelete(index)}
                        />
                      ) :
                        <>
                          Eg:- {new Array(2).fill("example").map((item, index) =>
                            <ReusableChip
                              key={index}
                              label={item}
                              // eslint-disable-next-line no-empty-function
                              onDelete={() => { }}
                            />
                          )}
                        </>}
                      <br />
                      <TextField
                        value={curr}
                        size="small"
                        autoComplete='off'
                        placeholder="Press enter to add more cookies"
                        variant="outlined"
                        onKeyDown={handleKeyUp}
                        onChange={(e) => setCurr(e.target.value)}
                        sx={{ marginTop: "10px" }}
                      />
                    </>}
                </Grid>
              </Grid >
            </Box>
          </Box>
        )}
        {
          Object?.keys(testcaseData?.securityAssertions)?.length < 4 && <Grid item md={12} p={1}>
            <Box className="v-center">
              <Box
                className="v-center h-center"
                sx={{ p: 3, border: "1px dashed #c8b8ff", flexGrow: 1 }}
              >
                Drag & Drop Rules here
              </Box>
            </Box>
          </Grid>
        }
      </Grid >
    </Box >
  )
}
