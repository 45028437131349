import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box, Button,
  Grid,
  IconButton,
  Typography, Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";


import TestTile from "./TestTile"
import TestList from "./TestList"
import { resource } from "data/resource"
import ApiService from "services/app.service"
import CreateTestForm from "components/Forms/TestForm"
import UtilsContext from "contexts/Utils/UtilsContext"
import TestCaseContext from "contexts/TestCase/TestCaseContext"

function TestCase() {
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(document.location.search)
  const tc = searchParams.get("tc")
  const autId = searchParams.get("aut")
  const tsId = searchParams.get("testsuite")

  const utilsContext = useContext(UtilsContext);
  const testCaseContext = useContext(TestCaseContext);

  const { showAlert, edit, deletes, setDelete } = utilsContext
  const { tcs, editTC, add_t, status, testcases, setStatus, setTestCases, setAddt,
    getTestCases, setEditTC, setTestCase, setTCs } = testCaseContext

  const [view, setView] = useState("tile");

  useEffect(() => {
    if (edit)
      { getTestCases(edit, true); }
  }, []);

  const handleEditSubmit = async (id) => {
    try {
      const ele = testcases.find((e) => e._id === id)
      ele.isSelected = !ele.isSelected
      await ApiService.updateTest(id, ele)
      getTestCases(edit, true)
    } catch (err) {
      console.log(err.response.data);
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      });
    }
  };

  const deleteHandler = async () => {
    try {
      if (deletes.length < 14) {
        const index = tcs.findIndex(e => e._id === deletes)
        tcs.splice(index, 1)
        setTCs(tcs)
      }
      else { await ApiService.deleteTest([deletes], tsId); }
      setTimeout(() => {
        setDelete(null);
        edit ? getTestCases(edit, true) : setTestCases(tcs);
      }, resource.TIMEOUT.SUCCESS);
    } catch (err) {
      console.log(err.response.data);
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      });
    }
  };

  return (
    <Box>
      {tc ? (
        <Box>
          <Grid container>
            <Grid item sm={0.5} my={1.5}>
              <Tooltip title="Go Back" placement="right">
                <IconButton
                  size="small"
                  className="addIcon3"
                  onClick={() => {
                    setStatus(true)
                    setTestCase(null)
                    if (editTC) {
                      setEditTC(null)
                      !add_t && getTestCases(edit, true)
                    }
                    setTimeout(() => navigate(-1), 100);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item sm={11} my={2} mb={3}>
              <Typography variant="h5" sx={{ color: "#54577d" }}>
                {resource.TC.NEW}
              </Typography>
            </Grid>
          </Grid>

          {!status && <CreateTestForm setStatus={setStatus} />}
        </Box>
      ) : (
        <Box>
          {!testcases.length ? (
            <Box textAlign="center" className="emptyAuts">
              <Typography variant="h6">{resource.TC.EMPTY}</Typography>
              <Button
                className="btnIcon2"
                onClick={() => {
                  setAddt(true);
                  setStatus(false);
                  navigate(`/addrequest?aut=${autId}&testsuite=${tsId}&tc=new_testcase`);
                }}
                size="small"
                variant="contained"
              >
                <AddIcon size="large" />
                {resource.CREATE} {resource.TC.NAME}
              </Button>
            </Box>
          ) : (
            <>
              <Grid container alignItems="center" my={1.5}>
                <Grid sm={6} item>
                  {edit &&
                    <Typography>
                      <b>Note:</b> <i>Select below boxes for Test Execution</i>{" "}
                    </Typography>
                  }
                </Grid>

                <Grid sm={6} item>
                  <Box justifyContent="end" gap={1.5} display="flex">
                    <Tooltip
                      title={
                        view === "tile"
                          ? resource.VIEW.TILE
                          : resource.VIEW.LIST
                      }
                    >
                      <IconButton
                        size="small"
                        sx={{ color: "#6580e0" }}
                        onClick={() => {
                          view === "tile" ? setView("list") : setView("tile");
                        }}
                      >
                        {view === "tile" ? (
                          <ViewListRoundedIcon />
                        ) : (
                          <GridViewRoundedIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Button
                      onClick={() => {
                        setAddt(true);
                        setStatus(false);
                        navigate(`/addrequest?aut=${autId}&testsuite=${tsId}&tc=new_testcase`);
                      }}
                      size="small"
                      variant="contained"
                      sx={{ backgroundColor: "#6580e0", ":hover": { backgroundColor: "#6580e0" } }}
                    >
                      {resource.CREATE} {resource.TC.NAME}
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              {view === "tile" ? (
                <TestTile
                  deleteHandler={deleteHandler}
                  handleEditSubmit={handleEditSubmit}
                  setStatus={setStatus}
                />
              ) : (
                <TestList
                  setStatus={setStatus}
                  deleteHandler={deleteHandler}
                  handleEditSubmit={handleEditSubmit}
                />
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default TestCase;
