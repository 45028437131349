/* eslint-disable react/prop-types */
import { useState, useRef, useContext } from "react"
import {
  Box, Button,
  Grid,
  Typography,
} from "@mui/material"
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined"
import dragImg from "assets/images/Drag_Drop.png"

import { resource } from "data/resource"
import ApiService from "services/app.service"
import APIsContext from "contexts/APIs/APIsContext"
import UtilsContext from "contexts/Utils/UtilsContext"


const FileHandle = ({ file, setFile, testDataStatus }) => {
  const boarderCss = {
    border: "1px dashed #096eb6",
    minHeight: "103px",
  }
  const fileInput = useRef()
  const [loader] = useState(false)
  const [wrapCss, setBoarder] = useState(boarderCss)
  const [fileSuccess, setFileSuccess] = useState(false)

  const apiContext = useContext(APIsContext)
  const utilsContext = useContext(UtilsContext)

  const { requestData, setRequestData } = apiContext
  const { showAlert, setTestData } = utilsContext

  const handleFileDrop = async (e) => {
    e.preventDefault()
    if (loader) { return }
    setFileSuccess(true)
    setFile()
    const files = e.dataTransfer.files
    if (files.length === 0) { return }
    setBoarder(boarderCss)
    setFileSuccess(true)
    setFile(files[0])
    if (testDataStatus) testDataFile(files[0])
  }

  const handleFileDragOver = (e) => {
    e.preventDefault()
    setBoarder({ border: "1px solid #1976d2" })
  }

  const handleFileDragEnter = (e) => {
    e.preventDefault()
    setBoarder({ border: "1px solid #1976d2" })
  }

  const handleFileDragLeave = (e) => {
    e.preventDefault()
    setBoarder(boarderCss)
  }

  const handleFileChange = (e) => {
    setFileSuccess(true)
    setFile(e.target.files[0])
    if (testDataStatus) testDataFile(e.target.files[0])
  }

  const testDataFile = async (file) => {
    try {
      const { data } = await ApiService.uploadFile3(file)
      showAlert({
        type: data.status,
        msg: data.message,
      })
      setRequestData({ ...requestData, testData: data.testData })
      setTestData(file.name)
    } catch (err) {
      console.log(err)
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      });
    }
  }

  return (
    <Box
      onDrop={(e) => handleFileDrop(e)}
      onDragOver={(e) => handleFileDragOver(e)}
      onDragEnter={(e) => handleFileDragEnter(e)}
      onDragLeave={(e) => handleFileDragLeave(e)}
    >
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Box
            className="fileTextbox"
            onClick={() => (loader ? "" : fileInput.current.click())}
            title={file ? file.name : "Choose a file"}
          >
            {file ? file.name : "choose_a_file"}

            {fileSuccess && (
              <FileDownloadDoneOutlinedIcon className="sucIcon" />
            )}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Button
            fullWidth
            size="small"
            sx={{ borderRadius: 0 }}
            onClick={() => fileInput.current.click()}
            variant="outlined"
            disabled={loader}
          >Browse</Button>
          <input
            ref={fileInput}
            onChange={handleFileChange}
            type="file"
            onClick={(e) => (e.target.value = null)}
            style={{ display: "none" }}
            accept={".json, .xml"}
          />
        </Grid>
      </Grid>

      <Box style={wrapCss} sx={{ textAlign: "center" }}>
        <Typography sx={{ m: "auto" }}>
          <img alt="loading.." src={dragImg} width="200px" />
        </Typography>
      </Box>
    </Box>
  )
}

export default FileHandle
