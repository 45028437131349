/* eslint-disable no-undef */
import { resource } from "./resource"
const autId = new URLSearchParams(document.location.search).get("aut")
const tsId = new URLSearchParams(document.location.search).get("testsuite")

const aut = {
    name: "",
    description: "",
    isPublic: true,
};

const ts = {
    name: "",
    description: "",
    baseURL: "",
    authenticationType: "none",
    bearerToken: "",
    autId: autId,
    basicAuth: {
        username: "",
        password: ""
    },
    apiToken: {
        key: "",
        value: "",
    },
};

const request = {
    name: "New Request",
    method: "GET",
    value: "/",
    bodyType: "none",
    baseURL: "",
    path: [],
    query: [],
    headers: [],
    authenticationType: "none",
    basicAuth: {
        username: "",
        password: ""
    },
    apiToken: {
        key: "",
        value: "",
        where: "header",
    },
    rawType: "json",
    rawData: "{}",
    form: {
        encrypt: "multipart/form-data",
        fields: [],
    },
    autId: autId

};

const test = {
    autId: autId,
    testSuiteId: tsId,
    name: resource.TC.DEF,
    description: resource.TC.NAME,
    source: "form",
    isSelected: true,
    functionalAssertions: [],
    performanceAssertions: {},
    securityAssertions: {},
};

const rule = {
    operation: "startsWith",
    expectedValue: "",
    isCaseSensitive: true,
};

const config = {
    name: "",
    description: "",
    delay: 0,
    environment: "",
    endpoints: []
};

const tsVar = {
    name: " ",
    value: " ",
    scope: "testSuite",
    responsePath: "",
    autId: autId,
    testSuiteId: tsId,
    endpointId: " "
};

const tsVar1 = {
    description: "",
    value: " ",
    defaultValue: "",
    type: "dynamic",
    scope: "testSuite",
    isMapped: false,
    responsePath: "",
    autId: autId,
    testSuiteId: tsId,
    // userId: JSON.parse(localStorage.getItem("APITEUserData")).UserId,
    // organizationId: JSON.parse(localStorage.getItem("APITEUserData")).OrganisationId,
};

const varData = [
    {
        variableName: "",
        variableValue: "",
    }
];

const validationRulesData = [
    {
        operator: "and",
        operation: "startsWith",
        expectedValue: "",
        isCaseSensitive: true,
        category: "functional",
        displayName: "Starts with",
        serialNumber: 1
    },
    {
        Id: 1,
        operator: "and",
        operation: "endsWith",
        category: "functional",
        expectedValue: "",
        isCaseSensitive: true,
        displayName: "Ends with",
        serialNumber: 1

    },
    {
        Id: 2,
        operator: "and",
        operation: "isExists",
        category: "functional",
        expectedValue: "",
        isCaseSensitive: true,
        serialNumber: 1,
        displayName: "Exists"
    },
    {
        Id: 3,
        operator: "and",
        operation: "contains",
        category: "functional",
        expectedValue: "",
        isCaseSensitive: true,
        serialNumber: 1,
        displayName: "Contains",
    },
    {
        Id: 4,
        expectedValue: "",
        operator: "and",
        operation: "equals",
        category: "functional",
        isCaseSensitive: true,
        serialNumber: 1,
        displayName: "Equals",
    },
    {
        Id: 5,
        expectedValue: "",
        operator: "and",
        operation: "existsInList",
        category: "functional",
        isCaseSensitive: true,
        serialNumber: 1,
        displayName: "Exists in list",
    },
    {
        Id: 6,
        operation: "lessThan",
        category: "functional",
        operator: "and",
        expectedValue: "",
        isCaseSensitive: true,
        serialNumber: 1,
        displayName: "Less than",
    },
    {
        Id: 7,
        operation: "lessThanEqualTo",
        category: "functional",
        expectedValue: "",
        isCaseSensitive: true,
        serialNumber: 1,
        operator: "and",
        displayName: "Less than equal to",
    },
    {
        Id: 8,
        operation: "greaterThan",
        category: "functional",
        expectedValue: "",
        isCaseSensitive: true,
        serialNumber: 1,
        operator: "and",
        displayName: "Greater than"
    },
    {
        Id: 9,
        operation: "greaterThanEqualTo",
        category: "functional",
        expectedValue: "",
        isCaseSensitive: true,
        serialNumber: 1,
        operator: "and",
        displayName: "Greater than equal to",
    },
    {
        Id: 10,
        operation: "minLength",
        category: "functional",
        expectedValue: "",
        isCaseSensitive: true,
        serialNumber: 1,
        operator: "and",
        displayName: "Min length of value",
    },
    {
        Id: 11,
        operation: "maxLength",
        category: "functional",
        expectedValue: "",
        isCaseSensitive: true,
        operator: "and",
        serialNumber: 1,
        displayName: "Max length of value"
    },
    {
        statusCode: {
            expectedValue: 200,
            isSelected: true,
        },
        operation: "statusCode",
        category: "security",
        displayName: "Response Status Code",
    },
    {
        contentType: {
            expectedValue: "application/json",
            isSelected: true,
        },
        operation: "contentType",
        category: "security",
        displayName: "Response Headers: Content Type",
    },
    {
        cookies: {
            rules: [],
            isSelected: true,
        },
        operation: "cookies",
        category: "security",
        displayName: "Response Cookie Name",
    },
    {
        responseTime: {
            expectedValue: 50,
            operation: "lessThan",
            isSelected: true,
        },
        operation: "responseTime",
        category: "performance",
        displayName: "Response Time (ms)",
    },
    {
        responseSize: {
            expectedValue: 50,
            operation: "lessThan",
            isSelected: true,
        },
        operation: "responseSize",
        category: "performance",
        displayName: "Response Size (KB)",
    },
];
const stateData = { aut, ts, tsVar, test, varData, config, request, tsVar1, rule, validationRulesData };
export default stateData;