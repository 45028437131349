import { useContext, useState, useEffect } from "react"
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Snackbar,
  Typography
} from "@mui/material"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import { resource } from "data/resource"
import InnerHeader from "components/Utils/InnerHeader"
import CreateVariableForm from "components/Forms/VariableForm"
import SearchVariables from "components/Variables/SearchVariables"
import CategoryVariablesTiles from "components/Variables/CategoryVariablesTiles"

import AutContext from "contexts/Aut/AutContext"
import APIsContext from "contexts/APIs/APIsContext"
import VarContext from "contexts/Variables/VarContext"
import UtilsContext from "contexts/Utils/UtilsContext"
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext"


export default function EnvVariables() {

  const autContext = useContext(AutContext)
  const varContext = useContext(VarContext)
  const apisContext = useContext(APIsContext)
  const utilsContext = useContext(UtilsContext)
  const testSuiteContext = useContext(TestSuiteContext)

  const { auts } = autContext
  const { curr, setCurr, setSaved } = apisContext
  const { setTestSuite } = testSuiteContext
  const { getVars, envName, setEnvName, visible_var } = varContext
  const {
    alert,
    removeAlert,
    edit,
    setEdit,
    deleteAll,
    setDeleteAll,
    delValues,
    setDelValues,
    changes
  } = utilsContext;

  const [envs, setEnvs] = useState([])
  const [selectAut, setSelectAut] = useState(null)
  const [selectEnv, setSelectEnv] = useState(null)
  const [showDetails, setShowDetails] = useState(null)

  const onclickCard = (e) => {
    setShowDetails(e)
    !selectAut && setSelectAut(auts?.[0]?._id)
  }

  const onCloseHandle = () => {
    if (changes) setSaved(false)
    else setShowDetails(null)
  }

  useEffect(() => {
    getVars();
    setTestSuite(null);
    edit && setEdit(null);
    curr && setCurr(null);
    envName && setEnvName(null);
    deleteAll && setDeleteAll(false);
    delValues.length && setDelValues([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="fullBox">
      {alert !== null && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
          className="snackbar2"
        >
          <Alert severity={alert.type} onClose={() => removeAlert()}> {alert.msg}</Alert>
        </Snackbar>
      )}

      <InnerHeader>
        <Typography variant="h6">Variables</Typography>
        <Typography variant="body1" ml="auto">
          <b>Note: </b>
          {resource.VAR}&nbsp; <b>&#123;&#123;</b> &nbsp;{resource.VAR2}
        </Typography>
      </InnerHeader>

      <Box className="varMain" pt={8}>
        <CategoryVariablesTiles
          envs={envs}
          setEnvs={setEnvs}
          onclickCard={onclickCard} />

        {showDetails &&
          <Box className="ecContainer">
            <Grid container alignItems="center" px={0.5} py={0.5}>
              <Grid sm={12} textAlign="right" item>
                <IconButton size="small" color="error" onClick={onCloseHandle}>
                  <CancelOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container className="ecRulesColumns" columnSpacing={0}>

              {showDetails?.scope !== "global" &&
                <SearchVariables
                  envs={envs}
                  setEnvs={setEnvs}
                  obj={showDetails}
                  selectAut={selectAut}
                  selectEnv={selectEnv}
                  setSelectAut={setSelectAut}
                  setSelectEnv={setSelectEnv}
                />}

              <Grid item sm={showDetails?.scope === "global" ? 12 : 9}>
                <Typography className="testHeader" sx={{ p: 1.1 }}>Variables</Typography>
                {showDetails?._id === visible_var &&
                  <CreateVariableForm
                    envs={envs}
                    setEnvs={setEnvs}
                    obj={showDetails}
                    selectAut={selectAut}
                    selectEnv={selectEnv}
                    setSelectEnv={setSelectEnv} />
                }
              </Grid>

            </Grid>
          </Box>
        }
      </Box>
    </Box >
  );
}
