export const allVars = [
  {
    "_id": 1,
    "categoryName": "Global Level Variable",
    "scope": "global",
    "description": "View and Manage Global Level Variables.",
    "variablecount": 0
  },
  {
    "_id": 2,
    "categoryName": "AUT Level Variable",
    "scope": "AUT",
    "description": "View and Manage AUT Level Variables.",
    "variablecount": 0
  },
  {
    "_id": 3,
    "categoryName": "Environment Variable",
    "scope": "environment",
    "description": "View and Manage Environment variables.",
    "variablecount": 0
  }
];

export const GlobalheadCells = [
  {
    id: 1,
    label: "Variable",
  },
  {
    id: 2,
    label: "Value",
  },
  {
    id: 3,
    label: "Manage",
  }
];

export const AUTHeadCells = [
  {
    id: 1,
    label: "AUT",
  },
  {
    id: 2,
    label: "Variable",
  },
  {
    id: 3,
    label: "Value",
  },
  {
    id: 4,
    label: "Manage",
  }
];

export const EnvheadCells = [
  {
    id: 1,
    label: "AUT",
  },
  {
    id: 2,
    label: "Environment",
  },
  {
    id: 3,
    label: "Variable",
  },
  {
    id: 4,
    label: "Value",
  },
  {
    id: 5,
    label: "Manage",
  },
];

export const AUTheadCells = [
  {
    // field: "name",
    id: 1,
    label: "Variable",
    // flex: 1,
    // minWidth: 100,
  },
  {
    // field: "value",
    id: 2,
    label: "Value",
    // flex: 1,
    // minWidth: 100,
  },
  {
    // field: "value",
    id: 3,
    label: "Manage",
    // flex: 1,
    // minWidth: 100,
  },
];

export const TSVheadCells = [
  {
    // field: "name",
    id: 1,
    label: "Variable",
    // flex: 1,
    // minWidth: 100,
  },
  {
    // field: "value",
    id: 2,
    label: "Value",
    // flex: 1,
    // minWidth: 100,
  },
  {
    // field: "responsePath",
    id: 3,
    label: "Mapping Key",
    // flex: 1,
    // minWidth: 100,
  },
  {
    // field: "endpoint",
    id: 4,
    label: "API",
    // flex: 1,
    // minWidth: 100,
  }
];
