import { useReducer } from "react"
import { PropTypes } from "prop-types"
import ExecutionContext from "./ExecutionContext"
import ExecutionReducer from "./ExecutionReducer"

import ApiService from "services/app.service";
import { userLocalSession } from "_helpers/Constant"

import { SET_ADD, SET_ALL_CONFIGS, SET_CONFIG, SET_CONFIGS, SET_CURRENT } from "../types.js";

function ExecutionState(props) {
    const initialState = {
        configs: [],
        allConfigs: [],
        config: null,
        currConfig: "",
        add: null
    };
    const [state, dispatch] = useReducer(ExecutionReducer, initialState);

    const setAdd = (data) => {
        dispatch({
            type: SET_ADD,
            payload: data
        });
    };

    const setCurrConfig = (data) => {
        dispatch({
            type: SET_CURRENT,
            payload: data
        });
    };

    const setConfig = (data) => {
        dispatch({
            type: SET_CONFIG,
            payload: data
        });
    };

    const setConfigs = (data) => {
        dispatch({
            type: SET_CONFIGS,
            payload: data
        });
    };

    const setAllConfigs = (data) => {
        dispatch({
            type: SET_ALL_CONFIGS,
            payload: data
        });
    };

    const getConfig = async (id) => {
        try {
            const { data } = await ApiService.fetchConfig(id)
            setConfig(data.configuration)
        } catch (err) {
            console.log(err.response.data);
        }
    };

    const getConfigs = async (id) => {
        try {
            const { data } = await ApiService.fetchConfigs(id)
            setConfigs(data.configurations)
            // console.log(data.configurations, 'ts configs')
        } catch (err) {
            console.log(err.response.data);
            // if (err.response?.data?.error === "jwtExpired") {
            //     localStorage.removeItem(userLocalSession);
            //     window.location.href = "/login"
            // }
        }
    };

    const getAllConfigs = async (id) => {
        try {
            const { data } = await ApiService.fetchAllConfigs(id)
            setAllConfigs(data.configurations)
            // console.log(data.configurations, 'all configs')
        } catch (err) {
            console.log(err.response.data);
            // if (err.response?.data?.error === "jwtExpired") {
            //     localStorage.removeItem(userLocalSession);
            //     window.location.href = "/login"
            // }
        }
    };

    return (
        <ExecutionContext.Provider
            value={{
                add: state.add,
                config: state.config,
                configs: state.configs,
                allConfigs: state.allConfigs,
                currConfig: state.currConfig,
                setAdd,
                setCurrConfig,
                setConfig,
                getConfig,
                setConfigs,
                getConfigs,
                setAllConfigs,
                getAllConfigs,
            }}
        >
            {props.children}
        </ExecutionContext.Provider>
    );
}

ExecutionState.propTypes = {
    children: PropTypes.node
};

export default ExecutionState;