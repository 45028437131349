import { EDIT_VAR, SET_AUTVARS, SET_ENV, SET_ENVS, SET_ENV_NAME, DEL_VAR, SET_GLOBALS, SET_TSVAR, SET_TSVARS, ALL_VARS, INP_VALUE, VISIBLE_VAR, NEW_VAR, SET_ENVNAMES } from "../types.js";

const VarReducer = (state, action) => {
    switch (action.type) {
        case SET_TSVARS:
            return {
                ...state,
                tsVars: action.payload,
            };
        case SET_AUTVARS:
            return {
                ...state,
                autVars: action.payload,
            };
        case SET_GLOBALS:
            return {
                ...state,
                globalVars: action.payload,
            };
        case SET_ENV:
            return {
                ...state,
                env: action.payload,
            };
        case SET_ENVS:
            return {
                ...state,
                envs: action.payload,
            };
        case SET_ENVNAMES:
            return {
                ...state,
                envNames: action.payload,
            };
        case SET_ENV_NAME:
            return {
                ...state,
                envName: action.payload,
            };
        case SET_TSVAR:
            return {
                ...state,
                tsVar: action.payload,
            };
        case EDIT_VAR:
            return {
                ...state,
                editVar: action.payload,
            };
        case DEL_VAR:
            return {
                ...state,
                delVar: action.payload,
            };
        case ALL_VARS:
            return {
                ...state,
                vars: action.payload,
            };
        case INP_VALUE:
            return {
                ...state,
                inputValue: action.payload,
            };
        case VISIBLE_VAR:
            return {
                ...state,
                visible_var: action.payload,
            };
        case NEW_VAR:
            return {
                ...state,
                new_var: action.payload,
            };
        default:
            return state;
    }
};

export default VarReducer;