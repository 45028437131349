import { SET_AUT, SET_AUTS, AUT_ID } from "../types.js";

const AutReducer = (state, action) => {
    switch (action.type) {
        case SET_AUTS:
            return {
                ...state,
                auts: action.payload,
            };
        case SET_AUT:
            return {
                ...state,
                aut: action.payload,
            };
        case AUT_ID:
            return {
                ...state,
                autId: action.payload,
            };

        default:
            return state;
    }
};

export default AutReducer;