import PropTypes from "prop-types"
import {
    Box,
    Grid,
    IconButton,
    Tab, Tabs,
    Typography,
} from "@mui/material"
import { useState, useContext } from "react"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"

import Body from "./Body";
import Header from "./Header";
import Cookies from "./Cookies";
import TestResult from "./TestResult";

import { resource } from "data/resource";
import APIsContext from "contexts/APIs/APIsContext";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}>
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function Response() {

    const apisContext = useContext(APIsContext);
    const { res, setResponse } = apisContext;

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box>
            <Box display="flex"
                justifyContent="space-between"
                alignItems="flex-start">
                <Typography variant="h5" sx={{ color: "#54577d" }}>{resource.RESP}</Typography>
            </Box>

            <IconButton
                sx={{ position: "absolute", top: "2px", right: "2px" }}
                onClick={() => setResponse(false)}
                size="small"
                color="error"
            >
                <CancelOutlinedIcon />
            </IconButton>

            <Box>
                <Box>
                    <Grid item container >
                        <Grid item sm={12}>
                            <Tabs value={value} onChange={handleChange}
                                className="resTabs"
                                TabIndicatorProps={{
                                    sx: {
                                        width: value === 0 ? "8% !important" : (value === 3 ? "16% !important" : (value === 1 ? "11.5% !important" : "15% !important")),
                                        marginLeft: "2.2%",
                                    },
                                }}>
                                <Tab label="Body" className="resTab0" />
                                <Tab label="Cookies" className='resTab' />
                                <Tab label={res ? `Headers (${res?.headers?.length ? res.headers.length : "0"})` : "Headers"} className="resTab1" />
                                <Tab label="Test Results" className='resTab' />
                            </Tabs>
                        </Grid>
                    </Grid>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Body />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Cookies />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Header />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <TestResult />
                </CustomTabPanel>
            </Box>
        </Box>
    );
}
