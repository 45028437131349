/* eslint-disable react/prop-types */
import { useContext } from "react";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  TextField, Typography
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import rule from "data/test";
import { handleDragOver } from "hooks";
import { resource } from "data/resource";
import UtilsContext from "contexts/Utils/UtilsContext";
import TestCaseContext from "contexts/TestCase/TestCaseContext";

function Performance() {
  const min = 0;
  const max = 60000;

  const utilsContext = useContext(UtilsContext);
  const testCaseContext = useContext(TestCaseContext);

  const { changes, setChanges, showAlert } = utilsContext;
  const { testcaseData, setTestcaseData } = testCaseContext;

  const onChangeHandler = (e) => {
    !changes && setChanges(true);
    setTestcaseData({
      ...testcaseData,
      performanceAssertions: {
        ...testcaseData.performanceAssertions,
        [e.target.name.split("-")[0]]: {
          ...testcaseData.performanceAssertions[e.target.name.split("-")[0]],
          [e.target.name.split("-")[1]]: e.target.value,
        },
      },
    });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const stringData = event.dataTransfer.getData("ruleData");
    const draggedItem = stringData && JSON?.parse?.(stringData);
    if (draggedItem) {
      const isItemAlreadyPresent = testcaseData.performanceAssertions[draggedItem.operation];
      if (!isItemAlreadyPresent) {
        !changes && setChanges(true);
        setTestcaseData({
          ...testcaseData,
          performanceAssertions: {
            ...testcaseData.performanceAssertions,
            [draggedItem.operation]: draggedItem[draggedItem.operation]
          }
        });
      }
      else
        { showAlert({
          msg: "Rule Check already exists",
          type: "warning",
        }); }
    }
  };

  const deleteRule = (item) => {
    !changes && setChanges(true)
    const newPerfAssertion = { ...testcaseData.performanceAssertions }
    delete newPerfAssertion[item]
    setTestcaseData({
      ...testcaseData,
      performanceAssertions: {
        ...newPerfAssertion
      }
    });
  };
  return (

    <Box sx={{ px: 1 }}>
      <Grid
        container
        className="ecContainers"
      >
        <Grid item md={12}>
          <Typography
            className="ecTitleHead"
            sx={{ borderTopRightRadius: "12px", borderTopLeftRadius: "12px", }}
          >
            {resource.TC.RULE}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        md={12}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        overflow={"auto"}
        border={"1px solid #cfcfcf"}
      >
        {Object?.entries(testcaseData?.performanceAssertions)?.map((each, i) =>
          each[0] !== "_id" &&
          <Box sx={{ height: "100%" }} key={i}>
            <Box
              sx={{
                color: "#54577d",
                margin: "10px",
                padding: "10px",
                borderRadius: "4px",
                position: "relative",
                alignItems: "center",
                background: "#ede8ff",
              }}>
              <IconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  "& svg": { fontSize: "16px" },
                }}
                size="small"
                color="error"
                onClick={() => deleteRule(each[0])}
              >
                <CancelOutlinedIcon />
              </IconButton>
              <Grid container>
                <Grid item sm={3}>
                  <Typography variant="h3" sx={{ fontSize: "15px" }}>{rule.perfData[each[0]]}</Typography>
                </Grid>
                <Grid item sm={9}>
                  <TextField
                    select
                    size="small"
                    autoComplete='off'
                    variant="outlined"
                    sx={{ marginRight: "10px" }}
                    name={`${each[0]}-operation`}
                    onChange={onChangeHandler}
                    value={testcaseData.performanceAssertions[`${each[0]}`]?.operation || "lessThan"}
                  >
                    {rule.perfRuleData.map(el => <MenuItem key={el.value} value={el.value}>{el.name}</MenuItem>)}
                  </TextField>

                  <TextField
                    type="number"
                    size="small"
                    autoComplete='off'
                    variant="outlined"
                    name={`${each[0]}-expectedValue`}
                    inputProps={{ style: { min, max } }}
                    onChange={onChangeHandler}
                    value={testcaseData.performanceAssertions[`${each[0]}`]?.expectedValue}
                  />
                </Grid>
              </Grid >
            </Box>
          </Box>
        )}
        {Object?.keys(testcaseData?.performanceAssertions)?.length < 3 && <Grid item md={12} p={1}>
          <Box className="v-center">
            <Box
              className="v-center h-center"
              sx={{ p: 3, border: "1px dashed #c8b8ff", flexGrow: 1 }}
            >
              Drag & Drop Rules here
            </Box>
          </Box>
        </Grid>}
      </Grid>
    </Box >
  );
}

export default Performance;
