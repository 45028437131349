import { useState, useEffect } from "react"
import PropTypes from "prop-types"

const ErrorBoundary = ({ children }) => {
    const [error, setError] = useState(null)

    useEffect(() => {
        const unhandledRejectionHandler = (event) => {
            setError(event.reason || new Error("Unhandled Promise Rejection"))
        };

        window.addEventListener("unhandledrejection", unhandledRejectionHandler)

        return () => {
            window.removeEventListener("unhandledrejection", unhandledRejectionHandler)
        }
    }, [])

    if (error) {
        return (
            <div>
                <h1>Something went wrong...</h1>
                <p>{error.toString()}</p>
                <p>Refresh the page or please try again later.</p>
            </div>
        )
    }

    return children
}

export default ErrorBoundary

ErrorBoundary.propTypes = {
    children: PropTypes.node
}
