import { Box, TextField } from "@mui/material"
import { PropTypes } from "prop-types"
import XMLViewer from "react-xml-viewer"
import JSONInput from "react-json-editor-ajrm"
import locale from "react-json-editor-ajrm/locale/en"

function Body({ res }) {
    return (
        <Box className='responseTab'>
            {(res?.response?.headers?.find(e => e.name === "Content-Type")?.value?.includes("application/json")) ?
                <JSONInput
                    height={302}
                    width='100%'
                    locale={locale}
                    viewOnly={true}
                    colors={{ default: "#4a6a87" }}
                    theme={"light_mitsuketa_tribute"}
                    placeholder={res?.response?.data ? res.response.data : (res?.response?.message ? res.response.message : { error: res?.error })}
                />
                :
                ((res?.response?.headers?.find(e => e.name === "Content-Type")?.value?.includes("application/xml")) ?
                    <Box sx={{ backgroundColor: "white", padding: "10px", minHeight: "448px" }}>
                        <XMLViewer xml={res?.response?.data} />
                    </Box>
                    :
                    <TextField
                        fullWidth
                        size="small"
                        name="Body"
                        minRows={20}
                        multiline
                        autoComplete='off'
                        variant="standard"
                        defaultValue={res?.response?.data}
                        InputProps={{
                            disableUnderline: true,
                            style: { color: (/<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(res?.response?.data)) ? "blue" : (res?.error ? "red" : "black") }
                        }}
                    />
                )
            }
        </Box>
    );
}

export default Body

Body.propTypes = {
    res: PropTypes.object
}
