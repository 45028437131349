import { useContext } from "react"
import { PropTypes } from "prop-types"
import { styled } from "@mui/material/styles"
import {
    Box,
    IconButton,
    Modal,
    Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses, Typography
} from "@mui/material"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"

import { resource } from "data/resource"
import APIsContext from "contexts/APIs/APIsContext"

const style = {
    height: "97.5%",
    overflowY: "scroll",
    overflowX: "scroll",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "#f4f1ff",
    p: "0px 30px 30px 30px"
}

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#dcd1ff",
        border: "1px solid #dcd1ff",
        color: "#54577d",
        padding: "7px",
        fontSize: 13,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12.2,
        padding: "13px",
        color: "#54577d",
        border: 0,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#f6f2fa",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#ede8ff",
    },
    cursor: "pointer",
}));

export default function TestDataPreview({ preview, setPreview }) {

    const apiContext = useContext(APIsContext)
    const { requestData } = apiContext

    return (
        <Box>
            <Modal
                open={preview}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">

                <Box sx={style} className="scrollbarStyle">
                    <IconButton
                        sx={{
                            position: "sticky", top: 0, left: 1300, zIndex: 1, marginRight: "-27px"
                        }}
                        onClick={() => setPreview(false)}
                        size="small"
                        color="error"
                    >
                        <CancelOutlinedIcon />
                    </IconButton>
                    <Typography variant="h5" sx={{ color: "#54577d" }}>{"PREVIEW DATA"}</Typography>
                    <TableContainer component={Paper} sx={{ marginTop: 1 }}>
                        <Table className="customized_table" >
                            <TableHead >
                                <TableRow >
                                    <StyledTableCell align="center">{resource.ITE}</StyledTableCell>
                                    {Object.keys(JSON.parse(requestData.testData[0])).map((e, i) =>
                                        <StyledTableCell align="center" key={i}>{e}</StyledTableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {requestData.testData.map((e, i) =>
                                    <StyledTableRow key={i}>
                                        <StyledTableCell align="center">{i + 1}</StyledTableCell>
                                        {Object.keys(JSON.parse(requestData.testData[0])).map((em, j) =>
                                            <StyledTableCell align="center" key={j}>{JSON.parse(e)[em] ?? "-"}</StyledTableCell>
                                        )}
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </Box>
    )
}

TestDataPreview.propTypes = {
    preview: PropTypes.bool,
    setPreview: PropTypes.func
}