import { useState, useContext, useEffect } from "react"
import { PropTypes } from "prop-types"
import { useNavigate } from "react-router-dom"
import { Box, Typography } from "@mui/material"
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded"
import List from "./List"
import APIsContext from "contexts/APIs/APIsContext"
import UtilsContext from "contexts/Utils/UtilsContext"
import TestCaseContext from "contexts/TestCase/TestCaseContext"
import ExecutionContext from "contexts/Execution/ExecutionContext"

const TSRunOrder = ({ configData, setConfigData, tsIds }) => {
  const navigate = useNavigate();
  const autId = new URLSearchParams(document.location.search).get("aut");

  const apisContext = useContext(APIsContext);
  const utilsContext = useContext(UtilsContext);
  const testCaseContext = useContext(TestCaseContext);
  const executionContext = useContext(ExecutionContext);

  const { reqs } = apisContext
  const { setOpens } = utilsContext
  const { editTC } = testCaseContext
  const { config, configs, getConfig } = executionContext

  const [dragId, setDragId] = useState();

  const getEndpoints = () => {
    return reqs.map((e, i) => ({
      name: e.name,
      rawType: e.rawType,
      rawData: e.rawData,
      tc: e.testCases.filter(el => !el.isDeleted).map(el => ({ name: el.name, isSelected: el.isSelected })),
      isSelected: true,
      endpointId: e._id,
      isDefaultTestData: true,
      testCaseIds: e.testCases.filter(el => !el.isDeleted).map(id => id._id),
      serialNumber: i + 1
    }));
  };

  useEffect(() => {
    if (!config && editTC) getConfig(editTC)
    const endpoints = getEndpoints()

    if (config && editTC) {
      const item = endpoints.map(e => {
        const foundEndpoint = config.endpoints.find(el => el.endpointId === e.endpointId)
        if (foundEndpoint) return ({ ...e, tc: foundEndpoint.tc })
        return ({ ...e, isSelected: false, tc: e.tc.map(el => ({ ...el, isSelected: false })) })
      })
      const count = configs.reduce((tot, curr) => {
        // eslint-disable-next-line no-param-reassign
        if (curr.name?.split(" copy")[0] === config.name?.split(" copy")[0]) return tot + 1
        return tot
      }, 0)
      const name = `${config.name.split(" copy")[0]} copy ${count}`
      setConfigData({
        ...configData,
        name: name,
        delay: config.delay,
        description: config.description,
        environment: config.environment,
        endpoints: item
      });
    }
    else if (!editTC) setConfigData({ ...configData, endpoints })

  }, [config]);

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const dragItem = configData.endpoints.find((box) => box.name === dragId);
    const dropItem = configData.endpoints.find((box) => box.name === ev.currentTarget.id);

    const dragItemOrder = dragItem.serialNumber;
    const dropItemOrder = dropItem.serialNumber;

    const newBoxState = configData.endpoints.map((box) => {
      if (box.name === dragId)
        { box.serialNumber = dropItemOrder; }
      if (box.name === ev.currentTarget.id)
        { box.serialNumber = dragItemOrder; }
      return box;
    });

    setConfigData({
      ...configData,
      endpoints: newBoxState
    });
  };

  return (
    <Box className="runOrder">
      {configData.endpoints.length ? configData.endpoints
        .sort((a, b) => a.serialNumber - b.serialNumber)
        .map((box, i) => (
          <List
            key={box.endpointId}
            endpoint={box}
            index={i}
            configData={configData}
            setConfigData={setConfigData}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
          />
        )) :
        <Box sx={{ marginTop: "50%" }}>
          <center>
            No requests found in the API Suite. <br />
            <Box >
              <Typography sx={{ pt: 1, cursor: "pointer" }} onClick={() => {
                setOpens(false);
                navigate(`/addrequest?aut=${autId}&testsuite=${tsIds}`);
              }}>
                Create New Request <ArrowForwardIosRoundedIcon />
              </Typography>
            </Box>
          </center>
        </Box>
      }
    </Box>
  );
};

export default TSRunOrder

TSRunOrder.propTypes = {
  configData: PropTypes.object,
  setConfigData: PropTypes.func,
  tsIds: PropTypes.string
}
