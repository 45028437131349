export const ConfigExeResultheadCells = [
  {
    field: "createdAt",
    valueGetter: (params) => new Date(params.data.createdAt).toLocaleString(),
    headerName: "Execution At",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "duration",
    headerName: "Duration (ms)",
    flex: 1,
    minWidth: 100
  }
];
