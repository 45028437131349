import { useContext } from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import {
  Box,
  Divider,
  ListItem, ListItemIcon, ListItemText,
  Tooltip
} from "@mui/material"
import PinIcon from "@mui/icons-material/Pin"
import LocalMallIcon from "@mui/icons-material/LocalMall"
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined"
import AddLinkOutlinedIcon from "@mui/icons-material/AddLinkOutlined"
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded"
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined"

import UtilsContext from "contexts/Utils/UtilsContext"
import darkLogo from "assets/images/API_testeasy-logo-01.png"
import whiteLogo from "assets/images/API_testeasy-logo-white-01.png"

export default function SideMenu({ openSideMenu }) {

  const utilsContext = useContext(UtilsContext);
  const { changes, setChanges } = utilsContext;

  const routes = [
    { to: "/", text: "AUTs", icon: AddLinkOutlinedIcon, status: true },
    {
      to: "/testsuites",
      text: "API Suites",
      icon: LocalMallIcon,
      status: true,
    },
    {
      to: "/variables",
      text: "Variables",
      icon: PinIcon,
      status: true,
    },
    {
      to: "/dashboard",
      text: "Dashboard",
      icon: DashboardRoundedIcon,
      status: true,
    },
    {
      to: "/scheduledlist",
      text: "Schedule Test",
      icon: TimerOutlinedIcon,
      status: true,
    },
    {
      to: "/loadtest",
      text: "Load Testing",
      icon: DataThresholdingOutlinedIcon,
      status: true,
    },
  ];

  return (
    <Box sx={{}}>
      <Box className="center">
        {openSideMenu ?
          <img src={darkLogo} height="50px" alt="logo-notavailable" />
          :
          <img src={whiteLogo} height="50px" alt="logo-notavailable" style={{ marginLeft: "63px", marginTop: "10px" }} />
        }
      </Box>
      <Divider sx={{ my: 1, mb: 2.2 }} />
      {routes
        .filter((e) => e.status)
        .map((route, index) => (
          <NavLink key={index} to={route.to} style={{ textDecoration: "none" }}>
            <ListItem button onClick={() => changes && setChanges(false)}>
              <Tooltip title={route.text} placement="top-end" arrow>
                <ListItemIcon>{<route.icon />}</ListItemIcon>
              </Tooltip>
              <ListItemText primary={route.text} />
            </ListItem>
          </NavLink>
        ))}
    </Box>
  );
}

SideMenu.propTypes = {
  openSideMenu: PropTypes.bool
}

