import { useContext } from "react"
import axios from "axios"
import { PropTypes } from "prop-types"
import { styled } from "@mui/material/styles"
import { ReactSortable } from "react-sortablejs"
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  List, ListItemButton, ListItemText,
  Paper,
  Typography, Tooltip,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/DeleteRounded"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecordRounded"

import stateData from "data/stateData"
import ApiListItem from "./ApiListItem"
import { resource } from "data/resource"
import ApiService from "services/app.service"
import ExecuteTestSuite from "pages/TSExecution"

import APIsContext from "contexts/APIs/APIsContext"
import UtilsContext from "contexts/Utils/UtilsContext"
import VarContext from "contexts/Variables/VarContext.js"
import TestCaseContext from "contexts/TestCase/TestCaseContext"
import ExecutionContext from "contexts/Execution/ExecutionContext"
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext"
import TestResultContext from "contexts/TestResult/TestResultContext"

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
})

export default function SideApiList({ setExecute }) {
  const autId = new URLSearchParams(document.location.search).get("aut")
  const testSuiteId = new URLSearchParams(document.location.search).get("testsuite")

  const varContext = useContext(VarContext)
  const apisContext = useContext(APIsContext)
  const utilsContext = useContext(UtilsContext)
  const testCaseContext = useContext(TestCaseContext)
  const executionContext = useContext(ExecutionContext)
  const testSuiteContext = useContext(TestSuiteContext)
  const testResultContext = useContext(TestResultContext)

  const { configs } = executionContext
  const { testSuite } = testSuiteContext
  const { getExesTS } = testResultContext
  const { setTestCases } = testCaseContext
  const { envName } = varContext
  const { add, reqs, setAdd, setRequestData, setReq, setReqs } = apisContext
  const { setEdit, deleteAll, setDeleteAll, setDelete, delValues, setDelValues, changes, setChanges, showAlert, setExeLoading1, removeExeLoading1, setTestData } = utilsContext

  const execute = async () => {
    try {
      setExeLoading1()
      const { data } = await axios.get(`/api/v1/configuration/execute/?id=${configs[0]._id}&environment=${envName}`,
        { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
      )
      getExesTS(data.configuration._id)
      removeExeLoading1()
      setExecute(data)
    } catch (err) {
      removeExeLoading1()
      console.log(err.response.data)
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      })
    }
  }

  const onReArrangeRuleChecks = async (newRuleList) => {
    setReqs([...newRuleList.map((e, ind) => ({ ...e, serialNumber: ind + 1 }))])
  }

  const handleEnd = async () => {
    const { data } = await ApiService.updateBulkEndpoint([...reqs])
    showAlert({
      type: data.status,
      msg: data.message,
    })
  }

  return (
    <Box className="apiList scrollbarStyle" sx={{ overflowY: "scroll" }} ml={-1}>
      <Paper elevation={0}>
        <Box display="flex" alignItems="center" px={1.5} py={1.45}>
          <Typography variant="h5">
            {Boolean(reqs.length) && (
              <Checkbox
                checked={delValues.length === reqs.length}
                onChange={(e) => {
                  setDeleteAll(!deleteAll)
                  e.target.checked ? setDelValues([...reqs.map(e => e._id)]) : setDelValues([])
                }}
              />
            )}{" "}
            {resource.TS.NAME}
          </Typography>

          <Box display="flex" alignItems="center" ml="auto">
            <Box>
              <IconButton
                size="small"
                onClick={execute}
                sx={{ color: "#6580e0", fontSize: "20px" }}
                disabled={!reqs?.length && true}
              >
                <Tooltip title="Execute API Suite">
                  <PlayCircleOutlineIcon />
                </Tooltip>
              </IconButton>
              <ExecuteTestSuite />
            </Box>

            <Tooltip title={!changes ? "New Request" : "Save/Unsave current request to create new"}>
              <Box>
                <IconButton
                  size="small"
                  disabled={changes}
                  sx={{ color: "#611EB6", fontSize: "20px" }}
                  onClick={() => {
                    !changes && setChanges(true)
                    setAdd(true)
                    setReq(null)
                    setTestData(null)
                    setEdit(null)
                    setTestCases([])
                    setRequestData({
                      ...stateData.request,
                      nameId: Date.now().toString(),
                      baseURL: testSuite?.baseURL,
                      protocol: testSuite?.protocol,
                      serialNumber: reqs.length + 1,
                      testSuiteId,
                      autId
                    })
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Box>
            </Tooltip>

            {Boolean(reqs.length) && deleteAll && Boolean(delValues.length) &&
              <Tooltip title={!changes ? "Delete Request/s" : "Save/Unsave current request to delete"}>
                <Box>
                  <IconButton
                    size="small"
                    disabled={changes}
                    sx={{
                      float: "right",
                      color: "#cf2b2b",
                      fontSize: "20px"
                    }}
                    onClick={() => setDelete(true)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            }

          </Box >
        </Box >

        <Divider />
        <FireNav component="nav" disablePadding>
          <ReactSortable
            animation={200}
            easing="ease-out"
            delayOnTouchStart={true}
            list={reqs || []}
            onEnd={handleEnd}
            setList={newList => onReArrangeRuleChecks(newList)}
          >
            {reqs.sort((a, b) => a.serialNumber - b.serialNumber).map((ele) => (
              <ApiListItem obj={ele} key={ele._id} />
            ))}
          </ReactSortable>

          {
            add && <Box
              sx={{
                bgcolor: "#fff",
                borderLeft: "3px solid #1976d2",
                "& .MuiTypography-root": {
                  fontWeight: "600",
                },
              }}
            >
              <ListItemButton className="apiName">
                <ListItemText primary={<div style={{ marginLeft: "58px" }}>
                  New Request
                </div>}
                  secondary={<Typography style={{ marginTop: "-18px", color: "green" }}>
                    <b>GET</b>
                  </Typography>} />
                <FiberManualRecordIcon color="error" className="unsavedIndicator" />
                <MoreHorizOutlinedIcon />
              </ListItemButton>
            </Box>
          }
        </FireNav >
      </Paper >
    </Box >
  )
}

SideApiList.propTypes = {
  setExecute: PropTypes.func
}