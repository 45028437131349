/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useContext, useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import {
  Box, Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField, Typography
} from "@mui/material";

import { FormTabs } from "./FormTabs";
import stateData from "data/stateData";
import { resource } from "data/resource";
import ApiService from "services/app.service";
import UtilsContext from "contexts/Utils/UtilsContext";
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext";

export default function CreateTSForm({ retVal }) {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const autId = new URLSearchParams(document.location.search).get("aut")

  const utilsContext = useContext(UtilsContext);
  const testSuiteContext = useContext(TestSuiteContext);

  const { showAlert, edit, setEdit } = utilsContext;
  const { testSuites, testSuite, setTestSuite, getTestSuites, getTestSuite } = testSuiteContext;

  const [check, setcheck] = useState(false);
  const [testSuiteData, settestSuiteData] = useState({ ...stateData.ts, autId: autId });

  useEffect(() => {
    if (!testSuite && edit) { getTestSuite(edit); }
    else if (edit) {
      settestSuiteData({
        name: testSuite.name,
        description: testSuite?.description,
        baseURL: testSuite.baseURL,
        authenticationType: testSuite.authenticationType,
        bearerToken: testSuite?.bearerToken,
        basicAuth: {
          username: testSuite.basicAuth?.username,
          password: testSuite.basicAuth?.password
        },
        apiToken: {
          key: testSuite.apiToken?.key,
          value: testSuite.apiToken?.value
        }
      });
    }
    testSuite?.authenticationType !== "none" ? setcheck(true) : setcheck(false);
  }, [testSuite]);

  const BearerToken = {
    tab1: "Apply Token",
    tab2: "Obtain Token From API",
    labelName: "Bearer Token",
  };

  const onChangeHandler = (e) => {
    settestSuiteData({
      ...testSuiteData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async () => {
    try {
      testSuiteData.name = testSuiteData.name.trim()
      testSuiteData.description = testSuiteData.description.trim()
      const { data } = await ApiService.createTs(testSuiteData)
      showAlert({
        type: data.status,
        msg: data.message,
      });
      setTimeout(() => {
        getTestSuites(autId);
        retVal(false);
      }, resource.TIMEOUT.SUCCESS);
    } catch (err) {
      console.log(err.response.data);
      // if (!err.response.data.error.errors)
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      });
    }
  };

  const editSubmitHandler = async () => {
    try {
      testSuiteData.name = testSuiteData.name.trim();
      testSuiteData.description = testSuiteData.description.trim();
      const { data } = await ApiService.updateTs(edit, testSuiteData);
      showAlert({
        type: data.status,
        msg: data.message,
      });
      setTimeout(() => {
        retVal(false);
        setEdit(null);
        setTestSuite(null);
        getTestSuites(autId);
      }, resource.TIMEOUT.SUCCESS);
    } catch (err) {
      if (!err.response.data.error.errors)
        showAlert({
          type: "error",
          msg: "API Suite name already exists",
        })
      else {
        showAlert({
          type: "error",
          msg: err.response.data.message ? err.response.data.message : resource.ERR,
        });
      }
    }
  };

  return (
    <Box
      noValidate
      component="form"
      onSubmit={handleSubmit(edit ? editSubmitHandler : submitHandler)}
    >
      <Box>
        <Typography variant="h6" sx={{ color: "#54577d" }}>
          {edit ? resource.EDIT : resource.CREATE} {resource.TS.NAME}
        </Typography>
      </Box>

      <Grid item container spacing={2} mt={.5}>
        <Grid sm={12} item>
          <TextField
            autoFocus
            fullWidth
            size="small"
            name="name"
            autoComplete='off'
            variant="outlined"
            label={resource.TS.HEAD.NAME}
            required
            value={testSuiteData?.name}
            {...register("name", {
              onChange: e => onChangeHandler(e),
              required: "Name is required.",
              minLength: {
                value: 4,
                message: "Minimum length is 4 characters."
              },
              maxLength: {
                value: 30,
                message: "Minimum length is 30 characters."
              },
              validate: (v) => {
                if (!edit && testSuites?.testSuites?.find(e => e.name === v)) return "API Suite already exists."
                const e = testSuites?.testSuites?.filter(e => e.name === v)
                if (e.length && e[0]._id !== edit) return "API Suite already exists."

              }
            })}
            error={Boolean(errors?.name)}
            helperText={errors?.name?.message}
          />
        </Grid>
        <Grid sm={12} item>
          <TextField
            fullWidth
            size="small"
            name="description"
            autoComplete='off'
            variant="outlined"
            label={resource.TS.HEAD.DESC}
            minRows={3}
            maxRows={6}
            multiline
            value={testSuiteData?.description}
            {...register("description", {
              onChange: e => onChangeHandler(e),
              maxLength: {
                value: 100,
                message: "Maximum length is 100 characters."
              }
            })}
            error={Boolean(errors?.description)}
            helperText={errors?.description?.message}
          />
        </Grid>

        <Grid sm={12} item>
          <TextField
            required
            fullWidth
            size="small"
            autoComplete='off'
            variant="outlined"
            name="baseURL"
            label={resource.TS.HEAD.URL}
            value={testSuiteData?.baseURL}
            {...register("baseURL", {
              onChange: e => onChangeHandler(e),
              required: !testSuiteData?.baseURL && "Base URL is required.",
              minLength: {
                value: 4,
                message: "Minimum length is 4 characters."
              }
            })}
            error={Boolean(errors?.baseURL)}
            helperText={errors?.baseURL?.message}
          />
        </Grid>
        <Grid sm={12} item ml={1.2}>
          <FormControlLabel
            control={
              <Checkbox
                value={check}
                checked={check}
                onChange={(e) => {
                  if (e.target.value === "false") { setcheck(true); }
                  else {
                    settestSuiteData({
                      ...testSuiteData,
                      authenticationType: "none",
                      bearerToken: "",
                      basicAuth: { username: "", password: "" },
                      apiToken: { key: "", value: "" }
                    });
                    setcheck(false);
                  }
                }}
              />
            }
            label={resource.TS.TOKEN}
          />
        </Grid>

        {check && (
          <Grid sm={12} item>
            <FormTabs
              label={BearerToken}
              testSuiteData={testSuiteData}
              settestSuiteData={settestSuiteData}
              onChangeHandler={onChangeHandler}
            />
          </Grid>
        )}
      </Grid>

      <Box className="tsForm">
        <Grid
          container
          m={1}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={3} textAlign="left">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                retVal(false);
                setEdit(null);
                setTestSuite(null);
              }}
              sx={{
                backgroundColor: "#eb5160",
                "&:hover": {
                  backgroundColor: "#eb5160",
                },
              }}
            >
              {resource.CANCEL}
            </Button>
          </Grid>

          <Grid item xs={9} textAlign="right">
            <Button variant="contained" size="small" type="submit"
              sx={{
                backgroundColor: "#6580e0",
                "&:hover": {
                  backgroundColor: "#6580e0",
                },
              }}>
              {edit ? resource.UPDATE : resource.CREATE}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
