import { useContext, useReducer } from "react"
import { PropTypes } from "prop-types"
import APIsContext from "./APIsContext"
import APIsReducer from "./APIsReducer"
import ApiService from "services/app.service"
import UtilsContext from "contexts/Utils/UtilsContext"

import { SET_REQS, SET_CURRENT, SET_REQ, SET_SAVED, NEW_REQ, SET_REQUEST_DATA, SET_RES, RESPONSE, SET_PROPS, ADD_NEW } from "../types.js"

function APIsState(props) {
    const initialState = {
        requestData: {},
        req: null,
        res: null,
        reqs: [],
        curr: "",
        saved: true,
        newReq: 0,
        add: null,
        response: false,
        propsArray: []
    }
    const [state, dispatch] = useReducer(APIsReducer, initialState)

    const setNewReq = () => { dispatch({ type: NEW_REQ }) }

    const utilsContext = useContext(UtilsContext)
    const { showAlert } = utilsContext

    const setAdd = (data) => {
        dispatch({
            type: ADD_NEW,
            payload: data
        })
    }

    const setReq = (data) => {
        dispatch({
            type: SET_REQ,
            payload: data
        })
    }

    const setReqs = (data) => {
        dispatch({
            type: SET_REQS,
            payload: data
        })
    }

    const setProps = (data) => {
        dispatch({
            type: SET_PROPS,
            payload: data
        })
    }

    const setRequestData = (data) => {
        dispatch({
            type: SET_REQUEST_DATA,
            payload: data
        })
    }

    const setRes = (data) => {
        dispatch({
            type: SET_RES,
            payload: data
        })
    }

    const setResponse = (data) => {
        dispatch({
            type: RESPONSE,
            payload: data
        })
    }

    const setCurr = (data) => {
        dispatch({
            type: SET_CURRENT,
            payload: data
        })
    }

    const setSaved = (data) => {
        dispatch({
            type: SET_SAVED,
            payload: data
        })
    }

    const getReqs = async (id) => {
        try {
            const { data } = await ApiService.fetchEndpoints(id)
            setReqs(data)
        } catch (err) {
            console.log(err.response.data)
            showAlert({
                type: "error",
                msg: err.response.data.message
            })
            // if (err.response?.data?.error === "jwtExpired") {
            //     localStorage.removeItem(userLocalSession)
            //     window.location.href = "/login"
            // }
        }
    }

    const getReq = async (id) => {
        try {
            const { data } = await ApiService.fetchEndpoint(id)
            setReq(data.endpoint)
        } catch (err) {
            console.log(err.response.data)
            // if (err.response?.data?.error === "jwtExpired") {
            //     localStorage.removeItem(userLocalSession)
            //     window.location.href = "/login"
            // }
        }
    }

    return (
        <APIsContext.Provider
            value={{
                req: state.req,
                res: state.res,
                add: state.add,
                reqs: state.reqs,
                curr: state.curr,
                saved: state.saved,
                newReq: state.newReq,
                requestData: state.requestData,
                response: state.response,
                propsArray: state.propsArray,
                setRes,
                setReq,
                setAdd,
                setReqs,
                getReq,
                getReqs,
                setCurr,
                setSaved,
                setNewReq,
                setRequestData,
                setResponse,
                setProps
            }}
        >
            {props.children}
        </APIsContext.Provider>
    )
}

export default APIsState

APIsState.propTypes = {
    children: PropTypes.node
}