/* eslint-disable no-unused-vars */
import { useContext, useState } from "react"
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  TextField, Tooltip, Typography,
} from "@mui/material"

import WarningIcon from "@mui/icons-material/Warning"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"

import { resource } from "data/resource.js"
import TextFieldWithDropdown from "../../Utils/AssignVarList.js"

import APIsContext from "contexts/APIs/APIsContext.js"
import UtilsContext from "contexts/Utils/UtilsContext.js"
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext.js"

export default function AuthTab() {

  const apisContext = useContext(APIsContext)
  const utilsContext = useContext(UtilsContext)
  const testSuiteContext = useContext(TestSuiteContext)

  const { testSuite } = testSuiteContext
  const { edit, changes, setChanges } = utilsContext
  const { requestData, setRequestData } = apisContext

  const [mask, setMask] = useState(false)

  const onChangeHandlerApi = (e) => {
    if (edit) { !changes && setChanges(true) }
    setRequestData({
      ...requestData,
      apiToken: { ...requestData.apiToken, [e.target.name]: e.target.value.trim() },
    })
  }

  const onChangeHandlerAuth = (e) => {
    if (edit) { !changes && setChanges(true) }
    setRequestData({
      ...requestData,
      basicAuth: { ...requestData.basicAuth, [e.target.name]: e.target.value.trim() },
    })
  }

  const onChangeHandler1 = (e) => {
    if (edit) { !changes && setChanges(true) }
    setRequestData({ ...requestData, [e.target.name]: e.target.value.trim() })
  }

  return (
    <Grid item container>
      <Grid item sm={2.5}>
        <Box pt="12px" pr="12px">
          <TextField
            fullWidth
            size="small"
            select
            autoComplete='off'
            label={resource.TYPE}
            name="authenticationType"
            value={requestData.authenticationType || "none"}
            onChange={onChangeHandler1}
          >
            <MenuItem key="none" value="none">
              {resource.AUTH.NONE}
            </MenuItem>
            <MenuItem key="inheritFromParent" value="inheritFromParent">
              {resource.AUTH.PARENT}
            </MenuItem>
            <MenuItem key="apiToken" value="apiToken">
              {resource.AUTH.API}
            </MenuItem>
            <MenuItem key="bearerToken" value="bearerToken">
              {resource.AUTH.BEARER}
            </MenuItem>
            <MenuItem key="basicAuth" value="basicAuth">
              {resource.AUTH.BASIC}
            </MenuItem>
          </TextField>
        </Box>
      </Grid>

      <Grid item sm={9.5} sx={{ borderLeft: 1, borderColor: "divider" }}>
        <Box className="authBox">
          {requestData.authenticationType === "none" && (
            <Typography variant="body1" className="authTypo">
              {resource.AUTH.NO_AUTH}
            </Typography>
          )}

          {requestData.authenticationType === "inheritFromParent" && (
            <Typography variant="body1" className="authTypo">
              {resource.AUTH.PARENT_AUTH} {testSuite?.name}.
            </Typography>
          )}

          {requestData.authenticationType === "apiToken" && (
            <Box>
              <Grid container>
                <Grid sm={6} item>
                  <TextField
                    required
                    fullWidth
                    name="key"
                    size="small"
                    autoComplete='off'
                    variant="outlined"
                    label={resource.KEY}
                    defaultValue={requestData?.apiToken?.key}
                    onChange={onChangeHandlerApi}
                    sx={{ mb: 1.5 }}
                  />
                  {/* <TextField
                    required
                    fullWidth
                    size="small"
                    name="value"
                    multiline
                    minRows={3}
                    maxRows={6}
                    autoComplete='off'
                    variant="outlined"
                    label={resource.VALUE}
                    defaultValue={requestData?.apiToken?.value}
                    onChange={onChangeHandlerApi}
                    sx={{ mb: 1.5 }}
                  /> */}
                  <TextFieldWithDropdown
                    name={"apiKey"}
                    defaults={requestData?.apiToken?.value}
                  />
                  <Typography className="apiKeyNote">
                    <b>Note: </b>
                    {resource.AUTH.API_NOTE}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}

          {requestData.authenticationType === "bearerToken" && (
            <Box>
              <TextFieldWithDropdown
                name={"bearerToken"}
                defaults={requestData?.bearerToken}
              />
              <Typography variant="body1" className="apiKeyNote">
                <b>Note: </b>
                {resource.AUTH.BEARER_NOTE}
              </Typography>
            </Box>
          )}

          {requestData.authenticationType === "basicAuth" && (
            <Box width="50%">
              <TextField
                required
                fullWidth
                size="small"
                name="username"
                label="Username"
                autoComplete='off'
                variant="outlined"
                defaultValue={requestData?.basicAuth?.username}
                onChange={onChangeHandlerAuth}
              />

              {/* <IconButton
                size="small"
                className="authIcons2"
                onClick={() => setMask(!mask)}
              >
                {mask ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton> */}
              <Box position="relative" mt={1.5}>
                <Tooltip title={resource.WARNING}>
                  <IconButton size="small" className="authIcons">
                    <WarningIcon />
                  </IconButton>
                </Tooltip>
                <TextFieldWithDropdown
                  name={"password"}
                  defaults={requestData?.basicAuth?.password}
                />
              </Box>
              <Typography variant="body1" className="apiKeyNote">
                <b>Note: </b>
                {resource.AUTH.BASIC_NOTE}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
