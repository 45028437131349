import { useContext, Fragment } from "react"
import { PropTypes } from "prop-types"
import { styled } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import {
  Box, Checkbox, Drawer, IconButton, Paper,
  Tooltip, Table, TableRow, TableBody, TableCell, TableHead, TableContainer, tableCellClasses, Typography
} from "@mui/material"
// import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import TuneRoundedIcon from "@mui/icons-material/TuneRounded"
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline"

import { resource } from "data/resource"
import ExecuteTestSuite from "../TSExecution"
import DeleteDialog from "components/Utils/DeleteDialog"
import CreateTestSuiteForm from "components/Forms/TSForm"

import APIsContext from "contexts/APIs/APIsContext"
import UtilsContext from "contexts/Utils/UtilsContext"
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext"
import ExecutionContext from "contexts/Execution/ExecutionContext"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#d0c2ff",
    border: "1px solid #d0c2ff",
    color: "#54577d",
    fontWeight: 550,
    padding: "7px",
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12.2,
    padding: "13px",
    color: "#54577d",
    border: 0,
  },
}))

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f2fa",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ede8ff",
  },
  cursor: "pointer"
}))

export default function TestList({ deleteHandler, autId }) {
  const navigate = useNavigate()

  const apisContext = useContext(APIsContext)
  const utilsContext = useContext(UtilsContext)
  const testSuiteContext = useContext(TestSuiteContext)
  const executionContext = useContext(ExecutionContext)

  const { getReqs } = apisContext
  const { testSuites } = testSuiteContext
  const { getConfigs } = executionContext
  const { opens, setOpens, edit, setEdit, visible, setVisible, deletes, delValues, setDelValues } = utilsContext

  return (
    <>
      <TableContainer component={Paper}>
        <Table className="customized_table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                <Checkbox
                  size="small"
                  checked={delValues.length === testSuites.testSuites.length}
                  onChange={(e) => {
                    e.stopPropagation()
                    e.target.checked ? setDelValues([...testSuites.testSuites.map(e => e._id)]) : setDelValues([])
                  }} />
              </StyledTableCell>
              <StyledTableCell align="center">{resource.TS.HEAD.NAME}</StyledTableCell>
              <StyledTableCell align="center">{resource.TS.HEAD.DESC}</StyledTableCell>
              <StyledTableCell align="center">{resource.TS.HEAD.COUNT}</StyledTableCell>
              <StyledTableCell align="center">{resource.TS.HEAD.URL}</StyledTableCell>
              <StyledTableCell align="center">{resource.TS.HEAD.DATE}</StyledTableCell>
              <StyledTableCell align="center">{resource.TS.HEAD.ACTION} </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testSuites.testSuites.map((row) => (
              <Fragment key={row._id}>
                {edit === row._id ? (
                  <Drawer anchor="right" open={visible}>
                    <Box role="presentation" className="resDrawer">
                      <CreateTestSuiteForm retVal={setVisible} />
                    </Box>
                  </Drawer>
                ) : (
                  <>
                    <StyledTableRow key={row.name} onClick={() => navigate(`/addrequest?aut=${autId}&testsuite=${row._id}`)}>

                      <StyledTableCell align="center">
                        <Checkbox
                          checked={delValues?.findIndex(ele => ele === row._id) !== -1}
                          value={delValues?.findIndex(ele => ele === row._id) !== -1}
                          onClick={(event) => {
                            event.stopPropagation()
                            if (event.target.checked) { setDelValues([...delValues, row._id]) }
                            else {
                              const index = delValues.findIndex(ele => ele === row._id)
                              delValues.splice(index, 1)
                              setDelValues([...delValues])
                            }
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                      <StyledTableCell align="center">{row.description}</StyledTableCell>
                      <StyledTableCell align="center">{row.noEndpoints}</StyledTableCell>
                      <StyledTableCell align="center">{`${row.baseURL}`}</StyledTableCell>
                      <StyledTableCell align="center">
                        {Math.round(
                          (Number(new Date()) - new Date(row.createdAt)) /
                          (1000 * 60 * 60 * 24)
                        )}{" "}
                        days ago
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box className="autListIcons">
                          <IconButton
                            className="accordianActionIcon"
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation()
                              getReqs(row._id)
                              setOpens(row._id)
                              getConfigs(row?._id)
                            }}
                          >
                            <Tooltip title={"Manage Configs"} placement="top" arrow>
                              <TuneRoundedIcon />
                            </Tooltip>
                          </IconButton>

                          {/* <IconButton
                          size="small"
                          className="accordianActionIcon"
                        >
                          <Tooltip title={resource.RES} placement="top" arrow>
                            <FactCheckOutlinedIcon />
                          </Tooltip>
                          </IconButton> */}

                          <IconButton
                            className="accordianActionIcon"
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation()
                              setEdit(row._id)
                              setVisible(true)
                            }}
                          >
                            <Tooltip title={resource.EDIT} placement="top" arrow>
                              <ModeEditOutlineIcon />
                            </Tooltip>
                          </IconButton>

                          {/* <IconButton
                          size="small"
                          className="accordianActionIcon"
                          onClick={(event) => {
                            event.stopPropagation()
                            setDelete(row._id)
                          }}>
                          <Tooltip
                            title={resource.DELETE}
                            placement="top"
                            arrow
                          >
                            <DeleteIcon />
                          </Tooltip>
                        </IconButton> */}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                    {/* Execute Test Suite Button Modal */}
                    {opens === row._id && <ExecuteTestSuite tsIds={opens} />}
                  </>
                )}
              </Fragment>
            ))}
            {/* {<ExportPopup obj={testSuites?.aut} open={opens} setOpen={setOpens} />} */}
            {deletes && <DeleteDialog deleteHandler={deleteHandler} name={"API Suite"} />}
          </TableBody>
        </Table>
      </TableContainer>

      {!testSuites?.testSuites?.length && <Box sx={{ padding: "5%" }}>
        <center>
          <Typography variant="h6">{resource.NO_LOAD}</Typography>
        </center>
      </Box>}

    </>
  )
}

TestList.propTypes = {
  autId: PropTypes.string,
  deleteHandler: PropTypes.func
}