export const SET_REQS = "SET_REQS";
export const SET_REQ = "SET_REQ";
export const NEW_REQ = "NEW_REQ";
export const SET_REQUEST_DATA = "SET_REQUEST_DATA";
export const SET_CURRENT = "SET_CURRENT";
export const SET_SAVED = "SET_SAVED";
export const SET_CHANGES = "SET_CHANGES";
export const SET_RES = "SET_RES";
export const RESPONSE = "RESPONSE";
export const SET_PROPS = "SET_PROPS";
export const ADD_NEW = "ADD_NEW";

export const SET_AUT = "SET_AUT";
export const SET_AUTS = "SET_AUTS";
export const AUT_ID = "AUT_ID";

export const SET_TESTSUITE = "SET_TESTSUITE";
export const SET_TESTSUITES = "SET_TESTSUITES";

export const EDIT_TC = "EDIT_TC";
export const SET_TCS = "SET_TCS";
export const SET_STATUS = "SET_STATUS";
export const VISIBLE_TC = "VISIBLE_TC";
export const SET_TESTCASE = "SET_TESTCASE";
export const SET_TESTCASES = "SET_TESTCASES";
export const SET_RES_PROPS = "SET_RES_PROPS";
export const SET_TESTCASE_DATA = "SET_TESTCASE_DATA";

export const SET_RESULTS = "SET_RESULTS";
export const SET_EXES = "SET_EXES";
export const SET_EXES_TS = "SET_EXES_TS";
export const VISIBLE_TR = "VISIBLE_TR";

export const SET_TSVAR = "SET_TSVAR";
export const SET_TSVARS = "SET_TSVARS";
export const SET_AUTVARS = "SET_AUTVARS";
export const SET_GLOBALS = "SET_GLOBALS";
export const SET_ENV_NAME = "SET_ENV_NAME";
export const SET_ENV = "SET_ENV";
export const SET_ENVS = "SET_ENVS";
export const SET_ENVNAMES = "SET_ENVNAMES";
export const DEL_VAR = "DEL_VAR";
export const EDIT_VAR = "EDIT_VAR";
export const ALL_VARS = "ALL_VARS";
export const INP_VALUE = "INP_VALUE";
export const NEW_VAR = "NEW_VAR";
export const VISIBLE_VAR = "VISIBLE_VAR";


export const SET_ALERT = "SET_ALERT";
export const SET_SNACK = "SET_SNACK";
export const REMOVE_SNACK = "REMOVE_SNACK";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_ERROR = "SET_ERROR";
export const SET_EDIT = "SET_EDIT";
export const SET_DELETE = "SET_DELETE";
export const SET_DEL_VALUES = "SET_DEL_VALUES";
export const SET_DELETE_ALL = "SET_DELETE_ALL";
export const SET_VISIBLE = "SET_VISIBLE";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_EXE = "SET_LOADING_EXE";
export const SET_LOADING_EXE1 = "SET_LOADING_EXE1";
export const REMOVE_LOADING_EXE = "REMOVE_LOADING_EXE";
export const REMOVE_LOADING_EXE1 = "REMOVE_LOADING_EXE1";
export const REMOVE_LOADING = "REMOVE_LOADING";
export const SET_OPENS = "SET_OPENS";
export const SET_TESTDATA = "SET_TESTDATA";


export const SET_CONFIG = "SET_CONFIG";
export const SET_CONFIGS = "SET_CONFIGS";
export const SET_ALL_CONFIGS = "SET_ALL_CONFIGS";
export const SET_ADD = "SET_ADD";
export const SET_ADDT = "SET_ADDT";

export const SET_SCHEDULE = "SET_SCHEDULE";
export const SET_SCHEDULES = "SET_SCHEDULES";
export const SET_LOAD = "SET_LOAD";
export const SET_LOADS = "SET_LOADS";
export const SET_UNIQUE = "SET_UNIQUE";

