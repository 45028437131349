import { SET_EXES, SET_EXES_TS, SET_RESULTS, VISIBLE_TR } from "../types.js";

const TestResultReducer = (state, action) => {
    switch (action.type) {
        case SET_RESULTS:
            return {
                ...state,
                results: action.payload,
            };
        case SET_EXES:
            return {
                ...state,
                exes: action.payload,
            };
        case SET_EXES_TS:
            return {
                ...state,
                exesTs: action.payload,
            };
        case VISIBLE_TR:
            return {
                ...state,
                visible_tr: action.payload,
            };
        default:
            return state;
    }
};

export default TestResultReducer;