import { useContext, useState } from "react"
import { PropTypes } from "prop-types"
import ReactJson from "react-json-view"
import { Alert, Box, IconButton, Modal, Snackbar, Typography } from "@mui/material"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined.js"

import { jsonPathFinder } from "hooks"
import APIsContext from "contexts/APIs/APIsContext"
import TestCaseContext from "contexts/TestCase/TestCaseContext"

const style = {
    p: 3.5,
    top: "50%",
    left: "50%",
    width: "50%",
    height: "90%",
    bgcolor: "#f4f1ff",
    overflowY: "scroll",
    position: "absolute",
    transform: "translate(-50%, -50%)",
};
function JsonViewer({ open, setOpen, onChangeHandler }) {

    const apisContext = useContext(APIsContext);
    const testCaseContext = useContext(TestCaseContext);

    const { res } = apisContext;
    const { testcaseData, setTestcaseData } = testCaseContext;

    const [selected, setSelected] = useState(null);

    return (
        <Modal
            open={Boolean(open)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">

            <Box sx={style} className="scrollbarStyle">
                <IconButton
                    size="small"
                    color="error"
                    sx={{ position: "absolute", top: "2px", right: "2px" }}
                    onClick={() => setOpen(null)}
                >
                    <CancelOutlinedIcon />
                </IconButton>
                <ReactJson
                    src={res?.data}
                    indentWidth={2}
                    displayArrayKey={false}
                    displayDataTypes={false}
                    style={{ cursor: "pointer" }}
                    onSelect={select => {
                        setSelected({ type: "success", msg: `"${jsonPathFinder(select)}"  property selected` });
                        if (open === true)
                            { onChangeHandler(jsonPathFinder(select)); }
                        else {
                            if (testcaseData.functionalAssertions.some((em) => em.targetField === jsonPathFinder(select))) {
                                setSelected({ type: "warning", msg: `"${jsonPathFinder(select)}"  property selected` });
                                return;
                            }
                            setTestcaseData({
                                ...testcaseData,
                                functionalAssertions: [
                                    ...testcaseData.functionalAssertions.slice(0, Number(open.split("-")[1])),
                                    {
                                        ...testcaseData.functionalAssertions[Number(open.split("-")[1])],
                                        targetField: jsonPathFinder(select),
                                    },
                                    ...testcaseData.functionalAssertions.slice(Number(open.split("-")[1]) + 1),
                                ],
                            })
                        }
                        setTimeout(() => {
                            setOpen(false);
                            setSelected(null);
                        }, 700);
                    }}
                />
                <Box className="Notes">
                    <Typography variant="body1" className='apiKeyNote'>
                        <b>Note: </b>
                        Click on the &apos;Value&apos; in the Key/Value pair to select the property.
                    </Typography>
                </Box>
                {
                    !res?.data &&
                    <Box sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}>
                        <center>
                            <Typography variant="h1" sx={{ fontSize: "16px", }}>
                                Please execute the API to auto-populate the JSON data
                            </Typography>
                        </center>
                    </Box>
                }

                {selected && (
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={true}
                    >
                        <Alert severity={selected.type}>{selected.msg}</Alert>
                    </Snackbar>
                )}
            </Box>
        </Modal >
    );
}
export default JsonViewer;

JsonViewer.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onChangeHandler: PropTypes.func
}
