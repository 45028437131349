import { useState } from "react"
import { PropTypes } from "prop-types"

import { Box, Chip, Typography, Button, Paper } from "@mui/material"

import { resource } from "data/resource";
import PreviewAuts from "./PreviewAuts";

const headerCellStyle = {
    p: 1,
    px: 2,
    mx: 0.5,
    display: "flex",
    color: "#54577d",
    alignItems: "center",
    background: "#d0c2ff",
};

const dataCellStyle = {
    px: 2,
    p: 0.8,
    display: "flex",
    marginTop: "8px",
    alignItems: "center",
    background: "#ede8ffs",
    boxShadow: "0 0 7px #00000024",
};

function AutStatusCard({ color, name, dbData }) {
    const [showPreview, setShowPreview] = useState(false);
    const auts = dbData.find(e => e._id === name)?.auts;
    console.log(auts, name)
    return (
        <>
            <Box sx={{
                borderRadius: "3px", border: "1px solid #e4e8ed",
                // background: "#eaeff4" 
            }} mt={1} >
                < Box
                    sx={{
                        p: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                    <Typography variant="h6" sx={{ color: "#54577d" }}>
                        Auts : {auts ? auts?.length : 0}
                    </Typography>
                    <Chip
                        size="small"
                        variant="contained"
                        sx={{ minWidth: "80px" }}
                        color={color}
                        label={name}
                    />
                </Box>
                <Paper
                    elevation={0}
                    sx={{ overflow: "auto", borderRadius: 0, pb: "8px" }}>
                    <Box sx={headerCellStyle}>
                        <Typography variant="bold" sx={{ flex: 1 }} >
                            {resource.AUT.HEAD.NAME}
                        </Typography>
                        <Typography variant="bold" align="center">
                            {resource.AUT.HEAD.COUNT}
                        </Typography>
                    </Box>
                    <Box sx={{ px: 0.5, pb: 0.2 }}>
                        {auts?.length ? auts.map(row => (
                            <Box
                                key={row._id}
                                sx={{ ...dataCellStyle }}
                            >
                                <Typography variant="body1" sx={{ flex: 1 }}>
                                    {row.name}
                                </Typography>
                                <Button
                                    onClick={() => setShowPreview(row._id)}
                                    variant="contained"
                                    sx={{
                                        minWidth: "20px",
                                        minHeight: "20px",
                                        p: 0,
                                        mx: 0,
                                        background: "#6580e0",
                                    }}>
                                    {row.testSuites?.length}
                                </Button>
                                {showPreview === row._id && <PreviewAuts handleclose={() => setShowPreview(false)} color={color} name={name} obj={row} />}
                            </Box>
                        )) :
                            <Box sx={{ textAlign: "center", p: 1, marginTop: "9px" }}>
                                <Typography variant="body1">
                                    {resource.NO_AUT}
                                </Typography>
                            </Box>
                        }
                    </Box>
                </Paper>
            </Box >
        </>
    );
}

export default AutStatusCard

AutStatusCard.propTypes = {
    color: PropTypes.string,
    name: PropTypes.string,
    dbData: PropTypes.array
}
