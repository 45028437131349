import { useReducer, useContext } from "react";
import { PropTypes } from "prop-types";

import APIsContext from "../APIs/APIsContext";
import TestResultContext from "./TestResultContext";
import TestResultReducer from "./TestResultReducer";
import ApiService from "services/app.service";

import { SET_EXES, SET_EXES_TS, SET_RESULTS, VISIBLE_TR } from "../types.js";
function TestResultState(props) {

    const apisContext = useContext(APIsContext);
    const { setRes } = apisContext;

    const initialState = {
        results: [],
        exes: [],
        exesTs: [],
        visible_tr: false

    };
    const [state, dispatch] = useReducer(TestResultReducer, initialState);

    const setTestResults = (data) => {
        dispatch({
            type: SET_RESULTS,
            payload: data
        });
    };

    const setVisibleTR = (data) => {
        dispatch({ type: VISIBLE_TR, payload: data });
    };

    const setExes = (data) => {
        dispatch({ type: SET_EXES, payload: data });
    };

    const setExesTs = (data) => {
        dispatch({ type: SET_EXES_TS, payload: data });
    };

    const getExes = async (id) => {
        try {
            const { data } = await ApiService.endpointExecutions(id);
            setExes(data.executions.reverse());
            console.log(data.executions.reverse())
        } catch (err) {
            console.log(err.response.data);
            setExes([]);
        }
    };

    const getExesTS = async (id) => {
        try {
            const { data } = await ApiService.tsExecutions(id)
            console.log(data.executions, "second")
            setExesTs(data.executions.reverse())
        } catch (err) {
            console.log(err.response.data);
        }
    };

    const getExeResults = async (id) => {
        try {
            const { data } = await ApiService.endpointTestResults(id);
            setTestResults(data.testResults.map(e => e.testCase));
            // setRes(data.testResults?.[0]?.response)
            const exe = state.exes.find(e => e._id === id)
            setRes(exe.responses[0]?.error ?
                { data: { message: exe.responses[0].error } }
                :
                (exe.responses[0]?.response ? exe.responses[0].response
                    :
                    { data: { status: "error", message: "Something went wrong. Try again later." } }))
        } catch (err) {
            console.log(err.response.data);
        }
    };

    const getExeResults2 = async (exe) => {
        try {
            const { data } = await ApiService.endpointTestResults(exe._id);
            const res = []
            exe.responses.forEach(e => {
                const obj = e
                obj.testResults = data.testResults.filter(el => el.testDataUniqueId === e.testDataUniqueId)
                res.push(obj)
            })
            setTestResults(res)
        } catch (err) {
            console.log(err.response);
        }
    };

    const getExeResultsTs = async (exe) => {
        try {
            const { data } = await ApiService.tsTestResults(exe._id)
            const trs = data.testResults.map(e => e.testResults).flat()
            const res = []
            exe.responses.forEach(e => {
                const obj = e
                obj.testResults = trs.filter(el => el.endpointId === e.endpointId)
                res.push(obj)
            })
            setTestResults(res)
        } catch (err) {
            console.log(err.response);
        }
    };

    return (
        <TestResultContext.Provider
            value={{
                exes: state.exes,
                exesTs: state.exesTs,
                results: state.results,
                visible_tr: state.visible_tr,
                getExeResults,
                getExeResults2,
                setTestResults,
                setVisibleTR,
                getExes,
                getExesTS,
                getExeResultsTs,
            }}
        >
            {props.children}
        </TestResultContext.Provider>
    );
}

TestResultState.propTypes = {
    children: PropTypes.node
};

export default TestResultState;