import { SET_LOAD, SET_LOADS, SET_SCHEDULE, SET_SCHEDULES, SET_UNIQUE } from "../types.js";

const ScheduleReducer = (state, action) => {
    switch (action.type) {
        case SET_SCHEDULES:
            return {
                ...state,
                schedules: action.payload,
            };
        case SET_LOAD:
            return {
                ...state,
                loadResult: action.payload,
            };
        case SET_LOADS:
            return {
                ...state,
                loadResults: action.payload,
            };
        case SET_SCHEDULE:
            return {
                ...state,
                schedule: action.payload,
            };
        case SET_UNIQUE:
            return {
                ...state,
                uniqueId: action.payload,
            };
        default:
            return state;
    }
};

export default ScheduleReducer;