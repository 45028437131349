import { SET_TESTSUITE, SET_TESTSUITES } from "../types.js";

const TestSuiteReducer = (state, action) => {
  switch (action.type) {
    case SET_TESTSUITES:
      return {
        ...state,
        testSuites: action.payload,
      };
    case SET_TESTSUITE:
      return {
        ...state,
        testSuite: action.payload,
      };

    default:
      return state;
  }
};

export default TestSuiteReducer;
