import { useRef, useContext } from "react"
import { Box, Grid, Typography } from "@mui/material"

import { resource } from "data/resource"
import { TSVheadCells } from "data/variables"
import CustomTabless from "components/Tables/CustomTabless"
import VarContext from "contexts/Variables/VarContext"

const TSVariablesList = () => {

  const varContext = useContext(VarContext);
  const { tsVars } = varContext;

  const ScrollRef = useRef()

  return (
    <Box sx={{ width: "100%" }}>
      <Box className="tsHeader" px="16px">
        <Grid container alignItems="center">
          <Grid sm={6} item>
            <Typography>
              Scope: <b>{resource.TS.NAME}</b> | {resource.VARS.COUNT}: <b>{tsVars.length}</b>
            </Typography>
          </Grid>
          <Grid sm={6} textAlign="right" item></Grid>
        </Grid>
      </Box>

      <Box ref={ScrollRef}>
        <Box
          sx={{ width: "100%", mb: 2, "& .refreshBtn": { display: "none" } }}>
          <CustomTabless
            category={"APISuite"}
            headCells={TSVheadCells}
            rows={tsVars}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TSVariablesList;
