import { useContext, useState } from "react"
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Tooltip, TextField, Typography,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddCircle";

import { resource } from "../../../data/resource.js";
import APIsContext from "../../../contexts/APIs/APIsContext";
import UtilsContext from "../../../contexts/Utils/UtilsContext";

export default function HeaderTab() {
  const apisContext = useContext(APIsContext);
  const utilsContext = useContext(UtilsContext);

  const { edit, changes, setChanges } = utilsContext;
  const { requestData, setRequestData } = apisContext;

  const [note, setNote] = useState(false)
  // const splitText = "{{hellos}}".split(/({{[^{}]+}})/);

  return (
    <Box mt={1.5}>
      <Grid container>
        <Grid sm={6} item>
          <Typography variant="h6" sx={{ color: "#54577d" }}>
            Add Header
            <IconButton size="small" sx={{ ml: "8px" }}>
              <Tooltip title="Add Header">
                <AddBoxIcon
                  className="addIcon2"
                  sx={{ color: "#6580e0" }}
                  onClick={() => {
                    setRequestData({
                      ...requestData,
                      headers: [
                        ...requestData.headers,
                        {
                          name: "",
                          key: Date.now().toString(),
                          value: "",
                          description: "",
                          isSelected: true,
                        },
                      ],
                    });
                  }}
                />
              </Tooltip>
            </IconButton>
          </Typography>
        </Grid>

        <Grid item sm={12} textAlign="right" position="relative">
          {Boolean(requestData.headers?.length) && (
            <IconButton
              size="small"
              className="infoIcon"
              onMouseOver={() => setNote(true)}
              onMouseLeave={() => setNote(false)}
            >
              <InfoIcon />
            </IconButton>
          )}
          {note && (
            <Typography
              variant="body1"
              sx={{
                position: "absolute",
                top: "0",
                right: "55px",
                textAlign: "left",
                width: "90.3%",
              }}
              className="apiKeyNote2"
            >
              <b>Note: </b>
              {resource.HEADER_NOTE}&#x2705;
            </Typography>
          )}
        </Grid>
      </Grid>

      {requestData.headers?.length ? (
        requestData.headers.map((el, i) => (
          <Grid container mt={note ? "20px" : "5px"} alignItems="center" spacing={1} key={i}>
            <Grid item md={0.5}>
              <Checkbox
                className="checkbox1"
                name="isSelected"
                checked={el.isSelected}
                value={el.isSelected}
                onChange={() => {
                  if (edit) { !changes && setChanges(true); }
                  setRequestData({
                    ...requestData,
                    headers: [
                      ...requestData.headers.slice(0, i),
                      { ...requestData.headers[i], isSelected: !el.isSelected },
                      ...requestData.headers.slice(i + 1),
                    ],
                  });
                }}
              />
            </Grid>

            <Grid item md={3}>
              <TextField
                required
                fullWidth
                size="small"
                autoComplete='off'
                variant="outlined"
                label={resource.KEY}
                name={`headerKey${i}`}
                defaultValue={el.name}
                disabled={!el.isSelected}
                onChange={(e) => {
                  if (edit) { !changes && setChanges(true); }
                  setRequestData({
                    ...requestData,
                    headers: [
                      ...requestData.headers.slice(0, i),
                      { ...requestData.headers[i], name: e.target.value },
                      ...requestData.headers.slice(i + 1),
                    ],
                  });
                }}
              />
            </Grid>

            <Grid item md={3}>
              <TextField
                required
                fullWidth
                size="small"
                autoComplete='off'
                variant="outlined"
                name={`headerKey${i}`}
                label={resource.VALUE}
                defaultValue={el.value}
                disabled={!el.isSelected}
                onChange={e => {
                  if (edit) { !changes && setChanges(true); }
                  setRequestData({
                    ...requestData,
                    headers: [
                      ...requestData.headers.slice(0, i),
                      { ...requestData.headers[i], value: e.target.value },
                      ...requestData.headers.slice(i + 1),
                    ],
                  });
                }}
              />
            </Grid>

            <Grid md={5} item>
              <TextField
                fullWidth
                size="small"
                name="description"
                autoComplete='off'
                label={resource.AUT.HEAD.DESC}
                minRows={1}
                maxRows={3}
                multiline
                variant="outlined"
                defaultValue={el.description}
                disabled={!el.isSelected}
                onChange={(e) => {
                  if (edit) { !changes && setChanges(true); }
                  setRequestData({
                    ...requestData,
                    headers: [
                      ...requestData.headers.slice(0, i),
                      {
                        ...requestData.headers[i],
                        description: e.target.value,
                      },
                      ...requestData.headers.slice(i + 1),
                    ],
                  });
                }}
              />
            </Grid>

            <Grid item md={0.5} textAlign="center">
              <Tooltip title={resource.DELETE}>
                <DeleteIcon
                  className="delIcon"
                  sx={{ color: "#cf2b2b" }}
                  onClick={() => {
                    if (edit) !changes && setChanges(true)
                    const index = requestData.headers.findIndex(ele => ele.key === el.key)
                    requestData.headers.splice(index, 1)
                    setRequestData({
                      ...requestData,
                      headers: requestData.headers
                    });
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        ))
      ) : (
        <Box className="emptyParams">
          <Typography variant="body1">{resource.NO_HEADER}</Typography>
        </Box>
      )}
      {/* <TextField
        value={splitText[1]}
        InputProps={{
          startAdornment: (
              <Typography variant="body1" color="primary">
                {splitText[0]}
              </Typography>
          ),
          endAdornment: (
            <div>
              <Typography variant="body1" color="primary">
                {splitText[2]}
              </Typography>
            </div>
          )
        }}
        inputProps={{ style: { color: "deeppink" } }}
      /> */}

    </Box>

  );
}
