import { PropTypes } from "prop-types"
import {
  Box,
  Checkbox,
  Grid,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
} from "@mui/material";
import rule from "data/test";
import TextFieldWithDropdown from "components/Utils/AssignVarList";

const TCInput = ({ row, input, columnIndex, ruleIndex, checkBoxHandler }) => {

  return (
    <Grid container>
      {input?.operation !== "isExists" &&
        <Grid item sm={12}>
          <TextFieldWithDropdown
            index={ruleIndex}
            index2={columnIndex}
            name={"Value"}
            defaults={input?.expectedValue}
          />
        </Grid>
      }
      <Grid item sm={7}>
        {(input?.isCaseSensitive !== null &&
          rule.inputType.includes(input?.operation)) ?
          <FormControlLabel
            sx={{ marginLeft: "5px" }}
            control={
              <Checkbox
                sx={{
                  "& .MuiCheckbox-root": {
                    "& .MuiSvgIcon-root": { fontSize: "20px", color: "red" },
                  }
                }}
                name="isCaseSensitive"
                value={input?.isCaseSensitive}
                checked={input?.isCaseSensitive}
                onChange={e => checkBoxHandler(e, columnIndex, ruleIndex)}
              />
            }
            label={"Case Sensitive"}
          />
          : <Box>{input?.operation !== "isExists" && <>&nbsp;</>}</Box>}
      </Grid>

      <Grid item sm={5}>
        {ruleIndex !== row?.rules?.length - 1 && (
          <Box
            sx={{
              position: "absolute",
              bottom: input?.operation !== "isExists" ? 3.5 : 8,
              right: 12,
            }}
          >
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontSize: "10px" }}><b>OR</b></Typography>
              <Switch
                name="operator"
                className="switchStyle"
                checked={input?.operator === "and"}
                value={input?.operator}
                onChange={(e) => checkBoxHandler(e, columnIndex, ruleIndex)}
              />
              <Typography sx={{ fontSize: "10px" }}><b>AND</b></Typography>
            </Stack>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
export default TCInput

TCInput.propTypes = {
  row: PropTypes.array,
  input: PropTypes.object,
  ruleIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  checkBoxHandler: PropTypes.func
}
