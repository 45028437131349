/* eslint-disable react/prop-types */
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Checkbox,
  ListItemButton, ListItemIcon, ListItemText,
  Tooltip, Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import FileCopyIcon from "@mui/icons-material/FileCopy"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecordRounded"

import { resource } from "data/resource.js"
import ApiService from "services/app.service"
import APIsContext from "contexts/APIs/APIsContext.js"
import UtilsContext from "contexts/Utils/UtilsContext.js"
import TestCaseContext from "contexts/TestCase/TestCaseContext.js"
import TestResultContext from "contexts/TestResult/TestResultContext.js"

const data = [
  { icon: <DeleteIcon sx={{ color: "#6580e0" }} />, label: "Delete" },
  { icon: <FileCopyIcon sx={{ color: "#6580e0" }} />, label: "Duplicate" },
]

export default function ApiListItem({ obj }) {
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(document.location.search)
  const tc = searchParams.get("tc")
  const autId = searchParams.get("aut")
  const tsId = searchParams.get("testsuite")

  const apisContext = useContext(APIsContext)
  const utilsContext = useContext(UtilsContext)
  const testCaseContext = useContext(TestCaseContext)
  const testResultContext = useContext(TestResultContext)

  const { setTestResults } = testResultContext
  const { testcase, setTestCase, setTCs, setResProps } = testCaseContext
  const { reqs, add, curr, setCurr, setSaved, setReq, setRes, getReqs, setAdd, setRequestData } = apisContext
  const { visible, setVisible, setEdit, changes, setDelete, showAlert, deleteAll, delValues, setDelValues, setTestData } = utilsContext

  const changeRequest = async () => {
    if (changes) { setSaved(false) }
    else {
      add && setAdd(null)
      setTestData(null)
      tc && navigate(`/addrequest?aut=${autId}&testsuite=${tsId}`)
      testcase && setTestCase(null)
      setReq(null)
      setRequestData({})
      setEdit(obj._id)
      setCurr(obj.nameId)
      setRes(null)
      setTCs([])
      setResProps([])
      setTestResults([])
    }
  }

  const duplicate = async () => {
    try {
      const reqData = reqs.find(e => e._id === visible)
      // eslint-disable-next-line no-unused-vars
      const { serialNumber, _id, ...reqData1 } = reqData
      reqData1.nameId = Date.now().toString()

      const count = reqs.reduce((tot, curr) => {
        if (curr.name?.split(" copy")[0] === reqData.name?.split(" copy")[0]) return tot + 1
        return tot
      }, 0)
      reqData1.name = `${reqData.name.split(" copy")[0]} copy ${count}`
      const { data } = await ApiService.createEndpoint(reqData1)
      showAlert({
        type: data.status,
        msg: data.message,
      })
      setAdd(null)
      getReqs(tsId)
      setCurr(reqData1.nameId)
      setEdit(data.endpoint._id)
      setReq(null)
      setVisible(false)
    } catch (err) {
      console.log(err.response.data)
      showAlert({
        type: "error",
        msg: err.response?.data?.message,
      })
    }
  }

  return (
    <Box
      className="flex column"
      sx={{
        bgcolor: curr === obj?.nameId && !add ? "#fff" : null,
        borderLeft: curr === obj?.nameId && !add ? "3px solid #611EB6" : "0",
        "& .MuiTypography-root": {
          fontWeight: curr === obj?.nameId && !add ? "600" : "normal",
        },
        zIndex: -1
      }}
    >
      <Tooltip title={obj?.name} placement="left">
        <ListItemButton
          className="apiName"
          alignItems="flex-start"
          onClick={changeRequest}
        >
          {deleteAll && <Checkbox
            size="small"
            checked={delValues?.findIndex(ele => ele === obj._id) !== -1}
            value={delValues?.findIndex(ele => ele === obj._id) !== -1}
            onClick={(event) => {
              event.stopPropagation()
              if (event.target.checked) { setDelValues([...delValues, obj._id]) }
              else {
                const index = delValues.findIndex(ele => ele === obj._id)
                delValues.splice(index, 1)
                setDelValues([...delValues])
              }
            }} />}
          <ListItemText primary={<div style={{ marginLeft: "48px" }} id="holaaa12">
            {obj?.name?.length > 20 ? `${obj?.name.substring(0, 20)}..` : obj?.name}
          </div>}
            secondary={<Typography style={{
              marginTop: "-18px",
              marginLeft: "-5px",
              color: obj?.method === "GET" ? "green" : (obj?.method === "POST" ? "goldenrod" : (obj?.method === "PUT" ? "deeppink" : (obj?.method === "DELETE" ? "crimson" : "dodgerblue")))
            }}>
              <b> {obj?.method}</b>
            </Typography>}

          />

          {curr === obj?.nameId && !add && changes && (
            <FiberManualRecordIcon color="error" className="unsavedIndicator" />
          )}

          <MoreHorizOutlinedIcon
            sx={{ marginTop: "4px" }}
            onClick={(e) => {
              e.stopPropagation()
              if (visible !== obj?._id) {
                setVisible(false)
                setVisible(obj?._id)
              } else if (visible === obj?._id) { setVisible(false) }
              else { setVisible(obj?._id) }
            }}
          />
        </ListItemButton>
      </Tooltip>

      <Box className="apiActions">
        {visible === obj?._id &&
          data.map((item) => (
            <ListItemButton
              key={item.label}
              className="moreActionList"
              onClick={() =>
                item.label === resource.DELETE
                  ? setDelete(obj?._id)
                  : duplicate()
              }
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          ))}
      </Box>
    </Box>
  )
}
