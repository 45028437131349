import { useState, useContext, useEffect } from "react"
import axios from "axios"
import { PropTypes } from "prop-types"
import {
  Alert, AlertTitle,
  Box, Button,
  IconButton, Modal, LinearProgress, Grid, Typography
} from "@mui/material"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded"

import Tabs from "./Tabs.js"
import { resource } from "data/resource.js"
import ApiService from "services/app.service.js"
import DeleteDialog from "components/Utils/DeleteDialog.js"
import CreateConfig from "components/TSExecution/AddConfig/index.js"
import SideConfigList from "components/TSExecution/SideConfigList.js"
// import ExportResult from "components/TSExecution/ExeResult/ExportResult.js"

import VarContext from "contexts/Variables/VarContext.js"
import UtilsContext from "contexts/Utils/UtilsContext.js"
import TestCaseContext from "contexts/TestCase/TestCaseContext.js"
import ExecutionContext from "contexts/Execution/ExecutionContext.js"
import TestResultContext from "contexts/TestResult/TestResultContext.js"

const style = {
  height: "97.5%",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "#f4f1ff",
  p: 3.5
}

export default function ExecuteTestSuite({ tsIds }) {
  const autId = new URLSearchParams(document.location.search).get("aut")
  const tsId = new URLSearchParams(document.location.search).get("testsuite")

  const varContext = useContext(VarContext)
  const utilsContext = useContext(UtilsContext)
  const testCaseContext = useContext(TestCaseContext)
  const executionContext = useContext(ExecutionContext)
  const testResultContext = useContext(TestResultContext)

  const { envNames, getEnvNames } = varContext
  const { editTC, setEditTC, config, setConfig } = testCaseContext
  const { getExesTS } = testResultContext
  const { configs, add, currConfig, getConfigs, setAdd } = executionContext
  const { deletes, setDelete, showAlert, setVisible, delValues, setDelValues, deleteAll, setDeleteAll, opens, setOpens, exeLoading,
    setExeLoading, removeExeLoading
  } = utilsContext

  const [popup, setPopUp] = useState(null)

  useEffect(() => {
    !envNames.length && getEnvNames(autId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => {
    setAdd(null)
    editTC && setEditTC(null)
    config && setConfig(null)
  }

  const handleClose1 = () => {
    editTC && setEditTC(null)
    config && setConfig(null)
    deleteAll & setDeleteAll(false)
    delValues.length && setDelValues([])
    setOpens(false)
    setAdd(null)
  }

  const deleteHandler = async () => {
    try {
      let res
      if (deletes.length) { res = await ApiService.deleteConfig2(deletes) }
      else { res = await ApiService.deleteConfig(delValues) }
      showAlert({
        type: res.data.status,
        msg: res.data.message,
      })

      setTimeout(() => {
        setDelete(null)
        setDelValues([])
        setDeleteAll(false)
        getConfigs(tsId ? tsId : tsIds)
        setVisible(false)
      }, resource.TIMEOUT.SUCCESS)
    } catch (err) {
      console.log(err.response.data)
      showAlert({
        type: err.response.data?.message,
        msg: resource.ERR,
      })
    }
  }

  const execute = async (id) => {
    try {
      setExeLoading()
      const { data } = await axios.get(`/api/v1/configuration/execute/?id=${id}`,
        { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
      )
      getExesTS(data.configuration._id)
      removeExeLoading()
      setPopUp({
        type: data.status,
        msg: resource.FEEDBACK.EXE_DONE,
      })
      setTimeout(() => setPopUp(null), resource.TIMEOUT.GET)
    } catch (err) {
      removeExeLoading()
      console.log(err.response.data)
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      })
    }
  }

  return (
    <Box>
      <Modal
        open={opens}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">

        <Box sx={style} className="scrollbarStyle">
          <IconButton
            sx={{ position: "absolute", top: "2px", right: "2px" }}
            onClick={handleClose1}
            size="small"
            color="error"
          >
            <CancelOutlinedIcon />
          </IconButton>

          <Grid container >
            <Grid
              item
              xs={2.5}
              bgcolor="#eaeff4">
              <SideConfigList setPopUp={setPopUp} tsIds={tsIds} />
            </Grid>

            <Grid item xs={9.5} pl={1.5}>

              {
                configs.length ? (!add && configs.map(e => e.nameId === currConfig &&
                  <Box className="ConfigPreview" key={e.nameId}>

                    <Grid container>
                      <Grid container alignItems="center" mb={1} px={"12px"} py={"8px"} bgcolor={"#ede8ff"} sx={{ color: "#54577d" }}>

                        <Grid sm={6} item >
                          <Typography variant="h6">{resource.EXES.CONF_DETS}</Typography>
                        </Grid>

                        <Grid sm={6} item>
                          <Box display="flex" gap={1}
                            justifyContent="right">
                            {/* <ExportResult /> */}
                            <IconButton
                              size="small"
                              variant="contained"
                              sx={{ color: "#6580e0" }}
                            >
                              <BorderColorRoundedIcon onClick={() => {
                                setEditTC(e._id)
                                setAdd(true)
                              }} />
                            </IconButton>
                            {!delValues.length &&
                              <Button variant="contained" size="small"
                                sx={{
                                  backgroundColor: "#6580e0", "&:hover": {
                                    backgroundColor: "#6580e0"
                                  }
                                }}
                                onClick={() => execute(e._id)} disabled={exeLoading}>
                                Execute
                              </Button>
                            }
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Tabs obj={e} setPopUp={setPopUp} popup={popup} />
                  </Box>
                )) :
                  !add &&
                  <Box sx={{ padding: "8%" }}>
                    <center>
                      <Typography variant="h5">No Configurations. Create new. </Typography>
                    </center>
                  </Box>
              }
              {add && <CreateConfig handleClose={handleClose} setPopUp={setPopUp} tsIds={tsIds} />}
            </Grid>
          </Grid>

          {/* Confirm Deletion Dialog */}
          {deletes && (
            <DeleteDialog
              deleteHandler={deleteHandler}
              name={resource.EXES.CON}
            />
          )}

          {popup && <Box sx={{
            right: "0",
            position: "absolute",
            bottom: 0,
          }}>
            <IconButton
              sx={{ position: "absolute", top: "2px", right: "2px" }}
              onClick={() => setPopUp(null)}
              size="small"
              color="error"
            >
              <CancelOutlinedIcon />
            </IconButton>
            <Alert severity={popup.type} sx={{ height: "100px", padding: "20px" }}>
              <AlertTitle sx={{ fontWeight: "600", fontSize: "15px" }}>{popup.type}</AlertTitle>
              {popup.msg}
              {/* <Link to={`/addrequest?aut=${autId}&testsuite=${tsId}`} style={{ color: "#406887" }}> */}
              {/* </Link> */}
            </Alert>
          </Box>}

          {exeLoading &&
            <Box sx={{
              right: "0",
              position: "absolute",
              bottom: 0,
            }}>
              <Alert severity="info" sx={{ height: "100px", padding: "20px" }}>
                <AlertTitle sx={{ fontWeight: "600", fontSize: "15px" }}>Execution In Progress</AlertTitle>
                {resource.FEEDBACK.PROGRESS}
              </Alert>
              <LinearProgress color="info" />
            </Box>}

        </Box>
      </Modal>
    </Box>
  )
}

ExecuteTestSuite.propTypes = {
  tsIds: PropTypes.string
}