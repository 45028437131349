/* eslint-disable no-undef */
import { useCallback, useReducer } from "react"
import { PropTypes } from "prop-types"

import VarContext from "./VarContext"
import VarReducer from "./VarReducer"
import ApiService from "services/app.service"
import { ALL_VARS, EDIT_VAR, INP_VALUE, NEW_VAR, SET_AUTVARS, SET_ENV, SET_ENVNAMES, SET_ENVS, SET_ENV_NAME, SET_GLOBALS, SET_TSVAR, SET_TSVARS, VISIBLE_VAR, DEL_VAR } from "../types.js";

function VarState(props) {
    const initialState = {
        env: [],
        vars: [],
        envs: [],
        tsVars: [],
        autVars: [],
        envNames: [],
        globalVars: [],
        tsVar: null,
        envName: null,
        editVar: null,
        delVar: null,
        inputValue: null,
        visible_var: null,
        new_var: false,
    };
    const [state, dispatch] = useReducer(VarReducer, initialState);

    const setEditVar = (data) => {
        dispatch({ type: EDIT_VAR, payload: data });
    };

    const setDelVar = (data) => {
        dispatch({ type: DEL_VAR, payload: data });
    };

    const setVisibleVar = (data) => {
        dispatch({ type: VISIBLE_VAR, payload: data });
    };

    const setNewVar = (data) => {
        dispatch({ type: NEW_VAR, payload: data });
    };

    const setVar = (data) => {
        dispatch({
            type: SET_TSVAR,
            payload: data
        });
    };

    const setTsVars = (data) => {
        dispatch({
            type: SET_TSVARS,
            payload: data
        });
    };

    const setAutVars = (data) => {
        dispatch({
            type: SET_AUTVARS,
            payload: data
        });
    };

    const setGlobals = (data) => {
        dispatch({
            type: SET_GLOBALS,
            payload: data
        });
    };

    const setEnv = (data) => {
        dispatch({
            type: SET_ENV,
            payload: data
        });
    };

    const setEnvs = (data) => {
        dispatch({
            type: SET_ENVS,
            payload: data
        });
    };

    const setEnvNames = (data) => {
        dispatch({
            type: SET_ENVNAMES,
            payload: data
        });
    };

    const setVars = (data) => {
        dispatch({
            type: ALL_VARS,
            payload: data
        });
    };

    const setEnvName = (data) => {
        dispatch({
            type: SET_ENV_NAME,
            payload: data
        });
    };

    const setInputValue = (data) => {
        dispatch({
            type: INP_VALUE,
            payload: data
        });
    };

    const getTsVars = async (id) => {
        try {
            const { data } = await ApiService.fetchTsVars(id)
            setTsVars(data.EnvVariables)
        } catch (err) {
            console.log(err.response.data);
        }
    };

    const getVars = async (scope) => {
        try {
            const { data } = await ApiService.fetchVars()
            setVars(data)
            setGlobals(data[0].global)
            setAutVars(data[0].AUT)
            setEnv(data[0].environment)
            if (scope === "AUT")
                return data[0].AUT
            return data[0].environment
        } catch (err) {
            console.log(err.response.data);
            // if (err.response?.data?.error === "jwtExpired") {
            //     localStorage.removeItem(userLocalSession);
            //     window.location.href = "/login"
            // }
        }
    };

    const getAutVars = async (id) => {
        try {
            const { data } = await ApiService.fetchAutVars(id)
            setAutVars(data.EnvVariables)
            return data.EnvVariables
        } catch (err) {
            console.log(err.response.data);
        }
    };

    const getGlobalVars = async () => {
        try {
            const { data } = await ApiService.fetchGlobalVars()
            setGlobals(data.envVariables)
            return data.envVariables
        } catch (err) {
            console.log(err.response.data);
        }
    };

    const getEnv = async (id, env) => {
        try {
            const { data } = await ApiService.fetchEnv(id, env)
            setEnv(data.envVariables)
            return data.envVariables
        } catch (err) {
            console.log(err.response.data);
        }
    };

    const getEnvs = async (id) => {
        try {
            const { data } = await ApiService.fetchEnvs(id)
            let envNames = data.environments.map(e => e._id).map(async e => {
                const { data } = await ApiService.fetchEnv(id, e)
                return data.envVariables
            })
            envNames = await Promise.all(envNames)
            setEnvs(envNames.flat())
            return envNames.flat()
        } catch (err) {
            console.log(err.response.data);
        }
    };

    const getEnvNames = useCallback(async (id) => {
        try {
            const { data } = await ApiService.fetchEnvs(id)
            setEnvNames(data.environments.map(e => e._id))
            // console.log(data.environments.map(e => e._id), 1888)
            return data.environments.map(e => e._id)
        } catch (err) {
            console.log(err.response.data);
        }
    }, [])

    const getVar = async (id) => {
        try {
            const { data } = await ApiService.fetchVar(id)
            setVar(data.variable)
        } catch (err) {
            console.log(err.response.data);
        }
    };

    return (
        <VarContext.Provider
            value={{
                vars: state.vars,
                tsVar: state.tsVar,
                tsVars: state.tsVars,
                new_var: state.new_var,
                env: state.env,
                envs: state.envs,
                envNames: state.envNames,
                envName: state.envName,
                autVars: state.autVars,
                globalVars: state.globalVars,
                editVar: state.editVar,
                delVar: state.delVar,
                inputValue: state.inputValue,
                visible_var: state.visible_var,
                setVar,
                setVars,
                getVar,
                getEnv,
                setEnvs,
                getEnvs,
                setTsVars,
                setAutVars,
                getTsVars,
                getAutVars,
                getGlobalVars,
                setDelVar,
                setEditVar,
                setEnvName,
                setEnvNames,
                getEnvNames,
                setEnv,
                getVars,
                setNewVar,
                setInputValue,
                setVisibleVar,
            }}>
            {props.children}
        </VarContext.Provider>
    );
}

VarState.propTypes = {
    children: PropTypes.node
};

export default VarState;