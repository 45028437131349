import PropTypes from "prop-types"
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import rule from "data/test";
import TCInput from "./TCInput";

const RuleCheckCard = ({
  row,
  rules,
  deleteRule,
  ruleIndex,
  columnIndex,
  checkBoxHandler,
}) => {
  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          color: "#54577d",
          display: "flex",
          margin: "0px 5px 0px 5px",
          padding: "5px",
          flexWrap: "wrap",
          cursor: "pointer",
          borderRadius: "4px",
          position: "relative",
          alignItems: "center",
          background: "#ede8ff",
          justifyContent: "flex-start",
        }}>
        <IconButton
          sx={{
            position: "absolute",
            top: -12,
            right: -10,
            "& svg": { fontSize: "15px" },
          }}
          size="small"
          color="error"
          onClick={() => deleteRule(columnIndex, rules?.key, rules?._id)}
        >
          <CancelOutlinedIcon />
        </IconButton>
        <Box className="space-between" sx={{ width: "100%" }}>
          <Box className="v-center">
            <Tooltip title="Drag & drop to reorder rules" placement="top">
              <IconButton size="small">
                <DragIndicatorIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Typography variant="h3" sx={{ fontSize: "13px" }}>{ruleIndex + 1}. {rules?.displayName || rule.ruleData.find(e => e.value === rules?.operation)?.name}</Typography>
          </Box>
        </Box>
        <TCInput
          row={row}
          input={rules}
          ruleIndex={ruleIndex}
          columnIndex={columnIndex}
          checkBoxHandler={checkBoxHandler}
        />

      </Box>
    </Box>
  );
};

export default RuleCheckCard;

RuleCheckCard.propTypes = {
  row: PropTypes.array,
  rules: PropTypes.object,
  deleteRule: PropTypes.func,
  ruleIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  checkBoxHandler: PropTypes.func,
}