import { useRef, useState, useContext, useMemo } from "react"
import { PropTypes } from "prop-types"
import { Box, Tooltip, IconButton } from "@mui/material"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"

import { resource } from "data/resource";
import { CustomAgGrid } from "components/AgGrid";
import { ExeResultheadCells } from "data/executions";

import APIsContext from "contexts/APIs/APIsContext";
import TestResultContext from "contexts/TestResult/TestResultContext";

function AgGridCheckbox({ data }) {
  const apisContext = useContext(APIsContext)
  const testResultContext = useContext(TestResultContext)

  const { setResponse } = apisContext;
  const { getExeResults } = testResultContext;

  return (
    <Box
      sx={{
        "& .MuiIconButton-root": {
          padding: "3px",
          fontSize: "12px",
        },
      }}
    >
      <IconButton
        size="small"
        className="accordianActionIcon"
        onClick={() => {
          getExeResults(data._id);
          setResponse(true);
        }}
      >
        <Tooltip title={resource.RES} placement="top" arrow>
          <RemoveRedEyeIcon />
        </Tooltip>
      </IconButton>
    </Box>
  );
}

const ExecutionsList = ({ exes }) => {
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState([]);

  const gridRef = useRef();
  const ScrollRef = useRef();

  const action = {
    headerName: "Manage",
    sortable: false,
    cellRenderer: AgGridCheckbox,
    lockPosition: "right",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    filter: false,
  };
  useMemo(() => {
    ExeResultheadCells[3] = action;
  }, []);

  return (
    <Box ref={ScrollRef} sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", mb: 2, "& .refreshBtn": { display: "none" } }}>
        <CustomAgGrid
          gridRef={gridRef}
          headCells={ExeResultheadCells}
          setSelected={setSelected}
          rows={exes}
        />
      </Box>
    </Box>
  );
};

export default ExecutionsList;

ExecutionsList.propTypes = {
  exes: PropTypes.array,
}
AgGridCheckbox.propTypes = {
  data: PropTypes.object,
}
