/* eslint-disable no-undef */
import { useState, useEffect, useContext, Fragment } from "react"
import { PropTypes } from "prop-types"
import { styled } from "@mui/material/styles"

import {
  Box,
  IconButton,
  Table, TableHead, TableCell, TableRow, TableBody, TableContainer, Tooltip, tableCellClasses
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

import stateData from "data/stateData"
import { resource } from "data/resource"
import ApiService from "services/app.service"
import EditVariable from "./EditTSVariableForm"

import VarContext from "contexts/Variables/VarContext"
import UtilsContext from "contexts/Utils/UtilsContext"
export default function CreateVariableForm({ onAddInput, status, setStatus }) {

  const tsId = new URLSearchParams(document.location.search).get("testsuite")

  const varContext = useContext(VarContext)
  const utilsContext = useContext(UtilsContext)

  const { showAlert, edit, errorData } = utilsContext
  const { tsVar, tsVars, editVar, getVar, getTsVars, setEditVar } = varContext

  const [note, setNote] = useState(null)
  const [addInp, setAddInp] = useState(0)
  const [varData, setVarData] = useState(stateData.tsVar)

  useEffect(() => {
    if (tsVar && editVar) {
      setVarData({
        name: tsVar?.name,
        value: tsVar?.value,
        isMapped: tsVar?.isMapped,
        scope: tsVar?.scope,
        endpointId: edit,
        testSuiteId: tsVar?.testSuiteId,
        responsePath: tsVar?.responsePath,
      })
    }
  }, [tsVar])

  const setEdit = async (id) => {
    if (editVar !== id) {
      setEditVar(id)
      getVar(id)
    }
    status === "add" ? await deleteHandler1() : setStatus("update")
  }

  const deleteHandler = async (id) => {
    try {
      const a = [id]
      const { data } = await ApiService.deleteVar(a)
      showAlert({
        type: data.status,
        msg: data.message,
      });
      getTsVars(tsId)
      // setAddInp(0);
    } catch (err) {
      console.log(err.response);
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      })
    }
  }

  const deleteHandler1 = async () => {
    if (editVar && status === "add") {
      const a = [editVar]
      await ApiService.deleteVar(a)
      getTsVars(tsId)
    }
    setEditVar(null);
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#d0c2ff",
      border: "1px solid #d0c2ff",
      color: "#54577d",
      fontWeight: 550,
      padding: "7px",
      fontSize: 13,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12.2,
      padding: "13px",
      color: "#54577d",
      border: 0,
    },
  }));

  return (
    <Fragment >
      <TableContainer>
        <Table >
          <TableHead onClick={deleteHandler1}>
            <TableRow>
              <StyledTableCell width="25%" align="center">{resource.AUT.HEAD.NAME}</StyledTableCell>
              <StyledTableCell width="25%" align="center">{resource.VALUE}</StyledTableCell>
              <StyledTableCell width="40%" align="center">{resource.VARS.KEY}</StyledTableCell>
              <StyledTableCell width="10%" align="center">{resource.AUT.HEAD.ACTION}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tsVars?.map((row, i) =>
              editVar === row?._id && row?.endpointId === edit ? (
                <EditVariable
                  key={row?._id}
                  obj={row}
                  index={i}
                  varData={varData}
                  setVarData={setVarData}
                  addInp={addInp}
                  setAddInp={setAddInp}
                  onAddInput={onAddInput}
                  deleteHandler={deleteHandler}
                  status={status}
                  setStatus={setStatus}
                />
              ) : (
                <Fragment key={row?._id}
                >                <Tooltip title={note && `Not Editable! The variable ${note} is already mapped to another API's Response value. To access it, visit the API.`}>
                    <TableRow
                      sx={{
                        opacity: row?.endpointId === edit ? 1 : 0.6, height: "35px", ":hover": row?.endpointId === edit && {
                          backgroundColor: "#ede8ff"
                        }
                      }}
                      onMouseEnter={() => row?.endpointId !== edit && setNote(row?.name)}
                      onMouseLeave={() => setNote(null)}
                    >
                      <TableCell
                        align="center"
                        onClick={() => {
                          setEdit(row?._id);
                        }}
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ width: "40%" }}
                        onClick={() => setEdit(row?._id)}
                      >
                        {row?.value.length > 50 ? `${row?.value.substring(0, 49)}...` : row?.value}
                      </TableCell>
                      <TableCell
                        align="center"
                        onClick={() => setEdit(row?._id)}
                      >
                        {row?.responsePath}
                      </TableCell>
                      <TableCell>
                        <Box className="autListIcons">
                          <IconButton
                            size="small"
                            className="accordianActionIcon"
                            onClick={() =>
                              row?.endpointId === edit && deleteHandler(row?._id)
                            }>
                            <Tooltip
                              title={resource.DELETE}
                              placement={"top"}
                              arrow>
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </Tooltip>
                </Fragment>
              )
            )}
            {!editVar &&
              <TableRow
                sx={{ ":hover": { backgroundColor: "#ede8ff" } }}
                onClick={() => {
                  editVar && setEditVar(null);
                  // setEditVar(true)
                  onAddInput();
                }}
              >
                <TableCell align="center" sx={{ color: errorData?.name ? "#D22B2B" : "grey", height: "35px", opacity: errorData?.name ? 1 : 0.5 }}>
                  {errorData?.name ? errorData?.name : "Add New Variable"}
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>

      <Box onClick={deleteHandler1} sx={{ paddingTop: "15%" }}></Box>
    </Fragment >
  );
}

CreateVariableForm.propTypes = {
  onAddInput: PropTypes.func,
  status: PropTypes.string,
  setStatus: PropTypes.func
};
