import { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@mui/material";

import Body from "./TabsList/Body";
import Header from "./TabsList/Header";
import Request from "./TabsList/Request";
import TestResults from "./TabsList/TestResults";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box className="scrollbarStyle" sx={{ overflowY: "auto", maxHeight: "calc(100vh - 360px)" }}>{children}</Box>}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function ResultTabs({ res, req, oneTime }) {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Tabs
                value={value}
                onChange={handleChange}
                sx={{ my: oneTime ? -1 : 1, ml: oneTime ? 0 : 1, paddingBottom: "6px" }}
            >
                <Tab label="Test Results" sx={{ mb: oneTime && -1 }} />
                <Tab label="Request" sx={{ mb: oneTime && -1 }} />
                <Tab label="Response" sx={{ mb: oneTime && -1 }} />
                <Tab label="Header" sx={{ mb: oneTime && -0.5 }} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
                <TestResults res={res} req={req?.find(e => e._id === res.endpointId)}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Request req={req?.find(e => e._id === res.endpointId)} res={res} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Body res={res} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <Header res={res} />
            </CustomTabPanel>
        </Box>
    );
}

ResultTabs.propTypes = {
    res: PropTypes.object,
    req: PropTypes.array,
    oneTime: PropTypes.bool,
}