import { SET_ADD, SET_ALL_CONFIGS, SET_CONFIG, SET_CONFIGS, SET_CURRENT } from "../types.js";

const ExecutionReducer = (state, action) => {
    switch (action.type) {
        case SET_CONFIGS:
            return {
                ...state,
                configs: action.payload,
            };
        case SET_ALL_CONFIGS:
            return {
                ...state,
                allConfigs: action.payload,
            };
        case SET_CONFIG:
            return {
                ...state,
                config: action.payload,
            };
        case SET_CURRENT:
            return {
                ...state,
                currConfig: action.payload,
            };
        case SET_ADD:
            return {
                ...state,
                add: action.payload,
            };
        default:
            return state;
    }
};

export default ExecutionReducer;