import { useContext } from "react"
import { Box, Grid, Typography } from "@mui/material"

import { resource } from "data/resource"
import { AUTheadCells } from "data/variables"
import VarContext from "contexts/Variables/VarContext"
import CustomTabless from "components/Tables/CustomTabless"

export default function GlobalVariablesList() {

  const varContext = useContext(VarContext)
  const { globalVars } = varContext

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Box className="tsHeader" px="16px">
          <Grid container alignItems="center">
            <Grid sm={6} item>
              <Typography>
                <b>Global</b> | {resource.VARS.COUNT}: <b>{globalVars?.length}</b>
              </Typography>
            </Grid>
            <Grid sm={6} textAlign="right" item></Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", mb: 2, "& .refreshBtn": { display: "none" } }}>
          <CustomTabless
            category={"global"}
            headCells={AUTheadCells}
            rows={globalVars}
          />
        </Box>
      </Box>
    </Box>
  );
}
