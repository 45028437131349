import { useContext } from "react"
import { PropTypes } from "prop-types"
import { styled } from "@mui/material/styles"
import { Box, Grid, Typography, IconButton } from "@mui/material"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"

import ResultTabs from "./Tabs"
import { resource } from "data/resource"
// import ExportResult from "./ExportResult"
import TestResultContext from "contexts/TestResult/TestResultContext"

const Accordion = styled((props) => (
  <MuiAccordion
    defaultExpanded
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const SingleExeResult = ({ ids, oneTime }) => {
  const testResultContext = useContext(TestResultContext);
  const { results, exesTs } = testResultContext;

  let configs;
  if (ids)
    configs = exesTs.find(e => e._id === ids)

  return (
    <>
      <Box className="OL singleExe">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" sx={{ color: "#54577d" }}>{resource.RES}</Typography>
          {/* <Box ml="auto">
            <IconButton
              size="small"
              variant="contained"
              // onClick={handleClick}
              disableelevation="true"
              sx={{ color: "#6580e0", fontSize: "22px" }}
            >
              <DownloadOutlinedIcon />
            </IconButton>
          </Box> */}
        </Box>

        {ids &&
          <Box my={1.5} bgcolor="#fff" p={1.5}>
            <Grid container textAlign="center">
              <Grid sm={4} item>
                <Typography>
                  Execution At <br /> <b>{new Date(configs?.createdAt).toLocaleString()}</b>
                </Typography>
              </Grid>
              <Grid sm={4} item>
                <Typography>
                  Config <br /> <b>{configs?.configuration?.name}</b>
                </Typography>
              </Grid>
              <Grid sm={4} item>
                <Typography>
                  Avg. Response Time <br /> <b>102.1 ms</b>
                </Typography>
              </Grid>

              <Grid sm={4} item mt={1}>
                <Typography>
                  No. of Requests <br /> <b>{results.length}</b>
                </Typography>
              </Grid>

              <Grid sm={4} item mt={1}>
                <Typography>
                  Environment <br /> <b>{configs?.configuration?.environment}</b>
                </Typography>
              </Grid>
              <Grid sm={4} item mt={1}>
                <Typography>
                  Delay <br /> <b>{configs?.configuration?.delay} ms</b>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        }
        {
          results?.sort((a, b) => a.serialNumber - b.serialNumber)?.map((res, i) =>
            <Accordion key={res._id}>
              <Box display="flex" alignItems="center" p="4px 0px" gap={1}>
                <AccordionSummary></AccordionSummary>

                <Grid container alignItems="left" mt={1}>
                  <Grid sm={12} item
                    sx={{ overflow: "hidden", textOverflow: "ellipsis", wordBreak: "break-all" }}>
                    <Typography component="div" mb={0.5}>
                      <Box component="span" color={res.method === "GET" ? "green" : (res.method === "POST" ? "goldenrod" : (res.mehod === "PUT" ? "deeppink" : (res.method === "DELETE" ? "orangered" : "rebeccapurple")))} fontWeight="600">
                        {res.method}
                      </Box>{" "}
                      - {res.endpointName} <b>{!ids && ` ( Iteration ${i + 1} )`}</b>
                    </Typography>
                    <Typography fontSize="10px" opacity="0.5" sx={{ wordBreak: "break-all", whiteSpace: "normal" }}>
                      {res.url}
                    </Typography>
                  </Grid>

                  <Grid sm={12} item>
                    <Box
                      gap={1.5}
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      className="responseRes"
                    >
                      <Typography sx={{ color: (res?.error || res?.response?.status === "FAIL") && "red" }}>
                        {res?.response?.statusCode} {res?.response?.statusText}
                      </Typography>
                      <Typography>{res?.response?.responseTime} ms</Typography>
                      <Typography>{res?.response?.responseSize} KB</Typography>
                    </Box>
                  </Grid>
                </Grid>

              </Box>

              <ResultTabs res={res} req={configs?.configuration?.endpoints?.endpoint} oneTime={oneTime} />
            </Accordion>
          )
        }
      </Box >
    </>
  );
};

export default SingleExeResult;

SingleExeResult.propTypes = {
  ids: PropTypes.string,
  oneTime: PropTypes.bool,
  config: PropTypes.object,
}
