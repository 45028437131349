import { useState } from "react"
import { PropTypes } from "prop-types"
import {
  Box,
  Collapse,
  Grid,
  List, ListItemButton, ListItemText, ListItemIcon,
  Typography
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import { resource } from "data/resource";

const TCRulesList = ({ validationTemplate, setType, type }) => {

  const [security, setSecurity] = useState(type === "Security");
  const [functional, setFunctional] = useState(type === "Functional");
  const [performance, setPerformance] = useState(type === "Performance");

  const toggleList = (listType) => {
    if (listType === resource.TC.FUNC) {
      setFunctional((prev) => !prev);
      setPerformance(false);
      setSecurity(false);
    }
    else if (listType === resource.TC.SEC) {
      setSecurity((prev) => !prev);
      setPerformance(false);
      setFunctional(false);
    }
    else {
      setPerformance((prev) => !prev);
      setSecurity(false);
      setFunctional(false);
    }
  };

  const securityAssertions = validationTemplate?.filter(each => each?.category === "security");
  const functionalAssertions = validationTemplate?.filter(each => each?.category === "functional");
  const performanceAssertions = validationTemplate?.filter(each => each?.category === "performance");

  const onDragStart = (e, data) => {
    e.dataTransfer.setData("ruleData", JSON.stringify(data));
  };

  const renderNestedList = (validationOptions, open) => (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List
        className="flex column"
        sx={{ gap: 0.3 }}
        component="div"
        disablePadding
      >
        {validationOptions?.map((each, i) => (
          <ListItemButton
            key={i}
            sx={{ pl: 1, py: 0.2, bgcolor: "#ede8ff", "&:hover": { backgroundColor: "#ede8ff" } }}
          >
            <Box
              className="v-center"
              draggable
              width={"100%"}
              onDragStart={(e) => onDragStart(e, each)}
            >
              <ListItemIcon fontSize="small" sx={{ minWidth: "26px" }}>
                <DragIndicatorIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={each?.displayName} />
            </Box>
          </ListItemButton>
        ))}
      </List>
    </Collapse>
  );

  const renderListItems = (primary, open, listType) => (
    <ListItemButton sx={{
      color: "#54577d",
      padding: "4px 0.5rem",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      "&:hover": {
        backgroundColor: "#ede8ff",
      },
    }}
      onClick={() => {
        toggleList(listType);
        setType(listType);
      }}>
      <ListItemText primary={primary} />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
  );

  return (
    <Grid item md={2.5} className="ecRules">
      <Typography className="rulesTitle" >{resource.TC.RULE}</Typography>

      <List sx={{
        width: "100%",
        paddingTop: "0px",
      }}>
        {renderListItems(resource.TC.FUNC, functional, resource.TC.FUNC)}
        {renderNestedList(functionalAssertions, functional)}

        {renderListItems(resource.TC.SEC, security, resource.TC.SEC)}
        {renderNestedList(securityAssertions, security)}

        {renderListItems(resource.TC.PERF, performance, resource.TC.PERF)}
        {renderNestedList(performanceAssertions, performance)}
      </List>
    </Grid>
  );
};

export default TCRulesList

TCRulesList.propTypes = {
  setType: PropTypes.func,
  type: PropTypes.string,
  validationTemplate: PropTypes.array,
}
