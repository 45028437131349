import React from "react"
import { PropTypes } from "prop-types"
import { styled } from "@mui/material/styles"
import { Box, TableCell, TableRow } from "@mui/material";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f2fa",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ede8ff",
  }
}));

export default function CustomExpandableTableRow({
  id,
  children,
  showAccordian,
  expandComponent,
  onClickArrow,
  ...otherProps
}) {
  return (
    <React.Fragment>
      <StyledTableRow {...otherProps}>
        {children}
        <TableCell>
          <Box
            className="alignCenter"
            onClick={() => onClickArrow()}>
            <span className="accordionArrow">
              {showAccordian === id ? <KeyboardArrowDownOutlinedIcon /> : <KeyboardArrowUpOutlinedIcon />}
            </span>
          </Box>
        </TableCell>
      </StyledTableRow>

      {showAccordian === id && <StyledTableRow>{expandComponent}</StyledTableRow>}
    </React.Fragment>
  );
}

CustomExpandableTableRow.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  showAccordian: PropTypes.bool,
  onClickArrow: PropTypes.func,
  expandComponent: PropTypes.node,
}