import { useEffect, useRef } from "react";
import { lastActivity } from "../_helpers/Constant";

const SessionTimeout = ({ timeout, onTimeout }) => {
  // Create a reference for the timer
  const timerRef = useRef(null);

  // Function to reset the session timer
  const resetTimer = () => {
    clearTimeout(timerRef.current);
    startTimer();

    try {
      const currentTime = Date.now();
      localStorage.setItem(lastActivity, currentTime);
    } catch (error) {
      console.error("Failed to update localStorage:", error);
    }
  };

  // Function to start the session timer
  const startTimer = () => {
    try {
      const dqgLastActivity = Number(localStorage.getItem(lastActivity));
      const remainingTime = timeout - (Date.now() - dqgLastActivity);
      timerRef.current = setTimeout(
        onTimeout,
        remainingTime > 0 ? remainingTime : 0
      );
    } catch (error) {
      console.error(
        "Failed to retrieve last activity from localStorage:",
        error
      );
    }
  };

  // Function to handle user activity
  const handleUserActivity = () => {
    resetTimer();
  };

  // Effect to set up event listeners and manage session timeout
  useEffect(() => {
    // List of user activity events to listen for
    const userActivityEvents = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
      "keydown",
    ];

    // Add event listeners for user activity events
    userActivityEvents.forEach((eventName) => {
      window.addEventListener(eventName, handleUserActivity);
    });

    // Start the session timer when the component mounts
    resetTimer();

    // Handle storage events from other tabs
    const handleStorageEvent = (event) => {
      if (event.key === lastActivity) {
        startTimer();
      }
    };
    window.addEventListener("storage", handleStorageEvent);

    // Cleanup: Remove event listeners and clear the timer
    return () => {
      userActivityEvents.forEach((eventName) => {
        window.removeEventListener(eventName, handleUserActivity);
      });
      window.removeEventListener("storage", handleStorageEvent);
      clearTimeout(timerRef.current);
    };
  }, [timeout, onTimeout]);

  // This component does not render any UI elements
  return null;
};

export default SessionTimeout;
