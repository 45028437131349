import { useContext, useState } from "react";
import { PropTypes } from "prop-types";
import { Box, Grid, Typography, Tooltip } from "@mui/material";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";

import { allVars } from "data/variables";
import ImportPopup from "../Utils/ImportPopup";
import APIsContext from "contexts/APIs/APIsContext";
import UtilsContext from "contexts/Utils/UtilsContext";
import VarContext from "contexts/Variables/VarContext";
export default function CategoryVariablesTiles({ envs, setEnvs, onclickCard }) {

  const varContext = useContext(VarContext);
  const apisContext = useContext(APIsContext);
  const utilsContext = useContext(UtilsContext);

  const { setSaved } = apisContext;
  const { setVisibleVar } = varContext;
  const { setDelValues, changes } = utilsContext;

  const [open, setOpen] = useState(false);

  return (
    <Box className="autTiles" mt={1}>
      <Box
        className="DBListMain"
        sx={{ gridTemplateColumns: "repeat(3, 1fr) !important" }}
      >
        {allVars?.map((obj, index) => (
          <Box key={index} className="DSListItem">
            <Box
              className="DBList"
              onClick={() => {
                if (changes)
                  setSaved(false)
                else {
                  setDelValues([]);
                  onclickCard(obj)
                  setVisibleVar(obj._id)
                }
              }}
              color="#455a64"
            >
              <Grid container alignItems="center" item>
                <Grid md={12} item>
                  <Box className="DBLeft">
                    <Box display="flex">
                      <Typography className="labels">
                        {obj.categoryName}
                      </Typography>
                    </Box>

                    <Typography align="justify" mt={1}>
                      <strong>{obj.description}</strong>
                    </Typography>
                    <br />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box className="autActions">
              {obj.scope === "environment" && (
                <Tooltip
                  size="small"
                  placement="top"
                  arrow
                  title="Import variables"
                >
                  <FileDownloadRoundedIcon
                    size="large"
                    className="DBAdd"
                    onClick={() => setOpen(!open)}
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      <ImportPopup
        open={open}
        envs={envs}
        setEnvs={setEnvs}
        setOpen={setOpen}
        name={"Environment Variables"}
      />
    </Box>
  );
}

CategoryVariablesTiles.propTypes = {
  envs: PropTypes.array,
  setEnvs: PropTypes.func,
  onclickCard: PropTypes.func,
};
