import { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { Logout as LogoutIcon } from "@mui/icons-material"
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded"

import authHeader from "services/auth-header"
import authService from "services/auth.service"
import { userLocalSession } from "_helpers/Constant"
import AutContext from "contexts/Aut/AutContext"

const LANGUAGES = {
  ENGLISH: "en",
  SPANISH: "es",
}

const AccountSettings = () => {
  const autContext = useContext(AutContext)
  const { setAuts } = autContext

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedLanguage, setSelectedLanguage] = useState("en")
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logOut = async () => {
    try {
      const reqData = {
        UserId: authHeader()?.User?.UserId,
        SessionId: authHeader()?.User?.SessionId,
      }
      const response = await authService.logout(reqData)
      if (response?.ApiStatusCode === 200) {
        console.log("removing item")
        localStorage.removeItem(userLocalSession)
        setAuts([])
        navigate("/login", { replace: true })
      }
    } catch (err) {
      console.log("Error during logout:", err)
    }
  }

  const handleLanguageChange = (e) => {
    const languageValue = e.target.value
    setSelectedLanguage(languageValue)
  }

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{ gap: 0.5, cursor: "pointer" }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className="v-center"
      >
        <Avatar alt="" sx={{ width: 26, height: 26 }} />
        <Typography variant="bold">
          {authHeader()?.User?.FirstName}{" "}{authHeader()?.User?.MiddleName}
        </Typography>
        <KeyboardArrowDownRoundedIcon fontSize="small" />
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 0,
          },
          "&:before": {
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Typography variant="bold"
          sx={{
            padding: "10px",
          }}>
          {authHeader()?.User?.Email}
        </Typography>
        <MenuItem sx={{ px: 0 }}>
          <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
            <InputLabel>{"Language"}</InputLabel>
            <Select
              value={selectedLanguage}
              label="Language"
              onChange={handleLanguageChange}
            >
              <MenuItem value={LANGUAGES.ENGLISH}>English</MenuItem>
              <MenuItem value={LANGUAGES.SPANISH}>Español</MenuItem>
            </Select>
          </FormControl>
        </MenuItem>
        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {"Logout"}
        </MenuItem>
      </Menu>
    </>
  )
}

export default AccountSettings
