/* eslint-disable react/prop-types */
import { useState, useContext, useEffect } from "react"
import { Chip, Paper, Stack, TextField, Box, Typography, Tooltip } from "@mui/material"

import APIsContext from "contexts/APIs/APIsContext";
import VarContext from "contexts/Variables/VarContext";
import UtilsContext from "contexts/Utils/UtilsContext";
import TestCaseContext from "contexts/TestCase/TestCaseContext";

function TextFieldWithDropdown({ index, index2, name, defaults, disabled, register, errors }) {

  const varContext = useContext(VarContext);
  const apisContext = useContext(APIsContext);
  const utilsContext = useContext(UtilsContext);
  const testCaseContext = useContext(TestCaseContext);

  const { changes, setChanges } = utilsContext;
  const { env, globalVars, autVars, tsVars } = varContext;
  const { requestData, setRequestData, add } = apisContext;
  const { testcaseData, setTestcaseData } = testCaseContext;

  useEffect(() => {
    const arr = []
    arr.push(...globalVars, ...autVars, ...tsVars, ...env)
    if (name) setInputValue(defaults)
    setFilteredDropDown(arr)
    setogData(arr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env, tsVars])

  const [scope, setScope] = useState("");
  const [ogData, setogData] = useState([]);
  const [varInfo, setVarInfo] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredDropDown, setFilteredDropDown] = useState([]);
  const [selectedVariableData, setSelectedVariableData] = useState({});

  const escapeRegExp = (string) => {
    return string?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  }

  const handleInputChange = (e) => {
    !changes && !add && setChanges(true);
    setInputValue(e.target.value);
    const matches = e.target.value.match(/{{(.*?)}}/g);

    if (e.target.value.includes("{{")) {
      if (matches?.length) {
        matches.forEach(match => {
          if (match === matches[matches.length - 1]) {
            const searchText = escapeRegExp(e.target.value.split(match)[1]?.split("{{")?.[1]?.split("}}")?.[0]);
            if (searchText !== undefined) {
              setDropdownOpen(true);
              setFilteredDropDown(ogData.filter(el => el.name.search(new RegExp(searchText, "i")) !== -1));
            }
            else { setDropdownOpen(false); }
          }
        });
      }
      else {
        setDropdownOpen(true);
        const searchText = escapeRegExp(e.target.value.split("{{")[1].split("}}")[0]);
        setFilteredDropDown(ogData.filter(el => el.name.search(new RegExp(searchText, "i")) !== -1));
      }
    }
    else { setDropdownOpen(false); }

    if (name === "password") {
      setRequestData({
        ...requestData,
        basicAuth: { ...requestData.basicAuth, [name]: e.target.value },
      });
    }
    else if (name === "apiKey")
      setRequestData({
        ...requestData,
        apiToken: { ...requestData.apiToken, value: e.target.value },
      });
    else if (name === "baseURL" || name === "value" || name === "bearerToken") {
      setRequestData({
        ...requestData,
        [name]: e.target.value,
      });
    }

    else if (name === "form") {
      setRequestData({
        ...requestData,
        form: {
          ...requestData.form,
          fields: [
            ...requestData.form.fields.slice(0, index),
            {
              ...requestData.form.fields[index],
              value: e.target.value,
            },
            ...requestData.form.fields.slice(index + 1),
          ],
        },
      });
    }
    else if (name === "Value") {
      setTestcaseData({
        ...testcaseData,
        functionalAssertions: [
          ...testcaseData.functionalAssertions.slice(0, index2),
          {
            ...testcaseData.functionalAssertions[index2],
            rules: [
              ...testcaseData.functionalAssertions[index2].rules.slice(0, index),
              {
                ...testcaseData.functionalAssertions[index2].rules[index],
                expectedValue: e.target.value,
              },
              ...testcaseData.functionalAssertions[index2].rules.slice(index + 1),
            ],
          },
          ...testcaseData.functionalAssertions.slice(index2 + 1),
        ],
      });
    }
    else {
      setRequestData({
        ...requestData,
        [name]: [
          ...requestData[name].slice(0, index),
          { ...requestData[name][index], value: e.target.value.trim() },
          ...requestData[name].slice(index + 1),
        ],
      });
    }
    // setDropdownOpen(false);
  };

  const handleOptionSelect2 = (option) => {
    if (!inputValue.match(/{{(.*?)}}/g)) { setInputValue(`${inputValue.split("{{")[0]}{{${option.name}}}`); }
    else { setInputValue(`${inputValue.slice(0, inputValue.lastIndexOf("{{"))}{{${option.name}}}`); }

    const output = !inputValue.match(/{{(.*?)}}/g) ?
      `${inputValue.split("{{")[0]}{{${option.name}}}` :
      `${inputValue.slice(0, inputValue.lastIndexOf("{{"))}{{${option.name}}}`;

    if (name === "password") {
      setRequestData({
        ...requestData,
        basicAuth: { ...requestData.basicAuth, [name]: output },
      });
    }
    else if (name === "apiKey")
      setRequestData({
        ...requestData,
        apiToken: { ...requestData.apiToken, value: output },
      });
    else if (name === "baseURL" || name === "value" || name === "bearerToken") {
      setRequestData({
        ...requestData,
        [name]: output,
      });
    }
    else if (name === "form") {
      setRequestData({
        ...requestData,
        form: {
          ...requestData.form,
          fields: [
            ...requestData.form.fields.slice(0, index),
            {
              ...requestData.form.fields[index],
              value: output,
            },
            ...requestData.form.fields.slice(index + 1),
          ],
        },
      });
    }
    else if (name === "Value") {
      setTestcaseData({
        ...testcaseData,
        functionalAssertions: [
          ...testcaseData.functionalAssertions.slice(0, index2),
          {
            ...testcaseData.functionalAssertions[index2],
            rules: [
              ...testcaseData.functionalAssertions[index2].rules.slice(0, index),
              {
                ...testcaseData.functionalAssertions[index2].rules[index],
                expectedValue: output,
              },
              ...testcaseData.functionalAssertions[index2].rules.slice(index + 1),
            ],
          },
          ...testcaseData.functionalAssertions.slice(index2 + 1),
        ],
      });
    }
    else {
      setRequestData({
        ...requestData,
        [name]: [
          ...requestData[name].slice(0, index),
          { ...requestData[name][index], value: output },
          ...requestData[name].slice(index + 1),
        ],
      });
    }
    setDropdownOpen(false);
  };

  const infoVarPop = () => {
    let matches = inputValue?.match(/{{(.*?)}}/g);
    matches = matches?.map(m => m.match(/{{(.*?)}}/)[1]);
    // console.log(ogData, 213)
    matches = matches?.map(el => {
      let val = ogData.filter(e => e.name === el).map(e => e.scope)
      console.log(el, val)
      if (val.includes("environment")) val = "environment"
      else if (val.includes("testSuite")) val = "testSuite"
      else if (val.includes("AUT")) val = "AUT"
      else if (val.includes("global")) val = "global"
      return ogData.find(e => e.name === el && e.scope === val) ?? el
    })
    // console.log(matches, 222)
    if (matches?.length === 1 && typeof matches[0] === "string") setVarInfo("noEnv")
    else if (matches) setVarInfo({ name, matches })
  }

  const selectScope = (each) => {
    setSelectedVariableData(each)
    setScope(each?.scope)
    const val = filteredDropDown.filter(e => e.name === each?.name).map(e => e.scope)
    if (each?.scope === "testSuite") {
      if (val.includes("environment")) { setScope("environment"); }
    }
    else if (each?.scope === "AUT") {
      if (val.includes("environment")) setScope("environment")
      else if (val.includes("testSuite")) setScope("testSuite")// env , ts
    }
    else if (each?.scope === "global") {
      if (val.includes("environment")) setScope("environment")
      else if (val.includes("testSuite")) setScope("testSuite")
      else if (val.includes("AUT")) setScope("AUT") // env , ts, aut
    }
  };

  return (
    <Box position="relative">

      <TextField
        fullWidth
        minRows={3}
        size="small"
        autoComplete='off'
        variant="outlined"
        name={`val${index}`}
        placeholder={name === "form" ? "Value" : name}
        value={name === "Value" ? defaults : inputValue}
        multiline={(name === "bearerToken" || name === "apiKey") && true}
        disabled={disabled !== undefined ? !disabled : false}

        sx={{
          input: {
            color: inputValue?.match(/{{(.*?)}}/g) && "deeppink",
          },
          "& .MuiInputBase-root":
            (name === "baseURL" || name === "value") && {}
        }}
        inputProps={{ style: { color: inputValue?.match(/{{(.*?)}}/g) && "deeppink" } }}
        onMouseOver={infoVarPop}
        onMouseOut={() => setVarInfo(false)}
        onChange={!register && handleInputChange}
        {...register && register(`val${index}`, {
          onChange: e => handleInputChange(e),
          required: !inputValue && "Field cannot be empty",
        })}
        error={Boolean(errors?.[`val${index}`]) || (name === "Value" && !defaults)}
        helperText={errors?.[`val${index}`]?.message || (name === "Value" && !defaults && "Field cannot be empty")}
      />

      {varInfo.name === name ?
        <Paper sx={{
          borderRadius: "10px", position: "fixed",
          // right: name === "bearerToken" ? 0 : -20,
          // top: 100,
          zIndex: 2,
        }}>
          <Box className="scrollbarStyle" sx={{
            padding: "15px", boxShadow: "10px 10px 15px #ccc", width: "100%"
          }}>
            <Stack direction="column" spacing={2}>
              {varInfo.matches.filter((e, i, arr) => e?.name ? (i === arr.findIndex(t => t.name === e.name)) : e).map((e, i) =>
                <Box key={i}>
                  {e?.name ?
                    <>
                      <Typography variant="h6" sx={{ pb: 0.5, color: "purple" }}>
                        {e?.name}
                      </Typography>
                      <Tooltip title={e?.value?.toString()}>
                        <Typography>
                          Value: <b>{e?.value?.length < 14 ? e?.value ?? "-"
                            : e?.value?.toString().substring(0, 25) ?? "-"}</b>
                        </Typography>
                      </Tooltip>
                      <Typography>
                        Scope: <b style={{ color: e?.scope && "deeppink" }}>{e?.scope ?? "-"}</b>
                      </Typography>
                      <Typography>
                        Mapping Key: <b>{e?.responsePath ?? "-"}</b>
                      </Typography>
                    </> :
                    <>
                      <Typography variant="h6" sx={{ pb: 0.5, color: "purple" }}>
                        {e} <i style={{ fontSize: "12px", color: "orange" }}>(unresolved)</i>
                      </Typography>
                      <Typography>
                        Define variables or
                        select environment
                      </Typography>
                    </>
                  }
                </Box>
              )}
            </Stack>
          </Box>
        </Paper> :
        varInfo === "noEnv" &&
        <Paper sx={{
          borderRadius: "10px", position: "absolute", right: name === "bearerToken" ? 0 : -15, bottom: -130, zIndex: 2,
        }}>
          <Box sx={{ padding: "15px", boxShadow: "10px 10px 15px #ccc" }}>
            <Typography variant="h6" sx={{ pb: 0.5, color: "crimson" }}>
              Unresolved Variable!
            </Typography>
            <Typography>
              Please ensure that your variables are defined (or)
              you have selected or activated the correct environment to use this variable.
            </Typography>
          </Box>
        </Paper>
      }

      {
        dropdownOpen && Boolean(filteredDropDown?.length) && inputValue &&
        (
          <Box className="dropdown" sx={{ width: name === "bearerToken" ? "80%" : "150%" }}>
            <ul className="itemList" style={{ width: "40%" }}>
              {filteredDropDown?.map((each) => (
                <li
                  onMouseEnter={() => selectScope(each)}
                  onMouseDown={() => {
                    setSelectedVariableData(each);
                  }}
                  onClick={() => handleOptionSelect2(each)}
                  key={each?._id}
                >
                  {each?.name}
                </li>
              ))}
            </ul>
            <Box className="varInfo" sx={{ width: "70%" }}>
              <>
                <Typography>
                  value: <b>{selectedVariableData?.value?.toString().substr(0, name === "bearerToken" ? 70 : 24)}</b>
                </Typography>

                {name !== "bearerToken" && <Typography>
                  <b>{selectedVariableData?.value?.toString().substring(25, 53)}</b>
                </Typography>}

                <Typography>
                  Mapping Key: <b>{selectedVariableData?.responsePath}</b>
                </Typography>
                <Typography>
                  Scope: <b>{selectedVariableData?.scope}</b>
                </Typography>
                {scope !== selectedVariableData?.scope && <>
                  <hr />
                  <Chip label={"Overridden"} size="small" color="secondary" variant="filled"
                    sx={{
                      mb: 1,
                      height: "16%",
                      fontSize: "10.5px"
                    }} />
                  <Typography variant="body2" sx={{ color: "grey" }}>
                    The variable exists in both {selectedVariableData?.scope} and {scope} scope.
                    {scope} variables always override {selectedVariableData?.scope} variables.
                  </Typography>
                </>}
              </>
            </Box>
          </Box>
        )
      }

    </Box >
  );
}

export default TextFieldWithDropdown;
