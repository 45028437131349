import { useContext, useEffect, useState } from "react"
import { PropTypes } from "prop-types"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import {
  Box, Button, ButtonGroup,
  Grid, IconButton,
  TextField, Typography
} from "@mui/material"
import InfoRoundedIcon from "@mui/icons-material/InfoRounded"

import stateData from "data/stateData"
import { resource } from "data/resource"
import ApiService from "services/app.service"

import TCRulesList from "components/AddRequest/TabsList/Tests/TCRulesList"
import Functional from "components/AddRequest/TabsList/Tests/Types/Functional"
import Security from "../AddRequest/TabsList/Tests/Types/Security"
import Performance from "../AddRequest/TabsList/Tests/Types/Performance"

import UtilsContext from "contexts/Utils/UtilsContext"
import TestCaseContext from "contexts/TestCase/TestCaseContext"
import ExecutionContext from "contexts/Execution/ExecutionContext"

export default function CreateTestForm({ setStatus }) {
  const { register, handleSubmit, formState: { errors } } = useForm()

  const searchParams = new URLSearchParams(document.location.search)
  const autId = searchParams.get("aut")
  const tsId = searchParams.get("testsuite")
  const navigate = useNavigate()

  const utilsContext = useContext(UtilsContext)
  const testCaseContext = useContext(TestCaseContext)
  const executionContext = useContext(ExecutionContext)

  const { getConfigs } = executionContext
  const {
    tcs, testcases,
    setTCs,
    add_t,
    testcase,
    editTC,
    getTestCase,
    testcaseData,
    setTestcaseData,
    setEditTC,
    setTestCase,
    setTestCases,
    getTestCases
  } = testCaseContext
  const { edit, showAlert, changes, setChanges } = utilsContext

  const [type, setType] = useState("Functional")
  const [note, setNote] = useState(false)

  useEffect(() => {
    if (!testcase && editTC)
      getTestCase(editTC, add_t)
    else if (editTC) {
      setTestcaseData({
        name: testcase.name,
        description: testcase.description,
        functionalAssertions: testcase?.functionalAssertions,
        performanceAssertions: testcase.performanceAssertions ?? {},
        securityAssertions: testcase.securityAssertions ?? {},
        endpointId: edit,
      })
      setType(((testcase?.securityAssertions && Object.keys(testcase.securityAssertions).length > 1) ||
        (testcase?.securityAssertions && Object.keys(testcase.securityAssertions).length === 1 && testcase?.securityAssertions && Object.keys(testcase.securityAssertions)[0] !== "_id")) ? "Security" :
        (((testcase?.performanceAssertions && Object.keys(testcase.performanceAssertions).length > 1) ||
          (testcase?.performanceAssertions && Object.keys(testcase.performanceAssertions).length === 1 && testcase?.performanceAssertions && Object.keys(testcase.performanceAssertions)[0] !== "_id"))
          ? "Performance" : "Functional"))
    }
    else {
      setTestcaseData({
        ...stateData.test,
        autId: autId,
        testSuiteId: tsId,
        key: Date.now().toString()
      })
    }
  }, [testcase])

  const onChangeHandler = (e) => {
    !changes && setChanges(true)
    setTestcaseData({
      ...testcaseData,
      [e.target.name]: e.target.value,
    })
  }

  const editSubmitHandler = async () => {
    try {
      if (testcaseData.functionalAssertions.some(e => !e.targetField) ||
        testcaseData.functionalAssertions.some(e => e.rules.some(em => em.operation !== "isExists" && !em.expectedValue))) {
        showAlert({
          type: "error",
          msg: "Cannot leave empty fields."
        })
        return
      }
      if (testcaseData.functionalAssertions.some(e => !e.rules.length)) {
        showAlert({
          type: "error",
          msg: "Cannot save without adding rules for Property."
        })
        return
      }
      // Saved request and new test
      if (!editTC && add_t) {
        setTestCases([...testcases, testcaseData])
        setTCs([...tcs, testcaseData])
      }
      // Saved Request and newly edited Test
      else if (editTC && add_t) {
        const ind = testcases.findIndex(e => e.key === editTC)
        const ind1 = tcs.findIndex(e => e.key === editTC)
        setTestCases([...testcases.slice(0, ind), { ...testcases[ind], ...testcaseData }, ...testcases.slice(1 + ind)])
        setTCs([...tcs.slice(0, ind1), { ...tcs[ind1], ...testcaseData }, ...tcs.slice(1 + ind1)])
      }
      // Saved Request and updated Test
      else {
        await ApiService.updateTest(editTC, testcaseData)
        getConfigs(tsId)
      }
      setStatus(true)
      setTestCase(null)
      if (editTC) {
        setEditTC(null)
        !add_t && getTestCases(edit, true)
      }
      setTimeout(() => navigate(-1), 200)
    } catch (err) {
      console.log(err.response?.data)
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      })
    }
  }

  const addRow = () => {
    setTestcaseData({
      ...testcaseData,
      functionalAssertions: [
        ...testcaseData.functionalAssertions, {
          rules: [],
          operator: "and",
          targetField: "",
          isSelected: true,
          key: Date.now().toString(),
          serialNumber: testcaseData?.functionalAssertions?.length + 1,
        }],
    })
  }

  return (
    <Box>
      <Grid container spacing={1.5}>
        <Grid sm={6} item>
          <TextField
            required
            autoFocus
            fullWidth
            name="name"
            size="small"
            autoComplete='off'
            variant="outlined"
            placeholder={resource.AUT.HEAD.NAME}
            value={testcaseData?.name}
            {...register("name", {
              onChange: e => onChangeHandler(e),
              required: "Field cannot be empty.",
              validate: (v) => {
                if (!v.trim()) { return "Minimum length is 4 characters." }
              },
              minLength: {
                value: 4,
                message: "Minimum length is 4 characters."
              },
              maxLength: {
                value: 30,
                message: "Minimum length is 30 characters."
              }
            })}
            error={(errors?.name)}
            helperText={errors?.name?.message}
            inputProps={{
              style: {
                height: "20px",
                color: "#54577d",
              }
            }}
          />
        </Grid>
        <Grid sm={6} item>
          <TextField
            fullWidth
            size="small"
            name="description"
            variant="outlined"
            autoComplete='off'
            placeholder={resource.AUT.HEAD.DESC}
            minRows={1}
            maxRows={3}
            multiline
            value={testcaseData?.description}
            onChange={onChangeHandler}
            inputProps={{
              style: { height: "20px", color: "#54577d" }
            }}
          // sx={{ zIndex: -1 }}
          />
        </Grid>
      </Grid>

      <Box className="ecContainer" mt={1}>
        <Grid container className="ecRulesColumns">
          <TCRulesList validationTemplate={stateData.validationRulesData} setType={setType} type={type} />
          <Grid item sm={9.5} className="scrollbarStyle ecContainers">

            <Typography className="testHeader" sx={{ p: 1.1 }}>
              {type === resource.TC.FUNC ?
                `${type} - Add Properties & Rules` : `${type} - Add Rules`}
            </Typography>

            <Grid container px={2} py={2} alignItems="left">
              {type === resource.TC.FUNC && <Grid item sm={7}>
                <Box display="flex" alignItems="left" >
                  <IconButton size="small"
                    sx={{ color: "#6580e0" }}
                    onMouseOver={() => setNote(true)}
                    onMouseLeave={() => setNote(false)}>
                    <InfoRoundedIcon />
                  </IconButton>
                  {note && <Typography textAlign="left" sx={{ paddingTop: "5px" }}><i>{resource.TC.INFO}</i></Typography>}
                </Box>
              </Grid>}
              <Grid item sm={type === resource.TC.FUNC ? 5 : 12} textAlign={"right"}>
                <ButtonGroup
                  disableElevation
                  variant="contained"
                  aria-label="Disabled button group"
                >
                  {type === resource.TC.FUNC &&
                    <Button
                      size="small"
                      className="addRow"
                      variant="contained"
                      onClick={addRow}
                    >{resource.ADD} {resource.TC.ASS}
                    </Button>
                  }
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ backgroundColor: "#1fcc75", ":hover": { backgroundColor: "#1fcc75" }, marginLeft: "11px" }}
                    onClick={handleSubmit(editSubmitHandler)}
                  >
                    {resource.SAVE} Test
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>

            {type === resource.TC.SEC && <Security />}
            {type === resource.TC.FUNC && <Functional register={register} errors={errors} />}
            {type === resource.TC.PERF && <Performance />}

          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

CreateTestForm.propTypes = {
  setStatus: PropTypes.func
}