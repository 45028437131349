import { PropTypes } from "prop-types"
import { styled } from "@mui/material/styles"
import { Box, Checkbox, Grid, Typography } from "@mui/material"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"

const Accordion = styled((props) => (
  <MuiAccordion disableGutters defaultExpanded elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const List = ({ index, endpoint, handleDrag, handleDrop, configData, setConfigData }) => {

  const changeHandler = () => {
    setConfigData({
      ...configData,
      endpoints: [
        ...configData.endpoints.slice(0, index),
        {
          ...configData.endpoints[index],
          isSelected: !endpoint.isSelected,
          tc: configData.endpoints[index].tc.map(e => ({ ...e, isSelected: !endpoint.isSelected }))
        },
        ...configData.endpoints.slice(index + 1),
      ]
    });
  };

  const changeHandler1 = (e, i) => {
    setConfigData({
      ...configData,
      endpoints: [
        ...configData.endpoints.slice(0, index),
        {
          ...configData.endpoints[index],
          tc: [
            ...configData.endpoints[index].tc.slice(0, i),
            {
              ...configData.endpoints[index].tc[i],
              isSelected: !e.isSelected
            },
            ...configData.endpoints[index].tc.slice(i + 1)
          ]
        },
        ...configData.endpoints.slice(index + 1),
      ]
    });
  };

  return (
    <>
      <Box
        className="OL"
        draggable={true}
        id={endpoint?.name}
        onDragOver={(ev) => ev.preventDefault()}
        onDragStart={handleDrag}
        onDrop={handleDrop}
        display="flex"
        sx={{ cursor: "grab" }}
      >
        <Accordion>
          <Box display="flex" alignItems="center" p="4px 0px" gap={1}>
            <DragIndicatorIcon />
            <Checkbox size="small" checked={endpoint?.isSelected}
              value={endpoint?.isSelected} onClick={(e) => changeHandler(e)} />
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            ></AccordionSummary>
            <Box>{endpoint?.name}</Box>
          </Box>

          {
            Boolean(endpoint?.tc?.length) &&
            <AccordionDetails>
              <Box display="flex" alignItems="center">
                <Box
                  className="DBListMain"
                  sx={{ gridTemplateColumns: "repeat(3, 1fr) !important" }}
                >
                  <>
                    {endpoint?.tc?.map((e, i) =>
                      <Box className="DSListItem" key={i}>
                        <Box className="DBList">
                          <Grid container alignItems="center">
                            <Grid xs={12} item>
                              <Box className="DBLeft">
                                <Box display="flex" gap={0.5} alignItems="center">
                                  <Checkbox value={e.isSelected} checked={e.isSelected} onClick={() => changeHandler1(e, i)} />
                                  <Typography>
                                    <strong>{e.name}</strong>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </>
                </Box>
              </Box>
            </AccordionDetails>
          }
        </Accordion>
      </Box>
    </>
  );
};

export default List;

List.propTypes = {
  index: PropTypes.number,
  handleDrag: PropTypes.func,
  handleDrop: PropTypes.func,
  endpoint: PropTypes.object,
  configData: PropTypes.object,
  setConfigData: PropTypes.func
};
