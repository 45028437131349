import { useReducer } from "react"
import { PropTypes } from "prop-types"

import AutContext from "./AutContext"
import AutReducer from "./AutReducer"
import ApiService from "services/app.service"

import { SET_AUT, SET_AUTS, AUT_ID } from "../types.js"

function AutState(props) {

    const initialState = {
        auts: [],
        aut: null,
        autId: null
    };
    const [state, dispatch] = useReducer(AutReducer, initialState);

    const setAut = (data) => {
        dispatch({
            type: SET_AUT,
            payload: data
        });
    };

    const setAuts = (data) => {
        dispatch({
            type: SET_AUTS,
            payload: data
        });
    };

    const setAutId = (id) => {
        dispatch({
            type: AUT_ID,
            payload: id
        });
    };

    const getAuts = async () => {
        try {
            const { data } = await ApiService.fetchAuts()
            setAuts(data)
            return data
        } catch (err) {
            console.log(err.response.data);
            return err.response.data
        }
    };

    const getAut = async (id) => {
        try {
            const { data } = await ApiService.fetchAut(id)
            setAut(data.aut)
        } catch (err) {
            console.log(err.response.data);
        }
    };

    return (
        <AutContext.Provider
            value={{
                aut: state.aut,
                auts: state.auts,
                autId: state.autId,
                setAutId,
                setAut,
                setAuts,
                getAut,
                getAuts
            }}
        >
            {props.children}
        </AutContext.Provider>
    );
}

AutState.propTypes = {
    children: PropTypes.node
};

export default AutState;