import { styled } from "@mui/material/styles"
import { PropTypes } from "prop-types"
import { Box, Chip, Grid, Typography } from "@mui/material"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import { resource } from "data/resource"
import { Fragment } from "react"

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}));

function TestResults({ res }) {
    const statusChip = {
        transform: "scale(0.8)",
        marginRight: "10px",
        color: "white",
        borderRadius: "5px",
    };
    return (
        <AccordionDetails >
            {res?.testResults?.length ? res?.testResults.map(tc =>
                <Box key={tc._id}>
                    <Typography sx={{ margin: "14px 0px 0px 14px" }}>
                        <b>{tc.testCase?.name}</b>
                        <span
                            style={{
                                color: tc.testCase?.result === "FAIL" ? "red" : "green",
                                fontWeight: 600
                            }}
                        >
                            &nbsp;(
                            {tc.testCase?.functionalAssertions?.filter((e) => e?.status === "PASS")?.length +
                                (tc.testCase.performanceAssertions ? Object.values(tc.testCase.performanceAssertions).filter((e) => e?.status === "PASS")?.length : 0) +
                                (tc.testCase.securityAssertions ? Object.values(tc.testCase.securityAssertions).filter((e) => e?.status === "PASS")?.length : 0)}
                            /
                            {tc.testCase?.functionalAssertions?.length +
                                (tc.testCase.performanceAssertions ? Object.values(tc.testCase.performanceAssertions).filter((e) => e?.isSelected)?.length : 0) +
                                (tc.testCase.securityAssertions ? Object.values(tc.testCase.securityAssertions).filter((e) => e?.isSelected)?.length : 0)}
                            )
                        </span>
                    </Typography>

                    <Box className='tcAssertions'>
                        <Grid container>

                            {Boolean(tc.testCase?.functionalAssertions?.length) &&
                                tc.testCase.functionalAssertions.map((ass) => (
                                    <Fragment key={ass._id}>
                                        <Grid item sm={1.7} textAlign={"center"}>
                                            <Chip
                                                sx={{
                                                    ...statusChip,
                                                    backgroundColor:
                                                        ass.status === "PASS" ? "#1AA376" : "#EF2D56",
                                                    padding: (ass.status === "FAIL" || !ass.status) && "0px 3.5px 0px 3.5px",
                                                }}
                                                label={ass?.status ? ass?.status?.toUpperCase() : "FAIL"}
                                            />
                                        </Grid>
                                        <Grid item sm={10.3} mt={.7}>
                                            <Typography sx={{
                                                display: "inline"
                                            }}>
                                                {ass.targetField}&nbsp;
                                                {ass.rules.map(
                                                    (rule, i) =>
                                                        `${rule.operation} "${rule.expectedValue}" ${i + 1 !== ass.rules.length ? rule.operator : ""
                                                        } `
                                                )}
                                            </Typography>
                                            <br />
                                            {ass.status === "FAIL" && (
                                                <Typography>
                                                    ||<b> Reason:</b>{" "}
                                                    {ass.rules.map((rule) => `${rule.reason}\n`)}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Fragment>
                                ))}

                            {tc.testCase.performanceAssertions &&
                                Object.keys(tc.testCase.performanceAssertions).map(
                                    (perf, i) =>
                                        tc.testCase.performanceAssertions[perf]?.isSelected && (
                                            <Fragment key={i}>
                                                <Grid item sm={1.7} textAlign={"center"} mt={1}>
                                                    <Chip
                                                        sx={{
                                                            ...statusChip,
                                                            backgroundColor: tc.testCase.performanceAssertions[perf]?.status === "PASS" ? "#1AA376" : "#EF2D56",
                                                            padding:
                                                                tc.testCase.performanceAssertions[perf]?.status === "FAIL" &&
                                                                "0px 3.5px 0px 3.5px",
                                                        }}
                                                        label={
                                                            tc.testCase.performanceAssertions[perf]?.status === "PASS"
                                                                ? "PASS"
                                                                : "FAIL"
                                                        }
                                                    />
                                                </Grid>

                                                <Grid item sm={10.3} mt={1.6}>
                                                    <Typography sx={{ display: "inline" }}>
                                                        {perf === "responseTime"
                                                            ? "Response Time"
                                                            : "Response Size"}{" "}
                                                        is&nbsp;
                                                        {`${tc.testCase.performanceAssertions[perf].operation ===
                                                            "lessThan"
                                                            ? "<"
                                                            : ">"
                                                            } ${tc.testCase.performanceAssertions[perf].expectedValue} ${perf === "responseTime" ? "ms" : "KB"
                                                            }`}
                                                    </Typography>
                                                    {tc.testCase.performanceAssertions[perf].status === "FAIL" && (
                                                        <Typography>
                                                            {" "}
                                                            ||<b> Reason:</b>{" "}
                                                            {tc.testCase.performanceAssertions[perf].reason}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Fragment>
                                        )
                                )}

                            {tc.testCase.securityAssertions &&
                                Object.keys(tc.testCase.securityAssertions).map(
                                    (sec, i) =>
                                        tc.testCase.securityAssertions[sec]?.isSelected && (
                                            <Fragment key={i}>
                                                <Grid item sm={1.7} textAlign={"center"} mt={1}>
                                                    <Chip
                                                        sx={{
                                                            ...statusChip,
                                                            backgroundColor: sec === "cookies" ? (tc.testCase.securityAssertions[sec]?.overAllCookiesStatus === "PASS" ? "#1AA376" : "#EF2D56") :
                                                                tc.testCase.securityAssertions[sec]?.status === "PASS" ? "#1AA376" : "#EF2D56",
                                                            padding: sec === "cookies" ?
                                                                (tc.testCase.securityAssertions[sec]?.overAllCookiesStatus === "FAIL" && "0px 3.5px 0px 3.5px") :
                                                                tc.testCase.securityAssertions[sec]?.status === "FAIL" && "0px 3.5px 0px 3.5px",
                                                        }}
                                                        label={sec === "cookies" ? (tc.testCase.securityAssertions[sec]?.overAllCookiesStatus === "PASS" ? "PASS" : "FAIL") :
                                                            tc.testCase.securityAssertions[sec]?.status === "PASS" ? "PASS" : "FAIL"}
                                                    />
                                                </Grid>

                                                <Grid item sm={10.3} mt={1.6}>
                                                    <Typography sx={{ display: "inline" }}>
                                                        {sec === "contentType"
                                                            ? "Response Header Content Type"
                                                            : sec === "statusCode"
                                                                ? "Status Code"
                                                                : "Cookie name"}{" "}
                                                        is&nbsp;
                                                        {tc.testCase.securityAssertions[sec].expectedValue
                                                            ? `"${tc.testCase.securityAssertions[sec].expectedValue}"`
                                                            : tc.testCase.securityAssertions[sec].rules.map(
                                                                (ele, i) =>
                                                                    `"${ele.name}" ${i + 1 !==
                                                                        tc.testCase.securityAssertions[sec].rules.length
                                                                        ? ", "
                                                                        : ""
                                                                    }`
                                                            )}
                                                    </Typography>
                                                    {tc.testCase.securityAssertions[sec].status === "FAIL" && (
                                                        <Typography>
                                                            {" "}
                                                            ||<b> Reason:</b>
                                                            {tc.testCase.securityAssertions[sec].reason}
                                                        </Typography>
                                                    )}
                                                    {
                                                        sec === "cookies" &&
                                                        <Typography>
                                                            {" "}
                                                            ||<b> Reason:</b>
                                                            {tc.testCase.securityAssertions[sec].rules.map(e => <>
                                                                {e.reason},&nbsp;
                                                            </>
                                                            )}
                                                        </Typography>
                                                    }
                                                </Grid>
                                            </Fragment>
                                        )
                                )}
                        </Grid>
                    </Box>
                    {/* <hr /> */}
                </Box>
            ) :
                <Box sx={{ padding: "5%" }}>
                    <center>
                        <Typography variant="h6">{!res?.endpointId ? " Save Request to create Test cases." : resource.NO_TS}</Typography>
                    </center>
                </Box>}
        </AccordionDetails>
    );
}

export default TestResults

TestResults.propTypes = {
    res: PropTypes.object
}
