import { useContext } from "react"
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Tooltip, TextField, Typography,
} from "@mui/material"

import AddBoxIcon from "@mui/icons-material/AddCircle"
import DeleteIcon from "@mui/icons-material/Delete"

import APIsContext from "contexts/APIs/APIsContext"
import UtilsContext from "contexts/Utils/UtilsContext"
import { resource } from "data/resource.js"
import TextFieldWithDropdown from "../../Utils/AssignVarList"

export default function ParamsTab() {
  const apisContext = useContext(APIsContext)
  const utilsContext = useContext(UtilsContext)

  const { edit, changes, setChanges } = utilsContext
  const { requestData, setRequestData } = apisContext

  return (
    <Box mt={1.5}>
      <Grid container>
        <Grid xs={12} item>
          <Typography variant="h6" sx={{ color: "#54577d" }}>
            {resource.QUERY_PARAMS}{" "}
            {Boolean(requestData?.query?.length) && `(${requestData?.query?.length})`}
            <IconButton
              size="small"
              onClick={() => {
                setRequestData({
                  ...requestData,
                  query: [
                    ...requestData.query,
                    {
                      name: "",
                      key: Date.now().toString(),
                      value: "",
                      description: "",
                      isSelected: true,
                    },
                  ],
                })
              }}
            >
              <Tooltip title="Add Query Params">
                <AddBoxIcon className="addIcon2" sx={{ color: "#6580e0" }} />
              </Tooltip>
            </IconButton>
          </Typography>
        </Grid>
      </Grid>

      {Boolean(requestData?.query?.length) &&
        requestData?.query.map((el, i) => (
          <Grid container key={i} mt="2px" alignItems="center" spacing={1}>
            <Grid item md={0.5}>
              <Checkbox
                className="checkbox"
                name="isSelected"
                checked={el.isSelected}
                value={el.isSelected}
                onChange={() => {
                  edit && !changes && setChanges(true)
                  setRequestData({
                    ...requestData,
                    query: [
                      ...requestData.query.slice(0, i),
                      { ...requestData.query[i], isSelected: !el.isSelected },
                      ...requestData.query.slice(i + 1),
                    ],
                  })
                }}
              />
            </Grid>

            <Grid item md={3}>
              <TextField
                required
                fullWidth
                size="small"
                autoComplete='off'
                variant="outlined"
                label={resource.KEY}
                name={`paramKey${i}`}
                defaultValue={el.name}
                disabled={!el.isSelected}
                onChange={(e) => {
                  edit && !changes && setChanges(true)
                  setRequestData({
                    ...requestData,
                    query: [
                      ...requestData.query.slice(0, i),
                      { ...requestData.query[i], name: e.target.value },
                      ...requestData.query.slice(i + 1),
                    ],
                  })
                }}
              />
            </Grid>

            <Grid item md={3}>
              <TextFieldWithDropdown
                index={i}
                name={"query"}
                defaults={el.value}
              />
            </Grid>

            <Grid md={5} item>
              <TextField
                fullWidth
                multiline
                minRows={1}
                maxRows={3}
                size="small"
                autoComplete='off'
                variant="outlined"
                name="description"
                label={resource.AUT.HEAD.DESC}
                defaultValue={el.description}
                disabled={!el.isSelected}
                onChange={(e) => {
                  edit && !changes && setChanges(true)
                  setRequestData({
                    ...requestData,
                    query: [
                      ...requestData.query.slice(0, i),
                      { ...requestData.query[i], description: e.target.value },
                      ...requestData.query.slice(i + 1),
                    ],
                  })
                }}
              />
            </Grid>

            <Grid item md={0.5} textAlign="center">
              <Tooltip title={resource.VALUE}>
                <DeleteIcon
                  className="delIcon"
                  sx={{ color: "#cf2b2b" }}
                  onClick={() => {
                    edit && !changes && setChanges(true)
                    const index = requestData.query.findIndex(
                      (ele) => ele.key === el.key
                    )
                    requestData.query.splice(index, 1)
                    setRequestData({
                      ...requestData,
                      query: requestData.query,
                    })
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        ))}

      <Box width="100%" my={1.5}>
        <Divider />
      </Box>

      <Grid container>
        <Grid xs={12} item>
          <Typography variant="h6" sx={{ color: "#54577d" }}>
            {resource.PATH_PARAMS}{" "}
            {Boolean(requestData?.path?.length) && `(${requestData?.path?.length})`}
            <IconButton
              size="small"
              onClick={() => {
                setRequestData({
                  ...requestData,
                  path: [
                    ...requestData.path,
                    {
                      name: "",
                      key: Date.now().toString(),
                      value: "",
                      description: "",
                      isSelected: true,
                    },
                  ],
                })
              }}
            >
              <Tooltip title="Add Path Variables">
                <AddBoxIcon className="addIcon2" sx={{ color: "#6580e0" }} />
              </Tooltip>
            </IconButton>
          </Typography>
        </Grid>
      </Grid>

      {Boolean(requestData.path?.length) &&
        requestData.path.map((el, i) => (
          <Grid container mt="2px" alignItems="center" spacing={1} key={i}>
            <Grid item md={0.5} key={i}>
              <Checkbox
                className="checkbox"
                name="isSelected"
                checked={el.isSelected}
                value={el.isSelected}
                onChange={() => {
                  edit && !changes && setChanges(true)
                  setRequestData({
                    ...requestData,
                    path: [
                      ...requestData.path.slice(0, i),
                      { ...requestData.path[i], isSelected: !el.isSelected },
                      ...requestData.path.slice(i + 1),
                    ],
                  })
                }}
              />
            </Grid>

            <Grid item md={3}>
              <TextField
                required
                fullWidth
                name="name"
                size="small"
                autoComplete='off'
                variant="outlined"
                label={resource.KEY}
                defaultValue={el.name}
                disabled={!el.isSelected}
                onChange={(e) => {
                  edit && !changes && setChanges(true)
                  // const temp = req.value.concat("/:")
                  setRequestData({
                    ...requestData,
                    path: [
                      ...requestData.path.slice(0, i),
                      { ...requestData.path[i], name: e.target.value },
                      ...requestData.path.slice(i + 1),
                    ],
                    // value: flag ? req.value.concat("/:") : temp.concat(e.target.value)
                  })
                  // setFlag(0)
                  // console.log(requestData.value)
                }}
              />
            </Grid>

            <Grid item md={3}>
              <TextFieldWithDropdown
                index={i}
                name={"path"}
                defaults={el.value}
              />
            </Grid>

            <Grid md={5} item>
              <TextField
                fullWidth
                multiline
                minRows={1}
                maxRows={3}
                size="small"
                autoComplete='off'
                variant="outlined"
                name="description"
                label={resource.AUT.HEAD.DESC}
                defaultValue={el.description}
                disabled={!el.isSelected}
                onChange={(e) => {
                  edit && !changes && setChanges(true)
                  setRequestData({
                    ...requestData,
                    path: [
                      ...requestData.path.slice(0, i),
                      { ...requestData.path[i], description: e.target.value },
                      ...requestData.path.slice(i + 1),
                    ],
                  })
                }}
              />
            </Grid>

            <Grid item md={0.5} textAlign="center">
              <Tooltip title={resource.DELETE}>
                <DeleteIcon
                  className="delIcon"
                  sx={{ color: "#cf2b2b" }}
                  onClick={() => {
                    edit && !changes && setChanges(true)
                    const index = requestData.path.findIndex(
                      (ele) => ele.key === el.key
                    )
                    requestData.path.splice(index, 1)
                    setRequestData({
                      ...requestData,
                      path: requestData.path,
                    })
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        ))}
    </Box>
  )
}
