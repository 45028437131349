import { useState, useContext } from "react"
import PropTypes from "prop-types"
import { Tabs, Tab, Box } from "@mui/material"

import AuthTab from "components/AddRequest/TabsList/Auth";
import HeaderTab from "components/AddRequest/TabsList/Header";
import ParamsTab from "components/AddRequest/TabsList/Params";
import RequestTab from "components/AddRequest/TabsList/Request";
import TestTab from "components/AddRequest/TabsList/Tests/index";
import Variables from "components/AddRequest/TabsList/TSVariables";
import ExecutionsListTab from "components/AddRequest/TabsList/Executions";

import APIsContext from "contexts/APIs/APIsContext";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function AddRequestTabs() {

  const apisContext = useContext(APIsContext);
  const { requestData } = apisContext;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="customTabs">
      <Tabs value={value} onChange={handleChange} className="reqTabs1">
        <Tab label="Authorization" />
        <Tab
          label={
            requestData?.query?.length || requestData?.path?.length
              ? `Params (${requestData?.query?.length + requestData?.path?.length
              })`
              : "Params"
          }
        />
        <Tab label="Headers" />
        <Tab label="Request" />
        <Tab label="Tests" />
        <Tab label="Variables" />
        <Tab label="Executions" />
      </Tabs>

      <CustomTabPanel value={value} index={0}>
        <AuthTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ParamsTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <HeaderTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <RequestTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <TestTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Variables />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <ExecutionsListTab />
      </CustomTabPanel>
    </Box>
  );
}
