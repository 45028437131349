import { useState, useContext, useEffect } from "react";
import {
  Alert,
  Box, Button,
  Checkbox,
  Grid,
  IconButton, InputBase,
  Paper,
  Snackbar,
  Tooltip, Typography
} from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import SearchIcon from "@mui/icons-material/Search"
import DeleteIcon from "@mui/icons-material/Delete"
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded"
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded"
// import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded"
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded"

import AutLists from "./AutList";
import AutTiles from "./AutTiles";
import { resource } from "data/resource.js";
import ApiService from "services/app.service";
import PopUpCreate from "components/Utils/PopUpCreate";
import InnerHeader from "components/Utils/InnerHeader";
import ImportPopup from "components/Utils/ImportPopup";

import AutContext from "contexts/Aut/AutContext";
import UtilsContext from "contexts/Utils/UtilsContext";

export default function AutList() {
  const [view, setView] = useState("tile");
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const autContext = useContext(AutContext);
  const utilsContext = useContext(UtilsContext);

  const { setAuts, getAuts, auts } = autContext;
  const { delValues, alert, showAlert, setDelValues, setDelete, removeAlert } = utilsContext;

  useEffect(() => {
    delValues.length && setDelValues([]);
  }, []);

  const searchHandler = async (e) => {
    try {
      setSearchValue(e.target.value);
      if (e.target.value.length > 3) {
        const { data } = await ApiService.search(e.target.value)
        setAuts(data)
      } else getAuts()
    } catch (err) {
      console.log(err.response.data);
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      });
    }
  };

  const deleteHandler = async () => {
    try {
      const { data } = await ApiService.deleteAut(delValues)
      showAlert({
        type: data.status,
        msg: data.message,
      });
      setTimeout(() => {
        setDelValues([]);
        setDelete(null);
        getAuts();
      }, resource.TIMEOUT.SUCCESS);
    } catch (err) {
      console.log(err.response.data);
      showAlert({
        type: "error",
        msg: err.response?.data?.message ? err.response.data.message : resource.ERR,
      });
    }
  };

  return (
    <Box className="fullBox">
      <InnerHeader>
        <Box className="tsHeader">
          <Grid alignItems={"center"} container item>
            <Grid md={4} item>
              <Typography variant="h6">AUTs</Typography>
            </Grid>

            <Grid md={8} item textAlign={"right"}>
              <Box
                display="flex"
                gap={1}
                alignItems="center"
                justifyContent="right"
              >
                {view === "tile" && <>
                  Select All
                  <Checkbox
                    size="small"
                    checked={delValues.length === auts.length}
                    icon={<span style={{ borderRadius: 4, width: 15, height: 15, backgroundColor: "transparent", border: "2px solid #1cd97a" }} />}
                    checkedIcon={<span style={{ borderRadius: 4, width: 15, height: 15, backgroundColor: "#1cd97a", display: "flex" }} >
                      <CheckIcon style={{ fontSize: 16, color: "#fff" }} />
                    </span>}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.target.checked ? setDelValues([...auts.map(e => e._id)]) : setDelValues([]);
                    }} />
                </>}

                <Paper
                  component="form"
                  sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <InputBase
                    size="small"
                    ml={1}
                    sx={{ flex: 1 }}
                    placeholder="Search AUT"
                    name="searchValue"
                    value={searchValue}
                    onChange={searchHandler}
                  />
                  <IconButton type="submit" size="small">
                    <SearchIcon />
                  </IconButton>
                </Paper>
                <Tooltip
                  title={
                    view === "tile" ? resource.VIEW.LIST : resource.VIEW.TILE
                  }
                >
                  <IconButton
                    size="small"
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      view === "tile" ? setView("list") : setView("tile");
                      setDelValues([]);
                    }}
                  >
                    {view === "tile" ? (
                      <ViewListRoundedIcon />
                    ) : (
                      <GridViewRoundedIcon />
                    )}
                  </IconButton>
                </Tooltip>

                <Button
                  className="btnIcon"
                  onClick={() => setOpen(!open)}
                  size="small"
                  variant="contained"
                  sx={{
                    backgroundColor: "#ede8ff",
                    border: "1px solid #096eb6",
                    color: "#096eb6", "&:hover": {
                      backgroundColor: "#ede8ff",
                      color: "#096eb6"
                    },
                  }}
                >
                  <FileDownloadRoundedIcon size="large" />
                  {resource.IMP}
                </Button>

                <PopUpCreate name={"AUT"} />

                {Boolean(delValues.length) && (
                  <>
                    <Tooltip title={resource.DELETE}>
                      <IconButton
                        className="actionIcon"
                        size="small"
                        color="error"
                        onClick={() => setDelete(true)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
{/* 
                    <Tooltip title={resource.EX}>
                      <IconButton className="actionIcon"
                        size="small"
                        onClick={() => setVisible(true)}
                      >
                        <FileUploadRoundedIcon />
                      </IconButton>
                    </Tooltip> */}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </InnerHeader>

      <Box pt={9}>
        {view === "tile" ? (
          <AutTiles deleteHandler={deleteHandler} />
        ) : (
          <AutLists deleteHandler={deleteHandler} />
        )}

        <ImportPopup open={open} setOpen={setOpen} name={"AUT"} />

        {alert !== null && (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={true}
            className="snackbar"
          >
            <Alert severity={alert.type} onClose={() => removeAlert()}>{alert.msg}</Alert>
          </Snackbar>
        )}
      </Box>
    </Box>
  );
}
