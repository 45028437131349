export const resource = {
    ERR: "Something went wrong, please try again later.",
    FEEDBACK: {
        EMPTY: "Empty! Please select the Configs to start the execution process.",
        EXE_DONE: "API Suite Execution completed —  View Results!",
        PROGRESS: "API Suite Execution started and is in progress — You will be notified once we are ready with the results.",
        PROGRESS2: "API Suite Execution started and is in progress — Please wait while we get ready with the results. ."
    },
    VIEW: {
        LIST: "List View",
        TILE: "Tile View"
    },
    TIMEOUT: {
        SUCCESS: 500,
        ERR: 9000,
        ERRS: 2000,
        GET: 5000
    },
    AUT: {
        NAME: "AUT",
        EMPTY: "No AUT found :(",
        CREATE: "Create one now!",
        PRIV: "Private",
        PUBC: "Public",
        HEAD: {
            NAME: "Name",
            DESC: "Description",
            COUNT: "No. of Suites",
            STATUS: "Status",
            DATE: "Created Date",
            ACTION: "Manage"
        }
    },
    TS: {
        NAME: "API Suite",
        EMPTY: "No API Suite found :(",
        HTTP: "HTTP",
        HTTPS: "HTTPS",
        HEAD: {
            NAME: "Name",
            DESC: "Description",
            PRO: "Protocol",
            COUNT: "No.of Requests",
            AUTH: "Auth.Type",
            URL: "Base URL",
            DATE: "Created Date",
            ACTION: "Manage"
        },
        TOKEN: "Do you want an Authentication Token?"
    },
    TC: {
        NAME: "Test",
        INFO: "Please execute the API at least once to auto-populate the dropdown menu.\n Add properties in the below table, Drag & Drop rules to start creating Assertions.\n Click on 'Add Row' to chain assertions together",
        EMPTY: "No Tests Available :(",
        NEW: "Create Test",
        ASS: "Assertion",
        RULE: " Rules",
        INP: "Input Value",
        SEQ: "Rearrange the rows by dragging and dropping them to apply rules based on your preference.",
        DRAG: "Drag & Drop the rules here. ",
        ADVANCED: "Advanced Property Selection",
        HEAD1: {
            PROP: "Property",
            TC: "Rules",
            EXP: "Input Value",
            CASE: "Case Sensitive",
            OP: "Condition",
            ACTION: "Manage",
        },
        HEAD: {
            SEQ: "Reorder",
            TC: "Rule",
            CASE: "Case Sensitive",
            OP: "Conditional Operator"
        },
        FUNC: "Functional",
        SEC: "Security",
        PERF: "Performance",
        DEF: "New Test",
        SECOBJ: {
            CODE: "Check Response Status Code",
            TYPE: "Check Response Headers: Content Type",
            COOKIE: " Check Response Cookie Name"
        },
        PERFOBJ: {
            TIME: "Response Time (ms)",
            SIZE: "Response Size (KB)"
        },
    },
    VARS: {
        TITLE: "Add Variables to API Suite",
        COUNT: "No. of Variables",
        CAT: "Category",
        KEY: "Mapping Key",
        SELECT: "Select key",
        ENTER: "Enter Key",
    },
    TR: {
        NAME: "Test Results",
        EXE: "No. of Executions",
        EX_TIME: "Executed On",
        PASS: "Pass %"
    },
    REQ: {
        CREATE: "Create Requests to get started."
    },
    RESP: "Response",

    CRUD: ["GET", "POST", "PUT", "DELETE", "PATCH"],
    ADD: "Add",
    EDIT: "Edit",
    CREATE: "Create",
    UPDATE: "Update",
    DELETE: "Delete",
    SEND: "Send",
    SAVE: "Save",
    EX: "Export",
    IMP: "Import",
    EXE: "Execute",
    RES: "Results",
    CLOSE: "Close",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    DOWN: "Download",
    NO_SAVE: "Don't Save",
    ITE: "Iteration",
    PREV: "Preview Data",
    FORMAT_SUPPORT: "Supported formats - .json",
    TD_TEMPLATE: "Test Data Template",

    TYPE: "Select Type",
    KEY: "Key",
    VALUE: "Value",

    JSON: "JSON",
    TEXT: "Text",
    XML: "XML",
    STATUS: "Status",
    TIME: "Time",
    SIZE: "Size",
    AUTH: {
        NONE: "No Auth",
        PARENT: "Inherit auth from parent",
        API: "API Key",
        BEARER: "Bearer Token",
        BASIC: "Basic Auth",

        NO_AUTH: "This request does not use any authorization.",
        PARENT_AUTH: "This request is using No Auth from API Suite",
        API_NOTE: "The API key will be automatically added to Request Header.",
        BEARER_NOTE: "The Bearer Token will be automatically added to Request Header.",
        BASIC_NOTE: "The Basic Auth will be automatically added to Request Header."
    },
    QUERY_PARAMS: "Query Params",
    PATH_PARAMS: "Path Variables",
    CONFIRM_SAVE: "Are you sure you want to Save",
    CONFIRM_DEL: "Are you sure you want to delete",
    WARNING: "Warning! Sensitive Data! Store value in variables to keep data secure.",

    FILE: "Upload Sample File",
    FILE1: "Upload Test Data File",
    RAW1: "Enter Raw Data content",
    RAW: "Upload Sample Raw Content",
    EXECUTE: "Please either Execute the API at least once",
    SAMPLE: "upload sample response to auto-populate the 'Mapping Key' dropdown.",
    VAR: "To access variables in requests, type",
    VAR2: "in the text field to get the variables dropdown.",

    NO_COOKIE: "No Cookies to show.",
    NO_TS: "No Test Results to display.",
    NO_TR: "No API Suites to display.",
    NO_AUT: "No AUTs to display.",
    NO_LOAD: "Nothing to display.",
    NO_BODY: "This request does not have a body.",
    NO_FORMDATA: "This request does not contain any Form Data. Add now!",
    NO_FORMURLENCODED: "This request does not contain any Form URL Encoded body. Add now!",
    NO_HEADER: "This request does not contain any Header. Add now!",
    HEADER_NOTE: "Default headers such as Host, User-Agent, Accept, Accept-Encoding, Content-Type & Connection will be automatically added and sent with the request.",
    TEST_DATA_NOTE: "To ensure your test data file is in the correct format, please download our sample template. The template includes the necessary structure required for the file upload.",

    EXES: {
        CON: "Configuration",
        CONS: "Configs",
        RUN: "API Suite Run Order",
        CONF: "Configuration Name",
        CONF_DETS: "Config. Details",
        RESULTS: "Refresh to view recent Execution Results!"
    },
    TEST: "a Scheduled Test"

};