import { PropTypes } from "prop-types"
import { Alert, Box, Snackbar } from "@mui/material"
import { Outlet, Route, Routes } from "react-router-dom"

import AutList from "pages/AUT"
import SignIn from "components/Login"
import LayoutComponent from "layouts"
import AddRequest from "pages/AddRequest"
import EnvVariables from "pages/Variables"
import LoadTest from "components/LoadTest"
import TestSuitesList from "pages/TestSuite"
import LoadTestResults from "pages/LoadTest"
import NotFound from "components/Utils/NotFound"
import ApiDashBoard from "pages/Dashboard/index.js"
import ScheduledList from "components/TestScheduler"
import PrivateRoute from "components/Utils/PrivateRoute"

import AutState from "contexts/Aut/AutState"
import APIsState from "contexts/APIs/APIsState"
import VarState from "contexts/Variables/VarState"
import UtilsState from "contexts/Utils/UtilsState"
import TestCaseState from "contexts/TestCase/TestCaseState"
import ScheduleState from "contexts/Schedule/ScheduleState"
import ExecutionState from "contexts/Execution/ExecutionState"
import TestSuiteState from "contexts/TestSuite/TestSuiteState"
import TestResultState from "contexts/TestResult/TestResultState"

const SidebarLayout = () => (
    <Box sx={{ display: "flex", marginTop: "52px" }}>
        <LayoutComponent />
        <Box className="outlet pt74">
            <Outlet />
        </Box>
    </Box>
)

const routesData = [
    { path: "/", element: <AutList /> },
    { path: "/testsuites/?aut", element: <TestSuitesList /> },
    { path: "/testsuites", element: <TestSuitesList /> },
    { path: "/addrequest/?aut&testsuite&tc", element: <AddRequest /> },
    { path: "/addrequest/?aut&testsuite", element: <AddRequest /> },
    { path: "/addrequest", element: <AddRequest /> },
    { path: "/variables", element: <EnvVariables /> },
    { path: "/dashboard", element: <ApiDashBoard /> },
    { path: "/scheduledlist", element: <ScheduledList /> },
    { path: "/loadtest/results/:uuid", element: <LoadTestResults /> },
    { path: "/loadtest", element: <LoadTest /> },
    { path: "*", element: <NotFound /> },
]

const RoutesManager = ({ snack, setSnack }) => {
    const handleClose = (event, reason) => {
        if (reason?.toLowerCase() === "clickaway") return
        setSnack((prevSnack) => ({ ...prevSnack, open: false }))
    }

    return (
        <UtilsState>
            <VarState>
                <AutState>
                    <TestSuiteState>
                        <APIsState>
                            <TestCaseState>
                                <ExecutionState>
                                    <TestResultState>
                                        <ScheduleState>
                                            <Snackbar
                                                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                                open={snack?.open}
                                                autoHideDuration={3000}
                                                onClose={handleClose}>
                                                <Alert onClose={handleClose} severity={snack?.color}>
                                                    {snack?.message}
                                                </Alert>
                                            </Snackbar>
                                            <Routes>
                                                <Route path="/login" element={<SignIn />} />
                                                <Route element={<PrivateRoute />}>
                                                    <Route element={<SidebarLayout />}>
                                                        {routesData.map(({ path, element }, index) => (
                                                            <Route key={index} path={path} element={element} />
                                                        ))}
                                                    </Route>
                                                </Route>
                                                {/* <Route path="*" element={<NotFound />} /> */}
                                            </Routes>
                                        </ScheduleState>
                                    </TestResultState>
                                </ExecutionState>
                            </TestCaseState>
                        </APIsState>
                    </TestSuiteState>
                </AutState>
            </VarState>
        </UtilsState>
    )
}

export default RoutesManager

RoutesManager.propTypes = {
    snack: PropTypes.object,
    setSnack: PropTypes.func
}