import PropTypes from "prop-types"
import { useState, useEffect, useRef } from "react"

import { Tabs, Tab, Box, Grid, IconButton, Typography } from "@mui/material"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"

import { resource } from "data/resource"
import TSVariablesList from "./TSList"
import AUTVariablesList from "./AUTList"
import GlobalVariablesList from "./GlobalList"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

export default function VariableTabs({ onCloseHandle }) {
  const [value, setValue] = useState(0)
  const ref = useRef(null)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onCloseHandle()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [onCloseHandle])

  return (
    <Box className="fullBox2" ref={ref}>
      <Box textAlign="right">
        <IconButton size="small" color="error" onClick={onCloseHandle}>
          <CancelOutlinedIcon />
        </IconButton>
      </Box>

      <Box>
        <Grid item container>
          <Grid item sm={12}>
            <Tabs value={value} onChange={handleChange} className="reqTabs1">
              <Tab label="API Suite Variables" />
              <Tab label="AUT Variables" />
              <Tab label="Global Variables" />
            </Tabs>
          </Grid>
        </Grid>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <TSVariablesList />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <AUTVariablesList />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <GlobalVariablesList />
      </CustomTabPanel>

      <Typography variant="body1" className="apiKeyNote" mb={2}>
        <b>Note: </b>
        {resource.VAR}&nbsp; <b>&#123;&#123;</b> &nbsp;{resource.VAR2}
      </Typography>

    </Box>
  )
}
VariableTabs.propTypes = {
  onCloseHandle: PropTypes.func,
}
