import { useState, useContext, useEffect } from "react"
import { toXML } from "jstoxml"
import xml2json from "@hendt/xml2json"
import XMLViewer from "react-xml-viewer"
import {
    Box, Button,
    Stack,
    TextField, Typography
} from "@mui/material"

import JSONInput from "react-json-editor-ajrm"
import locale from "react-json-editor-ajrm/locale/en"

import { resource } from "data/resource"
import APIsContext from "contexts/APIs/APIsContext"
// import VarContext from "contexts/Variables/VarContext"

function Body() {
    // const autId = new URLSearchParams(document.location.search).get("aut")

    const [rawType, setRawType] = useState("JSON")

    const apisContext = useContext(APIsContext)
    // const varContext = useContext(VarContext)

    const { res } = apisContext
    // const { getEnvs, envs } = varContext

    res?.data?.stack && delete res.data.stack

    useEffect(() => {
        if (res?.headers?.length) {
            if (res?.headers?.find(e => e.name === "Content-Type")?.value.includes("application/xml"))
                setRawType("XML")
            else if (res?.headers?.find(e => e.name === "Content-Type")?.value.includes("text/html"))
                setRawType("Text")
        }
        // if (!envs.length) getEnvs(autId)
    }, [])

    return (
        <Box className='responseTab'>
            <Typography mb={1} sx={{ color: "#54577d" }}>
                Base URL<b>: {res?.url?.includes("host.docker.internal") ?
                    res?.url?.replace("host.docker.internal", "localhost") : res?.url}</b>
            </Typography>
            <Stack direction="row">
                <Button
                    sx={{ borderRadius: "1px", borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px" }}
                    variant={rawType === "JSON" ? "contained" : "outlined"}
                    onClick={() => setRawType("JSON")}>
                    {resource.JSON}</Button>
                <Button
                    sx={{ borderRadius: "1px" }}
                    variant={rawType === "Text" ? "contained" : "outlined"}
                    onClick={() => setRawType("Text")}>
                    {resource.TEXT}</Button>
                <Button
                    sx={{ borderRadius: "1px", borderTopRightRadius: "4px", borderBottomRightRadius: "4px" }}
                    variant={rawType === "XML" ? "contained" : "outlined"}
                    onClick={() => setRawType("XML")}>
                    {resource.XML}</Button>
            </Stack>

            <Box display="flex" justifyContent="flex-end">
                {resource.STATUS}:
                <Typography sx={{ color: res?.status === "SUCCESS" ? "green" : "red" }}>
                    {res?.statusText}
                    &nbsp;&nbsp;&nbsp;
                </Typography>
                {resource.TIME}:
                <Typography className='status'>
                    {res?.responseTime} ms
                    &nbsp;&nbsp;&nbsp;
                </Typography>
                {resource.SIZE}:
                <Typography className='status'>
                    {res?.responseSize} KB&nbsp;
                </Typography>
            </Box>

            {(rawType === "JSON" && (res?.headers?.length ? (!res?.headers?.find(e => e.name === "Content-Type")?.value?.includes("text/html")) : true)) ?
                <JSONInput
                    viewOnly={true}
                    width='100%'
                    height={448}
                    locale={locale}
                    colors={{ default: "#4a6a87" }}
                    theme={"light_mitsuketa_tribute"}
                    placeholder={res?.headers?.length ?
                        (res?.headers?.find(e => e.name === "Content-Type")?.value?.includes("application/xml") ? xml2json(res?.data) :
                            (typeof res?.data === "string" ? { data: res?.data } : res?.data)) : res?.data}
                /> :
                (rawType === "XML" ?
                    <Box sx={{ backgroundColor: "white", padding: "10px", minHeight: "448px" }}>
                        <XMLViewer xml={res?.headers?.length ?
                            (res.headers?.find(e => e.name === "Content-Type")?.value?.includes("application/json") ? toXML(res?.data, { indent: "  " }) : res?.data)
                            : toXML(res?.data, { indent: "   " })} />
                    </Box>
                    :
                    <TextField
                        multiline
                        fullWidth
                        size="small"
                        minRows={26}
                        variant="standard"
                        autoComplete='off'
                        defaultValue={res?.headers?.find(e => e.name === "Content-Type")?.value?.includes("application/xml") ? JSON.stringify(res?.data).split("\"").filter(e => e) : JSON.stringify(res?.data)}
                        InputProps={{
                            disableUnderline: true,
                            style: { color: "black" }
                        }}
                    />
                )
            }
        </Box>
    )
}

export default Body