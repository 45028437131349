import { useState, useContext, useEffect } from "react"
import {
  Alert,
  Box,
  Checkbox,
  Grid,
  IconButton,
  Snackbar,
  Tooltip, Typography
} from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import DeleteIcon from "@mui/icons-material/Delete"
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded"
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded"

import TestList from "./TestList"
import TestTile from "./TestTile"
import { resource } from "data/resource"
import ApiService from "services/app.service"
import NotFound from "components/Utils/NotFound"
import PopUpCreate from "components/Utils/PopUpCreate"
import InnerHeader from "components/Utils/InnerHeader"
import ConnectionDBList from "components/TestSuite/SearchAUT"

import AutContext from "contexts/Aut/AutContext"
import UtilsContext from "contexts/Utils/UtilsContext"
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext"

export default function TestSuite() {
  const autId = new URLSearchParams(document.location.search).get("aut")

  const autContext = useContext(AutContext)
  const utilsContext = useContext(UtilsContext)
  const testSuiteContext = useContext(TestSuiteContext)

  const { getAuts } = autContext
  const { getTestSuites, testSuites } = testSuiteContext
  const { alert, showAlert, removeAlert, delValues, setDelValues, setDelete } = utilsContext

  const [view, setView] = useState("tile")
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    validate()
    delValues.length && setDelValues([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validate = async () => {
    const auts = await getAuts()
    if (auts.error === "jwtExpired")
      return
    if (autId && !auts?.find(e => e._id === autId))
      setIsValid(false)
  }

  const deleteHandler = async () => {
    try {
      const { data } = await ApiService.deleteTs(delValues)
      showAlert({
        type: data.status,
        msg: data.message,
      })
      setTimeout(() => {
        setDelete(null)
        setDelValues([])
        getTestSuites(autId)
      }, resource.TIMEOUT.SUCCESS)
    } catch (err) {
      console.log(err.response.data)
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR
      })
    }
  }

  if (isValid)
    return (
      <Box className="fullBox">
        <InnerHeader>
          <Box className="tsHeader">
            <Grid container alignItems="center">
              <Grid sm={3} item>
                <Typography variant="h6">API Suites</Typography>
              </Grid>
              <Grid sm={5} item>
                <ConnectionDBList />
              </Grid>
              {autId && (
                <Grid sm={4} textAlign="right" item>
                  <Box
                    display="flex"
                    gap={1}
                    alignItems="center"
                    justifyContent="right"
                  >
                    {view === "tile" && <>
                      Select All
                      <Checkbox
                        size="small"
                        checked={delValues.length === testSuites?.testSuites?.length}
                        icon={<span style={{ borderRadius: 4, width: 15, height: 15, backgroundColor: "transparent", border: "2px solid #1cd97a" }} />}
                        checkedIcon={<span style={{ borderRadius: 4, width: 15, height: 15, backgroundColor: "#1cd97a", display: "flex" }} >
                          <CheckIcon style={{ fontSize: 16, color: "#fff" }} />
                        </span>}
                        onChange={(e) => {
                          e.stopPropagation()
                          e.target.checked ? setDelValues([...testSuites.testSuites.map(e => e._id)]) : setDelValues([])
                        }} />
                    </>}
                    <Tooltip title={view === "tile" ? resource.VIEW.LIST : resource.VIEW.TILE}>
                      <IconButton
                        size="small"
                        sx={{ color: "#fff" }}
                        onClick={() => view === "tile" ? setView("list") : setView("tile")}>
                        {view === "tile" ? <ViewListRoundedIcon /> : <GridViewRoundedIcon />}
                      </IconButton>
                    </Tooltip>

                    <PopUpCreate name={"API Suite"} />
                    {Boolean(delValues.length) && <IconButton
                      className="actionIcon"
                      size="small"
                      color="error"
                      onClick={() => setDelete(true)}
                    >
                      <DeleteIcon />
                    </IconButton>}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </InnerHeader>

        <Box pt={9}>
          {autId &&
            (view === "tile" ?
              <TestTile
                deleteHandler={deleteHandler}
                autId={autId}
              />
              :
              <TestList
                deleteHandler={deleteHandler}
                autId={autId}
              />
            )}

          {alert !== null && (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={true}
              className="snackbar"
            >
              <Alert severity={alert.type} onClose={() => removeAlert()}>{alert.msg}</Alert>
            </Snackbar>
          )}
        </Box>
      </Box>
    )
  return <NotFound />
}
