import { useRef, useState, useContext, useMemo, useEffect } from "react"
import { PropTypes } from "prop-types"
import { Box, Drawer, IconButton, Tooltip, Typography, Chip } from "@mui/material"
import EastRoundedIcon from "@mui/icons-material/EastRounded"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import CachedRoundedIcon from "@mui/icons-material/CachedRounded"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"

import { resource } from "data/resource";
import { CustomAgGrid } from "../../AgGrid";
import { ConfigExeResultheadCells } from "data/tsExec";
import SingleExeResult from "../ExeResult/SingleExeResult";
import UtilsContext from "contexts/Utils/UtilsContext";
import ExecutionContext from "contexts/Execution/ExecutionContext";
import TestResultContext from "contexts/TestResult/TestResultContext";

export default function ConfigExecutions({ popup }) {

  const utilsContext = useContext(UtilsContext);
  const executionContext = useContext(ExecutionContext);
  const testResultContext = useContext(TestResultContext);

  const { configs, currConfig } = executionContext;
  const { loading, setLoading, removeLoading } = utilsContext;
  const { exesTs, getExeResultsTs, setTestResults, getExesTS } = testResultContext;

  const [ids, setId] = useState(null)
  const [open, setOpen] = useState(false)
  const [, setSelected] = useState([])

  const gridRef = useRef();
  const ScrollRef = useRef();

  const toggleDrawer = (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) { return; }
    setOpen(false);
  };

  useEffect(() => {
    const ele = configs.find(e => e.nameId === currConfig)
    getExesTS(ele._id)
  }, [])

  function AgGridCheckbox({ data }) {
    return (
      <Box
        sx={{
          "& .MuiIconButton-root": {
            padding: "3px",
            fontSize: "12px",
          }
        }} >
        <IconButton
          onClick={() => {
            setOpen(true)
            setId(data._id)
            getExeResultsTs(data)
          }}
          size="small"
          className="accordianActionIcon"
        >
          <Tooltip title="View Results" placement="top" arrow>
            <RemoveRedEyeIcon />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function AgGridStatus({ data }) {
    return (
      <Chip
        size="small"
        label={data.status}
        sx={{
          padding: "5px",
          marginLeft: "-11px",
          color: data.status === "completed" ? "#62b4b9" : "#6580e0",
          backgroundColor: data.status === "completed" ? "#e1f8f8" : "#CCD5F5",
          border: data.status === "completed" ? "0.5px solid #c7eaeb" : "0.5px solid #BBC7F2",
        }} />
    );
  }

  function AgGridExe({ data }) {
    return (
      <Chip
        size="small"
        label={data.executionType}
        sx={{
          padding: "5px",
          marginLeft: data.executionType === "manual" && "10px",
          color: data.executionType === "manual" ? "#f505bd" : "#8d50ad",
          backgroundColor: data.executionType === "manual" ? "#ffd9f6" : "#e2c4f2",
          border: data.executionType === "manual" ? "0.5px solid #ffb8ee" : "0.5px solid #b595c7",
        }} />
    );
  }

  const action = {
    headerName: "Manage",
    flex: 1,
    minWidth: 100,
    cellRenderer: AgGridCheckbox,
  };

  const action1 = {
    headerName: "Status",
    flex: 1,
    minWidth: 100,
    cellRenderer: AgGridStatus,
  };

  const action2 = {
    headerName: "Execution Type",
    flex: 1,
    minWidth: 100,
    cellRenderer: AgGridExe,
  };

  useMemo(() => {
    ConfigExeResultheadCells[2] = action1;
    ConfigExeResultheadCells[3] = action2;
    ConfigExeResultheadCells[4] = action;
  }, []);


  AgGridCheckbox.propTypes = {
    data: PropTypes.object
  }
  AgGridStatus.propTypes = {
    data: PropTypes.object
  }
  AgGridExe.propTypes = {
    data: PropTypes.object
  }

  return (
    <>
      <Box
        ref={ScrollRef}
        sx={{ width: "100%" }}
      >
        <Tooltip title="Refresh" placement="top">
          <IconButton
            size="small"
            sx={{
              mr: 0.7,
              float: "right",
              color: "#6580e0",
              animation: loading && "rotates 2s linear infinite",
              "@keyframes rotates": {
                "0%": { transform: "rotate3d(0, 0, 1, 0deg)" },
                "25%": { transform: "rotate3d(0, 0, 1, 90deg)" },
                "50%": { transform: "rotate3d(0, 0, 1, 180deg)" },
                "100%": { transform: "rotate3d(0, 0, 1, 360deg)" }
              },
            }}
            onClick={() => {
              setLoading()
              const ele = configs.find(e => e.nameId === currConfig)
              getExesTS(ele._id)
              setTimeout(() => removeLoading(), 2000)
            }}>
            <CachedRoundedIcon />
          </IconButton>
        </Tooltip>

        {popup?.type === "success" &&
          <>
            <Typography sx={{
              float: "right", mt: -0.9,
              animation: "arrow 1.1s linear 4",
              "@keyframes arrow": {
                "0%": { mr: 5 },
                "100%": { mr: 0 }
              },
              mr: 5
            }}>
              <IconButton sx={{ color: "#ef5cf2" }}>
                <EastRoundedIcon />
              </IconButton>
            </Typography>
            <Chip
              size="small"
              label={resource.EXES.RESULTS}
              sx={{ float: "right", backgroundColor: "#f8e1f8", color: "#ef5cf2", border: "1px solid #f396f5" }} />
          </>}

        <Box sx={{ mt: 1.5, width: "100%", "& .refreshBtn": { display: "none" } }}>
          <CustomAgGrid
            gridRef={gridRef}
            headCells={ConfigExeResultheadCells}
            setSelected={setSelected}
            rows={exesTs}
          />
        </Box>
      </Box>
      <Drawer anchor="right"
        open={open}
        onClose={toggleDrawer}
        variant="persistent"
      >
        <Box sx={{ width: "32.5vw" }}>
          <IconButton
            className="closeDrawerIcon"
            size="small"
            color="error"
            onClick={() => {
              setOpen(false);
              setTestResults([]);
            }}
            role="presentation"
          >
            <CancelOutlinedIcon />
          </IconButton>
          <SingleExeResult ids={ids} />
        </Box>
      </Drawer>
    </>
  );
}

ConfigExecutions.propTypes = {
  popup: PropTypes.string,
}