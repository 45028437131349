import { EDIT_TC, SET_ADDT, SET_RES_PROPS, SET_STATUS, SET_TCS, SET_TESTCASE, SET_TESTCASES, SET_TESTCASE_DATA, VISIBLE_TC } from "../types.js";

const TestCaseReducer = (state, action) => {
    switch (action.type) {
        case SET_TESTCASES:
            return {
                ...state,
                testcases: action.payload,
            };
        case SET_TESTCASE:
            return {
                ...state,
                testcase: action.payload,
            };
        case SET_TESTCASE_DATA:
            return {
                ...state,
                testcaseData: action.payload,
            };
        case EDIT_TC:
            return {
                ...state,
                editTC: action.payload,
            };
        case SET_STATUS:
            return {
                ...state,
                status: action.payload,
            };
        case VISIBLE_TC:
            return {
                ...state,
                visible_tc: action.payload,
            };
        case SET_RES_PROPS:
            return {
                ...state,
                resProps: action.payload,
            };
        case SET_ADDT:
            return {
                ...state,
                add_t: action.payload,
            };
        case SET_TCS:
            return {
                ...state,
                tcs: action.payload,
            };
        default:
            return state;
    }
};

export default TestCaseReducer;