import { useContext } from "react"
import { styled } from "@mui/material/styles"
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses, Typography } from "@mui/material"

import { resource } from "data/resource"
import cookie from "assets/images/cookie.jpg"
import APIsContext from "contexts/APIs/APIsContext"

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#d0c2ff",
        border: "1px solid #d0c2ff",
        color: "#54577d",
        fontWeight: 550,
        padding: "7px",
        fontSize: 13,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12.2,
        padding: "10px",
        color: "#455a64",
        border: 0,
    },
}))

const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#f6f2fa",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#ede8ff",
    },
}))

export default function Cookies() {
    const apisContext = useContext(APIsContext)
    const { res } = apisContext

    return (
        <TableContainer component={Paper} className='responseTab'>
            {res && !res.cookie ?
                <Box className='cookie'>
                    <center>
                        <img src={cookie} width={100} />
                        <Typography variant="h5">{resource.NO_COOKIE}</Typography>
                    </center>
                </Box> :
                <Table>
                    <TableHead >
                        <TableRow >
                            <StyledTableCell align="justify">{resource.TS.HEAD.NAME}</StyledTableCell>
                            <StyledTableCell align="justify">{resource.VALUE}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {res && res?.cookie[0].split("").map(e => e.split("=")).map((row, i) => (
                            <StyledTableRow key={i}>
                                <StyledTableCell align="justify">{row[0]}</StyledTableCell>
                                <StyledTableCell align="justify">{row[1]}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </TableContainer>
    )
}
