/* eslint-disable react/prop-types */
import { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import Tile from "components/AUT/Tile";
import { resource } from "data/resource";
import IconGroup from "components/AUT/IconGroup";
import DeleteDialog from "components/Utils/DeleteDialog";

import UtilsContext from "contexts/Utils/UtilsContext";
import TestCaseContext from "contexts/TestCase/TestCaseContext";

function TestTile({ deleteHandler, handleEditSubmit, setStatus }) {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(document.location.search);
  const autId = searchParams.get("aut");
  const tsId = searchParams.get("testsuite");

  const utilsContext = useContext(UtilsContext);
  const testCaseContext = useContext(TestCaseContext);

  const { deletes, setDelete } = utilsContext
  const { add_t, testcases, setEditTC, setAddt } = testCaseContext

  const iconData = [{
    icon: <DeleteIcon size="large" />, label: "Delete",
    clickHandler: (event, id) => {
      event.stopPropagation();
      setDelete(id);
    }
  }];

  return (
    <Box
      className="DBListMain"
      sx={{ gridTemplateColumns: "repeat(3, 1fr) !important" }}
    >
      {testcases?.map((obj, index) => (
        <Fragment key={obj?._id ? obj._id : obj.key}>
          <Box
            key={index}
            className="DSListItem"
            sx={{
              borderLeftColor: obj?.isSelected
                ? "#611EB6 !important"
                : "#e0e0e0",
              boxShadow: obj?.isSelected && "0 0.5em 0.5em -0.4em #096eb6",
            }}>
            <Box className="DBList"
              onClick={() => {
                setEditTC(obj?._id ? obj._id : obj.key);
                if (obj?._id) { add_t && setAddt(false); }
                else { setAddt(true); }
                setStatus(false);
                navigate(`/addrequest?aut=${autId}&testsuite=${tsId}&tc=${obj?._id ? obj._id : obj.key}`);
              }}>
              <Tile obj={obj} type={"T"} clickHandler={(event) => {
                event.stopPropagation()
                handleEditSubmit(obj?._id)
              }} />
            </Box>

            <Box className="autActions" >
              {iconData.map(e =>
                <IconGroup
                  obj={e}
                  name={"TC"}
                  key={obj?._id ? obj._id : obj.key}
                  id={obj?._id ? obj._id : obj.key}
                />)}
            </Box>

            {(deletes === obj?._id || deletes === obj?.key) && (
              <DeleteDialog
                // obj={obj}
                deleteHandler={deleteHandler}
                name={resource.TC.NAME}
              />
            )}
          </Box>
        </Fragment>
      ))}
    </Box>
  );
}

export default TestTile;
