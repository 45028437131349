import { useContext, useState } from "react"
import { PropTypes } from "prop-types"
import { styled } from "@mui/material/styles"
import {
  Box, Button,
  Checkbox,
  Divider,
  IconButton,
  List, ListItemText, ListItemIcon, ListItemButton,
  Paper,
  Tooltip, Typography,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete"
import FileCopyIcon from "@mui/icons-material/FileCopy"
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"

import { resource } from "data/resource";
import ApiService from "services/app.service";
import UtilsContext from "contexts/Utils/UtilsContext";
import TestCaseContext from "contexts/TestCase/TestCaseContext";
import ExecutionContext from "contexts/Execution/ExecutionContext";
import TestResultContext from "contexts/TestResult/TestResultContext";
const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

const data = [
  { icon: <DeleteIcon sx={{ color: "#6580e0" }} />, label: "Delete" },
  { icon: <FileCopyIcon sx={{ color: "#6580e0" }} />, label: "Duplicate" },
];

export default function SideConfigList({ setPopUp, tsIds }) {
  const searchParams = new URLSearchParams(document.location.search);
  const autId = searchParams.get("aut");
  const tsId = searchParams.get("testsuite");

  const utilsContext = useContext(UtilsContext);
  const testCaseContext = useContext(TestCaseContext);
  const executionContext = useContext(ExecutionContext);
  const testResultContext = useContext(TestResultContext);

  const { getExesTS } = testResultContext
  const { setEditTC } = testCaseContext
  const { currConfig, configs, setCurrConfig, setAdd, add, getConfigs, setConfig } = executionContext
  const { visible, setVisible, setDelete, showAlert, deleteAll, setDeleteAll, delValues, setDelValues, exeLoading, setExeLoading, removeExeLoading } = utilsContext

  const [, setConfigId] = useState("")

  const changeConfig = (nameId, configIds) => {
    setCurrConfig(nameId);
    setAdd(null);
    setEditTC(null);
    setConfig(null);
    setConfigId(configIds);
    getExesTS(configIds);
  };

  const duplicate = async () => {
    try {
      const config = configs.find(e => e.nameId === currConfig)
      const configData = {
        ...config,
        nameId: Date.now().toString(),
        autId: autId,
        testSuiteId: tsId ? tsId : tsIds
      }
      delete configData._id
      const count = configs.reduce((tot, curr) => {
        if (curr.name?.split(" copy")[0] === configData.name?.split(" copy")[0]) return tot + 1
        return tot
      }, 0)
      configData.name = `${configData.name.split(" copy")[0]} copy ${count}`
      const { data } = await ApiService.runConfig(false, configData)
      showAlert({
        type: data.status,
        msg: data.message,
      });
      setAdd(null);
      setCurrConfig(configData.nameId);
      getConfigs(tsId ? tsId : tsIds);
      getExesTS(data.configuration._id);
      setVisible(false);
    } catch (err) {
      console.log(err.response.data);
      showAlert({
        type: "error",
        msg: err.response.data?.message,
      });
    }
  };

  const execute = async () => {
    try {
      setExeLoading();
      const { data } = await ApiService.runConfigs(delValues);
      setPopUp({
        type: data.status,
        msg: resource.FEEDBACK.EXE_DONE,
      });
      removeExeLoading();
      setTimeout(() => setPopUp(null), resource.TIMEOUT.GET);
    } catch (err) {
      removeExeLoading();
      console.log(err.response.data);
    }
  };

  return (
    <Box className="apiList scrollbarStyle"
      sx={{ height: "calc(100vh - 75px)", overflowY: "scroll" }}
    >
      <Paper elevation={0}>
        <Box display="flex" alignItems="center" px={1.5} py={0.5}>
          <Typography variant="h6">
            {Boolean(configs.length) && (
              <Checkbox
                checked={delValues.length === configs.length}
                onChange={(e) => {
                  setDeleteAll(!deleteAll);
                  e.target.checked ? setDelValues([...configs.filter(e => e.nameId.split("-").length !== 5).map(e => e._id)]) : setDelValues([]);
                }}
              />
            )}{" "}
            Config. List</Typography>

          <Box display="flex" alignItems="center" ml="auto">
            <Tooltip title="Add New Config">
              <IconButton
                size="small"
                sx={{ color: "#611EB6" }}
                onClick={() => setAdd(true)}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Tooltip>

            {deleteAll && Boolean(delValues.length) &&
              <IconButton size="small"
                sx={{ float: "right" }}
                onClick={() => setDelete(true)}>
                <DeleteIcon color="error" />
              </IconButton>}
          </Box>
        </Box>

        <Divider />
        <FireNav component="nav" disablePadding>
          {configs?.map((e) => (
            <Box
              key={e?._id}
              sx={{
                bgcolor: currConfig === e?.nameId && !add ? "#fff" : null,
                borderLeft:
                  currConfig === e?.nameId && !add ? "3px solid #611eb6" : "0",
                "& .MuiTypography-root": {
                  fontWeight:
                    currConfig === e?.nameId && !add ? "600" : "normal",
                },
              }}
            >
              <Tooltip title={e?.name} placement="top">
                <ListItemButton
                  className="apiName"
                  onClick={() => changeConfig(e?.nameId, e?._id)}>

                  {deleteAll && e.nameId.split("-").length !== 5 &&
                    <Checkbox
                      size="small"
                      defaultChecked
                      checked={delValues?.findIndex(ele => ele === e._id) !== -1}
                      value={delValues?.findIndex(ele => ele === e._id) !== -1}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (event.target.checked)
                          { setDelValues([...delValues, e._id]); }
                        else {
                          const index = delValues.findIndex(ele => ele === e._id)
                          delValues.splice(index, 1)
                          setDelValues([...delValues])
                        }
                      }} />}

                  <ListItemText primary={<div>
                    {e?.name?.length > 19 ? `${e?.name.substring(0, 20)}..` : e?.name}
                  </div>} />
                  <MoreHorizOutlinedIcon onClick={() => {
                    if (visible !== e?._id) {
                      setVisible(false);
                      setVisible(e?._id);
                    } else if (visible === e?._id) { setVisible(false); }
                    else { setVisible(e?._id); }
                  }} />
                </ListItemButton>
              </Tooltip>

              <Box className="apiActions">
                {visible === e?._id &&
                  data.map((item) => (
                    <ListItemButton
                      key={item.label}
                      className="moreActionList"
                      disabled={e.nameId.split("-").length === 5}
                      onClick={() => item.label === resource.DELETE ? setDelete(e?._id) : duplicate()}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.label} />
                    </ListItemButton>
                  ))}
              </Box>
            </Box>
          ))}

          {add && <Box
            sx={{
              bgcolor: "#fff",
              borderLeft: "3px solid #1976d2",
              "& .MuiTypography-root": {
                fontWeight: "600",
              },
            }}
          >
            <ListItemButton className="apiName">
              <ListItemText primary={"New Config."} />
              <MoreHorizOutlinedIcon />
            </ListItemButton>
          </Box>}

          {Boolean(delValues.length) && <Box display="flex" justifyContent="right" px={1.5} py={2}>
            <Button variant="contained" size="small"
              sx={{
                backgroundColor: "#6580e0", "&:hover": {
                  backgroundColor: "#6580e0"
                }
              }}
              disabled={exeLoading}
              onClick={execute}>
              Execute <PlayArrowRoundedIcon />
            </Button>
          </Box>}
        </FireNav>
      </Paper >
    </Box >
  );
}

SideConfigList.propTypes = {
  tsIds: PropTypes.string,
  setPopUp: PropTypes.func,
}
