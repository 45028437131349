import { useContext, Fragment } from "react"
import PropTypes from "prop-types"
import { styled } from "@mui/material/styles"
import {
    Box, Checkbox,
    Drawer, IconButton,
    Paper, Stack,
    TableCell, Tooltip, TableHead, TableBody, Table, TableContainer, TableRow, tableCellClasses, Typography
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
// import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded"

import { toggleDrawer } from "hooks"
import { resource } from "data/resource"
import DeleteDialog from "components/Utils/DeleteDialog"
import TestScheduler from "components/Forms/SchedulerForm"

import UtilsContext from "contexts/Utils/UtilsContext"
import ScheduleContext from "contexts/Schedule/ScheduleContext"

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#d0c2ff",
        border: "1px solid #d0c2ff",
        color: "#54577d",
        fontWeight: 550,
        padding: "7px",
        fontSize: 13,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12.2,
        padding: "13px",
        color: "#54577d",
        border: 0,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#f6f2fa",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#ede8ff",
    },
    cursor: "pointer"
}));

function SchedulerTable({ headCells, rows, deleteHandler }) {

    const utilsContext = useContext(UtilsContext);
    const scheduleContext = useContext(ScheduleContext);

    const { setSchedule } = scheduleContext
    const { edit, setVisible, setDelete, setEdit, delValues, setDelValues, visible, deletes } = utilsContext
    return (
        <>
            <TableContainer component={Paper} sx={{ marginTop: "13px" }}>
                <Table className="customized_table" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">
                                <Checkbox
                                    size="small"
                                    checked={delValues.length === rows.length}
                                    onChange={(e) => {
                                        e.stopPropagation()
                                        e.target.checked ?
                                            setDelValues([...rows.map(e => e.id)]) : setDelValues([])
                                    }} />
                            </StyledTableCell>

                            {headCells?.map((headCell) => (
                                <StyledTableCell key={headCell.id} align={"center"}>{headCell.label}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(rows?.length) && rows?.map((row, i) => (
                            <Fragment key={i}>
                                {edit === row.id ?
                                    <Drawer
                                        anchor="right"
                                        open={visible}
                                        onClose={e => {
                                            setEdit(null)
                                            setSchedule(null)
                                            toggleDrawer(e, setVisible)
                                        }}>
                                        <Box role="presentation" className="resDrawer">
                                            <TestScheduler retVal={setVisible} />
                                        </Box>
                                    </Drawer>
                                    :
                                    <StyledTableRow key={i}>

                                        <StyledTableCell align="center">
                                            <Checkbox
                                                checked={delValues?.findIndex(ele => ele === row.id) !== -1}
                                                value={delValues?.findIndex(ele => ele === row.id) !== -1}
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    if (event.target.checked)
                                                        setDelValues([...delValues, row.id])
                                                    else {
                                                        const index = delValues.findIndex(ele => ele === row.id)
                                                        delValues.splice(index, 1)
                                                        setDelValues([...delValues])
                                                    }
                                                }}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align={"center"}><b>{row.name}</b></StyledTableCell>
                                        <StyledTableCell align={"center"}>{row.isRepeated ? "Yes" : "No"}</StyledTableCell>
                                        <StyledTableCell align={"center"}>{new Date(row.startDateAndTime * 1000).toLocaleString()}</StyledTableCell>
                                        <StyledTableCell align={"center"}>{row.endDateAndTime ? new Date(row.endDateAndTime * 1000).toLocaleString() : "-"}</StyledTableCell>
                                        <StyledTableCell align={"center"}>{row.repeatInterval ? row.repeatInterval : "-"}</StyledTableCell>
                                        <StyledTableCell align={"center"}>{row.repeatFrequencyUnit ? row.repeatFrequencyUnit : "-"}</StyledTableCell>
                                        <StyledTableCell align={"center"}>{row.aut[0]?.name}</StyledTableCell>
                                        <StyledTableCell align={"center"}>
                                            <Stack>
                                                {row.testSuites.map((ts, j) => <> <Fragment key={j}> {ts.name} </Fragment> {(j + 1) !== row.testSuites.length && <br />}</>)}
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell align={"center"}>
                                            {row.testSuites.map((ts, i) => <>
                                                <Stack key={i}>
                                                    {ts.configurationIds.map((e, j) => <Fragment key={j}>{j + 1}&#41; {e.name} </Fragment>)}
                                                </Stack>
                                                {(i + 1) !== row.testSuites.length && <br />}
                                            </>)}
                                        </StyledTableCell>
                                        <StyledTableCell align={"center"}>{new Date(row.createdAt * 1000).toLocaleDateString()}</StyledTableCell>
                                        <StyledTableCell align={"center"}>{row.lastExecutedAt ? new Date(row.lastExecutedAt * 1000).toLocaleString() : "-"}</StyledTableCell>
                                        <StyledTableCell align={"center"}>{row.nextScheduledAt ? new Date(row.nextScheduledAt * 1000).toLocaleString() : "-"}</StyledTableCell>

                                        <StyledTableCell align="center">
                                            <Box className='autListIcons'>

                                                {/* <IconButton
                                                    className="accordianActionIcon"
                                                    size="small"
                                                    onClick={() => {
                                                        // setEdit(row.id)
                                                        // setVisible(true)
                                                    }}
                                                >
                                                    <Tooltip title={`View ${resource.RES}`} placement="top" arrow>
                                                        <VisibilityRoundedIcon />
                                                    </Tooltip>
                                                </IconButton> */}

                                                <IconButton
                                                    className="accordianActionIcon"
                                                    size="small"
                                                    onClick={() => {
                                                        setEdit(row.id)
                                                        setVisible(true)
                                                    }}
                                                >
                                                    <Tooltip title={resource.EDIT} placement="top" arrow>
                                                        <EditOutlinedIcon />
                                                    </Tooltip>
                                                </IconButton>

                                                {!delValues.length && <IconButton
                                                    size="small"
                                                    className="accordianActionIcon"
                                                    onClick={() => setDelete(row.id)}
                                                >
                                                    <Tooltip title={resource.DELETE} placement="top" arrow>
                                                        <DeleteIcon />
                                                    </Tooltip>
                                                </IconButton>}

                                            </Box>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                }
                            </Fragment>
                        ))}
                        {deletes && <DeleteDialog deleteHandler={deleteHandler} name={"Scheduled Test"} />}
                    </TableBody>
                </Table>

            </TableContainer>

            {!rows?.length && <Box sx={{ padding: "5%" }}>
                <center>
                    <Typography variant="h6">{resource.NO_LOAD}</Typography>
                </center>
            </Box>}
        </>
    )
}
SchedulerTable.propTypes = {
    headCells: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    deleteHandler: PropTypes.func
};

export default SchedulerTable;