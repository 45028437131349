import { useContext } from "react"
import { styled } from "@mui/material/styles"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from "@mui/material";

import { resource } from "data/resource"
import APIsContext from "contexts/APIs/APIsContext"

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#d0c2ff",
        border: "1px solid #d0c2ff",
        color: "#54577d",
        fontWeight: 550,
        padding: "7px",
        fontSize: 13,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12.2,
        padding: "13px",
        color: "#54577d",
        border: 0,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#f6f2fa",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#ede8ff",
    },
}));

export default function Header() {

    const apisContext = useContext(APIsContext);
    const { res } = apisContext;

    return (
        <TableContainer component={Paper} className='responseTab'>
            <Table >
                <TableHead >
                    <TableRow >
                        <StyledTableCell align="justify">{resource.KEY}</StyledTableCell>
                        <StyledTableCell align="justify">{resource.VALUE}</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {res && res?.headers?.length && res?.headers?.map((row, i) => (
                        <StyledTableRow key={i}>
                            <StyledTableCell align="justify">{row.name}</StyledTableCell>
                            <StyledTableCell align="justify">{row.value}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
