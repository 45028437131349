/* eslint-disable react/prop-types */
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tooltip,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiFilterRemove } from "@mdi/js";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

export function CustomAgGrid(props) {
  const {
    gridRef,
    ClosePreview,
    setSelected,
    rows,
    headCells,
    context,
    previewId,
  } = props;
  const [pageSize, setpageSize] = useState(10);
  const [resetStatus, setresetStatus] = useState([]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      menuTabs: ["filterMenuTab", "columnsMenuTab"],
    };
  }, []);

  const gridOptions = {
    context: context,
    suppressCellFocus: true,
    isRowSelectable: (rowNode) => {
      return !(rowNode.data?.UsedInTests?.length > 0);
    },
  };

  const rowClassRules = useMemo(() => {
    return {
      "preview-highlight": (params) => {
        return params.data.preview ? params.data.preview : false;
      },
    };
  }, []);

  useEffect(() => {
    if (previewId) {
      const itemsToUpdate = [];
      gridRef.current.api.forEachNode(function (rowNode) {
        const data = rowNode.data;
        if (data.id === previewId || data._id === previewId) {
          data.preview = true;
        } else {
          data.preview = false;
        }
        itemsToUpdate.push(data);
      });
      gridRef.current.api.applyTransaction({ update: itemsToUpdate });
    } else if (gridRef && gridRef.current && gridRef.current.api) {
      const itemsToUpdate = [];
      gridRef.current.api.forEachNode(function (rowNode) {
        const data = rowNode.data;
        data.preview = false;
        itemsToUpdate.push(data);
      });
      gridRef.current.api.applyTransaction({ update: itemsToUpdate });
    }
  }, [previewId]);

  // on selection change
  const onSelectionChanged = useCallback(() => {
    const currentPage = gridRef?.current?.api?.paginationGetCurrentPage();
    const pageSize = gridRef?.current?.api?.paginationGetPageSize();
    const selectedRows = gridRef?.current?.api?.rowModel?.rowsToDisplay
      .slice(currentPage * pageSize, pageSize * (currentPage + 1))
      .filter((node) => node.isSelected())
      .map((node) => node.data);
    const selected = selectedRows?.map((obj) => (obj._id ? obj._id : obj.id));
    setSelected(selected);
  }, []);

  // on clear filter
  const clearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, []);

  // on filter change
  const onFilterChanged = useCallback(() => {
    const data = Object.keys(gridRef.current.api.getFilterModel());
    setresetStatus(data);
    ClosePreview();
    gridRef.current.api.hideOverlay();
    gridRef.current.api.deselectAll();
    if (gridRef.current.api.rowModel.rowsToDisplay.length === 0) {
      gridRef.current.api.showNoRowsOverlay();
    }
  }, []);

  // const onFirstDataRendered = useCallback(() => {
  //   gridRef.current.api.sizeColumnsToFit();
  // }, []);

  const onPageSizeChanged = useCallback((e) => {
    const value = e.target.value;
    setpageSize(value);
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = useCallback((e) => {
    e.api.deselectAll();
  }, []);

  // const onGridSizeChanged = (params) => {
  //   params.api.sizeColumnsToFit();
  // };

  return (
    <>
      <Box className="refreshBtn">
        <IconButton
          color="error"
          size="small"
          variant="oulined"
          onClick={clearFilters}
          disabled={resetStatus.length === 0}
        >
          <Tooltip title="Clear Filters" placement="right">
            <Icon path={mdiFilterRemove} size={0.5} />
          </Tooltip>
        </IconButton>
      </Box>
      <Grid container>
        <Grid sm={12} item>
          <Box className="ag-theme-balham custom-ag-grid" sx={{ height: "calc(100vh - 200px)", width: "100%" }}>
            <Box position="relative" sx={{ flex: "1 1 auto", minHeight: "0" }}>
              <Paper sx={{ height: "100%", width: "100%" }}>
                <AgGridReact
                  ref={gridRef}
                  columnDefs={headCells}
                  rowData={rows}
                  onSelectionChanged={onSelectionChanged}
                  animateRows={true}
                  rowClassRules={rowClassRules}
                  defaultColDef={defaultColDef}
                  onFilterChanged={onFilterChanged}
                  onSortChanged={ClosePreview}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  gridOptions={gridOptions}
                  paginationPageSize={10}
                  pagination={true}
                  // onFirstDataRendered={onFirstDataRendered}
                  onPaginationChanged={onPaginationChanged}
                  // onGridSizeChanged={onGridSizeChanged}
                  tooltipShowDelay={10}
                ></AgGridReact>
                <FormControl className="rowsDisplay" fullWidth size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={pageSize}
                    onChange={onPageSizeChanged}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </Paper>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
