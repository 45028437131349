/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useEffect, Fragment, useContext } from "react"
import { Link } from "react-router-dom"
import {
  Box,
  Drawer,
  Typography,
} from "@mui/material"
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline"
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded"

import Tile from "components/AUT/Tile";
import { resource } from "data/resource";
import IconGroup from "components/AUT/IconGroup";
import CreateAutForm from "components/Forms/AutForm";
import ExportPopup from "components/Utils/ExportPopup";
import DeleteDialog from "components/Utils/DeleteDialog";

import AutContext from "contexts/Aut/AutContext";
import APIsContext from "contexts/APIs/APIsContext";
import UtilsContext from "contexts/Utils/UtilsContext";
import VarContext from "contexts/Variables/VarContext";
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext";

export default function AutListCard({ deleteHandler }) {
  const varContext = useContext(VarContext);
  const autContext = useContext(AutContext);
  const apisContext = useContext(APIsContext);
  const utilsContext = useContext(UtilsContext);
  const testSuiteContext = useContext(TestSuiteContext);

  const { envName, setEnvName } = varContext;
  const { auts, getAuts } = autContext;
  const { curr, setCurr } = apisContext;
  const { setTestSuite } = testSuiteContext;
  const { edit, setEdit, visible, setVisible, deletes } = utilsContext;

  useEffect(() => {
    edit && setEdit(null)
    curr && setCurr(null)
    if (!auts.length) getAuts()
    setTestSuite(null)
    envName && setEnvName(null)
    // deleteAll & setDeleteAll(false)
  }, [])

  const iconData = [
    {
      icon: <FileUploadRoundedIcon size="large" />,
      label: "Export",
      clickHandler: (id) => setVisible(id),
    },
    {
      icon: <ModeEditOutlineIcon size="large" />,
      label: "Edit",
      clickHandler: (id) => {
        setEdit(id);
        setVisible(true);
      },
    },
    // { icon: <DeleteIcon size="large" />, label: "Delete", clickHandler: (id) => setDelete(id) },
  ];

  return (
    <Box className="autTiles">
      {!auts.length && (
        <Box textAlign="center" className="emptyAuts">
          <Typography variant="h5">
            {resource.AUT.EMPTY}
            <br />
            <p className="emptyListType">{resource.AUT.CREATE}</p>
          </Typography>
        </Box>
      )}

      <Box className="DBListMain">
        {auts?.map((obj, index) => (
          <Fragment key={obj?._id}>
            {edit === obj._id ? (
              <Drawer anchor="right" open={visible}>
                <Box role="presentation" className="resDrawer">
                  <CreateAutForm retVal={setVisible} />
                </Box>
              </Drawer>
            ) : (
              <Box
                key={index}
                className={obj?.isPublic ? "DSListItem public" : "DSListItem"}
              >
                <Link to={`/testsuites/?aut=${obj?._id}`}>
                  <Tile obj={obj} type={"AUT"} key={obj?._id} />
                </Link>
                <Box className="autActions">
                  {iconData.map((e, i) => (
                    <IconGroup obj={e} key={i} id={obj?._id} />
                  ))}
                </Box>
                {/* Confirm Deletion Dialog */}
                {/* Export AUT */}
                {visible === obj?._id && <ExportPopup obj={obj} />}
              </Box>
            )}
          </Fragment>
        ))}
        {deletes && (
          <DeleteDialog
            deleteHandler={deleteHandler}
            name={"AUT"}
          />
        )}
      </Box>
    </Box>
  );
}
