/* eslint-disable react/no-deprecated */
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import ResizeObserver from "resize-observer-polyfill";

// Override the default ResizeObserver
window.ResizeObserver = ResizeObserver;

// Add global error handler for ResizeObserver loop error
window.addEventListener("error", event => {
  if (event.message === "ResizeObserver loop completed with undelivered notifications") {
    event.stopImmediatePropagation();
  }
});

window.addEventListener("unhandledrejection", event => {
  if (event.reason && event.reason.message === "ResizeObserver loop completed with undelivered notifications") {
    event.preventDefault();
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();