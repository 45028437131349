import { useReducer } from "react"
import { PropTypes } from "prop-types"
import ScheduleContext from "./ScheduleContext"
import ScheduleReducer from "./ScheduleReducer"

import ApiService from "services/app.service";
import {  userLocalSession } from "_helpers/Constant"
import { SET_LOAD, SET_LOADS, SET_SCHEDULE, SET_SCHEDULES, SET_UNIQUE } from "../types.js";

function ScheduleState(props) {
    const initialState = {
        schedules: [],
        schedule: null,
        uniqueId: null,
        loadResult: null,
        loadResults: []
    };

    const [state, dispatch] = useReducer(ScheduleReducer, initialState);

    const setSchedule = (data) => {
        dispatch({
            type: SET_SCHEDULE,
            payload: data
        });
    };

    const setSchedules = (data) => {
        dispatch({
            type: SET_SCHEDULES,
            payload: data
        });
    };

    const setLoadResult = (data) => {
        dispatch({
            type: SET_LOAD,
            payload: data
        });
    };

    const setLoadResults = (data) => {
        dispatch({
            type: SET_LOADS,
            payload: data
        });
    };

    const setUniqueId = (data) => {
        dispatch({
            type: SET_UNIQUE,
            payload: data
        });
    };

    const getSchedule = async (id) => {
        try {
            const { data } = await ApiService.fetchSchedule(id)
            setSchedule(data.schedule)
            // console.log(data.schedule)
        } catch (err) {
            console.log(err.response.data);
            // if (err.response?.data?.error === "jwtExpired") {
            //     localStorage.removeItem(userLocalSession);
            //     window.location.href = "/login"
            // }
        }
    };

    const getSchedules = async () => {
        try {
            const { data } = await ApiService.fetchSchedules()
            setSchedules(data.schedules)
            // console.log(data.schedules)
        } catch (err) {
            console.log(err.response.data);
            // if (err.response?.data?.error === "jwtExpired") {
            //     localStorage.removeItem(userLocalSession);
            //     window.location.href = "/login"
            // }
        }
    };

    const getLoadResults = async () => {
        try {
            const { data } = await ApiService.loadResults()
            setLoadResults(data.loadResults)
        } catch (err) {
            console.log(err.response.data);
            // if (err.response?.data?.error === "jwtExpired") {
            //     localStorage.removeItem(userLocalSession);
            //     window.location.href = "/login"
            // }
        }
    };

    const getLoadResult = async (id) => {
        try {
            const { data } = await ApiService.loadResult(id)
            setLoadResult(data.loadResult.processedData)
            return data.loadResult.processedData
        } catch (err) {
            console.log(err.response.data);
            // if (err.response?.data?.error === "jwtExpired") {
            //     localStorage.removeItem(userLocalSession);
            //     window.location.href = "/login"
            // }
        }
    };
    return (
        <ScheduleContext.Provider
            value={{
                schedule: state.schedule,
                schedules: state.schedules,
                loadResult: state.loadResult,
                loadResults: state.loadResults,
                uniqueId: state.uniqueId,
                setSchedule,
                setSchedules,
                getSchedule,
                getSchedules,
                setUniqueId,
                getLoadResult,
                getLoadResults
            }}
        >
            {props.children}
        </ScheduleContext.Provider>
    );
}

ScheduleState.propTypes = {
    children: PropTypes.node
};

export default ScheduleState;