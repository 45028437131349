import { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
    Box,
    Grid,
    IconButton,
    List, ListItemButton, ListItemText, ListItem,
    MenuItem,
    Typography, TextField, Tooltip,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import AddRoundedIcon from "@mui/icons-material/AddRounded"

import { resource } from "data/resource"
import ApiService from "services/app.service"
import DeleteDialog from "components/Utils/DeleteDialog"

import AutContext from "contexts/Aut/AutContext"
import APIsContext from "contexts/APIs/APIsContext"
import VarContext from "contexts/Variables/VarContext"
import UtilsContext from "contexts/Utils/UtilsContext"
function SearchVariables({ envs, setEnvs, obj, selectAut, setSelectAut, selectEnv, setSelectEnv }) {

    const autContext = useContext(AutContext)
    const varContext = useContext(VarContext)
    const apisContext = useContext(APIsContext)
    const utilsContext = useContext(UtilsContext)

    const { auts } = autContext
    const { setSaved } = apisContext
    const { env, getEnvNames, inputValue, setInputValue } = varContext
    const { errorData, setErrorData, changes, setChanges, delValues, setDelValues, showAlert, deletes, setDelete } = utilsContext

    const [envName, setEnv] = useState(null)
    const [hover, setHover] = useState(null)

    useEffect(() => {
        const assignEnvironment = async () => {
            let vars = []
            if (!envs?.length && obj?.scope !== "global") {
                vars = await getEnvNames(selectAut)
                setEnvs(vars)
                setSelectEnv(vars?.[0])
            }
        }
        assignEnvironment()
    }, [obj.scope === "global"])

    const deleteHandler = async () => {
        try {
            let res
            if (inputValue && deletes) {
                await ApiService.deleteVar2(inputValue)
                setInputValue(null)
                setDelete(null)
            }
            else
                res = await ApiService.deleteVar(delValues)
            showAlert({
                type: res?.data?.status ?? "success",
                msg: res?.data?.message ?? "Variable deleted successfully",
            });

            setTimeout(async () => {
                setSaved(true)
                setChanges(false)
                setDelValues([])
                const vars = await getEnvNames(selectAut)
                setEnvs(vars)
                setSelectEnv(vars[0])
            }, resource.TIMEOUT.SUCCESS)
        } catch (err) {
            console.log(err.response.data);
            showAlert({
                type: "error",
                msg: err.response.data.message ? err.response.data.message : resource.ERR,
            });
        }
    };

    return (
        <Grid item xs={2.95} className="ecRules">
            <Typography className="rulesTitle">{obj?.scope}</Typography>

            {obj?.scope === "environment" &&
                <TextField
                    select
                    required
                    fullWidth
                    size="small"
                    autoComplete='off'
                    label="Select AUT"
                    sx={{ px: 1, mt: 1 }}
                    value={selectAut || ""}
                    onChange={async e => {
                        if (changes) setSaved(false)
                        else {
                            const vars = await getEnvNames(e.target.value)
                            setEnvs(vars)
                            setSelectAut(e.target.value)
                            setSelectEnv(vars[0])
                        }
                    }}
                >
                    {auts?.length ? auts.map(e => <MenuItem key={e.name} value={e._id}>{e.name} </MenuItem>) : <ListItem disabled sx={{ backgroundColor: "#ccc", fontSize: "0.67rem", color: "black" }}>Nothing to Display</ListItem>}
                </TextField>
            }

            {obj?.scope === "environment" &&
                <Grid container my={1}>

                    <Grid item xs={obj?.scope === "environment" ? 11 : 12}>
                        <TextField
                            sx={{ px: 1 }}
                            fullWidth
                            size="small"
                            name="name"
                            autoComplete='off'
                            variant="outlined"
                            label={`Add ${obj?.scope}`}
                            autoFocus
                            value={envName || ""}
                            onChange={(e) => {
                                setEnv(e.target.value)
                                errorData && setErrorData(null)
                            }}
                            error={Boolean(errorData)}
                            helperText={errorData?.msg}
                        />
                    </Grid>

                    <Grid item xs={1} >
                        <Box position="relative">
                            <Tooltip title={`${resource.ADD} Environment`}>
                                <IconButton size="large" sx={{
                                    top: "-10px",
                                    right: "-7px",
                                    color: "#6580e0",
                                    position: "absolute",
                                }}
                                    onClick={async () => {
                                        if (!envName)
                                            return setErrorData({
                                                msg: "Cannot Leave Empty"
                                            })
                                        else if (envs?.find(e => e === envName))
                                            return setErrorData({
                                                msg: "Environment name in the same AUT already exists"
                                            })

                                        if (changes) setSaved(false)
                                        else if (!selectAut)
                                            showAlert({
                                                type: "error",
                                                msg: "Please Select AUT"
                                            })
                                        else {
                                            !changes && setChanges(true)
                                            const { data } = await ApiService.createVar({
                                                type: "dynamic",
                                                isMapped: false,
                                                scope: "environment",
                                                value: "temp",
                                                defaultValue: "temp",
                                                name: "temp",
                                                autId: selectAut,
                                                environment: envName
                                            })
                                            setInputValue(data?.variable?._id)
                                            setEnvs([...envs, envName])
                                            setSelectEnv(envName)
                                            setEnv(null)
                                        }
                                    }}>
                                    <AddRoundedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>

                </Grid>
            }

            {obj?.scope !== "global" &&
                <List
                    className="scrollbarStyle"
                    sx={{
                        my: 2, pl: 1.5, pr: 1,
                        width: "100%",
                        paddingTop: "0px",
                        maxHeight: "75vh",
                        overflowY: "scroll"
                    }}
                >
                    {obj?.scope === "environment" ?
                        (envs?.length ?
                            envs?.map((item, i) =>
                                <ListItemButton
                                    key={i}
                                    sx={{
                                        padding: "4px 1rem",
                                        backgroundColor: selectEnv === item ? "#eee" : "white",
                                        borderLeft: selectEnv === item ? "3px solid #611eb6" : "0",
                                        "&:hover": { backgroundColor: "#f2f2f2" }
                                    }}
                                    onClick={async (e) => {
                                        e.stopPropagation()
                                        if (changes && !inputValue) setSaved(false)
                                        else setSelectEnv(item)
                                    }}
                                >
                                    <Box
                                        className="v-center"
                                        width={"100%"}>
                                        <ListItemText primary={item}
                                            onMouseOver={() => setHover(item)}
                                        />
                                        {hover === item &&
                                            <Tooltip title={`${resource.DELETE} Environment`}>
                                                <IconButton size="small" color="error"
                                                    onClick={async () => {
                                                        if (inputValue)
                                                            setDelete(true)
                                                        else
                                                            setDelValues(env.filter(e => e.environment === item && e.autId === selectAut))
                                                    }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </Box>
                                </ListItemButton>
                            ) : <Typography variant="body2" sx={{ p: 2 }}>No environments found</Typography>) :
                        (auts?.length ? auts?.map((item, i) =>
                            <ListItemButton
                                key={i}
                                sx={{
                                    padding: "4px 1rem",
                                    backgroundColor: selectAut === item?._id ? "#eee" : "white",
                                    borderLeft: selectAut === item?._id ? "3px solid #611eb6" : "0",
                                    "&:hover": { backgroundColor: "#f2f2f2" }
                                }}
                                onClick={async () => {
                                    if (changes)
                                        setSaved(false)
                                    else {
                                        const vars = await getEnvNames(item?._id)
                                        setEnvs(vars)
                                        setSelectAut(item?._id)
                                        setSelectEnv(vars[0])
                                    }
                                }}>
                                <Box
                                    className="v-center"
                                    width={"100%"}>
                                    <ListItemText primary={item?.name} />
                                </Box>
                            </ListItemButton>
                        ) : <Typography variant="body2" sx={{ p: 2 }}>No AUTs found</Typography>)}
                </List>
            }
            {(Boolean(delValues.length) || Boolean(deletes)) && <DeleteDialog
                name={"Environment"}
                deleteHandler={deleteHandler}
            />}
        </Grid>
    )
}

export default SearchVariables

SearchVariables.propTypes = {
    envs: PropTypes.array,
    obj: PropTypes.object,
    selectAut: PropTypes.string,
    selectEnv: PropTypes.string,
    setEnvs: PropTypes.func,
    setSelectAut: PropTypes.func,
    setSelectEnv: PropTypes.func
}