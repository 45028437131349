import { useContext, Fragment, useState } from "react"
import { ReactSortable } from "react-sortablejs"
import {
  Autocomplete,
  Box,
  Grid,
  IconButton, ListItem, ListItemText,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

import { handleDragOver } from "hooks"
import { resource } from "data/resource"
import RuleCheckCard from "../RuleCheckCard"
import JsonViewer from "components/Utils/JsonViewer"

import APIsContext from "contexts/APIs/APIsContext"
import UtilsContext from "contexts/Utils/UtilsContext"
import TestCaseContext from "contexts/TestCase/TestCaseContext"

const Functional = () => {
  const apisContext = useContext(APIsContext)
  const utilsContext = useContext(UtilsContext)
  const testCaseContext = useContext(TestCaseContext)

  const { req } = apisContext
  const { changes, setChanges } = utilsContext
  const { resProps, testcaseData, setTestcaseData } = testCaseContext

  const [open, setOpen] = useState(false)

  const onChangeHandler = (e, i) => {
    !changes && setChanges(true)
    setTestcaseData({
      ...testcaseData,
      functionalAssertions: [
        ...testcaseData.functionalAssertions.slice(0, i),
        {
          ...testcaseData.functionalAssertions[i],
          [e.target.name]: e.target.value,
        },
        ...testcaseData.functionalAssertions.slice(i + 1),
      ],
    })
  }

  const onOperatorChange = (e, i) => {
    !changes && setChanges(true)
    setTestcaseData({
      ...testcaseData,
      functionalAssertions: [
        ...testcaseData.functionalAssertions.slice(0, i),
        {
          ...testcaseData.functionalAssertions[i],
          [e.target.name]: e.target.value === "and" ? "or" : "and",
        },
        ...testcaseData.functionalAssertions.slice(i + 1),
      ],
    })
  }

  const onChangeHandler2 = (e, i, j) => {
    !changes && setChanges(true)
    setTestcaseData({
      ...testcaseData,
      functionalAssertions: [
        ...testcaseData.functionalAssertions.slice(0, i),
        {
          ...testcaseData.functionalAssertions[i],
          rules: [
            ...testcaseData.functionalAssertions[i].rules.slice(0, j),
            {
              ...testcaseData.functionalAssertions[i].rules[j],
              [e.target.name]:
                e.target.name === "isCaseSensitive"
                  ? e.target.value === "false"
                  : e.target.name === "operator"
                    ? e.target.value === "and"
                      ? "or"
                      : "and"
                    : e.target.value,
            },
            ...testcaseData.functionalAssertions[i].rules.slice(j + 1),
          ],
        },
        ...testcaseData.functionalAssertions.slice(i + 1),
      ],
    })
  }

  const deleteRow = (id, key) => {
    !changes && setChanges(true)
    setTestcaseData({
      ...testcaseData,
      functionalAssertions: testcaseData.functionalAssertions.filter((e) =>
        e._id ? e._id !== id : e.key !== key
      ),
    })
  }

  const handleDrop = (event, i) => {
    event.preventDefault()
    const stringData = event.dataTransfer.getData("ruleData")
    const draggedItem = stringData && JSON?.parse?.(stringData)
    if (draggedItem) {
      !changes && setChanges(true)
      setTestcaseData({
        ...testcaseData,
        functionalAssertions: [
          ...testcaseData.functionalAssertions.slice(0, i),
          {
            ...testcaseData.functionalAssertions[i],
            rules: [
              ...testcaseData.functionalAssertions[i].rules,
              {
                ...draggedItem,
                key: Date.now().toString(),
                serialNumber: testcaseData.functionalAssertions[i].rules?.length + 1
              },
            ],
          },
          ...testcaseData.functionalAssertions.slice(i + 1),
        ],
      })
    }
  }

  const deleteRule = (i, key, id) => {
    !changes && setChanges(true)
    setTestcaseData({
      ...testcaseData,
      functionalAssertions: [
        ...testcaseData.functionalAssertions.slice(0, i),
        {
          ...testcaseData.functionalAssertions[i],
          rules: testcaseData.functionalAssertions[i].rules.filter((e) =>
            e._id ? e._id !== id : e.key !== key
          ),
        },
        ...testcaseData.functionalAssertions.slice(i + 1),
      ],
    })
  }

  const onReArrangeRuleChecks = (i, newRuleList) => {
    setTestcaseData({
      ...testcaseData,
      functionalAssertions: [
        ...testcaseData.functionalAssertions.slice(0, i),
        {
          ...testcaseData.functionalAssertions[i],
          rules: newRuleList.map((e, ind) => ({ ...e, serialNumber: ind + 1 })),
        },
        ...testcaseData.functionalAssertions.slice(i + 1),
      ],
    })
  }

  return (
    <Box sx={{ px: 1 }}>
      <Grid container>
        <Grid item md={4}>
          <Typography
            className="ecTitleHead"
            sx={{ borderTopLeftRadius: "12px" }}
          >
            {resource.TC.HEAD1.PROP}
          </Typography>
        </Grid>
        <Grid item md={8}>
          <Typography
            className="ecTitleHead"
            sx={{ borderTopRightRadius: "12px" }}
          >
            {resource.TC.RULE}
          </Typography>
        </Grid>

        {testcaseData?.functionalAssertions?.map((each, columnIndex) => (
          <Fragment key={columnIndex}>
            <Grid item sm={4} border={"1px solid #cfcfcf"}>
              <Box sx={{ textAlign: "center", p: 1, mt: 1 }}>
                <Autocomplete
                  freeSolo
                  sx={{ zIndex: 0 }}
                  value={each?.targetField}
                  onChange={(e, v) => {
                    !changes && setChanges(true)
                    setTestcaseData({
                      ...testcaseData,
                      functionalAssertions: [
                        ...testcaseData.functionalAssertions.slice(0, columnIndex),
                        {
                          ...testcaseData.functionalAssertions[columnIndex],
                          targetField: v,
                        },
                        ...testcaseData.functionalAssertions.slice(columnIndex + 1),
                      ],
                    })
                  }}
                  options={req?.responsePaths.length ? req?.responsePaths.map(option => option) : resProps.map(option => option)}
                  renderOption={(props, option) => (
                    <MenuItem
                      {...props}
                      disabled={testcaseData.functionalAssertions.some((em) => em.targetField === option)}
                    >
                      <ListItem sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", padding: "15px" }}>
                        <ListItemText primary={option} />
                      </ListItem>
                    </MenuItem>
                  )}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label="Search Properties"
                      required
                      fullWidth
                      size="small"
                      autoComplete='off'
                      name="targetField"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault() // Prevent form submission
                          onChangeHandler(e, columnIndex)
                        }
                      }}
                      error={!each?.targetField}
                      helperText={!each?.targetField && "Field cannot be empty"}
                    />
                  }
                />
                <Box sx={{
                  textDecoration: "underline",
                  transform: "translate(15%, 50%)",
                }}>
                  <Typography sx={{ fontStyle: "oblique", cursor: "pointer" }}
                    onClick={() => setOpen(`i-${columnIndex}`)}>
                    {resource.TC.ADVANCED}
                  </Typography>
                </Box>
                <Grid sm={12} item textAlign={"left"} >
                  <IconButton
                    size="small"
                    color="error"
                    sx={{ transform: "translate(-2%, -55%)" }}
                    onClick={() => deleteRow(each?._id, each?.key)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              mt={1}
              sm={8}
              onDrop={(e) => handleDrop(e, columnIndex)}
              onDragOver={handleDragOver}
              position="relative"
              borderRight={"1px solid #cfcfcf"}
              borderBottom={"1px solid #cfcfcf"}
              py="16px"
            >
              <ReactSortable
                animation={200}
                easing="ease-out"
                className="dragItemsList"
                sx={{
                  boxSizing: "border-box",
                  display: "grid",
                  width: "100%",
                  gridGap: "10px",
                }}
                delayOnTouchStart={true}
                list={each.rules || []}
                setList={(newList) => onReArrangeRuleChecks(columnIndex, newList)}
              >
                {each.rules?.map((rule, i) => (
                  <RuleCheckCard
                    key={i}
                    row={each}
                    rules={rule}
                    ruleIndex={i}
                    columnIndex={columnIndex}
                    deleteRule={deleteRule}
                    checkBoxHandler={onChangeHandler2}
                  />
                ))}
              </ReactSortable>
              {
                !each.rules.length &&
                <Grid item sm={12} p={1} >
                  <Box className="v-center">
                    <Box
                      className="v-center h-center"
                      sx={{ p: 3, border: "1px dashed #c8b8ff", flexGrow: 1 }}
                    >
                      {resource.TC.DRAG}
                    </Box>
                  </Box>
                </Grid>
              }
              {columnIndex !==
                testcaseData?.functionalAssertions?.length - 1 && (
                  <Box
                    sx={{
                      zIndex: 1,
                      bottom: "-14px",
                      display: "flex",
                      position: "absolute",
                      justifyContent: "center",
                      width: "100%",
                      "& .and-or": {
                        padding: "0 8px",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                        border: "1px solid #cfcfcf",
                      },
                    }}
                  >
                    <Stack className="and-or" direction="row" alignItems="center">
                      <Typography>
                        <b>OR</b>
                      </Typography>
                      <Switch
                        name="operator"
                        className="switchStyle"
                        checked={each?.operator === "and"}
                        value={each?.operator}
                        onChange={(e) => onOperatorChange(e, columnIndex)}
                      />
                      <Typography>
                        <b>AND</b>
                      </Typography>
                    </Stack>
                  </Box>
                )}
            </Grid>
            {open === `i-${columnIndex}` && <JsonViewer open={open} setOpen={setOpen} onChangeHandler={onChangeHandler} />}
          </Fragment>
        ))}
      </Grid>
    </Box>
  )
}

export default Functional