/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, Fragment, useContext } from "react"
import { Link } from "react-router-dom"
import {
  Box,
  Drawer,
  Typography,
} from "@mui/material"
// import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import TuneRoundedIcon from "@mui/icons-material/TuneRounded"
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline"

import Tile from "components/AUT/Tile"
import { resource } from "data/resource"
import ExecuteTestSuite from "../TSExecution"
import IconGroup from "components/AUT/IconGroup"
// import ExportPopup from "components/Utils/ExportPopup"
import DeleteDialog from "components/Utils/DeleteDialog"
import CreateTestSuiteForm from "components/Forms/TSForm"

import APIsContext from "contexts/APIs/APIsContext"
import VarContext from "contexts/Variables/VarContext"
import UtilsContext from "contexts/Utils/UtilsContext"
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext"
import ExecutionContext from "contexts/Execution/ExecutionContext"

export default function TestTile({ deleteHandler, autId }) {

  const varContext = useContext(VarContext)
  const apisContext = useContext(APIsContext)
  const utilsContext = useContext(UtilsContext)
  const testSuiteContext = useContext(TestSuiteContext)
  const executionContext = useContext(ExecutionContext)

  const { getConfigs } = executionContext
  const { envName, setEnvName } = varContext
  const { curr, setCurr, getReqs } = apisContext
  const { testSuites, getTestSuites, setTestSuite } = testSuiteContext
  const { edit, setEdit, visible, setVisible, deletes, opens, setOpens } = utilsContext

  useEffect(() => {
    edit && setEdit(null)
    curr && setCurr(null)
    getTestSuites(autId)
    setTestSuite(null)
    envName && setEnvName(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testSuites?.testSuites?.length])

  // const execute = async () => {
  //   try {
  //     setExeLoading1()
  //     const { data } = await axios.get(`/api/v1/configuration/execute/?id=${configs[0]._id}&environment=${envName}`,
  //       { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
  //     )
  //     getExesTS(data.configuration._id)
  //     removeExeLoading1()
  //     setExecute(data)
  //   } catch (err) {
  //     removeExeLoading1()
  //     console.log(err.response.data)
  //     showAlert({
  //       type: "error",
  //       msg: err.response.data.message ? err.response.data.message : resource.ERR,
  //     })
  //   }
  // }

  const iconData = [
    {
      icon: <TuneRoundedIcon size="large" />, label: "Manage Configs", clickHandler: (id) => {
        setOpens(id)
        getReqs(id)
        getConfigs(id)
      }
    },
    // {
    //   icon: <PlayArrowIcon size="large" />, label: "Execute",
    // },
    {
      icon: <ModeEditOutlineIcon size="large" />, label: "Edit", clickHandler: (id) => {
        setEdit(id)
        setVisible(true)
      }
    },
    // { icon: <DeleteIcon size="large" />, label: "Delete", clickHandler: (id) => setDelete(id) },
  ]

  return (
    <Box className='autTiles'>

      {!testSuites?.testSuites?.length && (
        <Box textAlign="center" className="emptyAuts">
          <Typography variant="h5">
            {resource.TS.EMPTY}
            <br />
            <p className="emptyListType">{resource.AUT.CREATE}</p>
          </Typography>
        </Box>
      )}

      <Box className="DBListMain">
        {testSuites?.testSuites?.map((obj, index) => (
          <Fragment key={obj?._id}>

            {edit === obj?._id ?
              (
                <Drawer anchor="right" open={visible}>
                  <Box role="presentation" className="resDrawer">
                    <CreateTestSuiteForm retVal={setVisible} />
                  </Box>
                </Drawer>
              )
              :
              (<Box key={index} className={obj?.protocol === "http" ? "DSListItem public" : "DSListItem"}>
                <Link to={`/addrequest?aut=${autId}&testsuite=${obj?._id}`}>
                  <Tile obj={obj} type={"TS"} />
                </Link>

                <Box className="autActions">
                  {iconData.map((e, i) =>
                    <IconGroup
                      obj={e}
                      key={i}
                      id={obj._id}
                    />)}
                </Box>
                {opens === obj?._id && <ExecuteTestSuite tsIds={opens} />}
                {/* {execute && <APISuiteResult executes={execute} setExecute={setExecute} />} */}
                {/* {exeLoading1 && <DefaultConfig />} */}
              </Box>
              )}
          </Fragment>
        ))}
        {deletes && !opens && (
          <DeleteDialog
            deleteHandler={deleteHandler}
            name={"API Suite"}
          />
        )}
        {/* {<ExportPopup obj={testSuites?.aut} open={opens} setOpen={setOpens} />} */}
      </Box>
    </Box>
  )
}
