import { useState, useEffect, useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";

import { resource } from "data/resource";
import ApiService from "services/app.service";
import InnerHeader from "components/Utils/InnerHeader";
import AutStatusCard from "components/Dashboard/AutStatusCard";

import APIsContext from "contexts/APIs/APIsContext";
import VarContext from "contexts/Variables/VarContext";
import UtilsContext from "contexts/Utils/UtilsContext";
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext";

const status = [
  { name: "good", value: "success" },
  { name: "average", value: "warning" },
  { name: "poor", value: "error" },
  { name: "unknown", value: "info" },
];

const ApiDashBoard = () => {

  const varContext = useContext(VarContext);
  const apisContext = useContext(APIsContext);
  const utilsContext = useContext(UtilsContext);
  const testSuiteContext = useContext(TestSuiteContext);

  const { curr, setCurr } = apisContext;
  const { setTestSuite } = testSuiteContext;
  const { envName, setEnvName } = varContext;
  const { showAlert, edit, setEdit } = utilsContext;

  const [dbData, setdbData] = useState([]);

  useEffect(() => {
    getDbData();
    setTestSuite(null);
    edit && setEdit(null);
    curr && setCurr(null);
    envName && setEnvName(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDbData = async () => {
    try {
      const { data } = await ApiService.fetchDB();
      setdbData(data.dashboardData);
    } catch (err) {
      console.log(err.response.data);
      showAlert({
        type: "error",
        msg: err.response.data.message
          ? err.response.data.message
          : resource.ERR,
      });
    }
  };

  return (
    <Box width={"100%"}>
      <InnerHeader>
        <Typography variant="h6">Dashboard</Typography>
      </InnerHeader>
      <Box pt={8}>
        <Box
          sx={{
            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#d0c2ff",
            mt: "8px",
          }}
        >
          <Typography variant="h6" sx={{ color: "#54577d" }}>
            AUTs (Application under test):{" "}
            {dbData.reduce((tot, cur) => tot + cur.auts.length, 0)}
          </Typography>
        </Box>
        <Grid container>
          {status.map((e) => (
            <Grid item xs={12} md={2.95} mx={0.3} key={e.name}>
              <AutStatusCard color={e.value} name={e.name} dbData={dbData} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ApiDashBoard;
