/* eslint-disable no-undef */
import { useContext, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { PropTypes } from "prop-types";
import {
  Box, Button,
  Grid,
  IconButton,
  Table, TableHead, TableCell, TableRow, TableBody, TableContainer,
  TextField, Tooltip, Typography, tableCellClasses
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecordRounded"

import { resource } from "data/resource"
import ApiService from "services/app.service"
import UnsavedChangesDialog from "components/Utils/UnsavedChangesDialog"

import AutContext from "contexts/Aut/AutContext"
import APIsContext from "contexts/APIs/APIsContext"
import UtilsContext from "contexts/Utils/UtilsContext"
import VarContext from "contexts/Variables/VarContext"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#d0c2ff",
    border: "1px solid #d0c2ff",
    color: "#54577d",
    fontWeight: 550,
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12.2,
    // padding: "13px",
    color: "#54577d",
    border: 0,
  },
}))
const StyledTableContainer = styled(TableContainer)(() => ({
  maxHeight: "75vh",
}));
export default function CreateVariableForm({ envs, obj, setEnvs, selectAut, selectEnv, setSelectEnv }) {

  const varContext = useContext(VarContext)
  const autContext = useContext(AutContext)
  const apisContext = useContext(APIsContext)
  const utilsContext = useContext(UtilsContext)

  const { getAuts, auts } = autContext
  const { setSaved } = apisContext
  const { showAlert, changes, setChanges } = utilsContext
  const { getVars, env, globalVars, autVars, getGlobalVars, inputValue, setInputValue } = varContext

  const [varData, setVarData] = useState([])
  const [delVarData, setDelVarData] = useState([])

  useEffect(() => {
    getAuts()
    if (obj.scope === "global")
      setVarData(globalVars.map(e => ({ _id: e._id, variableName: e.name, variableValue: e.value })))
    else if (obj.scope === "AUT")
      setVarData(autVars.filter(e => e.autId === selectAut).map(e => ({ _id: e._id, variableName: e.name, variableValue: e.value })))
    else if (obj.scope === "environment")
      setVarData(env.filter(e => e.autId === selectAut && e.environment === selectEnv).map(e => ({ _id: e._id, variableName: e.name, variableValue: e.value })))

  }, [obj.categoryName, selectAut, selectEnv])

  const onChangeInput = (e, id) => {
    !changes && setChanges(true)
    const { name, value } = e.target
    const newParamsArray = varData.map((each, index) =>
      index === id ?
        {
          ...each,
          [name]: value,
          error1: name === "variableName" ? (value === "" || value.trim().length < 3) : each.error1,
          error2: name === "variableValue" ? value === "" : each.error2,
          helperText: value === "" ? "Cannot leave empty" : (value.trim().length < 3 ? "Minimum 3 characters required" : "")
        } : each
    )
    const emptyRows = newParamsArray.filter((each) =>
      Object.entries(each).filter(e => e[0] !== "error1" && e[0] !== "error2" && e[0] !== "helperText").every((value) => value[1] === "")
    )
    if (emptyRows.length === 0) {
      // All columns are filled, add a new row
      const newRow = {
        variableName: "",
        variableValue: "",
        error1: false,
        error2: false,
        helperText: ""
      }
      setVarData([...newParamsArray, newRow])
    } else if (emptyRows.length > 1) {
      // More than one column is empty, delete the current row
      const filteredData = newParamsArray.filter((each, index) => index !== id);
      setVarData(filteredData);
    } else {
      // At least one column is filled, update the data
      setVarData(newParamsArray);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault()
    // remove the deleted documents from the database
    delVarData?.length && await ApiService.deleteVar(delVarData)
    // remove the dynamic variable that was created during creation of env.
    inputValue && await ApiService.deleteVar2(inputValue)
    setInputValue(null)
    // return the updated object
    const updatedData = varData.map(each => {
      if (!each._id) {
        const newObj = {
          type: "static",
          isMapped: false,
          scope: obj?.scope,
          value: each.variableValue,
          defaultValue: each.variableValue,
          name: each.variableName.trim(),
          userId: JSON.parse(localStorage.getItem("APITEUserData")).UserId,
          organizationId: JSON.parse(localStorage.getItem("APITEUserData")).OrganisationId,
        }
        if (obj?.scope === "AUT" || obj?.scope === "environment") newObj.autId = selectAut
        if (obj?.scope === "environment") newObj.environment = selectEnv
        return newObj
      }
      return ({
        _id: each._id,
        value: each.variableValue,
        name: each.variableName.trim(),
      })
    })
    try {
      const variableData = updatedData?.filter(each => each?.name !== "" || each?.value !== "")
      const { data } = await ApiService.createAndUpdateVar(variableData)
      showAlert({
        type: data.status,
        msg: data.message,
      })
      setDelVarData([])
      setSaved(true)
      setChanges(false)
      // if varData is empty and scope is environment
      if ((!varData?.length || varData.every(each => each?.variableName === "" && each?.variableValue === "")) && obj?.scope === "environment") {
        const index = envs.indexOf(selectEnv)
        setEnvs([...envs.slice(0, index), ...envs.slice(index + 1)])
        index === 0 ? setSelectEnv(envs[index + 1]) : setSelectEnv(envs[index - 1])
      }

      if (obj.scope === "global") {
        const allVars = await getGlobalVars()
        setVarData([...allVars.map(e => ({ _id: e._id, variableName: e.name, variableValue: e.value }))])
      }
      else if (obj.scope === "AUT") {
        const allVars = await getVars("AUT")
        setVarData([...allVars.filter(e => e.autId === selectAut).map(e => ({ _id: e._id, variableName: e.name, variableValue: e.value }))])
      }
      else if (obj.scope === "environment" && varData?.length) {
        const allVars = await getVars()
        setVarData([...allVars.filter(e => e.autId === selectAut && e.environment === selectEnv).map(e => ({ _id: e._id, variableName: e.name, variableValue: e.value }))])
      }
    }
    catch (err) {
      console.log(err.response.data)
      const msg = err.response.data.message
      showAlert({
        type: "error",
        msg: msg ? msg.split(":")[msg.split(":")?.length - 1] : resource.ERR,
      });
    }
  };

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={submitHandler}>

      <Grid container my={1}>
        <Grid item xs={3.5}>
          <Typography pl={1} pt={.5}>
            Scope: <b>{obj?.scope}</b> | {resource.VARS.COUNT}:{" "}
            <b>
              {obj?.scope === "global"
                ? globalVars?.length
                : obj?.scope === "AUT"
                  ? autVars.filter(e => e.autId === selectAut)?.length
                  : env.filter(e => e.autId === selectAut && e.environment === selectEnv)?.length}
            </b> &nbsp;&nbsp;
            {changes && <FiberManualRecordIcon color="error" className="unsavedIndicator" />}
          </Typography>
        </Grid>
        <Tooltip title={"Create an AUT first to start creating variables."} placement="top">
          <Grid item xs={8.5} textAlign={"right"} pr={1}>
            <Button
              disabled={!auts?.length}
              size="small"
              type='submit'
              variant="contained"
              sx={{
                backgroundColor: "#1fcc75",
                "&:hover": {
                  backgroundColor: "#1fcc75",
                },
              }}
            >
              {resource?.SAVE}
            </Button>
          </Grid>
        </Tooltip>
      </Grid>

      <Grid item container mb={3}>
        <Grid xs={12} item mx={1}>
          <StyledTableContainer className="scrollbarStyle">
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">{resource.AUT.HEAD.NAME}</StyledTableCell>
                  <StyledTableCell align="center">{resource.VALUE}</StyledTableCell>
                  <StyledTableCell align="center">{resource.DELETE}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {varData.map((row, index) => (
                  <TableRow key={`${index}`}>
                    <TableCell align="center">
                      <TextField
                        fullWidth
                        size="small"
                        autoComplete='off'
                        sx={{ px: "7px" }}
                        variant="outlined"
                        id="variableName"
                        name="variableName"
                        placeholder="Variable Name"
                        value={row.variableName}
                        inputProps={{ style: { height: "10px" } }}
                        onChange={(e) => onChangeInput(e, index)}
                        error={row.error1}
                        helperText={row.error1 && row.helperText}
                      />
                    </TableCell>

                    <TableCell py={1} align="center">
                      <TextField
                        fullWidth
                        size="small"
                        sx={{ px: "7px" }}
                        autoComplete='off'
                        variant="outlined"
                        id="variableValue"
                        name="variableValue"
                        placeholder="Variable Value"
                        value={row.variableValue}
                        inputProps={{ style: { height: "10px" } }}
                        onChange={(e) => onChangeInput(e, index)}
                        error={row.error2}
                        helperText={row.error2 && "Cannot leave empty"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        className="accordianActionIcon"
                        onClick={() => {
                          !changes && setChanges(true)
                          row?._id && setDelVarData([...delVarData, row._id])
                          setVarData(varData.filter((e, i) => i !== index))
                        }}
                      >
                        <Tooltip title={resource.DELETE} placement="right" arrow>
                          <DeleteIcon />
                        </Tooltip>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {(!varData?.length || !varData.some(each => each?.variableName === "" && each?.variableValue === "")) &&
                  (obj?.scope === "environment" && !envs?.length)
                  ? <></> :
                  < TableRow onClick={() => setVarData([...varData, {
                    variableName: "",
                    variableValue: "",
                    error1: false,
                    error2: false,
                    helperText: ""
                  }])}>
                    <TableCell align="center" sx={{ color: "#adadad" }}>
                      <Typography p={.35}>
                        Add New Variable
                      </Typography>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
      </Grid>

      <UnsavedChangesDialog
        submitHandler={submitHandler}
        name={"Variables"}
      />
    </Box >
  )
}

CreateVariableForm.propTypes = {
  obj: PropTypes.shape({
    scope: PropTypes.string,
    categoryName: PropTypes.string,
  }),
  envs: PropTypes.array,
  setEnvs: PropTypes.func,
  setSelectEnv: PropTypes.func,
  selectAut: PropTypes.string,
  selectEnv: PropTypes.string,
}
