import { useContext } from "react"
import { PropTypes } from "prop-types"
import { Backdrop, Box, Button, Fade, IconButton, Modal, Typography } from "@mui/material"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"

import { resource } from "data/resource"
import ApiService from "services/app.service"
import UtilsContext from "contexts/Utils/UtilsContext"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 4,
};

function ExportPopup({ obj }) {

    const utilsContext = useContext(UtilsContext);
    const { setVisible, showAlert } = utilsContext;

    const exportAUT = async () => {
        try {
            const { data } = await ApiService.exportAut(obj?._id);

            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                JSON.stringify(data.aut)
            )}`
            const a = document.createElement("a")
            a.href = jsonString
            a.setAttribute(
                "download",
                `${obj?.name}.json`,
            );
            const clickEvt = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();

            setTimeout(() => {
                setVisible(null);
            }, resource.TIMEOUT.SUCCESS);
        } catch (err) {
            console.log(err.response);
            showAlert({
                type: "error",
                msg: err.response.data.message ? err.response.data.message : resource.ERR,
            });
        }
    };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={true}
            // onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}>
            <Fade in={true}>
                <Box sx={style}>
                    <IconButton
                        sx={{
                            position: "absolute",
                            right: "8px",
                            top: "8px",
                        }}
                        color="error"
                        size="small"
                        onClick={() => {
                            setVisible(null);
                            // open && setOpen(false)
                        }}
                    >
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Typography variant="h4" sx={{ color: "#611eb6" }}>
                        {resource.EX} {resource.AUT.NAME}
                    </Typography>
                    <Typography variant="h3" mt={2} sx={{ fontSize: "13px", color: "#54577d" }}>
                        The AUT <b>{obj?.name}</b> will be exported as a JSON file. Proceed to export it.
                    </Typography>

                    <Box sx={{ marginTop: "20px" }}>
                        <Button
                            sx={{
                                float: "right", backgroundColor: "#6580e0",
                                "&:hover": {
                                    backgroundColor: "#6580e0",
                                },
                            }}
                            variant="contained"
                            size="small"
                            color="warning"
                            onClick={exportAUT}>
                            {resource.EX}
                        </Button>
                        <Button
                            sx={{
                                float: "right", marginRight: "10px", backgroundColor: "#eb5160",
                                "&:hover": {
                                    backgroundColor: "#eb5160",
                                },
                            }}
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => {
                                // open && setOpen(false)
                                setVisible(null);
                            }}>
                            {resource.CANCEL}
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default ExportPopup

ExportPopup.propTypes = {
    obj: PropTypes.object
}
