import { useReducer, useContext } from "react"
import { PropTypes } from "prop-types"
import TestSuiteContext from "./TestSuiteContext"
import TestSuiteReducer from "./TestSuiteReducer"
import UtilsContext from "contexts/Utils/UtilsContext"

import ApiService from "services/app.service"
import { SET_TESTSUITE, SET_TESTSUITES } from "../types.js"

function TestSuiteState(props) {
  const initialState = {
    testSuites: [],
    testSuite: null,
  }
  const [state, dispatch] = useReducer(TestSuiteReducer, initialState)

  const { showAlert } = useContext(UtilsContext)

  const setTestSuites = (data) => {
    dispatch({
      type: SET_TESTSUITES,
      payload: data,
    })
  }

  const setTestSuite = (data) => {
    dispatch({
      type: SET_TESTSUITE,
      payload: data,
    })
  }

  const getTestSuites = async (id) => {
    try {
      const { data } = await ApiService.fetchTss(id)
      setTestSuites(data)
      return data
      // console.log(data, 'testsuites')
    } catch (err) {
      console.log(err.response)
      showAlert({
        type: "error",
        msg: err.response.data.message
      })
    }
  }

  const getTestSuite = async (id) => {
    try {
      const { data } = await ApiService.fetchTs(id)
      setTestSuite(data.testSuite)
    } catch (err) {
      console.log(err.response.data)
    }
  }

  return (
    <TestSuiteContext.Provider
      value={{
        testSuite: state.testSuite,
        testSuites: state.testSuites,
        setTestSuite,
        setTestSuites,
        getTestSuite,
        getTestSuites,
      }}
    >
      {props.children}
    </TestSuiteContext.Provider>
  )
}

export default TestSuiteState

TestSuiteState.propTypes = {
  children: PropTypes.node
}
