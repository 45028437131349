import { Fragment, useContext } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import { resource } from "data/resource";
import TestResultContext from "contexts/TestResult/TestResultContext";

const statusChip = {
  transform: "scale(0.8)",
  marginRight: "10px",
  color: "white",
  borderRadius: "5px",
};

const Accordion = styled((props) => (
  <MuiAccordion
    defaultExpanded
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(() => ({
  backgroundColor: "#f1edff"
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default function TestResult() {

  const testResultContext = useContext(TestResultContext);
  const { results } = testResultContext;

  return (
    <Grid container>
      {results.filter(e => e.isSelected)?.length ? (
        results.filter(e => e.isSelected).map((e) => (
          <Accordion key={e?._id}>
            <AccordionSummary>
              <Typography sx={{ marginLeft: "5px", marginTop: "7px", color: "#54577d" }}>
                <b>{e.name}</b>
                <span
                  style={{
                    fontWeight: 600,
                    color: e?.result === "FAIL" ? "#EF2D56" : "#1AA376",
                  }}
                >
                  &nbsp;(
                  {e?.functionalAssertions?.filter((e) => e?.status === "PASS")?.length +
                    (e.performanceAssertions ? Object.values(e.performanceAssertions).filter((e) => e?.status === "PASS")?.length : 0) +
                    (e.securityAssertions ? Object.values(e.securityAssertions).filter((e) => e?.status === "PASS")?.length : 0)}
                  /
                  {e?.functionalAssertions?.length +
                    (e.performanceAssertions ? Object.values(e.performanceAssertions).filter((e) => e?.isSelected)?.length : 0) +
                    (e.securityAssertions ? Object.values(e.securityAssertions).filter((e) => e?.isSelected)?.length : 0)}
                  )
                </span>
              </Typography>

              <IconButton disabled={true}>
                {e.result === "FAIL" ? <CloseRoundedIcon sx={{ color: "#EF2D56" }} /> : <CheckRoundedIcon sx={{ color: "#1AA376" }} />}
              </IconButton>
            </AccordionSummary>

            <MuiAccordionDetails>
              <Box sx={{
                borderLeft: "3px solid #6580e0",
                paddingLeft: "10px",
                display: "flex",
              }}>
                <Grid container>
                  {Boolean(e?.functionalAssertions?.length) &&
                    e.functionalAssertions.map((ass, i) => (
                      <Fragment key={ass._id}>
                        <Grid item sm={1.9}>
                          <Chip
                            sx={{
                              ...statusChip,
                              backgroundColor: ass.status === "PASS" ? "#1AA376" : "#EF2D56",
                              padding: (ass.status === "FAIL" || !ass.status) && "0px 3.5px 0px 3.5px",
                            }}
                            label={ass?.status ? ass?.status?.toUpperCase() : "FAIL"}
                          />
                        </Grid>

                        <Grid item sm={10.1} mt={.7}>
                          <Typography className="statement">
                            {ass.targetField}&nbsp;
                            {ass.rules.map(
                              (rule, i) => `${rule.operation} "${rule.expectedValue}" ${i + 1 !== ass.rules.length ? rule.operator : ""} `
                            )}
                          </Typography>
                          {/* <b>{i !== e?.functionalAssertions?.length - 1 && ass?.operator?.toUpperCase()}</b> */}

                          {ass.status === "FAIL" &&
                            <Typography className="errReason">
                              ||<b> Reason:</b>{" "}{ass.rules.map((rule) => `${rule.reason}\n`)}
                            </Typography>
                          }
                        </Grid>
                      </Fragment>
                    ))}

                  {e.performanceAssertions &&
                    Object.keys(e.performanceAssertions).map(
                      (perf, i) =>
                        e.performanceAssertions[perf]?.isSelected && (
                          <Fragment key={i}>
                            <Grid item sm={1.9} mt={1}>
                              <Chip
                                sx={{
                                  ...statusChip,
                                  backgroundColor: e.performanceAssertions[perf]?.status === "PASS" ? "#1AA376" : "#EF2D56",
                                  padding: e.performanceAssertions[perf]?.status === "FAIL" && "0px 3.5px 0px 3.5px",
                                }}
                                label={e.performanceAssertions[perf]?.status === "PASS" ? "PASS" : "FAIL"}
                              />
                            </Grid>
                            <Grid item sm={10.1} mt={1.6}>
                              <Typography className="statement">
                                {perf === "responseTime" ? "Response Time" : "Response Size"}&nbsp;is&nbsp;
                                {`${e.performanceAssertions[perf].operation === "lessThan" ? "<" : ">"}
                               ${e.performanceAssertions[perf].expectedValue} ${perf === "responseTime" ? "ms" : "KB"}`}
                              </Typography>
                              {e.performanceAssertions[perf].status === "FAIL" &&
                                <Typography className="errReason">
                                  {" "}||<b> Reason:</b>{" "}{e.performanceAssertions[perf].reason}
                                </Typography>
                              }
                            </Grid>
                          </Fragment>
                        )
                    )}

                  {e.securityAssertions &&
                    Object.keys(e.securityAssertions).map(
                      (sec, i) =>
                        e.securityAssertions[sec]?.isSelected && (
                          <Fragment key={i}>
                            <Grid item sm={1.9} mt={1}>
                              <Chip
                                sx={{
                                  ...statusChip,
                                  backgroundColor: sec === "cookies" ? (e.securityAssertions[sec]?.overAllCookiesStatus === "PASS" ? "#1AA376" : "#EF2D56") :
                                    e.securityAssertions[sec]?.status === "PASS" ? "#1AA376" : "#EF2D56",
                                  padding: sec === "cookies" ?
                                    (e.securityAssertions[sec]?.overAllCookiesStatus === "FAIL" && "0px 3.5px 0px 3.5px") :
                                    e.securityAssertions[sec]?.status === "FAIL" && "0px 3.5px 0px 3.5px",
                                }}
                                label={sec === "cookies" ? (e.securityAssertions[sec]?.overAllCookiesStatus === "PASS" ? "PASS" : "FAIL") :
                                  e.securityAssertions[sec]?.status === "PASS" ? "PASS" : "FAIL"}
                              />
                            </Grid>
                            <Grid item sm={10.1} mt={1.6}>
                              <Typography className="statement">
                                {sec === "contentType" ? "Response Header Content Type"
                                  : sec === "statusCode" ? "Status Code" : "Cookie name"}{" "}
                                is&nbsp;
                                {e.securityAssertions[sec].expectedValue
                                  ? `"${e.securityAssertions[sec].expectedValue}"`
                                  : e.securityAssertions[sec].rules.map(
                                    (ele, i) => `"${ele.name}" ${i + 1 !== e.securityAssertions[sec].rules.length ? ", " : ""}`
                                  )}
                              </Typography>
                              {e.securityAssertions[sec].status === "FAIL" && (
                                <Typography className="errReason">
                                  {" "}
                                  ||<b> Reason:</b>
                                  {e.securityAssertions[sec].reason}
                                </Typography>
                              )}
                              {
                                sec === "cookies" &&
                                <Typography className="errReason">
                                  {" "}
                                  ||<b> Reason:</b>
                                  {e.securityAssertions[sec].rules.map(e => <>{e.reason},&nbsp;</>)}
                                </Typography>
                              }
                            </Grid>
                          </Fragment>
                        )
                    )}

                  {!e?.functionalAssertions?.length &&
                    (e.securityAssertions
                      ? Object.values(e?.securityAssertions).every(
                        (el) => !el?.isSelected
                      )
                      : true) &&
                    (e.performanceAssertions
                      ? Object?.values(e?.performanceAssertions)?.every(
                        (el) => !el?.isSelected
                      )
                      : true) && (
                      <Box sx={{ color: "#455a64", padding: "1%" }}>
                        <center>
                          <Typography variant="h6">{resource.NO_TC}</Typography>
                        </center>
                      </Box>
                    )}
                </Grid>
              </Box>
            </MuiAccordionDetails>
          </Accordion>
        ))
      ) : (
        <Box sx={{ padding: "25% 27%", color: "#54577d" }}>
          <center>
            <Typography variant="h5">{resource.NO_TS}</Typography>
          </center>
        </Box>
      )}
    </Grid>
  );
}
