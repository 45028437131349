import { useContext, useState } from "react"
import PropTypes from "prop-types"
import { styled } from "@mui/material/styles"
import {
    Box,
    Chip,
    IconButton,
    Paper,
    TableCell, Tooltip, TableHead, TableBody, Table, TableContainer, TableRow, tableCellClasses, TextField
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"

import ApiService from "services/app.service"
import { resource } from "data/resource"
import VarContext from "contexts/Variables/VarContext"

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#d0c2ff",
        border: "1px solid #d0c2ff",
        color: "#54577d",
        fontWeight: 550,
        padding: "4px",
        fontSize: 13,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12.2,
        padding: "11px",
        color: "#54577d",
        border: 0,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#f6f2fa",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#ede8ff",
    },
    cursor: "pointer"
}));

function CustomTabless({ category, headCells, rows }) {
    const autId = new URLSearchParams(document.location.search).get("aut")

    const varContext = useContext(VarContext)
    const { editVar, delVar, envName,
        getEnv, getAutVars, getGlobalVars, setEditVar, setDelVar } = varContext

    const [varData, setVarData] = useState(null)
    const onChangeHandler = (e) => {
        const { name, value } = e.target
        setVarData({
            ...varData,
            [name]: value,
            error1: name === "name" ? (value === "" || value.trim().length < 3) : varData.error1,
            error2: name === "value" ? value === "" : varData.error2,
            helperText: value === "" ? "Cannot leave empty" : (value.trim().length < 3 ? "Minimum 3 characters required" : "")
        })
    }

    return (
        <TableContainer component={Paper}>
            <Table className="customized_table">
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell) => (
                            <StyledTableCell key={headCell.id} align={"center"}>{headCell.label}</StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row) => (
                        <StyledTableRow key={row?._id}>
                            {editVar === row?._id ?
                                <StyledTableCell align={"center"}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        size="small"
                                        autoComplete='off'
                                        variant="outlined"
                                        value={varData?.name}
                                        placeholder="Variable Name"
                                        inputProps={{ style: { height: "10px" } }}
                                        onChange={onChangeHandler}
                                        error={varData?.error1}
                                        helperText={varData?.error1 && varData?.helperText}
                                    />
                                </StyledTableCell>
                                :
                                <StyledTableCell align={"center"}>{row?.name}</StyledTableCell>
                            }
                            {editVar === row?._id ?
                                <StyledTableCell align={"center"}>
                                    <TextField
                                        fullWidth
                                        name="value"
                                        size="small"
                                        autoComplete='off'
                                        variant="outlined"
                                        value={varData?.value}
                                        placeholder="Variable Value"
                                        inputProps={{ style: { height: "10px" } }}
                                        onChange={onChangeHandler}
                                        error={varData?.error2}
                                        helperText={varData?.error2 && "Cannot leave empty"}
                                    />
                                </StyledTableCell>
                                :
                                <StyledTableCell align={"center"}>{row?.value?.length > 40 ? `${row?.value?.substring(0, 40)}....${row?.value?.substring(row?.value?.length - 5)}` : row?.value}</StyledTableCell>
                            }
                            {category === "APISuite" && <StyledTableCell align={"center"}>{row?.responsePath}</StyledTableCell>}
                            {category === "APISuite" && <StyledTableCell align={"center"}>{row?.endpoint[0]?.name}</StyledTableCell>}
                            {category !== "APISuite" &&
                                <StyledTableCell align="center">
                                    <Box className='autListIcons'>

                                        {(editVar !== row?._id && delVar !== row?._id) &&
                                            <>
                                                <IconButton
                                                    className="accordianActionIcon"
                                                    size="small"
                                                    onClick={() => {
                                                        setEditVar(row?._id);
                                                        delVar && setDelVar(null)
                                                        setVarData({
                                                            error1: false,
                                                            error2: false,
                                                            helperText: "",
                                                            name: row?.name,
                                                            value: row?.value,
                                                        })
                                                    }}>
                                                    <Tooltip title={resource.EDIT} placement="top" arrow>
                                                        <EditOutlinedIcon />
                                                    </Tooltip>
                                                </IconButton>

                                                <IconButton
                                                    className="accordianActionIcon"
                                                    sx={{ mr: .3 }}
                                                    onClick={() => {
                                                        setDelVar(row?._id)
                                                        editVar && setEditVar(null)
                                                    }}>
                                                    <Tooltip title={resource.DELETE} placement="top" arrow>
                                                        <DeleteIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            </>
                                        }

                                        {(editVar === row?._id || delVar === row?._id) && <>
                                            <Chip
                                                size="small"
                                                variant="contained"
                                                sx={{
                                                    py: "12px",
                                                    minWidth: "70px",
                                                    color: "#ff0066",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#ffdeeb",
                                                    border: "0.5px solid #ff0066",

                                                }}
                                                label={`${resource.CANCEL} ✖`}
                                                onClick={() => {
                                                    setDelVar(null)
                                                    setEditVar(null)
                                                    setVarData(null)
                                                }}
                                            />
                                            <Chip
                                                size="small"
                                                variant="contained"
                                                sx={{
                                                    py: "12px",
                                                    minWidth: "70px",
                                                    color: "#1fcc75",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#d4ffe9",
                                                    border: "0.5px solid #1fcc75",

                                                }}
                                                label={`${resource.SAVE} ✔`}
                                                disabled={varData?.name === "" || varData?.value === "" || varData?.name?.length < 3}
                                                onClick={async () => {
                                                    if (editVar)
                                                        await ApiService.updateVar(editVar, varData)
                                                    delVar && await ApiService.deleteVar2(delVar)
                                                    category === "AUT" && getAutVars(autId)
                                                    category === "global" && getGlobalVars()
                                                    category === "environment" && getEnv(autId, envName)
                                                    delVar && setDelVar(null)
                                                    varData && setVarData(null)
                                                    editVar && setEditVar(null)
                                                }}

                                            />
                                        </>}
                                    </Box>
                                </StyledTableCell>}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    )
}
CustomTabless.propTypes = {
    category: PropTypes.string,
    rows: PropTypes.array.isRequired,
    headCells: PropTypes.array.isRequired,
};

export default CustomTabless;