import { Link } from "react-router-dom";
import error404 from "assets/images/error404.png";
import { Box, Button, Grid } from "@mui/material"

function NotFound() {
    document.body.style = "background: #F4F5F6;";
    return (
        <>
            <Box mt={1}>
                <Grid container>
                    <Grid item sm={12} textAlign={"center"}>
                        <img
                            src={error404}
                            style={{ border: "10px solid #F4F5F6", width: "30%", }}
                        />
                    </Grid>

                    <Grid item sm={12} textAlign={"center"}>
                        <h1 >
                            <b>Error 404!! 🤖</b>
                        </h1>
                        <h3                       >
                            Sorry, this page isn&apos;t available.
                            <br />
                            The link you followed may be broken, or the page may have been
                            removed.
                        </h3>
                    </Grid>
                </Grid>
                <center>
                    <Link to="/">
                        <Button
                            style={{
                                color: "white",
                                backgroundColor: "#6580e0",
                                border: "2px solid #6580e0",
                                margin: "10px",
                                cursor: "pointer",
                                marginBottom: "30px",
                                boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
                            }}
                        >
                            Home {">>"}
                        </Button>
                    </Link>
                </center>
            </Box>
        </>
    );
}

export default NotFound;