import { useContext } from "react"
import { PropTypes } from "prop-types"
import {
    Button,
    Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material"
import { resource } from "data/resource"
import UtilsContext from "contexts/Utils/UtilsContext"

export default function DeleteDialog({ deleteHandler, name }) {
    const utilsContext = useContext(UtilsContext);
    const { deletes, visible, setDelete, setEdit, setVisible, delValues, setDelValues, setDeleteAll } = utilsContext;

    const handleClose = () => {
        name !== "Request" && setEdit(null);
        setDelete(null);
        setDelValues([]);
        setDeleteAll(false);
        visible && setVisible(false);
    };

    return (
        <Dialog
            className="dialogCus"
            open={Boolean(deletes) || delValues.length}
            onClose={handleClose}
        >
            <DialogTitle variant="h6" className='unsavedChanges1'>
                {resource.CONFIRM_DEL} <i></i>?
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant="body1" className='unsavedChanges2'>
                    This will delete the <b>{name}{(delValues.length > 1 && name !== "Environment") && "s"}</b> permanently. You cannot undo the action once deleted.
                </DialogContentText>
            </DialogContent>
            <DialogActions className='dialogAct'>
                <Button
                    size="small"
                    variant="contained"
                    onClick={handleClose}
                    sx={{
                        backgroundColor: "#eb5160",
                        "&:hover": {
                            backgroundColor: "#eb5160",
                        },
                    }}
                >{resource.CANCEL}
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    onClick={deleteHandler}
                    sx={{
                        backgroundColor: "#6580e0",
                        "&:hover": {
                            backgroundColor: "#6580e0",
                        },
                    }}
                >
                    {resource.DELETE}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DeleteDialog.propTypes = {
    deleteHandler: PropTypes.func,
    name: PropTypes.string,
};
