import { useContext } from "react"
import { PropTypes } from "prop-types"
import { useNavigate } from "react-router-dom"
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";

import { resource } from "data/resource";
import ApiService from "services/app.service";
import APIsContext from "contexts/APIs/APIsContext";
import UtilsContext from "contexts/Utils/UtilsContext";
import VarContext from "contexts/Variables/VarContext";
import TestCaseContext from "contexts/TestCase/TestCaseContext";
export default function UnsavedChangesDialog({ submitHandler, name }) {
    const navigate = useNavigate();
    const tcId = new URLSearchParams(document.location.search).get("tc");

    const varContext = useContext(VarContext)
    const apisContext = useContext(APIsContext)
    const utilsContext = useContext(UtilsContext)
    const testCaseContext = useContext(TestCaseContext)

    const { tsVars, setNewVar, inputValue, setInputValue } = varContext
    const { setChanges, edit } = utilsContext
    const { add, saved, setAdd, setSaved } = apisContext
    const { editTC, add_t, status, testcase, setStatus, getTestCases, setEditTC, setTestCase } = testCaseContext

    const handleUnsave = async () => {
        try {
            setSaved(true);
            setChanges(false);
            // remove the dynamic variable that was created during creation of env.
            if (inputValue) {
                await ApiService.deleteVar2(inputValue)
                setInputValue(null)
            }
            if (add) {
                setAdd(null)
                const found = tsVars.filter(e => !e.endpointId).map(e => e._id)
                found.length && await ApiService.deleteVar(found)
                setNewVar(true)
            }
            !status && setStatus(true)
            !testcase && setTestCase(null)
            if (editTC) {
                setEditTC(null)
                !add_t && getTestCases(edit, true)
            }
            if (tcId) { navigate(-1); }
        } catch (err) {
            console.log(err.response.data);
        }
    };

    const handleClose = () => {
        setSaved(true);
        setChanges(true);
    };

    return (
        <Dialog
            justifycontent='right'
            open={!saved}
            onClose={handleClose}
        >
            <DialogTitle variant="h6" className='unsavedChanges1'>
                {resource.CONFIRM_SAVE}
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant="body1" className='unsavedChanges2'>
                    The {name === "TestTabx" ? "Test" : (name === "Variables" ? name : <>Request <b>{name.toUpperCase()}</b></>)} has unsaved changes which will be lost if you choose to
                    {name === "Variables" ? " leave the screen. " : " close it. "}
                    Save these changes to avoid losing your work.
                </DialogContentText>
            </DialogContent>

            <DialogActions className='dialogAct'>
                <Button
                    className='unsavedButton2'
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={handleUnsave}
                >{resource.NO_SAVE}
                </Button>
                <Button
                    className='unsavedButton'
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={handleClose}
                >{resource.CANCEL}
                </Button>
                <Button
                    sx={{
                        backgroundColor: "#6580e0",
                        "&:hover": {
                            backgroundColor: "#6580e0",
                        },
                        right: "2.5%"
                    }}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={submitHandler}
                >
                    {resource.SAVE}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

UnsavedChangesDialog.propTypes = {
    name: PropTypes.string,
    submitHandler: PropTypes.func
};
