/* eslint-disable react/prop-types */
import { useContext, Fragment } from "react"
import { styled } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import { Paper, Box, Checkbox, IconButton, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"

import { resource } from "data/resource"
import DeleteDialog from "components/Utils/DeleteDialog"
import UtilsContext from "contexts/Utils/UtilsContext"
import TestCaseContext from "contexts/TestCase/TestCaseContext"

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 13,
        fontWeight: 550,
        padding: "7px",
        color: "#54577d",
        border: "1px solid #d0c2ff",
        backgroundColor: "#d0c2ff",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12.2,
        padding: "8px",
        color: "#54577d",
        border: 0,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#f6f2fa",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#ede8ff",
    },
    cursor: "pointer"
}));

function TestList({ deleteHandler, handleEditSubmit, setStatus }) {
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(document.location.search)
    const autId = searchParams.get("aut")
    const tsId = searchParams.get("testsuite")

    const utilsContext = useContext(UtilsContext);
    const testCaseContext = useContext(TestCaseContext);

    const { deletes, setDelete } = utilsContext
    const { add_t, testcases, setEditTC, setAddt } = testCaseContext

    return (
        <TableContainer component={Paper}>
            <Table >
                <TableHead >
                    <TableRow >
                        <StyledTableCell align="center">{resource.EXE}</StyledTableCell>
                        <StyledTableCell align="center">{resource.AUT.HEAD.NAME}</StyledTableCell>
                        <StyledTableCell align="center">{resource.AUT.HEAD.DESC}</StyledTableCell>
                        <StyledTableCell align="center">{resource.AUT.HEAD.DATE}</StyledTableCell>
                        <StyledTableCell align="center">{resource.AUT.HEAD.ACTION}</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {testcases.map(row => (
                        <Fragment key={row._id ? row._id : row.key}>
                            <StyledTableRow onClick={() => {
                                setEditTC(row._id ? row._id : row.key);
                                if (row._id) { add_t && setAddt(false); }
                                else { setAddt(true); }
                                setStatus(false);
                                navigate(`/addrequest?aut=${autId}&testsuite=${tsId}&tc=${row._id ? row._id : row.key}`);
                            }}>
                                <StyledTableCell align="center">
                                    {row._id && <Checkbox
                                        name="cookies"
                                        checked={row.isSelected}
                                        value={row.isSelected}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleEditSubmit(row._id);
                                        }}
                                    />}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.name}</StyledTableCell>
                                <StyledTableCell align="center" sx={{ width: "46%" }} >
                                    {row.description}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.key ? 0 : Math.round((Number(new Date()) - new Date(row.createdAt)) / (1000 * 60 * 60 * 24))} days ago</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Box className='autListIcons'>
                                        <IconButton
                                            size="small"
                                            className="accordianActionIcon" onClick={(event) => {
                                                event.stopPropagation();
                                                setDelete(row._id ? row._id : row.key);
                                            }}>
                                            <Tooltip title={resource.DELETE} placement={"top"} arrow>
                                                <DeleteIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                            {(deletes === row._id || deletes === row.key) && <DeleteDialog obj={row} deleteHandler={deleteHandler} name={resource.TC.NAME} />}
                        </Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TestList;