import validator from "is-my-json-valid"

export const isJsonValid = (str) => {
  const validate = validator({
    oneOf: [
      {
        type: "object",
        additionalProperties: true
      },
      {
        type: "array",
        items: {
          oneOf: [
            {
              type: "object",
              additionalProperties: true
            },
            {
              type: "array"
            }
          ]
        }
      }
    ]
  })
  try {
    return validate(JSON.parse(str))
  } catch (e) {
    return false
  }
}

export const toggleDrawer = (event, method) => {
  if (
    event.type === "keydown" &&
    (event.key === "Tab" || event.key === "Shift")
  ) { return }
  method(false)
}

export const checkDuration = (date) => {
  const days = Math.round((Number(new Date()) - new Date(date)) / (1000 * 60 * 60 * 24))
  if (days > 30) // months
    return `${Math.round(days / 30)} months ago`
  else if ((days / 30) > 12) // years
    return `${Math.round(days / (30 * 12))} years ago`
  return `${days} days ago`
}

export const jsonPathFinder = (select) => {
  if (select.namespace.length)
    return `${select.namespace.slice(/^\d+$/.test(select.namespace[0]) ? 0 : 1).reduce((tot, curr) => (/^\d+$/.test(tot) ? "" : tot) + (isNaN(curr) ? `.${curr}` : `[${curr}]`), select.namespace[0])
      }${isNaN(select.name) ? `.${select.name}` : `[${select.name}]`}`
  return select.name

}

export const handleDragOver = (event) => {
  event.preventDefault()
}