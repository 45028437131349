export const ExeResultheadCells = [
    {
        field: "createdAt",
        valueGetter: (params) => new Date(params.data.createdAt).toLocaleString(),
        headerName: "Executed On",
        sortable: true,
        suppressColumnsToolPanel: true,
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 100
    },
    {
        field: "testCasesSize",
        flex: 1,
        minWidth: 100,
        headerName: "No of Test Cases",
    },
    {
        field: "result",
        flex: 1,
        minWidth: 100,
        headerName: "Result",
    }
];