import { useEffect, useContext } from "react"
import { Box, Grid, Typography } from "@mui/material"

import ExecutionsList from "./List";
import { resource } from "data/resource";
import APIsContext from "contexts/APIs/APIsContext";
import UtilsContext from "contexts/Utils/UtilsContext";
import TestResultContext from "contexts/TestResult/TestResultContext";

const ExecutionsListTab = () => {
  const apisContext = useContext(APIsContext);
  const utilsContext = useContext(UtilsContext);
  const testResultContext = useContext(TestResultContext);

  const { add } = apisContext;
  const { edit } = utilsContext;
  const { exes, getExes } = testResultContext;

  useEffect(() => {
    getExes(edit);
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box className="tsHeader">
        <Grid container alignItems="center">
          <Grid sm={6} item>
            <Typography>
              {resource.TR.EXE} <b>{exes.length}</b>
            </Typography>
          </Grid>
          <Grid sm={6} textAlign="right" item></Grid>
        </Grid>
      </Box>

      {(add && !edit) ?
        <Typography className="apiKeyNote">Save Request to see Execution Results.</Typography>
        : <ExecutionsList exes={exes} />}
    </Box>
  );
};

export default ExecutionsListTab;
