import { useState } from "react"
import { PropTypes } from "prop-types"
import { Backdrop, Box, Fade, Modal, IconButton, Typography } from "@mui/material"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo"

import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";

import "prismjs/themes/prism.css"
import "prismjs/components/prism-clike"
import "prismjs/components/prism-json.min"
import "prismjs/components/prism-javascript"

const hightlightWithLineNumbers = (input, language) =>
    highlight(input, language)
        .split("\n")
        .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
        .join("\n");

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

export default function ResModal({ response, status, setStatus }) {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        setStatus(null);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <IconButton
                        sx={{
                            position: "absolute",
                            right: "8px",
                            top: "8px",
                        }}
                        color="error"
                        size="small"
                        onClick={handleClose}
                    >
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Typography id="transition-modal-title" variant="h5">
                        API Response
                        {status !== "error" ?
                            <Typography py={1}>
                                <i> Copy Token to the clipboard </i>&nbsp;
                                <ContentPasteGoIcon />
                            </Typography>
                            :
                            <Typography sx={{ color: "red" }} py={1}>
                                ERROR! &nbsp;
                            </Typography>}
                    </Typography>
                    {/* <TextField
                            fullWidth
                            size="small"
                            minRows={10}
                            multiline
                            variant="outlined"
                        /> */}
                    <Editor
                        value={JSON?.stringify(response)}
                        disabled={true}
                        // onValueChange={(e) => setCodeValue(e)}
                        highlight={code => hightlightWithLineNumbers(code, languages.json)}
                        padding={10}
                        textareaId="codeArea"
                        className="editor"
                        style={{
                            fontFamily: "\"Fira code\", \"Fira Mono\", monospace",
                            fontSize: 14,
                            outline: 0,
                            width: "100%",
                            color: status === "error" && "red"
                        }}
                    />
                    {status !== "error" &&
                        < Typography variant="h3" pt={1}>
                            <b> Note: This is One Time Display. Copy it carefully and paste it in the <i>`Apply Token`</i> Box.</b>
                        </Typography>
                    }
                </Box>
            </Fade>
        </Modal>
    );
}

ResModal.propTypes = {
    response: PropTypes.object,
    status: PropTypes.string,
    setStatus: PropTypes.func
}