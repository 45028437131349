import { useContext, useState, useEffect } from "react"
import axios from "axios"
import Editor from "react-simple-code-editor"
import "prismjs/themes/prism.css"
import "prismjs/components/prism-yaml"
import "prismjs/components/prism-json.min"
import "prismjs/components/prism-javascript"

import { highlight, languages } from "prismjs/components/prism-core"
import { Link } from "react-router-dom"
import {
  Box, Button,
  Checkbox, Chip,
  Divider,
  FormControl, FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio, RadioGroup,
  Tooltip, TextField, Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import AddBoxIcon from "@mui/icons-material/AddCircle"

import { isJsonValid } from "hooks"
import { resource } from "data/resource.js"
import FileHandle from "components/Utils/FileUpload"
import TestDataPreview from "components/Utils/TestDataPreview"
import TextFieldWithDropdown from "components/Utils/AssignVarList"

import APIsContext from "contexts/APIs/APIsContext"
import UtilsContext from "contexts/Utils/UtilsContext"

const hightlightWithLineNumbers = (input, language) =>
  highlight(input, language)
    .split("\n")
    .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
    .join("\n")

export default function RequestTab() {

  const apisContext = useContext(APIsContext)
  const utilsContext = useContext(UtilsContext)

  const { requestData, setRequestData } = apisContext
  const { edit, changes, setChanges, showAlert, setTestData, testData } = utilsContext

  const [file, setFile] = useState()
  const [check, setCheck] = useState(false)
  const [preview, setPreview] = useState(false)
  const [selectedOption, setSelectedOption] = useState(testData ? "file" : "raw");

  const onChangeFile = async (e, i) => {
    try {
      if (edit) !changes && setChanges(true)
      const fd = new FormData()
      fd.append("file", e.target.files[0])
      const { data } = await axios.post("/upload/file", fd, {
        headers: {
          "Content-Type": "multipart/form-data boundary=---XYZ",
        },
      })
      setRequestData({
        ...requestData,
        form: {
          ...requestData.form,
          fields: [
            ...requestData.form.fields.slice(0, i),
            {
              ...requestData.form.fields[i],
              value: data.url, originalName: e.target.files[0].name
            },
            ...requestData.form.fields.slice(i + 1),
          ],
        },
      })
    } catch (err) {
      console.log(err.response.data)
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      })
    }
  }

  useEffect(() => {

    if (requestData.rawType === "json") {
      if (isJsonValid(requestData.rawData)) {
        setRequestData({
          ...requestData,
          rawData: JSON.stringify(JSON.parse(requestData.rawData), null, 3)
        })
      }
      else { setCheck(true) }
    }

  }, [])

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // const closeHandler = () => {
  //   setSelectedOption("raw");
  // };

  return (
    <Box>
      <FormControl className="formControl">
        <RadioGroup
          row
          value={requestData.bodyType}
          onChange={(e) => {
            if (edit) { !changes && setChanges(true) }
            setRequestData({
              ...requestData,
              bodyType: e.target.value,
              form: {
                ...requestData.form,
                encrypt: e.target.value === "form" ? "multipart/form-data" : "application/x-www-form-urlencoded",
                fields: !requestData.form.fields ? [] : requestData.form.fields
              },
            })
          }}
        >
          <FormControlLabel
            value="none"
            control={<Radio className="radioSel1" />}
            label="none"
          />
          <FormControlLabel
            value="form"
            control={<Radio className="radioSel2" />}
            label="form-data"
          />
          <FormControlLabel
            value="formUrl"
            control={<Radio className="radioSel2" />}
            label="x-www-form-urlencoded"
          />
          <FormControlLabel
            value="raw"
            control={<Radio className="radioSel2" />}
            label="raw"
          />
        </RadioGroup>
      </FormControl>
      <Divider />
      {requestData.bodyType === "none" && <Box className="emptyParams">{resource.NO_BODY}</Box>}
      {requestData.bodyType === "raw" && (
        <>
          <Grid container my={1.5}>
            <Grid sm={4} item>
              <TextField
                select
                required
                fullWidth
                size="small"
                name="rawType"
                autoComplete='off'
                label="Select an option to provide request data"
                value={selectedOption || ""}
                onChange={handleOptionChange}
              >
                <MenuItem value="file">{resource.FILE1}</MenuItem>
                <MenuItem value="raw">{resource.RAW1}</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          {selectedOption === "file" ? (
            <Grid container justifyContent={testData ? "left" : "center"}>
              {testData ?
                <>
                  <Grid item sm={2.4} style={{ paddingRight: "8px" }}>
                    <Chip label={testData}
                      sx={{ width: "170px" }}
                      onDelete={() => {
                        setTestData(false)
                        setFile()
                      }}
                    />
                  </Grid>
                  <Grid item sm={1.6} >
                    <Button variant="contained" size="small" fullWidth
                      sx={{ backgroundColor: "#1fcc75", "&:hover": { backgroundColor: "#1fcc75" } }}
                      onClick={() => setPreview(true)}
                    >
                      {resource.PREV}
                    </Button>
                  </Grid>
                </>
                :
                <Grid sm={8} item textAlign={"right"}>
                  <FileHandle file={file} setFile={setFile} testDataStatus={true} />
                  <Typography variant="body1" sx={{ textAlign: "left" }}>{resource.FORMAT_SUPPORT}</Typography>
                  <Box style={{
                    color: "#54577d",
                    borderRadius: "5px",
                    border: "1px solid #ede8ff",
                    backgroundColor: "#ede8ff",
                    minHeight: "50px",
                  }} sx={{ mt: 1 }}>
                    <Typography variant="h6" sx={{ textAlign: "left", p: "10px 0px 5px 10px" }}>
                      {resource.TD_TEMPLATE}
                    </Typography>
                    <Grid container>

                      <Grid item xs={9} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant="body1" sx={{ textAlign: "left", p: "0px 0px 10px 10px", color: "#8c6e91" }}>
                          {resource.TEST_DATA_NOTE}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ display: "flex", alignItems: "right", justifyContent: "right" }}>
                        <Link to="/sample_test_data.json" target="_blank" download>
                          <Button variant="contained" size="small"
                            sx={{ backgroundColor: "#6580e0", "&:hover": { backgroundColor: "#6580e0" }, mr: 3.5, bottom: 6 }}
                          >
                            {resource.DOWN}
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>}
            </Grid>
          ) :
            <>
              <Box textAlign="right" my={1.5}>
                <TextField
                  select
                  size="small"
                  name="rawType"
                  autoComplete='off'
                  className="rawType"
                  value={requestData.rawType}
                  onChange={(e) => {
                    if (edit) { !changes && setChanges(true) }

                    if (e.target.value === "json") { !isJsonValid(requestData.rawData) && setCheck(true) }

                    setRequestData({
                      ...requestData,
                      rawType: e.target.value,
                    })
                  }}
                >
                  <MenuItem key="text" value="text">
                    &nbsp; Text
                  </MenuItem>
                  <MenuItem key="JSON" value="json">
                    JSON
                  </MenuItem>
                  <MenuItem key="XML" value="xml">
                    &nbsp; XML
                  </MenuItem>
                </TextField>
              </Box>
              {requestData.rawType === "json" ?
                <Box sx={{
                  height: check ? "calc(100vh - 487px)" : "calc(100vh - 415px)",
                  overflowY: "scroll",
                }}>
                  <Editor
                    padding={10}
                    className="editor"
                    textareaId="codeArea"
                    style={{
                      fontFamily: "\"Fira code\", \"Fira Mono\", monospace",
                      fontSize: 14,
                      width: "100%",
                    }}
                    highlight={code => hightlightWithLineNumbers(code, languages.json)}
                    value={requestData.rawData}
                    onValueChange={(e) => {
                      if (edit) { !changes && setChanges(true) }
                      e !== "" ? (isJsonValid(e) ? setCheck(false) : setCheck(true)) : setCheck(false)
                      setRequestData({ ...requestData, rawData: e })
                    }}
                  />
                </Box>
                :
                (requestData.rawType === "xml" ?
                  <Box sx={{
                    height: "calc(100vh - 415px)",
                    overflowY: "scroll",
                  }}>
                    <Editor
                      padding={10}
                      className="editor"
                      textareaId="codeArea"
                      style={{
                        fontFamily: "\"Fira code\", \"Fira Mono\", monospace",
                        fontSize: 14,
                        outline: 0,
                        width: "100%"
                      }}
                      highlight={code => hightlightWithLineNumbers(
                        code,
                        (requestData.rawData.replaceAll("\n", "").replaceAll(" ", "").startsWith("{") ? languages.yaml : languages.js)
                      )}
                      value={requestData.rawData}
                      onValueChange={(e) => {
                        if (edit) { !changes && setChanges(true) }
                        setRequestData({ ...requestData, rawData: e })
                      }}
                    />
                  </Box>
                  :
                  <TextField
                    fullWidth
                    multiline
                    size="small"
                    minRows={10}
                    maxRows={11}
                    height="200px"
                    autoComplete='off'
                    variant="outlined"
                    defaultValue={requestData.rawData}
                    onChange={(e) => {
                      if (edit) { !changes && setChanges(true) }
                      setRequestData({ ...requestData, rawData: e.target.value })
                    }}
                  />
                )
              }
              {check && requestData.rawType === "json" && <Typography className="apiKeyNote3"
                sx={{
                  animation: "arrow 0.3s linear 2",
                  "@keyframes arrow": {
                    "0%": { transform: "rotate(0.5deg)" },
                    "30%": { transform: "rotate(-0.5deg)" },
                    "50%": { transform: "rotate(0deg)" },
                    "70%": { transform: "rotate(0.5deg)" },
                    "100%": { transform: "rotate(-0.5deg)" }
                  }
                }}>
                Invalid JSON format!
              </Typography>}
            </>
          }
        </>
      )
      }

      {
        requestData.bodyType === "form" && (
          <Grid container>
            <Grid sm={12} item mt={1} textAlign="right">
              <Tooltip title="Add body">
                <IconButton
                  onClick={() => {
                    setRequestData({
                      ...requestData,
                      form: {
                        ...requestData.form,
                        fields: [
                          ...requestData.form.fields,
                          {
                            name: "",
                            value: "",
                            type: "text",
                            originalName: "",
                            isSelected: true,
                            key: Date.now().toString(),
                            description: "",
                          },
                        ],
                      },
                    })
                  }}
                  size="medium"
                >
                  <AddBoxIcon sx={{ color: "#6580e0" }} />
                </IconButton>
              </Tooltip>
            </Grid>

            {requestData.form.fields?.length ? (
              requestData.form.fields.map((el, i) => (
                <Grid container mt="2px" key={i} alignItems="center" spacing={1}>
                  <Grid item sm={0.5}>
                    <Checkbox
                      className="checkbox"
                      name="isSelected"
                      checked={el.isSelected}
                      value={el.isSelected}
                      onChange={() => {
                        if (edit) { !changes && setChanges(true) }
                        setRequestData({
                          ...requestData,
                          form: {
                            ...requestData.form,
                            fields: [
                              ...requestData.form.fields.slice(0, i),
                              {
                                ...requestData.form.fields[i],
                                isSelected: !el.isSelected,
                              },
                              ...requestData.form.fields.slice(i + 1),
                            ],
                          },
                        })
                      }}
                    />
                  </Grid>

                  <Grid item md={2.5}>
                    <TextField
                      fullWidth
                      size="small"
                      autoComplete='off'
                      variant="outlined"
                      name={`formKey${i}`}
                      label={resource.KEY}
                      defaultValue={el.name}
                      disabled={!el.isSelected}
                      onChange={e => {
                        if (edit) { !changes && setChanges(true) }
                        setRequestData({
                          ...requestData,
                          form: {
                            ...requestData.form,
                            fields: [
                              ...requestData.form.fields.slice(0, i),
                              {
                                ...requestData.form.fields[i],
                                name: e.target.value,
                              },
                              ...requestData.form.fields.slice(i + 1),
                            ],
                          },
                        })
                      }}
                    />
                  </Grid>

                  <Grid item md={2.5}>
                    {requestData.form.fields[i].type === "text" ? (
                      <TextFieldWithDropdown
                        index={i}
                        name={"form"}
                        defaults={el.value}
                        disabled={el.isSelected}
                      />
                    ) :
                      <>
                        {requestData.form.fields[i]?.value ? <Chip label={requestData.form.fields[i]?.originalName}
                          sx={{ width: "210px" }} onDelete={() => {
                            setRequestData({
                              ...requestData,
                              form: {
                                ...requestData.form,
                                fields: [
                                  ...requestData.form.fields.slice(0, i),
                                  {
                                    ...requestData.form.fields[i],
                                    value: null
                                  },
                                  ...requestData.form.fields.slice(i + 1),
                                ],
                              },
                            })
                          }} /> :
                          <TextField
                            size="small"
                            name="file"
                            type="file"
                            onChange={e => onChangeFile(e, i)}
                          />}
                      </>
                    }
                  </Grid>

                  <Grid item md={2}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label="Type"
                      name="type"
                      autoComplete='off'
                      value={el.type || "text"}
                      disabled={!el.isSelected}
                      onChange={(e) => {
                        if (edit) { !changes && setChanges(true) }
                        setRequestData({
                          ...requestData,
                          form: {
                            ...requestData.form,
                            fields: [
                              ...requestData.form.fields.slice(0, i),
                              {
                                ...requestData.form.fields[i],
                                type: e.target.value,
                              },
                              ...requestData.form.fields.slice(i + 1),
                            ],
                          },
                        })
                      }}
                    >
                      <MenuItem key="text" value="text">
                        Text
                      </MenuItem>
                      <MenuItem key="file" value="file">
                        File
                      </MenuItem>
                    </TextField>
                  </Grid>

                  <Grid md={4} item>
                    <TextField
                      fullWidth
                      size="small"
                      name="description"
                      autoComplete='off'
                      variant="outlined"
                      label={resource.AUT.HEAD.DESC}
                      minRows={1}
                      maxRows={3}
                      multiline
                      disabled={!el.isSelected}
                      defaultValue={el.description}
                      onChange={(e) => {
                        if (edit) { !changes && setChanges(true) }
                        setRequestData({
                          ...requestData,
                          form: {
                            ...requestData.form,
                            fields: [
                              ...requestData.form.fields.slice(0, i),
                              {
                                ...requestData.form.fields[i],
                                description: e.target.value,
                              },
                              ...requestData.form.fields.slice(i + 1),
                            ],
                          },
                        })
                      }}
                    />
                  </Grid>

                  <Grid item md={0.5} textAlign="center">
                    <Tooltip title={resource.DELETE}>
                      <DeleteIcon
                        className="delIcon"
                        sx={{ color: "#cf2b2b" }}
                        onClick={() => {
                          const index = requestData.form.fields.findIndex(ele => ele.key === el.key)
                          requestData.form.fields.splice(index, 1)
                          setRequestData({
                            ...requestData,
                            form: {
                              ...requestData.form,
                              fields: requestData.form.fields,
                            },
                          })
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Box className="emptyForm">
                <Typography variant="body1">{resource.NO_FORMDATA}</Typography>
              </Box>
            )}
          </Grid>
        )
      }

      {
        requestData.bodyType === "formUrl" && (
          <Grid item container>
            <Grid sm={12} item mt={1} textAlign="right">
              <Tooltip title="Add body">
                <IconButton
                  onClick={() => {
                    setRequestData({
                      ...requestData,
                      form: {
                        ...requestData.form,
                        fields: [
                          ...requestData.form.fields,
                          {
                            name: "",
                            key: Date.now().toString(),
                            value: "",
                            description: "",
                            type: "text",
                            isSelected: true,
                          },
                        ],
                      },
                    })
                  }}
                  size="medium"
                >
                  <AddBoxIcon sx={{ color: "#6580e0" }} />
                </IconButton>
              </Tooltip>
            </Grid>

            {requestData.form.fields?.length ? (
              requestData.form.fields.map((el, i) => (
                <Grid container alignItems="center" mt="2px" spacing={1} key={i}>
                  <Grid item sm={0.5}>
                    <Checkbox
                      className="checkbox"
                      name="isSelected"
                      checked={el.isSelected}
                      value={el.isSelected}
                      onChange={() => {
                        if (edit) { !changes && setChanges(true) }
                        setRequestData({
                          ...requestData,
                          form: {
                            ...requestData.form,
                            fields: [
                              ...requestData.form.fields.slice(0, i),
                              {
                                ...requestData.form.fields[i],
                                isSelected: !el.isSelected,
                              },
                              ...requestData.form.fields.slice(i + 1),
                            ],
                          },
                        })
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      fullWidth
                      disabled={!el.isSelected}
                      size="small"
                      name={`formKey${i}`}
                      label={resource.KEY}
                      autoComplete='off'
                      variant="outlined"
                      defaultValue={el.name}
                      onChange={e => {
                        if (edit) { !changes && setChanges(true) }
                        setRequestData({
                          ...requestData,
                          form: {
                            ...requestData.form,
                            fields: [
                              ...requestData.form.fields.slice(0, i),
                              {
                                ...requestData.form.fields[i],
                                name: e.target.value,
                              },
                              ...requestData.form.fields.slice(i + 1),
                            ],
                          },
                        })
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextFieldWithDropdown
                      index={i}
                      name={"form"}
                      defaults={el.value}
                      disabled={el.isSelected}
                    />
                  </Grid>

                  <Grid sm={5} item>
                    <TextField
                      fullWidth
                      size="small"
                      name="description"
                      autoComplete='off'
                      variant="outlined"
                      minRows={1}
                      maxRows={3}
                      multiline
                      label={resource.AUT.HEAD.DESC}
                      disabled={!el.isSelected}
                      defaultValue={el.description}
                      onChange={(e) => {
                        if (edit) { !changes && setChanges(true) }
                        setRequestData({
                          ...requestData,
                          form: {
                            ...requestData.form,
                            fields: [
                              ...requestData.form.fields.slice(0, i),
                              {
                                ...requestData.form.fields[i],
                                description: e.target.value,
                              },
                              ...requestData.form.fields.slice(i + 1),
                            ],
                          },
                        })
                      }}
                    />
                  </Grid>

                  <Grid item sm={0.5} textAlign="center">
                    <Tooltip title={resource.DELETE}>
                      <DeleteIcon
                        className="delIcon"
                        color="error"
                        onClick={() => {
                          if (edit) !changes && setChanges(true)
                          const index = requestData.form.fields.findIndex(
                            (ele) => ele.key === el.key
                          )
                          requestData.form.fields.splice(index, 1)
                          setRequestData({
                            ...requestData,
                            form: {
                              ...requestData.form,
                              fields: requestData.form.fields,
                            },
                          })
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Box className="emptyForm">
                <Typography variant="body1">{resource.NO_FORMURLENCODED}</Typography>
              </Box>
            )}
          </Grid>
        )
      }

      {preview && <TestDataPreview preview={preview} setPreview={setPreview} />}
    </Box >
  )
}
