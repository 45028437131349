import { createContext, useLayoutEffect, useState, useMemo } from "react"
import { theme } from "assets/Styles"
import { CssBaseline } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { ThemeProvider } from "@mui/material/styles"

import "./i18n"
import "assets/App.css"
import RoutesManager from "RoutesManager"
import authService from "services/auth.service"
import SessionTimeout from "services/SessionTimeout"
import { userLocalSession } from "./_helpers/Constant"
import { interceptor } from "services/axios-interceptor"
import ErrorBoundary from "components/Utils/ErrorBoundary"
import { UserDetailsContext } from "./services/UserDetailsContext"

export const SideBarContext = createContext({})
export const SnackbarContext = createContext({})

function App() {
  const navigate = useNavigate()
  const userData = JSON.parse(localStorage.getItem(userLocalSession))
  const token = userData?.Token || ""

  const [snack, setSnack] = useState({
    message: "",
    color: "",
    open: false,
  })

  const [drawerWidth, setDrawerWidth] = useState(200)
  const [userDetails, setUserDetails] = useState(userData || {})

  const contextValue = useMemo(() => ({ setDrawerWidth, drawerWidth }), [setDrawerWidth, drawerWidth])

  const checkAccess = (eName, action) => {
    const user = userData?.RolePermissions?.find((e) => e?.EntityName === eName)
    return user?.[action] ?? false
  }

  useLayoutEffect(() => {
    setUserDetails(userData)
  }, [token])

  interceptor(userData, setUserDetails, navigate, setSnack)

  const handleLogout = async () => {
    try {
      const response = await authService.logout(userDetails)
      console.log("removing youuuu")
      if (response?.ApiStatusCode === 200) {
        localStorage.removeItem(userLocalSession)
        navigate("/login", { replace: true })
      }
    } catch (error) {
      console.log("Error during logout:", error)
    }
  }

  const handleSessionTimeout = () => {
    try {
      if (userData?.SessionId) {
        setSnack({
          message: "Your session has expired. Please log in again.",
          open: true,
          color: "error",
        })
        handleLogout()
      }
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        color: "error",
      })
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        {token && <SessionTimeout timeout={1000 * 60 * 60} onTimeout={handleSessionTimeout} />}
        <UserDetailsContext.Provider value={{ userDetails, setUserDetails, checkAccess }}>
          <SideBarContext.Provider value={{ setDrawerWidth, drawerWidth }}>
            <SnackbarContext.Provider value={contextValue}>
              <RoutesManager snack={snack} setSnack={setSnack} />
            </SnackbarContext.Provider>
          </SideBarContext.Provider>
        </UserDetailsContext.Provider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
