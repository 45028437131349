import { SET_REQS, SET_CURRENT, SET_REQ, SET_SAVED, NEW_REQ, SET_REQUEST_DATA, SET_RES, RESPONSE, SET_PROPS, ADD_NEW } from "../types.js";

const APIsReducer = (state, action) => {
    switch (action.type) {
        case ADD_NEW:
            return {
                ...state,
                add: action.payload,
            };
        case SET_REQ:
            return {
                ...state,
                req: action.payload,
            };
        case SET_RES:
            return {
                ...state,
                res: action.payload,
            };
        case SET_REQS:
            return {
                ...state,
                reqs: action.payload,
            };
        case SET_PROPS:
            return {
                ...state,
                propsArray: action.payload,
            };
        case SET_REQUEST_DATA:
            return {
                ...state,
                requestData: action.payload,
            };
        case SET_CURRENT:
            return {
                ...state,
                curr: action.payload,
            };
        case SET_SAVED:
            return {
                ...state,
                saved: action.payload,
            };
        case NEW_REQ:
            return {
                ...state,
                newReq: state.newReq + 1
            };
        case RESPONSE:
            return {
                ...state,
                response: action.payload
            };
        default:
            return state;
    }
};

export default APIsReducer;