/* eslint-disable no-mixed-operators */
import { useContext, useEffect, useState, useRef } from "react"
import { PropTypes } from "prop-types"
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import { styled } from "@mui/material/styles"
import {
  Box, Button,
  Chip, CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  Tooltip, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses, Typography,
  ListItem,
} from "@mui/material"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"

import { resource } from "data/resource"
import ApiService from "services/app.service"
import AutContext from "contexts/Aut/AutContext"
import UtilsContext from "contexts/Utils/UtilsContext"
import ScheduleContext from "contexts/Schedule/ScheduleContext"
import ExecutionContext from "contexts/Execution/ExecutionContext"
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
    fontWeight: 600,
    color: "#54577d",
    backgroundColor: "#d0c2ff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12.2,
    padding: "10px",
    color: "#54577d",
  },
}))

const StyledTableRow = styled(TableRow)(() => ({
  cursor: "pointer"
}))

export default function SchedulerForm({ retVal }) {
  const { register, handleSubmit, formState: { errors }, clearErrors, setValue } = useForm()

  const autContext = useContext(AutContext)
  const utilsContext = useContext(UtilsContext)
  const scheduleContext = useContext(ScheduleContext)
  const testSuiteContext = useContext(TestSuiteContext)
  const executionContext = useContext(ExecutionContext)

  const { auts, getAuts } = autContext
  const { allConfigs, getAllConfigs, setAllConfigs } = executionContext
  const { getTestSuites, testSuites, setTestSuites } = testSuiteContext
  const { getSchedules, schedule, getSchedule, setSchedule } = scheduleContext
  const { exeLoading, setExeLoading, removeExeLoading, showAlert, edit, setEdit } = utilsContext

  const pageRender = useRef(false)
  const [scheduleData, setscheduleData] = useState({
    autId: "",
    name: "",
    description: "",
    testSuites: [],
    isRepeated: false,
    scheduleType: "single",
    startTime: new Date(new Date() - (new Date().getTimezoneOffset() * 60 * 1000) + (5 * 60 * 1000)).toISOString().slice(0, 16),
    endTime: new Date(new Date() - (new Date().getTimezoneOffset() * 60 * 1000) + (6 * 60 * 1000)).toISOString().slice(0, 16),
    repeatInterval: 1,
    repeatFrequencyUnit: "MINUTE"
  })

  useEffect(() => {
    if (!auts.length) { getAuts() }

    if (!schedule && edit) { getSchedule(edit) }

    else if (schedule && !pageRender.current) {
      getTestSuites(schedule?.requestBody?.autId)
      getAllConfigs(schedule?.requestBody?.autId)
      pageRender.current = true
    }

    else if (edit) {
      setscheduleData({
        id: schedule?.id,
        name: schedule?.name,
        createdAt: schedule?.createdAt,
        createdBy: schedule?.createdBy,
        description: schedule?.description,
        isRepeated: schedule?.isRepeated,
        repeatInterval: schedule?.repeatInterval ?? 1,
        repeatFrequencyUnit: schedule?.repeatFrequencyUnit ?? "MINUTE",
        autId: schedule?.requestBody?.autId,
        testSuites: schedule?.requestBody?.testSuites,
        startTime: new Date(schedule?.startDateAndTime * 1000 - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, 16),
        endTime: schedule?.endDateAndTime ? new Date(schedule?.endDateAndTime * 1000 - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, 16) :
          new Date(schedule?.startDateAndTime * 1000 - (new Date().getTimezoneOffset() * 60 * 1000) + (1 * 60 * 1000)).toISOString().slice(0, 16),
      })
      setValue("autId", schedule?.requestBody?.autId)
      setValue("endTime", schedule?.endDateAndTime ? new Date(schedule?.endDateAndTime * 1000 - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, 16) :
        new Date(schedule?.startDateAndTime * 1000 - (new Date().getTimezoneOffset() * 60 * 1000) + (1 * 60 * 1000)).toISOString().slice(0, 16))
      setValue("startTime", new Date(schedule?.startDateAndTime * 1000 - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, 16))
    }

  }, [pageRender.current, schedule])

  const onChangeHandler = (e) => {
    setscheduleData({
      ...scheduleData,
      [e.target.name]: e.target.value,
      testSuites: e.target.name === "autId" ? [] : scheduleData.testSuites
    })
  }

  const deleteTS = (key) => {
    const index = scheduleData.testSuites.findIndex(ele => ele.key === key)
    scheduleData.testSuites.splice(index, 1)
    setscheduleData({
      ...scheduleData,
      testSuites: scheduleData.testSuites,
    })
  }

  const submitHandler = async () => {
    try {
      setExeLoading()
      scheduleData.name = scheduleData.name.trim()
      scheduleData.description = scheduleData.description.trim()
      scheduleData.startTime = new Date(scheduleData.startTime).toISOString()
      scheduleData.endTime = new Date(scheduleData.endTime).toISOString()
      if (scheduleData.testSuites.some(e => !e.configurationIds.length)) {
        showAlert({
          type: "error",
          msg: "Please select configs",
        })
        removeExeLoading()
        return
      }
      const { data } = await ApiService.createSchedule(scheduleData)
      showAlert({
        type: data.status,
        msg: data.message,
      })
      setTimeout(() => {
        getSchedules()
        retVal(false)
        setTestSuites([])
        setAllConfigs([])
      }, resource.TIMEOUT.SUCCESS)
      removeExeLoading()
    } catch (err) {
      removeExeLoading()
      console.log(err.response.data)
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      })
    }
  }

  const editSubmitHandler = async () => {
    try {
      setExeLoading()
      if (scheduleData.testSuites.some(e => !e.configurationIds.length)) {
        showAlert({
          type: "error",
          msg: "Please select configs",
        })
        removeExeLoading()
        return
      }
      const { data } = await ApiService.updateSchedule({
        ...scheduleData,
        name: scheduleData.name.trim(),
        description: scheduleData.description.trim(),
        startTime: new Date(scheduleData.startTime).toISOString(),
        endTime: new Date(scheduleData.endTime).toISOString(),
      })
      showAlert({
        type: data.status,
        msg: "Schedule updated successfully",
      })
      setTimeout(() => {
        getSchedules()
        setEdit(null)
        setSchedule(null)
        retVal(false)
        setTestSuites([])
        setAllConfigs([])
      }, resource.TIMEOUT.SUCCESS)
      removeExeLoading()
    } catch (err) {
      removeExeLoading()
      console.log(err.response.data)
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      })
    }
  }

  return (
    <Box component="form" noValidate autoComplete="off"
      onSubmit={handleSubmit(edit ? editSubmitHandler : submitHandler)}>
      <Box>
        <Typography variant="h6" sx={{ color: "#54577d" }}> {edit ? resource.EDIT : resource.CREATE} {resource.TEST}</Typography>
      </Box>

      <Grid item container spacing={2}>
        <Grid sm={12} item>
          <TextField
            required
            autoFocus
            fullWidth
            size="small"
            name="name"
            autoComplete='off'
            variant="outlined"
            label={resource.AUT.HEAD.NAME}
            value={scheduleData?.name}
            {...register("name", {
              onChange: e => onChangeHandler(e),
              required: "Field cannot be empty.",
              minLength: {
                value: 4,
                message: "Minimum length is 4 characters."
              },
              maxLength: {
                value: 30,
                message: "Minimum length is 30 characters."
              },
            })}
            error={Boolean(errors?.name)}
            helperText={errors?.name?.message}
          />
        </Grid>
        <Grid sm={12} item>
          <TextField
            fullWidth
            multiline
            size="small"
            maxRows={4}
            minRows={2}
            autoComplete='off'
            variant="outlined"
            name="description"
            label="Description"
            value={scheduleData?.description}
            {...register("description", {
              onChange: e => onChangeHandler(e),
              maxLength: {
                value: 150,
                message: "Maximum length is 150 characters."
              }
            })}
            error={Boolean(errors?.description)}
            helperText={errors?.description?.message}
          />
        </Grid>
        <Grid sm={12} item mb={2}>
          <TextField
            select
            required
            fullWidth
            name="autId"
            size="small"
            autoComplete='off'
            label="Select AUT"
            value={scheduleData?.autId}
            {...register("autId", {
              onChange: e => {
                onChangeHandler(e)
                getTestSuites(e.target.value)
                getAllConfigs(e.target.value)
              },
              required: "Select AUT."
            })}
            error={Boolean(errors?.autId)}
            helperText={errors?.autId?.message}
          >
            {auts?.length ? auts.map(e => <MenuItem key={e._id} value={e._id}>{e.name}</MenuItem>) : <ListItem disabled sx={{ backgroundColor: "#ccc", fontSize: "0.67rem", color: "black" }}>Nothing to Display</ListItem>}
          </TextField>
        </Grid>
      </Grid>

      <Grid sm={12} item mb={2}>
        <TableContainer component={Paper} >
          <Table >
            <TableHead >
              <TableRow >
                <StyledTableCell align="center">{resource.TS.NAME}</StyledTableCell>
                <StyledTableCell align="center">{resource.EXES.CON}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {
                Boolean(scheduleData?.testSuites?.length) && scheduleData?.testSuites.map((em, i) => (
                  <StyledTableRow key={em.key}>
                    <StyledTableCell align="center" sx={{ width: "200px" }}>
                      <TextField
                        select
                        autoFocus
                        required
                        fullWidth
                        size="small"
                        name={`ts${[i]}`}
                        autoComplete='off'
                        label="Select API Suite"
                        value={scheduleData?.testSuites[i]?.id || ""}
                        {...register(`ts${[i]}`, {
                          onChange: (e) => {
                            setscheduleData({
                              ...scheduleData,
                              testSuites: [...scheduleData.testSuites.slice(0, i),
                              { ...scheduleData.testSuites[i], id: e.target.value, configurationIds: [] },
                              ...scheduleData.testSuites.slice(i + 1)]
                            })
                          },
                          required: "Select API Suite"
                        })}
                        helperText={errors?.[`ts${[i]}`]?.message}
                        error={Boolean(errors?.[`ts${[i]}`])}
                      >
                        {testSuites?.testSuites?.length ? testSuites?.testSuites?.map(e => <MenuItem key={e._id} value={e._id}
                          disabled={scheduleData?.testSuites.find(el => el.id === e._id) || !e.noEndpoints}
                        >{e.name}</MenuItem>) : <ListItem disabled sx={{ backgroundColor: "#ccc", fontSize: "0.67rem", color: "black" }}>Nothing to Display</ListItem>}
                      </TextField>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: "380px" }}>
                      <Grid container>
                        <Grid item sm={11}>

                          <TextField
                            select
                            required
                            fullWidth
                            size="small"
                            autoComplete='off'
                            name={`confg${[i]}`}
                            label="Select Configurations"
                            {...register(`confg${[i]}`, {
                              onChange: (e) => {
                                setscheduleData({
                                  ...scheduleData,
                                  testSuites: [...scheduleData.testSuites.slice(0, i),
                                  {
                                    ...scheduleData.testSuites[i],
                                    configurationIds: [...scheduleData.testSuites[i].configurationIds, e.target.value]
                                  },
                                  ...scheduleData.testSuites.slice(i + 1)]
                                })
                              },
                              validate: () => {
                                if (!em.configurationIds.length) { return "Select Configurations" }
                              },
                              // required: "Select Configurations"
                            })}
                            helperText={errors?.[`confg${[i]}`]?.message}
                            error={Boolean(errors?.[`confg${[i]}`])}
                          >
                            {allConfigs.length ? allConfigs.filter(e => e.testSuiteId === em?.id).map(e => <MenuItem key={e._id} value={e._id} disabled={em?.configurationIds.includes(e._id)} >{e.name}</MenuItem>)
                              : <ListItem disabled sx={{ backgroundColor: "#ccc", fontSize: "0.67rem", color: "black" }}>Nothing to Display</ListItem>}
                          </TextField>

                          {em.configurationIds?.map((item, index) =>
                            <Chip
                              key={index}
                              size="small"
                              label={allConfigs?.find(e => e._id === item)?.name}
                              sx={{ ml: 0.5, color: "#707070", backgroundColor: "#d0c2ff", mt: 0.5 }}
                              onDelete={() => {
                                const arr = [...em.configurationIds]
                                arr.splice(index, 1)
                                setscheduleData({
                                  ...scheduleData,
                                  testSuites: [...scheduleData.testSuites.slice(0, i),
                                  {
                                    ...scheduleData.testSuites[i],
                                    configurationIds: arr
                                  },
                                  ...scheduleData.testSuites.slice(i + 1)]
                                })
                              }}
                            />
                          )}

                        </Grid>
                        <Grid item xs={1} mt={-1}>
                          <IconButton size="large" sx={{
                            right: 26.5,
                            position: "absolute",
                            "& svg": { fontSize: "15px" },
                          }}>
                            <Tooltip title={resource.DELETE} placement={"top"} arrow>
                              <CancelOutlinedIcon onClick={() => deleteTS(em.key)} color="error" />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              }
              <TableRow>
                <StyledTableCell align="right" onClick={() => setscheduleData({ ...scheduleData, testSuites: [...scheduleData.testSuites, { key: Date.now(), id: "", configurationIds: [] }] })}>
                  <Link>+ {resource.ADD} {resource.TS.NAME}</Link>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid container>
        <Grid item sm={6} mb={1}>
          <TextField
            fullWidth
            required
            size="small"
            name="startTime"
            type="datetime-local"
            value={scheduleData?.startTime}
            label={`${scheduleData?.isRepeated ? "Start" : "Schedule"} Date & Time`}
            disabled={Boolean(scheduleData?.isRepeated && edit)}
            {...register("startTime", {
              onChange: e => {
                setscheduleData({ ...scheduleData, startTime: e.target.value })
                if (Number(new Date(e.target.value)) > Number(new Date())) clearErrors("startTime")
              },
              required: "Date and time is required",
              validate: (value) => {
                if ((Number(new Date(value)) < Number(new Date()))) {
                  if (edit && scheduleData?.isRepeated) return
                  return "Date and Time cannot be backdated."
                }
              }
            })}
            error={Boolean(errors?.startTime)}
            helperText={errors?.startTime?.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item sm={5} ml={2}>
          Repeat
          <Switch
            className="switchStyle"
            onChange={(e) => setscheduleData({ ...scheduleData, isRepeated: e.target.checked })}
            checked={scheduleData?.isRepeated}
          />
        </Grid>
        {scheduleData?.isRepeated === true && (
          <>
            <Grid item sm={1} mb={2} pt={1}>
              Every
            </Grid>
            <Grid item sm={1.8} mb={2} >
              <TextField
                size="small"
                type="number"
                min={1}
                name="repeatInterval"
                value={scheduleData?.repeatInterval || 1}
                variant="outlined"
                inputProps={{
                  style: {
                    min: 1,
                    max: 2000,
                  },
                }}
                placeholder="Input Value"
                onChange={onChangeHandler}
              />
            </Grid>
            <Grid item sm={2.5} mb={2} mx={1.8}>
              <TextField
                select
                fullWidth
                size="small"
                name="repeatFrequencyUnit"
                value={scheduleData?.repeatFrequencyUnit || "MINUTE"}
                {...register("repeatFrequencyUnit", {
                  onChange: e => onChangeHandler(e),
                  required: true
                })}
              >
                <MenuItem value={"MINUTE"}>Minute(s)</MenuItem>
                <MenuItem value={"HOUR"}>Hour(s)</MenuItem>
                <MenuItem value={"DAY"}>Day(s)</MenuItem>
                <MenuItem value={"WEEK"}>Week(s)</MenuItem>
                <MenuItem value={"MONTH"}>Month(s)</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={6} mb={2}>
              <TextField
                fullWidth
                size="small"
                name="endTime"
                type="datetime-local"
                label="End Date & Time"
                value={scheduleData?.endTime}
                {...register("endTime", {
                  onChange: e => {
                    setscheduleData({ ...scheduleData, endTime: e.target.value })
                    if (Number(new Date(e.target.value)) > Number(new Date())) clearErrors("endTime")
                  },
                  validate: (v) => {
                    if (Number(new Date(v)) < Number(new Date()))
                      return "Date and Time cannot be backdated."
                    if (Number(new Date(v)) <= Number(new Date(scheduleData.startTime)))
                      return "End Time should be greater than Start Time"
                  }
                })}
                error={Boolean(errors?.endTime)}
                helperText={errors?.endTime?.message}
              />
            </Grid>
          </>
        )}
        <Grid item sm={12}>
          <Box sx={{ padding: "10px", border: "1px solid #d0c2ff", background: "#f7f5ff", color: "#54577d" }}>
            <center>
              {
                scheduleData.isRepeated ?
                  <Typography variant="h2" sx={{ fontSize: "14px", lineHeight: 1.5 }}>
                    Starts on <b>{`${new Date(scheduleData.startTime).toDateString()} - ${new Date(scheduleData.startTime).toLocaleTimeString()}`}</b>, <br />
                    repeats every <b>{scheduleData?.repeatInterval} {scheduleData?.repeatFrequencyUnit}(s)</b>, <br />
                    and ends on <b>{`${new Date(scheduleData.endTime).toDateString()} - ${new Date(scheduleData.endTime).toLocaleTimeString()}`}</b>
                  </Typography>
                  :
                  <Typography variant="h2" sx={{ fontSize: "14px" }}>
                    Scheduled for <b>{`${new Date(scheduleData.startTime).toDateString()} - ${new Date(scheduleData.startTime).toLocaleTimeString()}`}</b>
                  </Typography>
              }
            </center>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          mt: 2,
          textAlign: "center",
          "& .MuiButton-root": {
            "&:nth-of-type(1)": {
              mr: 1,
            },
          },
        }}
      >
        <Grid
          container
          sx={{ m: 1 }}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={6} textAlign="left">
            <Button
              sx={{
                backgroundColor: "#eb5160",
                "&:hover": {
                  backgroundColor: "#eb5160",
                },
              }}
              variant="contained"
              onClick={() => {
                retVal(false)
                setEdit(null)
                setSchedule(null)
                setTestSuites([])
                setAllConfigs([])
              }}
              color="error"
              size="small"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Button
              type="submit"
              disabled={exeLoading}
              variant="contained"
              size="small"
              sx={{
                mr: 1,
                backgroundColor: "#6580e0",
                "&:hover": {
                  backgroundColor: "#6580e0",
                },
              }}
            >
              {exeLoading && <CircularProgress color="inherit" size={15} />}
              {edit ? resource.UPDATE : resource.CREATE}
            </Button>
          </Grid>
        </Grid>
      </Box>

    </Box >
  )
}

SchedulerForm.propTypes = {
  retVal: PropTypes.func
}