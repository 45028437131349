import { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material"
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from "@mui/icons-material"
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined"

import AccountSettings from "./AccountSettings"

const Header = ({ openSideMenu, onClickMenuIcon }) => {

  const [mode, setMode] = useState("dark")
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }), [])

  useEffect(() => {
    document.body.classList.add(mode === "light" ? "dark" : "light")
    document.body.classList.remove(mode === "light" ? "light" : "dark")
  }, [mode])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="row" justifyContent="left" alignItems="center">
        <Grid item sm={4}>
          <Box className="v-center">
            <Box
              sx={{
                width: openSideMenu ? `calc(200px - ${24}px)` : " fit-content",
              }}
            >
              <Tooltip title={openSideMenu ? "Hide" : "Show"}>
                <IconButton
                  sx={{ mr: 2 }}
                  size="small"
                  onClick={onClickMenuIcon}
                  edge="start"
                >
                  {openSideMenu ? <ChevronLeftIcon /> : <MenuIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>

        <Grid item sm={4} textAlign="center">
        </Grid>
        <Grid item sm={4} className="headerIcons">
          <Divider
            orientation="vertical"
            variant="string"
            flexItem
            style={{ backgroundColor: "#ccc", width: 2 }}
          />
          <Tooltip
            arrow
            placement="top"
            title={mode === "dark" ? "Dark" : "Light"}
          >
            <IconButton
              size="small"
              sx={{
                background: "linear-gradient(45deg, #611EB6, #66A2EE)",
                color: "#fff",
              }}
              onClick={colorMode.toggleColorMode}
            >
              {mode === "dark" ?
                <DarkModeOutlinedIcon fontSize="small" /> : <LightModeOutlinedIcon fontSize="small" />
              }
            </IconButton>
          </Tooltip>

          <AccountSettings />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header

Header.propTypes = {
  openSideMenu: PropTypes.bool,
  onClickMenuIcon: PropTypes.func
}
