/* eslint-disable no-undef */
import { useContext } from "react"
import { PropTypes } from "prop-types"
import { useForm } from "react-hook-form"
import {
  Box, Button, ButtonGroup,
  Grid,
  InputAdornment,
  MenuItem,
  TextField, Typography,
} from "@mui/material";

import { resource } from "data/resource";
import ApiService from "services/app.service";
import UtilsContext from "contexts/Utils/UtilsContext";
import VarContext from "contexts/Variables/VarContext";
import TestCaseContext from "contexts/TestCase/TestCaseContext";
import ExecutionContext from "contexts/Execution/ExecutionContext";
import TestResultContext from "contexts/TestResult/TestResultContext";

export default function ChooseRun({ handleClose, configData, setConfigData, setPopUp, tsIds }) {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const searchParams = new URLSearchParams(document.location.search);
  const tsId = searchParams.get("testsuite");

  const varContext = useContext(VarContext);
  const utilsContext = useContext(UtilsContext);
  const testCaseContext = useContext(TestCaseContext);
  const executionContext = useContext(ExecutionContext);
  const testResultContext = useContext(TestResultContext);

  const { envNames } = varContext
  const { getExesTS } = testResultContext
  const { editTC, setEditTC } = testCaseContext
  const { config, setConfig, getConfigs, setCurrConfig, setAdd } = executionContext
  const { showAlert, errorData, setErrorData, exeLoading, setExeLoading, removeExeLoading } = utilsContext

  const onChangeHandler = (e) => {
    setConfigData({
      ...configData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (runNow) => {
    try {
      const og = Object.assign({}, configData)
      og.name = og.name.trim()
      og.endpoints = og.endpoints.filter(e => e.isSelected)
      og.endpoints.forEach((e, i) => og.endpoints[i].testCaseIds = e.testCaseIds.filter((el, j) => og.endpoints[i].tc[j].isSelected))

      runNow === "true" && setExeLoading();
      const { data } = await ApiService.runConfig(runNow, og);
      if (runNow === "false")
        showAlert({
          type: data.status,
          msg: data.message,
        });
      else {
        setPopUp({
          type: data.status,
          msg: resource.FEEDBACK.EXE_DONE,
        });
        removeExeLoading();
      }

      setCurrConfig(configData.nameId)
      getConfigs(tsId ? tsId : tsIds)
      setAdd(null)
      editTC && setEditTC(null)
      config && setConfig(null)
      if (runNow === "true") {
        getExesTS(data.configuration._id);
        setTimeout(() => setPopUp(null), resource.TIMEOUT.GET);
      }
    } catch (err) {
      removeExeLoading()
      console.log(err)
      const data = {}
      if (err.response?.data?.message) {
        err.response.data.message.trim().split(",").filter(e => e).forEach((e) => {
          const msg = e.trim().split(":").filter(e => e)
          data[msg[0].trim()] = msg[1]
        })
        setErrorData(data)
        data.endpoints && showAlert({
          type: "error",
          msg: "No requests found! Select or create new requests",
        });
        setTimeout(() => {
          setErrorData({});
        }, resource.TIMEOUT.ERR);
      }
    }
  };
  return (
    <Box>
      <Box>
        <Grid container spacing={1.5}>
          <Grid sm={12} item>
            <Box className="createConfig" color={"#54577d"}>
              <Typography mb={1.5}>
                <b>Create New Execution Configuration</b>
              </Typography>
              <TextField
                label="Config Name"
                size="small"
                autoFocus
                fullWidth
                name="name"
                autoComplete='off'
                value={configData.name}
                {...register("name", {
                  onChange: e => onChangeHandler(e),
                  required: "Config Name is required.",
                  validate: (v) => {
                    if (!v.trim()) { return "Minimum length is 4 characters."; }
                  },
                  minLength: {
                    value: 4,
                    message: "Config Name must have at least 4 characters."
                  },
                  maxLength: {
                    value: 30,
                    message: "Config Name must have at max. 30 characters."
                  },
                })}
                error={Boolean(errors?.name || errorData?.name)}
                helperText={errors?.name?.message || errorData?.name}
              />
              <TextField
                name="description"
                autoComplete='off'
                label={resource.AUT.HEAD.DESC}
                minRows={3}
                maxRows={6}
                multiline
                value={configData.description}
                {...register("description", {
                  onChange: e => onChangeHandler(e),
                  maxLength: {
                    value: 150,
                    message: "Description must have at max. 150 characters."
                  }
                })}
                error={errors?.description}
                helperText={errors?.description?.message}
                fullWidth
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box display="grid" my={1.5}>
        <TextField
          select
          fullWidth
          size="small"
          name="environment"
          label='Select Environment'
          value={configData.environment}
          onChange={onChangeHandler}
        >
          {envNames.map(e =>
            <MenuItem key={e} value={e}>
              {e}
            </MenuItem>
          )}
        </TextField>
      </Box>
      <Box display="grid" my={1.5}>
        <TextField
          label="Delay"
          size="small"
          name="delay"
          autoComplete='off'
          value={configData.delay}
          onChange={onChangeHandler}
          error={Boolean(errorData?.delay)}
          helperText={errorData?.delay}
          InputProps={{
            endAdornment: <InputAdornment position="end">ms</InputAdornment>,
          }}
        />
      </Box>
      <Grid container mt={2}>
        <Grid sm={4} item>
          <Button variant="contained" size="small" color="error" onClick={handleClose}
            sx={{
              backgroundColor: "#eb5160",
              "&:hover": {
                backgroundColor: "#eb5160",
              },
            }}>
            {resource.CANCEL}
          </Button>
        </Grid>
        <Grid sm={8} textAlign="right" item>
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled button group"
          >
            <Button variant="contained" size="small" color="primary" disabled={exeLoading}
              sx={{
                marginRight: "5px",
                backgroundColor: "#6580e0",
                "&:hover": {
                  backgroundcolor: "#6580e0",
                },
              }}
              onClick={handleSubmit(() => submitHandler("false"))}>
              {editTC ? "Save as New" : resource.SAVE}
            </Button>
            {!editTC && <Button variant="contained" size="small" disabled={exeLoading}
              sx={{
                backgroundColor: "#1fcc75",
                "&:hover": {
                  backgroundColor: "#1fcc75",
                },
              }}
              onClick={handleSubmit(() => submitHandler("true"))}>
              Save & Execute
            </Button>}
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
}

ChooseRun.propTypes = {
  tsIds: PropTypes.string,
  setPopUp: PropTypes.func,
  handleClose: PropTypes.func,
  setConfigData: PropTypes.func,
  configData: PropTypes.object,
}
