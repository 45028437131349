import { useContext, useEffect } from "react";
import {
  Alert, Box,
  Grid,
  IconButton,
  Snackbar,
  Typography, Tooltip
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { resource } from "data/resource";
import { headCells } from "data/scheduler";
import SchedulerTable from "./SchedulerTable";
import ApiService from "../../services/app.service";
import PopUpCreate from "components/Utils/PopUpCreate";
import InnerHeader from "components/Utils/InnerHeader";

import UtilsContext from "contexts/Utils/UtilsContext";
import ScheduleContext from "contexts/Schedule/ScheduleContext";

export default function ScheduledList() {
  const utilsContext = useContext(UtilsContext);
  const scheduleContext = useContext(ScheduleContext);

  const { schedules, getSchedules } = scheduleContext;
  const { alert, edit, curr, envName, deletes, delValues, deleteAll,
    showAlert, removeAlert, setEdit, setCurr, setEnvName, setDelete, setDelValues, setDeleteAll } = utilsContext;

  useEffect(() => {
    getSchedules();
    edit && setEdit(null);
    curr && setCurr(null);
    envName && setEnvName(null);
    deleteAll & setDeleteAll(false);
    delValues.length && setDelValues([]);
  }, []);

  const deleteHandler = async () => {
    try {
      const { data } = await ApiService.deleteSchedule(delValues.length ? delValues : [deletes])
      showAlert({
        type: data.status,
        msg: data.message,
      });
      setTimeout(() => {
        setDelValues([]);
        setDelete(null);
        getSchedules();
      }, resource.TIMEOUT.SUCCESS);
    } catch (err) {
      console.log(err.response.data);
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      {alert !== null && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
          className="snackbar"
        >
          <Alert severity={alert.type} onClose={() => removeAlert()}>{alert.msg}</Alert>
        </Snackbar>
      )}

      <InnerHeader>
        <Grid container alignItems="center">
          <Grid md={6} item>
            <Typography variant="h6">Scheduled List</Typography>
          </Grid>
          <Grid md={6} item >
            <Box className="tsHeader">
              <Box display="flex" alignItems="right" justifyContent="right" gap={1}>
                <PopUpCreate name={"Execution Schedule"} />
                {Boolean(delValues.length) &&
                  <Tooltip title={resource.DELETE}>
                    <IconButton
                      size="small"
                      color="error"
                      className="actionIcon"
                      onClick={() => setDelete(true)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                }
              </Box>
            </Box>
          </Grid>
        </Grid>
      </InnerHeader>

      <Box pt={7}>
        <SchedulerTable
          rows={schedules?.sort((a, b) => b.id - a.id)}
          headCells={headCells}
          deleteHandler={deleteHandler}
        />
      </Box>
    </Box>
  );
}
