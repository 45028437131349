import { useContext, Fragment } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { styled } from "@mui/material/styles"
import {
    Box,
    LinearProgress,
    IconButton,
    Tooltip, Typography, TableCell, TableHead, TableBody, Table, TableContainer, TableRow, tableCellClasses
} from "@mui/material"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"

import { resource } from "data/resource"
import UtilsContext from "contexts/Utils/UtilsContext"
import ScheduleContext from "contexts/Schedule/ScheduleContext"

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#d0c2ff",
        border: "1px solid #d0c2ff",
        color: "#54577d",
        fontWeight: 550,
        padding: "7px",
        fontSize: 13,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12.2,
        padding: "13px",
        color: "#54577d",
        border: 0,
    },
}))

const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#f6f2fa",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#ede8ff",
    },
    cursor: "pointer"
}))

function TableLoad({ headCells }) {
    const navigate = useNavigate()
    const utilsContext = useContext(UtilsContext)
    const scheduleContext = useContext(ScheduleContext)

    const { loadResults } = scheduleContext
    const { exeLoading } = utilsContext
    return (
        <Fragment>
            <TableContainer sx={{ marginTop: "13px" }}>
                <Table className="customized_table" >
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <StyledTableCell key={headCell.id} align={"center"}>{headCell.label}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {Boolean(loadResults?.length) && loadResults?.map((row, i) =>
                                <StyledTableRow key={i}>
                                    <StyledTableCell align={"center"}>{row?.processedData?.aut}</StyledTableCell>
                                    <StyledTableCell align={"center"}>{row?.processedData?.testSuite}</StyledTableCell>
                                    <StyledTableCell align={"center"}>
                                        {row?.processedData?.endpoint}
                                    </StyledTableCell>
                                    <StyledTableCell align={"center"}>{row?.processedData?.load?.noOfUsers}</StyledTableCell>
                                    <StyledTableCell align={"center"}>{row?.processedData?.load?.loopCount}</StyledTableCell>
                                    <StyledTableCell align={"center"}>{row?.processedData?.load?.rampUpPeriod}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Box className='autListIcons'>
                                            {/* <Link to={`/loadtest/results/${row?.uuid}`}> */}
                                            <IconButton
                                                size="small"
                                                className="accordianActionIcon"
                                                sx={{ marginLeft: "5px" }}
                                                onClick={() => {
                                                    navigate(`/loadtest/results/${row?.uuid}`)
                                                }}
                                            >
                                                <Tooltip title={`View ${resource.RES}`} placement="right" arrow>
                                                    <RemoveRedEyeIcon />
                                                </Tooltip>
                                            </IconButton>
                                            {/* </Link> */}
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                            <StyledTableRow>
                                {Boolean(exeLoading) && Array.from({ length: 7 }, (_, i) => (
                                    <StyledTableCell key={i}><LinearProgress /></StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </>
                    </TableBody>
                </Table>

            </TableContainer>

            {!loadResults?.length && <Box sx={{ padding: "5%" }}>
                <center>
                    <Typography variant="h6">{resource.NO_LOAD}</Typography>
                </center>
            </Box>}
        </Fragment>
    )
}
TableLoad.propTypes = {
    headCells: PropTypes.array,
    uniqueId: PropTypes.array,
}

export default TableLoad