import PropTypes from "prop-types"
import { styled } from "@mui/material/styles"
import { Box, Grid, Typography } from "@mui/material"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import { resource } from "data/resource"

const Accordion = styled((props) => (
  <MuiAccordion
    disableGutters
    defaultExpanded
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const TSRunOrder = ({ obj }) => {

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <Box ml={2} sx={{ color: "#54577d" }}>
            <Typography variant="h3" mb={1}>
              <Box component="span" fontWeight="600">{resource.EXES.CONF}:</Box>
              {" "}{obj.name}
            </Typography>
            <Typography variant="h3" mb={1}>
              <Box component="span" fontWeight="600">{"Delay"}:</Box>
              {" "} {obj.delay} ms
            </Typography>
            <Typography variant="h3" mb={1.5}>
              <Box component="span" fontWeight="600" sx={{ lineHeight: "20px" }}>{resource.AUT.HEAD.DESC}:</Box>
              {" "} {obj.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box className="runOrder">
        {obj.endpoints.map((box, i) => (
          <Box className="OL" draggable={true} key={i} display="flex">

            <Accordion>
              <Box display="flex" alignItems="center" p="4px 0px" gap={1}>
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                ></AccordionSummary>
                <Box>{box.endpoint[0]?.name}</Box>
              </Box>

              <AccordionDetails>
                <Box display="flex" alignItems="center">
                  <Box
                    className="DBListMain"
                    sx={{ gridTemplateColumns: "repeat(2, 1fr) !important" }}
                  >
                    {Boolean(box.testCases.length) && box.testCases.map(e =>
                      <Box className="DSListItem" key={e._id}>
                        <Box className="DBList">
                          <Grid container alignItems="center">
                            <Grid md={12} item>
                              <Box>
                                <Box sx={{
                                  backgroundColor: "#ede8ff",
                                  padding: "8px",
                                  marginBottom: "4px",
                                }}>
                                  <Typography>
                                    <strong>{e.name}</strong>
                                  </Typography>
                                </Box>
                                <Box>
                                  {e.functionalAssertions.map(ass => (
                                    <li key={ass._id} style={{ fontSize: "13px" }}>
                                      {ass.targetField}&nbsp;
                                      {ass.rules.map(
                                        (rule, i) =>
                                          `${rule.operation} "${rule.expectedValue}" ${i + 1 !== ass.rules.length ? rule.operator : ""
                                          } `
                                      )}
                                    </li>
                                  ))}

                                  {Object.keys(e.securityAssertions).map((sec, i) =>
                                    e.securityAssertions[sec]?.isSelected && (
                                      <li key={i} style={{ fontSize: "13px" }}>
                                        {sec === "contentType" ? "Response Header Content Type" : sec === "statusCode" ? "Status Code" : "Cookie name"}{" "}
                                        is&nbsp;
                                        {e.securityAssertions[sec].expectedValue ? `"${e.securityAssertions[sec].expectedValue}"` :
                                          e.securityAssertions[sec].rules.map((ele, i) =>
                                            `"${ele.name}" ${i + 1 !== e.securityAssertions[sec].rules.length ? ", " : ""}`
                                          )}
                                      </li>
                                    ))}

                                  {Object.keys(e.performanceAssertions).map((perf, i) => e.performanceAssertions[perf]?.isSelected && (
                                    <li style={{ fontSize: "13px" }} key={i}>
                                      {perf === "responseTime" ? "Response Time" : "Response Size"}{" "}
                                      is&nbsp;
                                      {`${e.performanceAssertions[perf].operation === "lessThan" ? "<" : ">"} 
                                      ${e.performanceAssertions[perf].expectedValue} ${perf === "responseTime" ? "ms" : "KB"}`}
                                    </li>
                                  ))}

                                  {!e?.functionalAssertions?.length &&
                                    (e.securityAssertions
                                      ? Object.values(e?.securityAssertions).every(
                                        (el) => !el?.isSelected
                                      )
                                      : true) &&
                                    (e.performanceAssertions
                                      ? Object?.values(e?.performanceAssertions)?.every(
                                        (el) => !el?.isSelected
                                      )
                                      : true) && (
                                      <Box sx={{ padding: "8%" }}>
                                        <center>
                                          <Typography variant="BODY1">{resource.NO_TC}</Typography>
                                        </center>
                                      </Box>
                                    )}
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default TSRunOrder;

TSRunOrder.propTypes = {
  obj: PropTypes.object,
  setPopUp: PropTypes.func,
};
