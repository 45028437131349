import { useState } from "react"
import { PropTypes } from "prop-types"
import { styled } from "@mui/material/styles"
import {
  Box,
  Chip,
  Dialog, DialogContent, DialogTitle,
  FormControl,
  Grid,
  IconButton, InputLabel,
  MenuItem,
  Select,
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import { resource } from "data/resource";
import PreviewExecutions from "./Executions";
import CustomExpandableTableRow from "../Tables/CustomExpandableTableRow";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#d0c2ff",
    border: "1px solid #d0c2ff",
    color: "#54577d",
    fontWeight: 550,
    padding: "7px",
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12.2,
    padding: "13px",
    color: "#54577d",
    border: 0,
  },
}));

const TestSuitePreview = ({ setShowPreviewTestSuite, obj, tsObj }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(`${tsObj.testSuite.name}^${tsObj.testSuite._id}`);
  const [showAccordian, setShowAccordian] = useState(false);

  return (
    <Dialog fullWidth={true} maxWidth="lg" open={true} onClose={() => setShowPreviewTestSuite(null)}>
      <DialogTitle sx={{ backgroundColor: "#ede8ff", color: "#54577d" }}>
        <IconButton
          onClick={() => setShowPreviewTestSuite(null)}
          size="small"
          color="error"
          sx={{ position: "absolute", top: 0, right: 0 }}
        >
          <CancelOutlinedIcon />
        </IconButton>
        <Grid container spacing={2} alignItems="center">
          <Grid md={4} item textAlign="center">
            <Typography variant="h6">
              <Box component="span" fontWeight="normal">
                {resource.AUT.NAME}{" "}
              </Box>{" "}
              : {obj.name}
            </Typography>
          </Grid>
          <Grid md={4} item>
            <FormControl fullWidth>
              <InputLabel>{resource.TS.NAME}</InputLabel>
              <Select
                required
                fullWidth
                size="small"
                name="method"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setShowPreviewTestSuite(e.target.value.split("^")[1]);
                }}
              >
                {obj.testSuites.map(e =>
                  <MenuItem key={e._id} value={`${e.testSuite.name}^${e.testSuite._id}`}>{e.testSuite.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid md={4} item textAlign="center">
            <Typography variant="h6">
              <Box component="span" fontWeight="normal">
                {resource.EXES.CONS}
              </Box>
              : {tsObj.configurations.length}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TableContainer sx={{ mt: 2, backgroundColor: "#f1edff" }} aria-label="simple table">
          <Table>
            <TableHead >
              <TableRow>
                <StyledTableCell align="center" >{resource.EXES.CON}</StyledTableCell>
                <StyledTableCell align="center">{resource.TR.EXE}</StyledTableCell>
                <StyledTableCell align="center">{resource.TR.PASS}</StyledTableCell>
                <StyledTableCell align="center">View Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                tsObj.configurations.map(e =>
                  <CustomExpandableTableRow
                    key={e.configuration._id}
                    id={e.configuration._id}
                    showAccordian={showAccordian}
                    onClickArrow={() => {
                      open ? setShowAccordian(false) : setShowAccordian(e.configuration._id);
                      setOpen(!open);
                    }}
                    expandComponent={
                      <StyledTableCell
                        className="expandableRow"
                        sx={{ backgroundColor: "#f5f7f7 !important" }}
                        colSpan="4"
                      >
                        <PreviewExecutions obj={e} />
                      </StyledTableCell>
                    }
                  >
                    <StyledTableCell align="center" sx={{ width: "30.5%" }}>{e.configuration.name}</StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: "30.5%" }}>{e.executions.length}</StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: "30.5%" }}>
                      <Chip
                        variant="outlined"
                        size="small"
                        sx={{ background: e.passPercentage < 50 ? "rgba(253, 5, 5, 0.13)" : (e.passPercentage >= 70 ? "rgba(63, 244, 6, 0.16)" : "rgba(244, 87, 6, 0.16)") }}
                        color={e.passPercentage < 50 ? "error" : (e.passPercentage >= 70 ? "success" : "warning")}
                        label={`${Number.isInteger(e.passPercentage) ? e.passPercentage : e.passPercentage.toFixed(1)}%`}
                      />
                    </StyledTableCell>
                  </CustomExpandableTableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default TestSuitePreview;

TestSuitePreview.propTypes = {
  obj: PropTypes.object,
  tsObj: PropTypes.object,
  setShowPreviewTestSuite: PropTypes.func,
}