/* eslint-disable no-undef */
import { useState } from "react"
import { PropTypes } from "prop-types"
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";

import ChooseRun from "./ChooseRun";
import TSRunOrder from "./TSRunOrder";
import stateData from "data/stateData";

const CreateConfig = ({ handleClose, setPopUp, tsIds }) => {
  const tsId = new URLSearchParams(document.location.search).get("testsuite");
  const autId = new URLSearchParams(document.location.search).get("aut");

  const [configData, setConfigData] = useState({
    ...stateData.config,
    nameId: Date.now().toString(),
    autId: autId,
    testSuiteId: tsId ? tsId : tsIds,
  });

  return (
    <Grid container spacing={1.5}>
      <Grid sm={7.9} item >
        <Box mb={1} px={"12px"} py={"12px"} bgcolor={"#ede8ff"} color={"#54577d"}>
          <Typography variant="h6">API Suite Run Order</Typography>
        </Box>
        <TSRunOrder configData={configData} setConfigData={setConfigData} tsIds={tsIds} />
      </Grid>
      <Grid sm={4.1} item>
        <Box mb={1} px={"12px"} py={"12px"} bgcolor={"#ede8ff"} color={"#54577d"}>
          <Typography variant="h6"> Choose how to run your API Suite</Typography>
        </Box>
        <ChooseRun handleClose={handleClose} configData={configData} setConfigData={setConfigData} setPopUp={setPopUp} tsIds={tsIds} />
      </Grid>
    </Grid>
  );
};

export default CreateConfig

CreateConfig.propTypes = {
  tsIds: PropTypes.string,
  setPopUp: PropTypes.func,
  handleClose: PropTypes.func,
}
