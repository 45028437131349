import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Typography, MenuItem, TextField, Divider, ListItem } from "@mui/material"

import { resource } from "data/resource"
import AutContext from "contexts/Aut/AutContext"
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext"
export default function ConnectionDBList() {
  const autId = new URLSearchParams(document.location.search).get("aut")

  const navigate = useNavigate()

  const autContext = useContext(AutContext)
  const testSuiteContext = useContext(TestSuiteContext)

  const { auts, getAuts } = autContext
  const { testSuites, getTestSuites } = testSuiteContext


  useEffect(() => {
    getAuts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className="active-ds" justifyContent={"center"} gap={2}>
      <TextField
        select
        // color="info"
        value={autId || ""}
        size="small"
        autoComplete='off'
        label="Select AUT"
        sx={{ width: "50%" }}
        onChange={e => {
          getTestSuites(e.target.value)
          navigate(`/testsuites/?aut=${e.target.value}`)
        }}
      >
        {auts?.length ? auts.map((e) => (
          <MenuItem key={e.name} value={e._id}>
            {e.name}{" "}
          </MenuItem>
        )) : <ListItem disabled sx={{ backgroundColor: "#ccc", fontSize: "0.67rem", color: "black" }}>Nothing to Display</ListItem>}
      </TextField>

      <Typography>
        {resource.AUT.NAME}: <b>{autId ? testSuites?.aut?.name : "-"}</b>
      </Typography>
      <Divider orientation="vertical" flexItem />
      <Typography>
        {resource.AUT.HEAD.COUNT}:{" "}
        <b>{autId ? testSuites?.aut?.noTestSuites : 0}</b>
      </Typography>
    </Box>
  )
}
