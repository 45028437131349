/* eslint-disable no-undef */
import { useState, useContext, useEffect } from "react"
import { Box, Typography } from "@mui/material"

import { resource } from "data/resource";
import stateData from "data/stateData";
import ApiService from "services/app.service";
import EnterSampleResponse from "./EnterSampleResponse";
import CreateVariableForm from "components/Forms/TSVariableForm";

import APIsContext from "contexts/APIs/APIsContext";
import VarContext from "contexts/Variables/VarContext";
import UtilsContext from "contexts/Utils/UtilsContext";

export default function Variables() {
  const autId = new URLSearchParams(document.location.search).get("aut");
  const tsId = new URLSearchParams(document.location.search).get("testsuite");

  const varContext = useContext(VarContext);
  const apisContext = useContext(APIsContext);
  const utilsContext = useContext(UtilsContext);

  const { add } = apisContext;
  const { edit, changes, setChanges, setErrorData } = utilsContext;
  const { new_var, getTsVars, setEditVar, getVar, setNewVar } = varContext;

  const [status, setStatus] = useState("update");

  useEffect(() => {
    if (new_var) {
      getTsVars(tsId);
      setNewVar(false);
    }
  }, []);

  const onAddInput = async () => {
    try {
      if (add) { !changes && setChanges(true); }
      const { data } = await ApiService.createVar({
        ...stateData.tsVar1,
        name: `new variable-${Date.now().toString().slice(9, 12)}`,
        endpointId: edit,
        autId: autId,
        testSuiteId: tsId,
      });
      setStatus("add");
      getTsVars(tsId);
      setEditVar(data.variable._id);
      getVar(data.variable._id);

    } catch (err) {
      console.log(err.response);
      setErrorData({ name: err.response.data.message });
      setTimeout(() => {
        setErrorData({});
      }, resource.TIMEOUT.ERR);
    }
  };

  return (
    <Box mt={1.5}>
      <Typography variant="h5">{resource.VARS.TITLE}</Typography>

      <EnterSampleResponse />

      <CreateVariableForm onAddInput={onAddInput} status={status} setStatus={setStatus} />
    </Box>
  );
}
