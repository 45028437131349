import * as React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@mui/material";
import TSRunOrder from "components/TSExecution/ConfigPreview/TSRunOrder";
import ConfigExecutions from "components/TSExecution/ConfigPreview/ConfigExecutions";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ConfigExeTabs({ obj, setPopUp, popup }) {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="customTabs scrollbarStyle">
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{ mb: 1.5 }}
        className="reqTabs1">
        <Tab label="Run Order" />
        <Tab label="Executions" />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <TSRunOrder obj={obj} setPopUp={setPopUp} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ConfigExecutions popup={popup} />
      </CustomTabPanel>
    </Box>
  );
}

ConfigExeTabs.propTypes = {
  obj: PropTypes.object,
  popup: PropTypes.bool,
  setPopUp: PropTypes.func,
};