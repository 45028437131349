/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  Button,
  Grid,
  Switch,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import stateData from "data/stateData";
import ApiService from "services/app.service";
import { resource } from "../../data/resource";
import AutContext from "../../contexts/Aut/AutContext";
import UtilsContext from "../../contexts/Utils/UtilsContext";

export default function CreateAutForm({ retVal }) {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const autContext = useContext(AutContext);
  const utilsContext = useContext(UtilsContext);

  const { showAlert, edit, setEdit } = utilsContext;
  const { getAuts, getAut, aut, auts, setAut } = autContext;

  const [autData, setautData] = useState(stateData.aut);

  useEffect(() => {
    if (!aut && edit) { getAut(edit); }
    else if (edit) {
      setautData({
        name: aut.name,
        description: aut.description,
        isPublic: aut.isPublic,
        userId: JSON.parse(localStorage.getItem("APITEUserData")).UserId,
        organizationId: JSON.parse(localStorage.getItem("APITEUserData")).OrganisationId,
      });
    }
  }, [aut]);

  const onChangeHandler = (e) => {
    setautData({
      ...autData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async () => {
    try {
      autData.name = autData.name.trim();
      autData.description = autData.description.trim();
      const { data } = await ApiService.createAut(autData);
      showAlert({
        type: data.status,
        msg: data.message,
      });
      setAut(null);
      setTimeout(() => {
        getAuts();
        retVal(false);
      }, resource.TIMEOUT.SUCCESS);
    } catch (err) {
      console.log(err.response.data);
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      });
    }
  };

  const editSubmitHandler = async () => {
    try {
      autData.name = autData.name.trim();
      autData.description = autData.description.trim();
      const { data } = await ApiService.updateAut(edit, autData);
      showAlert({
        type: data.status,
        msg: data.message,
      });
      setTimeout(() => {
        getAuts();
        setEdit(null);
        setAut(null);
        retVal(false);
      }, resource.TIMEOUT.SUCCESS);
    } catch (err) {
      console.log(err.response.data);
      setAut(null);
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      });
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(edit ? editSubmitHandler : submitHandler)}
    >

      <Box display="flex" alignItems="center">
        <Typography sx={{ color: "#54577d" }} variant="h6">
          {edit ? resource.EDIT : resource.CREATE} {resource.AUT.NAME}
        </Typography>
        <Box ml="auto">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{resource.AUT.PRIV}</Typography>
            <Switch
              className="switchStyle"
              checked={autData.isPublic}
              value={autData.isPublic}
              onClick={(e) =>
                setautData({
                  ...autData,
                  isPublic: e.target.value === "false",
                })
              }
            />
            <Typography ml="2px">{resource.AUT.PUBC}</Typography>
          </Stack>
        </Box>
      </Box>

      <Grid item container spacing={2} mt={.5}>
        <Grid sm={12} item>
          <TextField
            required
            autoFocus
            fullWidth
            size="small"
            name="name"
            autoComplete='off'
            variant="outlined"
            label={resource.AUT.HEAD.NAME}
            value={autData?.name}
            {...register("name", {
              onChange: e => onChangeHandler(e),
              required: "Field cannot be empty.",
              minLength: {
                value: 4,
                message: "Minimum length is 4 characters."
              },
              maxLength: {
                value: 30,
                message: "Minimum length is 30 characters."
              },
              validate: (v) => {
                if (!edit && auts.find(e => e.name === v)) return "AUT already exists."

                const e = auts.filter(e => e.name === v)
                if (e.length && e[0]._id !== edit) return "AUT already exists."

              }
            })}
            error={Boolean(errors?.name)}
            helperText={errors?.name?.message}
          />
        </Grid>
        <Grid sm={12} item>
          <TextField
            fullWidth
            size="small"
            name="description"
            autoComplete='off'
            variant="outlined"
            label={resource.AUT.HEAD.DESC}
            minRows={3}
            maxRows={6}
            multiline
            value={autData?.description}
            {...register("description", {
              onChange: e => onChangeHandler(e),
              maxLength: {
                value: 150,
                message: "Maximum length is 150 characters."
              }
            })}
            error={Boolean(errors?.description)}
            helperText={errors?.description?.message}
          />
        </Grid>
      </Grid>

      <Box className="AutFormButtons">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} textAlign="left">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#eb5160",
                "&:hover": {
                  backgroundColor: "#eb5160",
                },
              }}
              size="small"
              onClick={() => {
                retVal(false);
                setEdit(null);
                setAut(null);
              }}
            >
              {resource.CANCEL}
            </Button>
          </Grid>

          <Grid item xs={6} textAlign="right">
            <Button variant="contained" size="small" type="submit"
              sx={{
                backgroundColor: "#6580e0",
                "&:hover": {
                  backgroundColor: "#6580e0",
                },
              }}>
              {edit ? resource.UPDATE : resource.CREATE}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
