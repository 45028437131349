import { useContext, useState } from "react"
import { toXML } from "jstoxml"
import xml2json from "@hendt/xml2json"
import Editor from "react-simple-code-editor"
import { highlight, languages } from "prismjs/components/prism-core"

import "prismjs/themes/prism.css"
import "prismjs/components/prism-clike"
import "prismjs/components/prism-json.min"
import "prismjs/components/prism-javascript"
import {
  Box, Button, ButtonGroup,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField, Typography,
} from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

import { resource } from "data/resource";
import ApiService from "services/app.service";
import FileHandle from "components/Utils/FileUpload";
import APIsContext from "contexts/APIs/APIsContext";
import UtilsContext from "contexts/Utils/UtilsContext";

const hightlightWithLineNumbers = (input, language) =>
  highlight(input, language)
    .split("\n")
    .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
    .join("\n");

export default function EnterSampleResponse() {
  const apisContext = useContext(APIsContext);
  const utilsContext = useContext(UtilsContext);

  const { getReq } = apisContext;
  const { showAlert, edit } = utilsContext;

  const [file, setFile] = useState();
  const [text, setText] = useState("{}");
  const [type, setType] = useState("json");
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  const handleOptionChange = (event) => {
    setIsSectionVisible(true);
    setSelectedOption(event.target.value);
  };

  const submitHandler = async () => {
    try {
      let res;
      if (selectedOption === "file") {
        res = await ApiService.uploadFile(edit, file);
      }
      else {
        const b = text.split("{").filter(e => e).join().split("}").filter(e => e).join().split(",").join(":").split(":").join().replaceAll("\"", "").split(",").map(e => e.trim())
        const obj = {}
        for (let i = 0; i < b.length; i += 2)
          { if (b[i + 1]) { Number(b[i + 1]) ? obj[b[i]] = Number(b[i + 1]) : obj[b[i]] = b[i + 1]; } }
        res = await ApiService.uploadFile2(edit, file, type, obj, text);
      }

      showAlert({
        type: res.data.status,
        msg: res.data.message,
      });
      getReq(edit);
      setTimeout(() => {
        setIsSectionVisible(false);
        setSelectedOption(null);
      }, resource.TIMEOUT.SUCCESS);
    } catch (err) {
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      });
    }
  };

  const closeHandler = () => {
    setIsSectionVisible(false);
    setSelectedOption(null);
  };

  return (
    <>
      <Grid container alignItems="center" my={1.5}>
        <Grid sm={4} item>
          <TextField
            select
            required
            fullWidth
            size="small"
            name="rawType"
            autoComplete='off'
            label="Enter Sample Response"
            value={selectedOption || ""}
            onChange={handleOptionChange}
          >
            <MenuItem value="file">{resource.FILE}</MenuItem>
            <MenuItem value="raw">{resource.RAW}</MenuItem>
          </TextField>
        </Grid>

        <Grid sm={8} item display="flex" justifyContent="flex-end">
          <Box display="flex" alignItems="center" gap={1}>
            <Typography textAlign="right">
              <i>
                {resource.EXECUTE} <b>(or)</b>
                <br />
                {resource.SAMPLE}
              </i>
            </Typography>
            <Divider orientation="vertical" flexItem />
            <IconButton size="small" sx={{ color: "#6580e0" }}>
              <InfoRoundedIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {isSectionVisible && (
        <Grid container mb="12px">
          {selectedOption === "file" ? (
            <Grid sm={12} item>
              <FileHandle file={file} setFile={setFile} />
            </Grid>
          ) : (
            <Grid sm={12} item>
              <Box textAlign="right" my={1.5}>
                <TextField
                  select
                  required
                  size="small"
                  name="rawType"
                  value={type}
                  autoComplete='off'
                  className="rawType"
                  onChange={(e) => {
                    if (e.target.value === "xml") {
                      let types;
                      if (typeof text === "string") {
                        const b = text.split("{").filter(e => e).join().split("}").filter(e => e).join().split(",").join(":").split(":").join().replaceAll("\"", "").split(",").map(e => e.trim())
                        const obj = {}
                        for (let i = 0; i < b.length; i += 2)
                          { if (b[i + 1]) { Number(b[i + 1]) ? obj[b[i]] = Number(b[i + 1]) : obj[b[i]] = b[i + 1]; } }
                        types = obj;
                      } else
                        types = text
                      setText(toXML(types, { indent: "    " }))
                    }
                    else
                      { setText(JSON.stringify(xml2json(text))); }

                    setType(e.target.value);
                  }}
                >
                  <MenuItem key="JSON" value="json">
                    JSON
                  </MenuItem>
                  <MenuItem key="XML" value="xml">
                    &nbsp; XML
                  </MenuItem>
                </TextField>
              </Box>
              <Editor
                value={text}
                onValueChange={(e) => {
                  setText(e);
                }}
                highlight={code => hightlightWithLineNumbers(code, languages.json)}
                padding={10}
                textareaId="codeArea"
                className="editor"
                style={{
                  fontFamily: "\"Fira code\", \"Fira Mono\", monospace",
                  fontSize: 14,
                  outline: 0,
                  width: "100%"
                }}
              />
            </Grid>
          )}
          <Grid sm={12} item textAlign={"right"} pt={1}>
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled button group"
            >
              <Button
                size="small" variant="contained"
                onClick={closeHandler}
                sx={{ backgroundColor: "#eb5160", "&:hover": { backgroundColor: "#eb5160" }, marginRight: "5px" }}>
                {resource.CANCEL}
              </Button>
              <Button variant="contained" size="small"
                sx={{ backgroundColor: "#634cca", "&:hover": { backgroundColor: "#634cca" } }}
                onClick={submitHandler}>
                Upload
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid >
      )
      }
    </>
  );
}
