// Content-Type
const statusCode = [
    { name: 100, value: "100 Continue" },
    { name: 101, value: "101 Switching Protocols" },
    { name: 102, value: "102 Processing" },
    { name: 103, value: "103 Early Hints" },
    { name: 200, value: "200 OK" },
    { name: 201, value: "201 Created" },
    { name: 202, value: "202 Accepted" },
    { name: 203, value: "203 Non-Authoritative Information" },
    { name: 204, value: "204 No Content" },
    { name: 205, value: "205 Reset Content" },
    { name: 206, value: "206 Partial Content" },
    { name: 207, value: "207 Multi-Status" },
    { name: 208, value: "208 Already Reported" },
    { name: 226, value: "226 IM Used" },
    { name: 300, value: "300 Multiple Choices" },
    { name: 301, value: "301 Moved Permanently" },
    { name: 302, value: "302 Found" },
    { name: 303, value: "303 See Other" },
    { name: 304, value: "304 Not Modified" },
    { name: 305, value: "305 use proxy" },
    { name: 306, value: "306 unused" },
    { name: 307, value: "307 Temporary Redirect" },
    { name: 308, value: "307 Permanent Redirect" },
    { name: 400, value: "400 Bad Request" },
    { name: 401, value: "401 Unauthorized" },
    { name: 402, value: "402 Payment Required" },
    { name: 403, value: "403 Forbidden" },
    { name: 404, value: "404 Not Found" },
    { name: 405, value: "405 Method Not Allowed" },
    { name: 406, value: "406 Not Acceptable" },
    { name: 407, value: "407 Proxy Authentication Required" },
    { name: 408, value: "408 Request Timeout" },
    { name: 409, value: "409 Conflict" },
    { name: 410, value: "410 Gone" },
    { name: 411, value: "411 Length Required" },
    { name: 412, value: "412 Precondition failed" },
    { name: 413, value: "413 Payload Too Large" },
    { name: 414, value: "414 URI Too Long" },
    { name: 415, value: "415 Unsupported Media Type" },
    { name: 416, value: "416 Range Not Satisfiable" },
    { name: 417, value: "417 Expectation Failed" },
    { name: 418, value: "418 I'm a teapot" },
    { name: 421, value: "421 Misdirected Request" },
    { name: 422, value: "422 Unprocessable Entity" },
    { name: 423, value: "423 Locked" },
    { name: 424, value: "424 Failed Dependency" },
    { name: 425, value: "425 Too Early" },
    { name: 426, value: "426 Upgrade Required" },
    { name: 428, value: "428 Precondition Required" },
    { name: 429, value: "429 Too Many Requests" },
    { name: 431, value: "431 Request Header Fields Too Large" },
    { name: 451, value: "451 Unavailable For Legal Reasons" },
    { name: 500, value: "500 Internal Server Error" },
    { name: 501, value: "501 Not Implemented" },
    { name: 502, value: "502 Bad Gateway" },
    { name: 503, value: "503 Service Unavailable" },
    { name: 504, value: "504 Gateway Timeout" },
    { name: 505, value: "505 HTTP Version Not Supported" },
    { name: 506, value: "506 Variant Also Negotiates" },
    { name: 507, value: "507 Insufficient Storage" },
    { name: 508, value: "508 Loop Detected" },
    { name: 510, value: "510 Not Extended" },
    { name: 511, value: "511 Network Authentication Required" }
];

const contentType = [
    "application/javascript",
    "application/json",
    "application/pdf",
    "application/ogg",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/xhtml+xml",
    "application/xml",
    "application/x-www-form-urlencoded",
    "application/zip",
    "audio/mpeg",
    "audio/vnd.rn-realaudio",
    "audio/x-ms-wma",
    "audio/x-wav",
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/vnd.djvu",
    "image/x-icon",
    "multipart/form-data",
    "text/csv",
    "text/html",
    "text/plain",
    "text/xml",
    "video/mp4",
    "video/mpeg",
    "video/quicktime",
    "video/webm",
]

const ruleData = [
    { name: "Starts with", value: "startsWith" },
    { name: "Ends with", value: "endsWith" },
    { name: "Exists", value: "isExists" },
    { name: "Contains", value: "contains" },
    { name: "Equals", value: "equals" },
    { name: "Exists in List", value: "existsInList" },
    { name: "Less than", value: "lessThan" },
    { name: "Less than equal to", value: "lessThanEqualTo" },
    { name: "Greater than", value: "greaterThan" },
    { name: "Greater than equal to", value: "greaterThanEqualTo" },
    { name: "Max length of value", value: "maxLength" },
    { name: "Min length of value", value: "minLength" },
]

const perfRuleData = [
    { name: "less than", value: "lessThan" },
    { name: "greater than", value: "greaterThan" },
]

const inputType = ["startsWith", "endsWith", "contains", "equals", "existsInList"]

const perfData = {
    responseTime: "Response Time (ms)",
    responseSize: "Response Size (KB)",
}
const securityData = {
    statusCode: "Response Status Code",
    contentType: "Response Headers: Content Type",
    cookies: "Response Cookie Names"
};

const rule = { contentType, ruleData, perfRuleData, statusCode, inputType, securityData, perfData };
export default rule;