import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Backdrop,
  Card,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ConfirmModel = ({ confirmModel, setConfirmModel, returnValue }) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setConfirmModel(false);
    returnValue({ logedInHere: false, confirmModel: false });
  };
  // const logedInHere = () => {
  //   setConfirmModel(false);
  //   returnValue({ logedInHere: true, confirmModel: false });
  // };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={confirmModel}
      onClose={handleClose}
    >
      <Card>
        <DialogTitle>{t("Active Login Session Exists")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <DialogContentText>
              {t("Please logout from your existing session to login here.")}
            </DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button variant="contained" style={{ backgroundColor: "#AAAAAA" }} onClick={handleClose}>
            {t("Cancel")}
          </Button> */}
          <Button variant="contained" onClick={handleClose}>
            {t("Ok")}
          </Button>
        </DialogActions>
      </Card>
    </Backdrop>
  );
};

export default ConfirmModel;
