import { PropTypes } from "prop-types"
import { Box, Tooltip, } from "@mui/material"

function IconGroup({ obj, id, name }) {

    return (
        <>{
            <Tooltip placement="top" arrow title={obj.label}>
                <Box className="DBAdd" onClick={name ? (e) => obj.clickHandler(e, id) : () => obj.clickHandler(id)}>
                    {obj.icon}
                </Box>
            </Tooltip>
        }
        </>
    );
}

export default IconGroup

IconGroup.propTypes = {
    id: PropTypes.string,
    obj: PropTypes.object,
    name: PropTypes.string
}
