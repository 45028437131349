import { useReducer } from "react"
import { PropTypes } from "prop-types"
import UtilsContext from "./UtilsContext"
import UtilsReducer from "./UtilsReducer"

import { SET_ALERT, REMOVE_ALERT, SET_ERROR, SET_EDIT, SET_VISIBLE, SET_DELETE, SET_CHANGES, SET_LOADING, REMOVE_LOADING, SET_LOADING_EXE, REMOVE_LOADING_EXE, SET_DELETE_ALL, SET_DEL_VALUES, SET_OPENS, REMOVE_LOADING_EXE1, SET_LOADING_EXE1, REMOVE_SNACK, SET_SNACK, SET_TESTDATA } from "../types.js";

function UtilsState(props) {
    const initialState = {
        edit: null,
        snack: null,
        alert: null,
        deletes: null,
        errorData: null,
        visible: false,
        changes: false,
        loading: false,
        deleteAll: false,
        exeLoading: false,
        exeLoading1: false,
        delValues: [],
        opens: false,
        testData: null
    };
    const [state, dispatch] = useReducer(UtilsReducer, initialState);

    const setLoading = () => {
        dispatch({ type: SET_LOADING });
    };
    const setExeLoading = () => {
        dispatch({ type: SET_LOADING_EXE });
    };

    const setExeLoading1 = () => {
        dispatch({ type: SET_LOADING_EXE1 });
    };

    const removeExeLoading = () => {
        dispatch({ type: REMOVE_LOADING_EXE });
    };

    const removeExeLoading1 = () => {
        dispatch({ type: REMOVE_LOADING_EXE1 });
    };

    const removeLoading = () => {
        dispatch({ type: REMOVE_LOADING });
    };

    const setOpens = (data) => {
        dispatch({ type: SET_OPENS, payload: data });
    };

    const setErrorData = (data) => {
        dispatch({ type: SET_ERROR, payload: data });
    };

    const setEdit = (data) => {
        dispatch({ type: SET_EDIT, payload: data });
    };

    const setDelete = (data) => {
        dispatch({ type: SET_DELETE, payload: data });
    };

    const setDeleteAll = (data) => {
        dispatch({ type: SET_DELETE_ALL, payload: data });
    };

    const setDelValues = (data) => {
        dispatch({ type: SET_DEL_VALUES, payload: data });
    };

    const setVisible = (data) => {
        dispatch({ type: SET_VISIBLE, payload: data });
    };

    const setChanges = (data) => {
        dispatch({ type: SET_CHANGES, payload: data });
    };

    const setTestData = (data) => {
        dispatch({ type: SET_TESTDATA, payload: data });
    };

    const showAlert = (data) => {
        dispatch({
            type: SET_ALERT,
            payload: data
        });
        setTimeout(() => {
            dispatch({
                type: REMOVE_ALERT,
            });
        }, data.type === "success" ? 2500 : 12000);
    };

    const setSnack = (data) => {
        dispatch({
            type: SET_SNACK,
            payload: data
        });
        setTimeout(() => {
            dispatch({
                type: REMOVE_SNACK,
            });
        }, 4000);
    };

    const removeSnack = () => {
        dispatch({ type: REMOVE_SNACK });
    };

    const removeAlert = () => {
        dispatch({ type: REMOVE_ALERT });
    };

    return (
        <UtilsContext.Provider
            value={{
                edit: state.edit,
                opens: state.opens,
                snack: state.snack,
                alert: state.alert,
                loading: state.loading,
                visible: state.visible,
                deletes: state.deletes,
                changes: state.changes,
                testData: state.testData,
                errorData: state.errorData,
                deleteAll: state.deleteAll,
                delValues: state.delValues,
                exeLoading: state.exeLoading,
                exeLoading1: state.exeLoading1,
                setEdit,
                setOpens,
                setSnack,
                showAlert,
                setDelete,
                setVisible,
                setChanges,
                setLoading,
                removeSnack,
                removeAlert,
                setErrorData,
                setDelValues,
                setDeleteAll,
                setTestData,
                setExeLoading,
                removeLoading,
                setExeLoading1,
                removeExeLoading,
                removeExeLoading1
            }}
        >
            {props.children}
        </UtilsContext.Provider>
    );
}

export default UtilsState

UtilsState.propTypes = {
    children: PropTypes.node
}
