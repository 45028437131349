export const Messages = [
  {
    ExpiryType: "NoLicense",
    Header: "No License",
    Message:
      "You don't have a License, Please contact Kairos technologies for License",
  },
  {
    ExpiryType: "LicenseExpired",
    Header: "License Expired",
    Message:
      "Your License has expired, Please contact Kairos technologies for License subscription / Renewal",
  },
  {
    ExpiryType: "VolumeLimitExceeded",
    Header: "Volume Limit Exceeded",
    Message:
      "Your License cannot support this volume of data, Please contact Kairos technologies for License upgrade",
  },
];

export const interpolationTechniques = [
  {
    label: "Linear Interpolation",
    value: "Linear",
  },
  {
    label: "Polynomial Interpolation",
    value: "Polynomial",
  },
  { label: "Radial Basis", value: "Radial Basis" },
  { label: "Cubic Spline", value: "Cubic Spline" },
  { label: "Default", value: "Default" },
];

export const connectionTypes = [
  "SQL",
  "Oracle",
  "My SQL",
  "SAP HANA",
  "PostgreSQL",
  "Snowflake",
  "Azure SQL",
  "Databricks",
];

export const AuthenticationTypes = [
  {
    value: "SQL Server Authentication",
    label: "Database Native",
  },
  // {
  //   value: "Active Directory - Integrated",
  //   label: "Active Directory - Integrated",
  // },
];

export const ScheduleFrequency = {
  1: "Minute(s)",
  2: "Day(s)",
  3: "Week(s)",
  4: "Month(s)",
};

export const LoginRedirectUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL;

export const ServerBaseURLENV = process.env.REACT_APP_SERVER_BASE_URL;

export const LicenseManagementBaseURLENV =
  process.env.REACT_APP_LICENSE_MANAGEMENT_BASE_URL_ENV;

export const AuthBaseURLENV = process.env.REACT_APP_AUTH_BASE_URL_ENV;

export const DQGEngineURLENV = process.env.REACT_APP_DQG_ENGINE_URL_ENV;

export const KeyManagerURLENV = process.env.REACT_APP_KEY_MANAGER_URL_ENV;

export const ChatBotAPI = `${DQGEngineURLENV}/api/Copilot/AskQuery`;
export const azureLogin = false;
export const azureAppInsights = false;
export const lastActivity = "APITELastActivity";
export const userLocalSession = "APITEUserData";
export const ProductCode = "APITE";
export const idealTimeout = 1000 * 60 * 45;