
export const headCells = [
    {
      id: 1,
      label: "Name"
    },
    {
      id: 2,
      label: "Repeated",
    },
    {
      id: 3,
      label: "start Date"
    },
    {
      id: 4,
      label: "End Date"
    },
    {
      id: 5,
      label: "Repeat Interval",
    },
    {
      id: 6,
      label: "Frequency"
    },
    {
      id: 7,
      label: "AUT",
    },
    {
      id: 8,
      label: "API Suite",
    },
    {
      id: 9,
      label: "Configs."
    },
    {
      id: 10,
      label: "Created At",
    },
    {
      id: 11,
      label: "Last Executed At",
    },
    {
      id: 12,
      label: "Next Execution At",
    },
    {
      id: 13,
      label: "Manage",
    },
  ];
  
  
  export const LoadTestCells = [
    {
      id: 1,
      label: "AUT",
    },
    {
      id: 2,
      label: "Test Suite",
    },
    {
      id: 3,
      label: "Endpoint",
    },
    {
      id: 4,
      label: "Virtual User(s)",
    },
    {
      id: 5,
      label: "Loop Count",
    },
    {
      id: 6,
      label: "Ramp up Period(sec)",
    },
    {
      id: 7,
      label: "View Results",
    },
  ];