/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import {
  Box, Button,
  Grid,
  MenuItem,
  TextField, Typography, Tabs, Tab,
} from "@mui/material";

import ResModal from "../Utils/ResModal";

export function FormTabs({ label, testSuiteData, settestSuiteData, onChangeHandler }) {
  const { register, formState: { errors } } = useForm();

  const [apiUrl, setApiUrl] = useState("");
  const [token, setToken] = useState("");
  const [apiToken, setApiToken] = useState({
    key: "",
    value: ""
  });
  const [response, setResponse] = useState(null);
  const [status, setStatus] = useState(null);
  const [tabvalue, setTabvalue] = useState("one");

  const handleChangeTab = (event, newValue) => {
    setTabvalue(newValue);
  };

  const apiHeader = {
    [apiToken?.key?.trim()]: apiToken?.value?.trim(),
  };

  const bearerHeader = {
    "Authorization": `Bearer ${token}`,
  };

  const generateToken = async () => {
    const authType = testSuiteData.authenticationType === "none" ? {} : (testSuiteData.authenticationType === "bearerToken" ? bearerHeader :
      (apiToken?.key.trim() && apiHeader))
    try {
      const { data } = await axios({
        method: "get",
        url: apiUrl,
        headers: {
          ...authType,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        }
      })
      setResponse(data)
      setStatus("success")
    } catch (err) {
      setResponse(err);
      setStatus("error");
    }
  };
  return (
    <Box className="formTabs">

      <Box className='fullBox '>
        <Tabs value={tabvalue} onChange={handleChangeTab}>
          <Tab value="one" label={label.tab1} />
          <Tab value="two" label={label.tab2} />
        </Tabs>
      </Box>

      {tabvalue === "one" && (
        <Box className="authFields">
          <Grid item sm={4} mb={1}>
            <TextField
              select
              autoComplete='off'
              fullWidth size="small"
              name="authenticationType"
              label="Select Auth.Type"
              value={testSuiteData.authenticationType}
              {...register("authenticationType", {
                onChange: e => onChangeHandler(e)
              })}
              error={Boolean(errors?.authenticationType)}
              helperText={errors?.authenticationType?.message}
            >
              <MenuItem key="none" value="none">
                none
              </MenuItem>
              <MenuItem key="apiToken" value="apiToken">
                API Key
              </MenuItem>
              <MenuItem key="bearerToken" value="bearerToken">
                Bearer Token
              </MenuItem>
              <MenuItem key="basicAuth" value="basicAuth">
                Basic Auth
              </MenuItem>
            </TextField>
          </Grid>


          <Grid item sm={12}>
            {/* API Key */}
            {testSuiteData.authenticationType === "apiToken" &&
              <div >
                <TextField
                  fullWidth
                  label="Key"
                  autoComplete='off'
                  variant="outlined"
                  size="small"
                  value={testSuiteData.apiToken?.key}
                  onChange={(e) => settestSuiteData({
                    ...testSuiteData, apiToken: {
                      ...testSuiteData.apiToken, key: e.target.value
                    }
                  })}
                // error={!testSuiteData.apiToken.key ? true : false}
                // helperText={!testSuiteData.apiToken.key && "Key cannot be empty"}
                />
                <TextField
                  style={{ marginTop: "8px" }}
                  className="tsAuthForm"
                  fullWidth
                  label="Value"
                  minRows={3}
                  maxRows={6}
                  multiline
                  autoComplete='off'
                  variant="outlined"
                  size="small"
                  value={testSuiteData.apiToken?.value}
                  onChange={(e) => settestSuiteData({
                    ...testSuiteData, apiToken: {
                      ...testSuiteData.apiToken, value: e.target.value
                    }
                  })}
                />
                <Typography variant="body1" className="testSuiteNote">
                  <b>Note: </b>The API key will be automatically added to Request Header.
                </Typography>
              </div>
            }

            {/* Bearer Token */}
            {testSuiteData?.authenticationType === "bearerToken" &&
              <div>
                <TextField
                  fullWidth
                  autoComplete='off'
                  variant="outlined"
                  size="small"
                  name="bearerToken"
                  label="Token"
                  minRows={3}
                  maxRows={4}
                  multiline
                  value={testSuiteData?.bearerToken}
                  onChange={onChangeHandler}
                />
                <Typography variant="body1" className="testSuiteNote">
                  <b>Note: </b>The Bearer Token will be automatically added to Request Header.
                </Typography>
              </div>
            }

            {/* Basic Auth */}
            {testSuiteData.authenticationType === "basicAuth" &&
              <div>
                <TextField
                  fullWidth
                  label="Username"
                  autoComplete='off'
                  variant="outlined"
                  size="small"
                  value={testSuiteData.basicAuth?.username}
                  onChange={(e) => settestSuiteData({
                    ...testSuiteData, basicAuth: {
                      ...testSuiteData.basicAuth, username: e.target.value
                    }
                  })}
                />
                <TextField
                  fullWidth
                  size="small"
                  type="password"
                  label="Password"
                  autoComplete='off'
                  variant="outlined"
                  className="tsAuthForm"
                  value={testSuiteData.basicAuth?.password}
                  onChange={(e) => settestSuiteData({
                    ...testSuiteData, basicAuth: {
                      ...testSuiteData.basicAuth, password: e.target.value
                    }
                  })}
                />
                <Typography variant="body1" className="testSuiteNote">
                  <b>Note: </b>The Basic Auth will be automatically added to Request Header.
                </Typography>
              </div>
            }
          </Grid>
        </Box>
      )}
      {tabvalue === "two" && (
        <>
          <Box className="authFields">
            <Typography>
              <b>{label.tab2}:</b>
            </Typography>

            <Grid item sm={12}>
              <Grid container spacing={1}>
                {/* <Grid md={2.2} item>
                  <Select
                    required
                    value={method}
                    onChange={(e) => setMethod(e.target.value)}
                    fullWidth
                    size="small"
                  >
                    <MenuItem value="get">
                      {resource.CRUD[0]}
                    </MenuItem>
                    <MenuItem value="post">
                      {resource.CRUD[1]}
                    </MenuItem>
                  </Select>
                </Grid> */}

                <Grid sm={9.8} item>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    label="API Url"
                    value={apiUrl}
                    autoComplete='off'
                    onChange={(e) => setApiUrl(e.target.value)}

                  />
                </Grid>
                <Grid sm={1} item>
                  <Button variant="contained" size="small" onClick={generateToken}
                    sx={{
                      backgroundColor: "#611EB6",
                      "&:hover": {
                        backgroundColor: "#611EB6",
                      },
                    }}>
                    Generate
                  </Button>
                </Grid>

                <Grid item sm={4} mt={1}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    autoComplete='off'
                    name="authenticationType"
                    label="Select Auth. Type"
                    value={testSuiteData.authenticationType}
                    onChange={onChangeHandler}

                  >
                    <MenuItem key="none" value="none">
                      none
                    </MenuItem>
                    <MenuItem key="apiToken" value="apiToken">
                      API Key
                    </MenuItem>
                    <MenuItem key="bearerToken" value="bearerToken">
                      Bearer Token
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item sm={8} mt={1}>
                  {/* API Key */}
                  {testSuiteData.authenticationType === "apiToken" &&
                    <div >
                      <TextField
                        fullWidth
                        label="Key"
                        size="small"
                        autoComplete='off'
                        variant="outlined"
                        value={apiToken.key}
                        onChange={(e) => setApiToken({
                          ...apiToken, key: e.target.value
                        })}
                      />
                      <TextField
                        fullWidth
                        size="small"
                        autoComplete='off'
                        variant="outlined"
                        style={{ marginTop: "8px" }}
                        className="tsAuthForm"
                        label="Value"
                        multiline
                        minRows={3}
                        maxRows={6}
                        value={apiToken.value}
                        onChange={(e) => setApiToken({
                          ...apiToken, value: e.target.value
                        })}
                      />
                      <Typography variant="body1" className="testSuiteNote">
                        <b>Note: </b>The API key will be automatically added to Request Header.
                      </Typography>
                    </div>
                  }

                  {/* Bearer Token */}
                  {testSuiteData.authenticationType === "bearerToken" &&
                    <div>
                      <TextField
                        fullWidth
                        size="small"
                        label="Token"
                        autoComplete='off'
                        variant="outlined"
                        name="bearerToken"
                        minRows={5}
                        maxRows={6}
                        multiline
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                      />
                      <Typography variant="body1" className="testSuiteNote">
                        <b>Note: </b>The Bearer Token will be automatically added to Request Header.
                      </Typography>
                    </div>
                  }
                </Grid>

              </Grid>

            </Grid>
          </Box>

          <Box className='authFields'>
            <Typography>
              <b>{label.tab1}:</b>
            </Typography>
            <Grid sm={12} item className="tsAuthForm">
              <TextField
                fullWidth
                size="small"
                label="Token"
                autoComplete='off'
                variant="outlined"
                name="bearerToken"
                value={testSuiteData?.bearerToken}
                onChange={onChangeHandler}
                minRows={3}
                maxRows={4}
                multiline
              />
            </Grid>
          </Box>

          {status &&
            <ResModal
              status={status}
              response={response}
              setStatus={setStatus}
            />
          }
        </>
      )}
    </Box>
  );
}
