import { useContext, useEffect } from "react"
import { PropTypes } from "prop-types"
import { Box, IconButton, Modal } from "@mui/material"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined.js"

import TestResultContext from "contexts/TestResult/TestResultContext"
import SingleExeResult from "components/TSExecution/ExeResult/SingleExeResult"

const style = {
    p: 3.5,
    top: "50%",
    left: "50%",
    width: "70%",
    height: "90%",
    bgcolor: "#f4f1ff",
    overflowY: "scroll",
    position: "absolute",
    transform: "translate(-50%, -50%)",
}

export default function APISuiteResult({ executes, setExecute }) {

    const testResultContext = useContext(TestResultContext)
    const { getExeResultsTs } = testResultContext

    useEffect(() => {
        if (executes.exe)
            getExeResultsTs(executes.exe)
    }, [])

    return (
        <Modal
            open={Boolean(executes)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">

            <Box sx={style} className="scrollbarStyle">
                <IconButton
                    size="small"
                    color="error"
                    sx={{
                        position: "sticky",
                        top: 0,
                        left: 2000,
                        margin: "-22px",
                        zIndex: 1,
                    }}
                    role="presentation"
                    onClick={() => setExecute(null)}
                >
                    <CancelOutlinedIcon />
                </IconButton>
                <SingleExeResult
                    ids={executes?.exe?._id}
                    oneTime={true}
                />
            </Box>
        </Modal>
    )
}

APISuiteResult.propTypes = {
    executes: PropTypes.string,
    setExecute: PropTypes.func
}
