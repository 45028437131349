import { useContext } from "react"
import { PropTypes } from "prop-types"
import { Box, Checkbox, Grid, Typography, Tooltip } from "@mui/material"

import { checkDuration } from "hooks";
import { resource } from "data/resource";
import UtilsContext from "contexts/Utils/UtilsContext";

function Tile({ obj, type, clickHandler }) {
  const utilsContext = useContext(UtilsContext);
  const { delValues, setDelValues } = utilsContext;

  return (
    <Box className="DBList">
      <Grid container alignItems="center" item>
        <Grid md={12} item>
          <Box className="DBLeft">
            <Box display="flex" alignItems="center" mb={1}>
              {((type === "T" && obj?._id?.length > 14) || type === "AUT" || type === "TS") && (
                <Checkbox
                  checked={
                    type === "T"
                      ? obj?.isSelected
                      : delValues?.findIndex(ele => (ele === obj._id || ele === obj.key)) !== -1
                  }
                  disabled={!obj?._id?.length > 14}
                  value={
                    type === "T"
                      ? obj?.isSelected
                      : delValues?.findIndex(ele => (ele === obj._id || ele === obj.key)) !== -1
                  }
                  onClick={
                    type === "T"
                      ? clickHandler
                      : (event) => {
                        event.stopPropagation();
                        if (event.target.checked) { setDelValues([...delValues, obj._id]); }
                        else {
                          const index = delValues.findIndex(ele => (ele === obj._id || ele === obj.key))
                          delValues.splice(index, 1)
                          setDelValues([...delValues])
                        }
                      }
                  }
                />
              )}
              {type === "AUT" &&
                <>
                  <Typography className="privacy" mb={0}>
                    {obj.isPublic ? resource.AUT.PUBC : resource.AUT.PRIV}
                  </Typography>
                  {obj?.isImported &&
                    <Typography className="imported" sx={{ ml: 1 }}>
                      Imported
                    </Typography>
                  }
                </>
              }
              <Typography className="timeTT">
                <strong>
                  {obj.key ? "0 days ago" : checkDuration(obj?.createdAt)}</strong>
              </Typography>
            </Box>
            <Typography align="justify" variant="body2">
              <strong>{resource.AUT.HEAD.NAME}: </strong> {obj?.name}
            </Typography>
            <Tooltip title={obj?.description} placement={"top"} arrow>
              <Typography align="left" variant="body2">
                <strong>{resource.AUT.HEAD.DESC}: </strong>
                {obj?.description}
              </Typography>
            </Tooltip>
            {type === "AUT" ? (
              <Typography align="justify" variant="body2">
                <strong>{resource.AUT.HEAD.COUNT}: </strong>
                {obj?.noTestSuites}
              </Typography>
            ) : (
              type === "TS" && (
                <>
                  <Typography align="left" variant="body2">
                    <strong>{resource.TS.HEAD.URL}: </strong>
                    {obj?.baseURL}
                  </Typography>
                  <Typography align="justify" variant="body2">
                    <strong>{resource.TS.HEAD.AUTH}: </strong>
                    {obj?.authenticationType}
                  </Typography>
                  <Typography align="justify" variant="body2" mb={2}>
                    <strong>{resource.TS.HEAD.COUNT}: </strong>
                    {obj?.noEndpoints}
                  </Typography>
                </>
              )
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Tile;

Tile.propTypes = {
  obj: PropTypes.object,
  type: PropTypes.string,
  clickHandler: PropTypes.func
}