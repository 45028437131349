/* eslint-disable no-undef */

// import axios from "_helpers/axios"
import axios from "axios"
import { AuthBaseURLENV } from "_helpers/Constant";
class ApiService {
  // constructor() {
  //   JSON.parse(localStorage.getItem("APITEUserData")) = this.retrieveToken();
  // }

  // retrieveToken() {
  //   const tokenString = localStorage.getItem("APITEUserData");
  //   if (!tokenString) {
  //     // Handle case where token is not present in localStorage
  //     return null; // or return a default value as needed
  //   }
  //   try {
  //     return JSON.parse(tokenString);
  //   } catch (error) {
  //     console.error("Error parsing token from localStorage:", error);
  //     return null; // Handle parse error gracefully
  //   }
  // }

  search = (data) => {
    return axios.get(`/api/v1/aut/search?search=${data}`);
  };

  fetchAut = (id) => {
    return axios.get(`/api/v1/aut/?id=${id}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  fetchAuts = () => {
    return axios.get(
      `/api/v1/aut/all/?userId=${JSON.parse(localStorage.getItem("APITEUserData")).UserId}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  createAut = (data) => {
    return axios.post("/api/v1/aut", data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  updateAut = (id, data) => {
    return axios.patch(`/api/v1/aut?id=${id}`, data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  deleteAut = (data) => {
    return axios.delete("/api/v1/aut/",
      {
        headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` },
        data: { ids: data }
      })
  }

  exportAut = (data) => {
    return axios.get(`/api/v1/aut/export?id=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  importAut = (type, data) => {
    const fd = new FormData();
    fd.append("file", data);
    return axios.post(`/api/v1/aut/import?importType=${type}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=---XYZ",
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}`
      },
    });
  };

  importEnv = (data) => {
    const fd = new FormData();
    fd.append("file", data.file);
    fd.append("autId", data.selectedAut);
    return axios.post("/api/v1/variable/environment", fd, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=---XYZ",
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}`
      },
    });
  };

  uploadFile = (id, file) => {
    const fd = new FormData()
    fd.append("file", file)
    return axios.put(`/api/v1/endpoint/response?id=${id}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=---XYZ",
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}`
      },
    });
  };

  uploadFile2 = (id, data, type, obj, text) => {
    const fd = new FormData()
    fd.append("file", data)
    return axios.put(`/api/v1/endpoint/response?id=${id}`, type === "json" ? obj : text, {
      headers: {
        "Content-Type": type === "json" ? "application/json" : "application/xml",
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}`
      },
    })
  }

  uploadFile3 = (file) => {
    const fd = new FormData()
    fd.append("file", file)
    return axios.post("/api/v1/endpoint/testdata", fd, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=---XYZ",
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}`
      },
    });
  };

  fetchTs = (data) => {
    return axios.get(`/api/v1/test-suite/?id=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  fetchTss = (data) => {
    return axios.get(`/api/v1/test-suite/all?autId=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  createTs = (data) => {
    return axios.post("/api/v1/test-suite", data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  updateTs = (id, data) => {
    return axios.patch(`/api/v1/test-suite?id=${id}`, data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  deleteTs = (data) => {
    return axios.delete("/api/v1/test-suite/",
      {
        headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` },
        data: { ids: data }
      })
  }

  fetchTest = (data) => {
    return axios.get(`/api/v1/test-case/?id=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  fetchTests = (data) => {
    return axios.get(`/api/v1/test-case/all/?endpointId=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  updateTest = (id, data) => {
    return axios.patch(`/api/v1/test-case/?id=${id}`, data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  createTest = (data) => {
    return axios.post("/api/v1/test-case", data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  deleteTest = (data, id) => {
    return axios.delete(`/api/v1/test-case?testSuiteId=${id}`,
      {
        headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` },
        data: { ids: data }
      })
  };

  fetchEndpoint = (data) => {
    return axios.get(`/api/v1/endpoint/?id=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  fetchEndpoints = (data) => {
    return axios.get(`/api/v1/endpoint/all?testSuiteId=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  createEndpoint = (data) => {
    return axios.post("/api/v1/endpoint", data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  updateEndpoint = (id, data) => {
    return axios.patch(`/api/v1/endpoint?id=${id}`, data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  updateBulkEndpoint = (data) => {
    return axios.post("/api/v1/endpoint/update", { updates: data },
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  }

  deleteEndpoint = (data) => {
    return axios.delete("/api/v1/endpoint/",
      {
        headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` },
        data: { ids: data }
      })
  }

  deleteEndpoint2 = (data) => {
    return axios.delete(`/api/v1/endpoint?id=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  fetchEnv = (id, env) => {
    return axios.get(
      `/api/v1/variable/environment/?autId=${id}&environment=${env}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  fetchEnvs = (data) => {
    return axios.get(
      `/api/v1/variable/environment/all?autId=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  fetchVar = (data) => {
    return axios.get(`/api/v1/variable/?id=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  fetchVars = () => {
    return axios.get(
      `/api/v1/variable/all?userId=${JSON.parse(localStorage.getItem("APITEUserData")).UserId}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  fetchGlobalVars = () => {
    return axios.get(
      `/api/v1/variable/global/?userId=${JSON.parse(localStorage.getItem("APITEUserData")).UserId}&organizationId=${JSON.parse(localStorage.getItem("APITEUserData")).OrganisationId}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  fetchAutVars = (data) => {
    return axios.get(`/api/v1/variable/aut/?autId=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  fetchTsVars = (data) => {
    return axios.get(
      `/api/v1/variable/test-suite/?testSuiteId=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  createVar = (data) => {
    return axios.post("/api/v1/variable", data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  updateVar = (id, data) => {
    return axios.patch(`/api/v1/variable?id=${id}`, data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  createAndUpdateVar = (data) => {
    return axios.post("/api/v1/variable/endpoint", data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  }

  deleteVar = (data) => {
    return axios.delete("/api/v1/variable/",
      {
        headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` },
        data: { ids: data }
      })
  }

  deleteVar2 = (data) => {
    return axios.delete(`/api/v1/variable/?id=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  fetchConfig = (data) => {
    return axios.get(`/api/v1/configuration?id=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  fetchConfigs = (data) => {
    return axios.get(
      `/api/v1/configuration/all?testSuiteId=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  fetchAllConfigs = (data) => {
    return axios.get(`/api/v1/configuration/all/aut?autId=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  deleteConfig = (data) => {
    return axios.delete("/api/v1/configuration/",
      {
        headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` },
        data: { ids: data }
      }
    )
  }

  deleteConfig2 = (data) => {
    return axios.delete(`/api/v1/configuration?id=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  runConfig = (runNow, data) => {
    return axios.post(`/api/v1/configuration?runNow=${runNow}`, data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  runConfigs = (data) => {
    return axios.post("/api/v1/configuration/execute", { configurationIds: data },
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  }

  fetchDB = () => {
    return axios.get("/api/v1/dashboard",
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  sendRequest = (requestData, envName) => {
    return axios.post(
      `/api/v1/endpoint/execute2?userId=${JSON.parse(localStorage.getItem("APITEUserData")).UserId}&organizationId=${JSON.parse(localStorage.getItem("APITEUserData")).OrganisationId}&environment=${envName}`,
      requestData,
      {
        headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` },
        timeout: 60000
      }
    );
  };

  sendRequestTD = (requestData, envName) => {
    return axios.post(
      `/api/v1/endpoint/execute4?userId=${JSON.parse(localStorage.getItem("APITEUserData")).UserId}&organizationId=${JSON.parse(localStorage.getItem("APITEUserData")).OrganisationId}&environment=${envName}`,
      requestData,
      {
        headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` },
        timeout: 60000
      }
    );
  };

  endpointExecutions = (data) => {
    return axios.get(
      `/api/v1/execution/endpoint?endpointId=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  endpointTestResults = (data) => {
    return axios.get(
      `/api/v1/test-result/endpoint?executionId=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  tsExecutions = (data) => {
    return axios.get(
      `/api/v1/execution/configuration?configurationId=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  tsTestResults = (data) => {
    return axios.get(
      `/api/v1/test-result/execution?executionId=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  createSchedule = (data) => {
    return axios.post("/api/v1/schedulers", data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  updateSchedule = (data) => {
    return axios.put("/api/v1/schedulers", data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  fetchSchedule = (id) => {
    return axios.get(`/api/v1/schedulers/${id}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    );
  };

  fetchSchedules = () => {
    return axios.get("/api/v1/schedulers",
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  deleteSchedule = (data) => {
    return axios.delete("/api/v1/schedulers",
      {
        headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` },
        data: { ids: data }
      }
    );
  };

  generateUnique = (data) => {
    return axios.post("/api/v1/endpoint/load", data,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  loadResult = (data) => {
    return axios.get(`/api/v1/endpoint/load/result?uuid=${data}`,
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  };

  loadResults = () => {
    return axios.get("/api/v1/endpoint/load/results",
      { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("APITEUserData")).Token}` } }
    )
  }

  createValidation(data) {
    data.UserId = UserDetails?.UserId;
    data.OrganisationId = UserDetails?.OrganisationId;
    data.UserEmail = `${UserDetails?.FirstName} ${UserDetails?.LastName}`;
    return axios.post(`${ServerBaseURL}/validations/create`, data);
  }

  RefreshToken(data) {
    return axios.post(`${AuthBaseURLENV}/api/Login/V1/RefreshToken`, data);
  }
}

export default new ApiService()
