import { useState, Fragment } from "react"
import { PropTypes } from "prop-types"
import { styled } from "@mui/material/styles"
import {
  Box, Button,
  Chip,
  Drawer,
  IconButton,
  Typography, TableContainer, TableCell, TableHead, TableBody, TableRow, Table, tableCellClasses
} from "@mui/material"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"

import { resource } from "data/resource";
import TestSuitePreview from "./TestSuitePreview";


const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#d0c2ff",
    border: "1px solid #d0c2ff",
    color: "#54577d",
    fontWeight: 550,
    padding: "7px",
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12.2,
    padding: "8px",
    color: "#54577d",
    border: 0,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f2fa",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ede8ff",
  }
}));
const PreviewAuts = ({ handleclose, color, name, obj }) => {
  const [showPreviewTestSuite, setShowPreviewTestSuite] = useState(null);

  const toggleDrawer = (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) { return; }
    handleclose();
  };

  return (
    <Drawer anchor="right" open={true} onClose={toggleDrawer}>
      <Box sx={{ width: "32.5vw", color: "#54577d" }}>
        <IconButton
          sx={{ position: "absolute", top: "2px", right: "2px" }}
          size="small"
          color="error"
          onClick={handleclose}
        >
          <HighlightOffIcon />
        </IconButton>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 4 }}>
          <Typography variant="h6">
            <Box component="span" fontWeight={"normal"}>
              {resource.AUT.NAME}{" "}
            </Box>{" "}
            : {obj.name} &nbsp;&nbsp;|
          </Typography>
          <Typography variant="h6" sx={{ flex: 1 }}>
            <Box component="span" fontWeight={"normal"}>
              {resource.AUT.HEAD.COUNT}{" "}
            </Box>{" "}
            : {obj.testSuites.length}
          </Typography>

          <Button
            variant="contained"
            sx={{ py: 0.3, borderRadius: "40px", px: 3 }}
            color={color}>
            {name}
          </Button>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">{resource.TS.NAME}</StyledTableCell>
                <StyledTableCell align="center">{resource.EXES.CONS}</StyledTableCell>
                <StyledTableCell align="center">Pass %</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {obj.testSuites.map(row => (
                <Fragment key={row?.testSuite?._id ?? row._id}>
                  <StyledTableRow >
                    <StyledTableCell align="center" onClick={() => setShowPreviewTestSuite(row?.testSuite?._id ?? row._id)}>
                      <Typography color="primary" sx={{ cursor: "pointer" }}>
                        {row?.testSuite?.name ?? row.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.configurations.length}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Chip
                        size="small"
                        color={color}
                        variant="outlined"
                        sx={{ background: name === "poor" ? "rgba(253, 5, 5, 0.13)" : (name === "good" ? "rgba(63, 244, 6, 0.16)" : "rgba(244, 87, 6, 0.16)") }}
                        label={row?.passPercentage ? `${Number.isInteger(row.passPercentage) ? row.passPercentage : row?.passPercentage?.toFixed(1)}%` : "0%"}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                  {(showPreviewTestSuite === row?.testSuite?._id ?? row._id) && <TestSuitePreview
                    obj={obj}
                    tsObj={row}
                    setShowPreviewTestSuite={setShowPreviewTestSuite}
                  />}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Drawer>
  );
};

export default PreviewAuts;

PreviewAuts.propTypes = {
  handleclose: PropTypes.func,
  color: PropTypes.name,
  name: PropTypes.name,
  obj: PropTypes.object
}