import { useContext, useEffect } from "react";
import {
    Alert, Box,
    Grid,
    Snackbar,
    Typography,
} from "@mui/material"

import TableLoad from "./Table"
import { LoadTestCells } from "data/scheduler"
import PopUpCreate from "components/Utils/PopUpCreate"
import InnerHeader from "components/Utils/InnerHeader"

import UtilsContext from "contexts/Utils/UtilsContext"
import ScheduleContext from "contexts/Schedule/ScheduleContext";

export default function LoadTest() {
    const utilsContext = useContext(UtilsContext)
    const scheduleContext = useContext(ScheduleContext)

    const { getLoadResults } = scheduleContext
    const { alert, edit, curr, envName, delValues, deleteAll,
        removeAlert, setEdit, setCurr, setEnvName, setDelValues, setDeleteAll, } = utilsContext

    useEffect(() => {
        edit && setEdit(null)
        curr && setCurr(null)
        envName && setEnvName(null)
        deleteAll & setDeleteAll(false)
        delValues.length && setDelValues([])
        getLoadResults()
    }, [])


    return (
        <Box sx={{ width: "100%" }}>
            {alert !== null && (
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={true}
                    className="snackbar"
                >
                    <Alert severity={alert.type} onClose={() => removeAlert()}>{alert.msg}</Alert>
                </Snackbar>
            )}

            <InnerHeader>
                <Grid container alignItems="center">
                    <Grid md={6} item>
                        <Typography variant="h6">Load Testing</Typography>
                    </Grid>
                    <Grid md={6} item >
                        <Box className="tsHeader">
                            <Box display="flex" alignItems="right" justifyContent="right" gap={1}>
                                <PopUpCreate name={"Load Test"} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </InnerHeader>

            <Box pt={7}>
                <TableLoad
                    headCells={LoadTestCells}
                />
            </Box>
        </Box>
    );
}
