import { styled } from "@mui/material/styles"
import { PropTypes } from "prop-types"
import { Chip, Typography, TableContainer, TableCell, TableHead, TableBody, TableRow, Table, tableCellClasses } from "@mui/material";
import { resource } from "data/resource"


const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#d0c2ff",
    border: "1px solid #d0c2ff",
    color: "#54577d",
    fontWeight: 550,
    padding: "2px",
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12.2,
    padding: "6px",
    color: "#54577d",
    border: 0,
    borderBottom: "1px solid #e6e6e6"
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: "#f6f2fa",
}))

export default function PreviewExecutions({ obj }) {

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="center">{resource.TR.EX_TIME}</StyledTableCell>
            <StyledTableCell align="center">Duration (ms)</StyledTableCell>
            <StyledTableCell align="center">{resource.AUT.HEAD.STATUS}</StyledTableCell>
            <StyledTableCell align="center">{resource.TR.PASS}</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {obj.executions.map((row, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell align="center">
                <Typography>{new Date(row.execution.createdAt).toLocaleString()}</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">{row.execution?.duration}ms</StyledTableCell>
              <StyledTableCell align="center">{row.execution.status}</StyledTableCell>
              <StyledTableCell align="center">
                <Chip
                  variant="outlined"
                  size="small"
                  sx={{ background: row.passPercentage < 50 ? "rgba(253, 5, 5, 0.13)" : (row.passPercentage >= 70 ? "rgba(63, 244, 6, 0.16)" : "rgba(244, 87, 6, 0.16)") }}
                  color={row.passPercentage < 50 ? "error" : (row.passPercentage >= 70 ? "success" : "warning")}
                  label={`${Number.isInteger(row.passPercentage) ? row.passPercentage : row.passPercentage.toFixed(1)}%`}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

PreviewExecutions.propTypes = {
  obj: PropTypes.object
}