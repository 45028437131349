import { useContext, useEffect, useState } from "react"
import { PropTypes } from "prop-types"
import { useForm } from "react-hook-form"
import {
    Box, Button,
    CircularProgress,
    Grid,
    MenuItem,
    TextField, Typography,
} from "@mui/material"

import { resource } from "data/resource"
import ApiService from "services/app.service"
import AutContext from "contexts/Aut/AutContext"
import APIsContext from "contexts/APIs/APIsContext"
import VarContext from "contexts/Variables/VarContext"
import UtilsContext from "contexts/Utils/UtilsContext"
import ScheduleContext from "contexts/Schedule/ScheduleContext"
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext"

export default function LoadTestForm({ retVal }) {
    const { register, handleSubmit, formState: { errors } } = useForm()

    const autContext = useContext(AutContext)
    const varContext = useContext(VarContext)
    const apisContext = useContext(APIsContext)
    const utilsContext = useContext(UtilsContext)
    const scheduleContext = useContext(ScheduleContext)
    const testSuiteContext = useContext(TestSuiteContext)

    const { auts, getAuts } = autContext
    const { getLoadResults } = scheduleContext
    const { reqs, getReqs, setReqs } = apisContext
    const { getEnvNames, setEnvNames, envNames } = varContext
    const { getTestSuites, testSuites, setTestSuites } = testSuiteContext
    const { exeLoading, setExeLoading, removeExeLoading, showAlert } = utilsContext

    const [loadData, setloadData] = useState({
        name: "",
        tsId: "",
        autId: "",
        endpointId: "",
        environment: "",
        noOfUsers: 20,
        rampUpPeriod: 10,
        loopCount: 2,
    })

    useEffect(() => {
        if (!auts.length) { getAuts() }
    }, [])

    const onChangeHandler = (e) => {
        setloadData({
            ...loadData,
            [e.target.name]: e.target.value,
        })
    }

    const onChangeHandler2 = (e) => {
        setloadData({
            ...loadData,
            [e.target.name]: e.target.value.split("#")[1]
        })
    }

    const submitHandler = async () => {
        try {
            setExeLoading()
            const { data } = await ApiService.generateUnique(loadData)
            showAlert({
                type: "success",
                msg: "Load execution Started and in progress.",
            })
            setTimeout(() => {
                retVal(false)
                setReqs([])
                setEnvNames([])
                setTestSuites([])
            }, resource.TIMEOUT.SUCCESS)
            let res = await ApiService.loadResult(data.uuid)
            const intervalId = setInterval(async () => {
                if (res.data.loadResult) {
                    clearInterval(intervalId)
                    removeExeLoading()
                    getLoadResults()
                    return
                }
                res = await ApiService.loadResult(data.uuid)
            }, 1000)
        } catch (err) {
            removeExeLoading()
            console.log(err.response.data)
            showAlert({
                type: "error",
                msg: err.response.data.message ? err.response.data.message : resource.ERR,
            })
        }
    }

    return (
        <Box component="form" sx={{}} noValidate autoComplete="off"
            onSubmit={handleSubmit(submitHandler)}>
            <Box>
                <Typography variant="h6" sx={{ color: "#54577d" }}> {resource.CREATE} Load Profile</Typography>
            </Box>

            <Grid item container spacing={1.5}>
                <Grid sm={12} item mt={3}>
                    <TextField
                        select
                        required
                        fullWidth
                        name="autId"
                        size="small"
                        autoComplete='off'
                        label="Select AUT"
                        // onChange={e => {
                        //     onChangeHandler2(e)
                        //     getTestSuites(e.target.value.split("#")[0])
                        //     getEnvNames(e.target.value.split("#")[0])
                        // }}
                        {...register("autId", {
                            onChange: e => {
                                onChangeHandler2(e)
                                getTestSuites(e.target.value.split("#")[0])
                                getEnvNames(e.target.value.split("#")[0])
                            },
                            required: "Select AUT"
                        })}
                        error={Boolean(errors?.autId)}
                        helperText={errors?.autId?.message}
                    >
                        {auts?.length ? auts?.map(e => <MenuItem key={e._id} value={`${e._id}#${e.name}`}>{e.name}</MenuItem>) : <MenuItem>Nothing to Display</MenuItem>}
                    </TextField>
                </Grid>
                <Grid sm={12} item >
                    <TextField
                        select
                        required
                        fullWidth
                        name="tsId"
                        size="small"
                        autoComplete='off'
                        label="Select API Suite"
                        // defaultValue={loadData?.tsId}
                        {...register("tsId", {
                            onChange: e => {
                                onChangeHandler2(e)
                                getReqs(e.target.value.split("#")[0])
                            },
                            required: "Select API Suite"
                        })}
                        error={Boolean(errors?.tsId)}
                        helperText={errors?.tsId?.message}
                    >
                        {testSuites?.testSuites?.length ? testSuites?.testSuites?.map(e => <MenuItem key={e._id} value={`${e._id}#${e.name}`}>{e.name}</MenuItem>) : <MenuItem>Nothing to Display</MenuItem>}
                    </TextField>
                </Grid>
                <Grid sm={12} item >
                    <TextField
                        select
                        required
                        fullWidth
                        size="small"
                        name="endpointId"
                        autoComplete='off'
                        label="Select API"
                        {...register("endpointId", {
                            onChange: e => {
                                setloadData({
                                    ...loadData,
                                    endpointId: e.target.value.split("#")[0],
                                    name: e.target.value.split("#")[1]
                                })
                            },
                            required: "Select API"
                        })}
                        error={Boolean(errors?.endpointId)}
                        helperText={errors?.endpointId?.message}
                    >
                        {reqs?.length ? reqs?.map(e => <MenuItem key={e._id} value={`${e._id}#${e.name}`}>{e.name}</MenuItem>) : <MenuItem>Nothing to Display</MenuItem>}
                    </TextField>
                </Grid>
                <Grid sm={12} item >
                    <TextField
                        select
                        required
                        fullWidth
                        size="small"
                        autoComplete='off'
                        name="environment"
                        label="Select Environment"
                        // value={loadData?.environment}
                        onChange={onChangeHandler}
                    >
                        {envNames?.length ? envNames.map(e => <MenuItem value={e} key={e}>{e}</MenuItem>) : <MenuItem>Nothing to Display</MenuItem>}
                    </TextField>
                </Grid>
                <Grid item sm={3} mt={1}>
                    <TextField
                        min={1}
                        size="small"
                        type="number"
                        name="noOfUsers"
                        defaultValue={loadData?.noOfUsers}
                        variant="outlined"
                        inputProps={{
                            style: {
                                min: 1,
                                max: 100,
                            },
                        }}
                        label="Virtual Users"
                        onChange={onChangeHandler}
                    />
                </Grid>
                <Grid item sm={3} mt={1}>
                    <TextField
                        min={1}
                        fullWidth
                        size="small"
                        type="number"
                        name="loopCount"
                        label="Loop Count"
                        inputProps={{
                            style: {
                                min: 1,
                                max: 100,
                            },
                        }}
                        defaultValue={loadData?.loopCount}
                        onChange={onChangeHandler} />

                </Grid>
                <Grid item sm={6} mt={1}>
                    <TextField
                        fullWidth
                        size="small"
                        type="number"
                        name="rampUpPeriod"
                        label="Ramp-Up Period (seconds)"
                        defaultValue={loadData?.rampUpPeriod}
                        onChange={onChangeHandler}
                    />
                </Grid>
            </Grid>

            <Box
                sx={{
                    mt: 2,
                    textAlign: "center",
                    "& .MuiButton-root": {
                        "&:nth-of-type(1)": {
                            mr: 1,
                        },
                    },
                }}
            >
                <Grid
                    container
                    sx={{ m: 1 }}
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={6} textAlign="left">
                        <Button
                            sx={{
                                backgroundColor: "#eb5160",
                                "&:hover": {
                                    backgroundColor: "#eb5160",
                                },
                            }}
                            variant="contained"
                            onClick={() => {
                                retVal(false)
                                setReqs([])
                                setEnvNames([])
                                setTestSuites([])
                            }}
                            color="error"
                            size="small"
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        <Button
                            type="submit"
                            disabled={exeLoading}
                            variant="contained"
                            size="small"
                            sx={{
                                mr: 1,
                                backgroundColor: "#6580e0",
                                "&:hover": {
                                    backgroundColor: "#6580e0",
                                },
                            }}
                        >
                            {exeLoading && <CircularProgress color="inherit" size={15} />}
                            {resource.CREATE}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}

LoadTestForm.propTypes = {
    retVal: PropTypes.func
}