import {
  Alert, AlertTitle,
  Box,
  LinearProgress,
  Modal,
} from "@mui/material"
import { resource } from "data/resource"

export default function DefaultConfig() {

  return (
      <Modal
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={{
              top: "50%",
              left: "50%",
              width: "50%",
              position: "absolute",
              transform: "translate(-50%, -50%)",
          }}>
              <Alert severity="info" sx={{ height: "100px", padding: "20px" }}>
                  <AlertTitle sx={{ fontWeight: "600", fontSize: "15px" }}>Execution In Progress</AlertTitle>
                  {resource.FEEDBACK.PROGRESS2}
              </Alert>
              <LinearProgress color="info" />
          </Box>
      </Modal>
  )
}
