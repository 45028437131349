import { useContext, Fragment } from "react"
import { PropTypes } from "prop-types"
import { styled } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Checkbox,
  Drawer,
  IconButton,
  Paper,
  Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses, Typography
} from "@mui/material"
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline"
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded"

import { checkDuration } from "hooks"
import { resource } from "../../data/resource"
import CreateAutForm from "../../components/Forms/AutForm"
import ExportPopup from "../../components/Utils/ExportPopup"
import DeleteDialog from "../../components/Utils/DeleteDialog"

import AutContext from "../../contexts/Aut/AutContext"
import UtilsContext from "../../contexts/Utils/UtilsContext"

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#d0c2ff",
    border: "1px solid #d0c2ff",
    // color: theme.palette.common.white,
    color: "#54577d",
    fontWeight: 550,
    padding: "7px",
    // borderTop: "40px",
    // borderBottom: "30px",
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12.2,
    padding: "13px",
    color: "#54577d",
    border: 0,
  },
}))

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f2fa",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ede8ff",
  },
  cursor: "pointer",
}))

export default function AutList({ deleteHandler }) {
  const navigate = useNavigate()

  const autContext = useContext(AutContext)
  const utilsContext = useContext(UtilsContext)

  const { auts } = autContext
  const { edit, visible, deletes, setEdit, setVisible, delValues, setDelValues } = utilsContext

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 0.45 }}>
        <Table className="customized_table" >
          <TableHead >
            <TableRow >
              <StyledTableCell align="center">
                <Checkbox
                  size="small"
                  checked={delValues.length === auts.length}
                  onChange={(e) => {
                    e.stopPropagation()
                    e.target.checked ? setDelValues([...auts.map(e => e._id)]) : setDelValues([])
                  }} />
              </StyledTableCell>
              <StyledTableCell align="center">{resource.AUT.HEAD.NAME}</StyledTableCell>
              <StyledTableCell align="center">{resource.AUT.HEAD.DESC}</StyledTableCell>
              <StyledTableCell align="center">{resource.AUT.HEAD.COUNT}</StyledTableCell>
              <StyledTableCell align="center">{resource.AUT.HEAD.STATUS}</StyledTableCell>
              <StyledTableCell align="center">{resource.AUT.HEAD.DATE}</StyledTableCell>
              <StyledTableCell align="center">{resource.AUT.HEAD.ACTION}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auts.map(row => (
              <Fragment key={row._id}>

                {edit === row._id ?
                  <Drawer anchor={"right"} open={visible}>
                    <Box role={"presentation"} className='resDrawer' >
                      <CreateAutForm
                        retVal={setVisible} />
                    </Box>
                  </Drawer> : (
                    <>
                      <StyledTableRow key={row.name} onClick={() => navigate(`/testsuites/?aut=${row._id}`)}>
                        <StyledTableCell align="center">
                          <Checkbox
                            checked={delValues?.findIndex(ele => ele === row._id) !== -1}
                            value={delValues?.findIndex(ele => ele === row._id) !== -1}
                            onClick={(event) => {
                              event.stopPropagation()
                              if (event.target.checked) { setDelValues([...delValues, row._id]) }
                              else {
                                const index = delValues.findIndex(ele => ele === row._id)
                                delValues.splice(index, 1)
                                setDelValues([...delValues])
                              }
                            }}
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">{row.name}</StyledTableCell>
                        <StyledTableCell align="center" sx={{ width: "40%" }}>
                          {row.description}
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.noTestSuites}</StyledTableCell>
                        <StyledTableCell align="center">{row.isPublic ? "Public" : "Private"}</StyledTableCell>
                        <StyledTableCell align="center">{checkDuration(row.createdAt)}</StyledTableCell>
                        <StyledTableCell align="center">
                          <Box className='autListIcons'>

                            <IconButton
                              className="accordianActionIcon"
                              size="small"
                              onClick={(event) => {
                                event.stopPropagation()
                                setVisible(row?._id)
                              }}>
                              <Tooltip placement={"top"} arrow title={resource.EX}>
                                {/* <Box className="DBAdd" > */}
                                <FileUploadRoundedIcon />
                                {/* </Box> */}
                              </Tooltip>
                            </IconButton>

                            <IconButton
                              className="accordianActionIcon"
                              onClick={(event) => {
                                event.stopPropagation()
                                setEdit(row._id)
                                setVisible(true)
                              }}>
                              <Tooltip title={resource.EDIT} placement={"top"} arrow>
                                <ModeEditOutlineIcon />
                              </Tooltip>
                            </IconButton>

                            {/* <IconButton
                                                        size="small"
                                                        className="accordianActionIcon" onClick={(event) => {
                                                            event.stopPropagation()
                                                            setDelete(row._id)
                                                        }}>
                                                        <Tooltip title={resource.DELETE} placement={'top'} arrow>
                                                            <DeleteIcon />
                                                        </Tooltip>
                                                    </IconButton> */}
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                      {visible === row?._id && <ExportPopup obj={row} />}
                    </>)}
              </Fragment>
            ))}
          </TableBody>
          {deletes && <DeleteDialog deleteHandler={deleteHandler} name={resource.AUT.NAME} />}
        </Table>
      </TableContainer>

      {!auts?.length && <Box sx={{ padding: "5%" }}>
        <center>
          <Typography variant="h6">{resource.NO_LOAD}</Typography>
        </center>
      </Box>}
    </>
  )
}

AutList.propTypes = {
  deleteHandler: PropTypes.func
}