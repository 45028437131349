import { useReducer } from "react";
import { PropTypes } from "prop-types";

import TestCaseContext from "./TestCaseContext";
import TestCaseReducer from "./TestCaseReducer";

import ApiService from "services/app.service"
import { EDIT_TC, SET_ADDT, SET_RES_PROPS, SET_STATUS, SET_TCS, SET_TESTCASE, SET_TESTCASES, SET_TESTCASE_DATA, VISIBLE_TC } from "../types.js";

function TestCaseState(props) {
    const initialState = {
        tcs: [],
        testcases: [],
        testcase: null,
        testcaseData: null,
        add_t: false,
        status: true,
        editTC: null,
        resProps: [],
        visible_tc: false,
    };
    const [state, dispatch] = useReducer(TestCaseReducer, initialState);

    const setStatus = (data) => {
        dispatch({ type: SET_STATUS, payload: data });
    };

    const setResProps = (data) => {
        dispatch({ type: SET_RES_PROPS, payload: data });
    };

    const setAddt = (data) => {
        dispatch({ type: SET_ADDT, payload: data });
    };

    const setTestCase = (data) => {
        dispatch({
            type: SET_TESTCASE,
            payload: data
        });
    };

    const setTestcaseData = (data) => {
        dispatch({
            type: SET_TESTCASE_DATA,
            payload: data
        });
    };

    const setEditTC = (data) => {
        dispatch({
            type: EDIT_TC,
            payload: data
        });
    };

    const setVisibleTC = (data) => {
        dispatch({ type: VISIBLE_TC, payload: data });
    };

    const setTestCases = (data) => {
        dispatch({
            type: SET_TESTCASES,
            payload: data
        });
    };

    const setTCs = (data) => {
        dispatch({
            type: SET_TCS,
            payload: data
        });
    };

    const getTestCases = async (id, status) => {
        try {
            const { data } = await ApiService.fetchTests(id)
            status ? setTestCases([...data, ...state.tcs]) : setTestCases(data)
        } catch (err) {
            console.log(err.response.data);
        }
    };

    const getTestCase = async (id, status) => {
        try {
            if (status) {
                const el = state.testcases.find(e => e._id ? e._id === state.editTC : e.key === state.editTC)
                setTestCase(el)
            }
            else {
                const { data } = await ApiService.fetchTest(id)
                setTestCase(data.testCase)
            }
        } catch (err) {
            console.log(err.response.data);
        }
    };


    return (
        <TestCaseContext.Provider
            value={{
                tcs: state.tcs,
                add_t: state.add_t,
                status: state.status,
                editTC: state.editTC,
                resProps: state.resProps,
                visible_tc: state.visible_tc,
                testcase: state.testcase,
                testcases: state.testcases,
                testcaseData: state.testcaseData,
                setTCs,
                setAddt,
                setStatus,
                setResProps,
                setTestCase,
                setTestCases,
                setEditTC,
                setVisibleTC,
                getTestCase,
                getTestCases,
                setTestcaseData
            }}
        >
            {props.children}
        </TestCaseContext.Provider>
    );
}

TestCaseState.propTypes = {
    children: PropTypes.node
};

export default TestCaseState;