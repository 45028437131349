/* eslint-disable react/prop-types */
import { useState, Fragment } from "react";
import {
    Box, Button,
    Drawer,
    FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CreateAutForm from "../Forms/AutForm";
import { resource } from "../../data/resource";
import CreateTestForm from "../Forms/TestForm";
import CreateTestSuiteForm from "../Forms/TSForm";
import LoadTestForm from "components/Forms/LoadTestForm";
import TestScheduler from "components/Forms/SchedulerForm";
export default function PopUpCreate({ name }) {
    const [open, setOpen] = useState(false);

    return (
        <Fragment key='right'>
            <FormControl size="small">
                <Button className="btnIcon"
                    onClick={() => setOpen(!open)}
                    size="small"
                    variant="contained"
                    sx={{
                        backgroundColor: "#20d479", border: "1px solid #20d479", "&:hover": {
                            backgroundColor: "#20d479",
                        },
                    }}
                >
                    <AddIcon size="large" />
                    {resource.CREATE} {name}
                </Button>
            </FormControl>

            <Drawer anchor="right" open={open}>
                <Box role="presentation" className="resDrawer" >
                    {name === "AUT" ? <CreateAutForm retVal={setOpen} /> : (name === "Test" ? <CreateTestForm retVal={setOpen} /> :
                        (name === "Execution Schedule" ? <TestScheduler retVal={setOpen} /> : (name === "Load Test" ? <LoadTestForm retVal={setOpen} /> : <CreateTestSuiteForm retVal={setOpen} />)))}
                </Box>
            </Drawer>
        </Fragment>
    );
}
