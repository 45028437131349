import { useContext, useEffect } from "react"
import Iframe from "react-iframe"
import { useParams } from "react-router-dom"

import ScheduleContext from "contexts/Schedule/ScheduleContext"

function LoadTest(id) {
    const { uuid } = useParams(id)
    const scheduleContext = useContext(ScheduleContext)
    const { getLoadResult, loadResult } = scheduleContext

    useEffect(() => {
        getLoadResult(uuid)
    }, [])

    return (
        <div style={{ position: "relative", height: "86vh", width: "100%" }}>
            <Iframe
                url={`https://kairostech.ai/apite-reports/index.html?dashboardJs=${loadResult?.result?.dashboardFile}&graphjs=${loadResult?.result?.graphFile}&sourceFileName=${loadResult?.result?.sourceFile}&startTime=${loadResult?.result?.startTime}&endTime=${loadResult?.result?.endTime}&autName=${loadResult?.aut}&filterForDisplay=${loadResult?.result?.filterForDisplay}`}
                width="100%"
                height="100%"
                allowFullScreen
                style={{
                    border: "none",
                    position: "absolute",
                    top: 0,
                    left: 0,
                }}
                title="Load Test Results"
            />
        </div>
    )
}

export default LoadTest