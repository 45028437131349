import { useState, useContext, useEffect } from "react"
import { PropTypes } from "prop-types"
import {
    Backdrop, Box, Button,
    CircularProgress,
    Fade, FormControl, FormControlLabel,
    IconButton,
    MenuItem, Modal,
    Radio, RadioGroup,
    TextField, Typography
} from "@mui/material"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"

import FileHandle from "./FileUpload"
import { resource } from "../../data/resource"
import ApiService from "services/app.service"
import AutContext from "../../contexts/Aut/AutContext"
import VarContext from "../../contexts/Variables/VarContext"
import UtilsContext from "../../contexts/Utils/UtilsContext"

const style = {
    p: 3,
    top: "50%",
    left: "50%",
    width: "50%",
    borderRadius: "10px",
    position: "absolute",
    bgcolor: "background.paper",
    transform: "translate(-50%, -50%)",
}

function ImportPopup({ envs, open, setEnvs, setOpen, name }) {

    const varContext = useContext(VarContext);
    const autContext = useContext(AutContext);
    const utilsContext = useContext(UtilsContext);

    const { getVars } = varContext;
    const { auts, getAuts } = autContext;
    const { loading, setLoading, removeLoading, showAlert } = utilsContext;

    const [file, setFile] = useState();
    const [type, setType] = useState("copy");
    const [selectedAut, setSelectedAut] = useState("");

    useEffect(() => {
        if (name !== "AUT") { getAuts(); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const importAUT = async () => {
        try {
            setLoading();
            const { data } = await ApiService.importAut(type, file);
            showAlert({
                type: data.status,
                msg: data.message
            });
            setTimeout(() => {
                removeLoading();
                setOpen(false);
                getAuts();
            }, resource.TIMEOUT.SUCCESS);
        } catch (err) {
            removeLoading();
            setOpen(false);
            getAuts();
            console.log(err.response.data);
            showAlert({
                type: "error",
                msg: err.response.data.message ? err.response.data.message : resource.ERR,
            });
        }
    };

    const importEnv = async () => {
        try {
            const { data } = await ApiService.importEnv({ file, selectedAut });
            showAlert({
                type: data.status,
                msg: data.message
            });
            setTimeout(() => {
                removeLoading()
                setOpen(false)
                setEnvs([...envs, data.env])
                getVars()
            }, resource.TIMEOUT.SUCCESS)
        } catch (err) {
            console.log(err.response.data);
            showAlert({
                type: "error",
                msg: err.response.data.message ? err.response.data.message : resource.ERR,
            });
        }
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                // onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <IconButton
                            sx={{
                                position: "absolute",
                                right: "8px",
                                top: "8px",
                            }}
                            color="error"
                            size="small"
                            onClick={() => setOpen(false)}
                        >
                            <CancelOutlinedIcon />
                        </IconButton>
                        <Typography variant="h4" mb={2} sx={{ color: "#611eb6" }}>
                            {resource.IMP} {name}
                        </Typography>

                        <FileHandle file={file} setFile={setFile} />

                        {name !== "AUT" && <>
                            <Typography variant="h3" mt={2} mb={1}>
                                Select the AUT you would like to import the variables.
                            </Typography>
                            <TextField
                                sx={{ minWidth: "50%", mb: 1 }}
                                size="small"
                                select
                                label="Select AUT"
                                required
                                value={selectedAut || ""}
                                onChange={(e) => setSelectedAut(e.target.value)}
                            >
                                {auts.map(e =>
                                    <MenuItem key={e.name} value={e._id}>{e.name} </MenuItem>
                                )}

                            </TextField>
                            <br />
                        </>}
                        {
                            name === "AUT" && <FormControl className="formControl">
                                <RadioGroup
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <FormControlLabel
                                        value="copy"
                                        control={<Radio
                                            sx={{
                                                "&, &.Mui-checked": {
                                                    color: "#6580e0",
                                                },
                                            }} />}
                                        label="Create a new copy"
                                    />
                                    <FormControlLabel
                                        sx={{ marginTop: "-10px" }}
                                        value="replace"
                                        control={<Radio sx={{
                                            "&, &.Mui-checked": {
                                                color: "#6580e0",
                                            },
                                        }} />}
                                        label="Replace the existing AUT"
                                    />
                                </RadioGroup>
                            </FormControl>
                        }

                        <Box>
                            <Button
                                sx={{
                                    float: "right", backgroundColor: "#6580e0",
                                    "&:hover": {
                                        backgroundColor: "#6580e0",
                                    },
                                }}
                                variant="contained"
                                size="small"
                                onClick={name === "AUT" ? importAUT : importEnv}>
                                {resource.IMP}&nbsp;
                                {loading && <CircularProgress color="inherit" size={18} />}
                            </Button>
                            <Button
                                sx={{
                                    float: "right", marginRight: "10px", backgroundColor: "#eb5160",
                                    "&:hover": {
                                        backgroundColor: "#eb5160",
                                    },
                                }}
                                variant="contained"
                                size="small"
                                onClick={() => setOpen(false)}>
                                {resource.CANCEL}
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div >
    );
}

export default ImportPopup

ImportPopup.propTypes = {
    open: PropTypes.bool,
    envs: PropTypes.array,
    setEnvs: PropTypes.func,
    setOpen: PropTypes.func,
    name: PropTypes.string
}
