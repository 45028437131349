import { Box, TextField } from "@mui/material"
import { PropTypes } from "prop-types"
import { toXML } from "jstoxml"
import XMLViewer from "react-xml-viewer"
import JSONInput from "react-json-editor-ajrm"
import locale from "react-json-editor-ajrm/locale/en"

function Request({ req, res }) {
    return (
        <Box className='responseTab'>

            {(req?.rawType === "json" || res?.body) ?
                <JSONInput
                    height={302}
                    width='100%'
                    viewOnly={true}
                    locale={locale}
                    colors={{ default: "#4a6a87" }}
                    theme={"light_mitsuketa_tribute"}
                    placeholder={JSON.parse(req?.rawData ?? res?.body)}
                /> :
                (req?.rawType === "xml" ?
                    <Box sx={{ backgroundColor: "white", padding: "10px", minHeight: "448px" }}>
                        <XMLViewer xml={toXML(req?.rawData, { indent: "   " })} />
                    </Box>
                    :
                    <TextField
                        multiline
                        fullWidth
                        size="small"
                        minRows={26}
                        autoComplete='off'
                        variant="standard"
                        defaultValue={req?.rawData}
                        InputProps={{
                            disableUnderline: true,
                            style: { color: "black" }
                        }}
                    />
                )
            }
        </Box>
    );
}

export default Request

Request.propTypes = {
    req: PropTypes.object,
    res: PropTypes.object
}
