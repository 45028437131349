import { useState, useContext, Fragment } from "react"
import { PropTypes } from "prop-types"
import {
    Autocomplete,
    Box,
    Chip,
    IconButton, MenuItem,
    ListItem, ListItemText,
    Tooltip, TableCell, TableRow, TextField, Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

import { resource } from "data/resource"
import ApiService from "services/app.service"
import JsonViewer from "components/Utils/JsonViewer"

import APIsContext from "contexts/APIs/APIsContext"
import VarContext from "contexts/Variables/VarContext"
import UtilsContext from "contexts/Utils/UtilsContext"
import TestCaseContext from "contexts/TestCase/TestCaseContext"

const inputBoxStyle = {
    height: "10px",
    backgroundColor: "#ede8ff",
}

export default function EditVariable({ obj, index, varData, setVarData, deleteHandler, status, setStatus }) {
    const searchParams = new URLSearchParams(document.location.search)
    const tsId = searchParams.get("testsuite")

    const varContext = useContext(VarContext)
    const apisContext = useContext(APIsContext)
    const utilsContext = useContext(UtilsContext)
    const testCaseContext = useContext(TestCaseContext)

    const { req } = apisContext
    const { resProps } = testCaseContext
    const { editVar, getTsVars, setEditVar } = varContext
    const { showAlert, errorData, edit, setErrorData } = utilsContext

    const [open, setOpen] = useState(false)

    const onChangeHandler = (e) => {
        if (e.target.value === "#advanced#") { setOpen(true) }
        // if (e.target.name === "name" && e.target.value.length >= 1 && !addInp && (tsVars.length - 1 == e.target.id.split("-")[1])) {
        //     onAddInput()
        //     setAddInp(1)
        // }
        // else if (e.target.id && (tsVars.length - 1 != e.target.id.split("-")[1]) && addInp)
        //     setAddInp(0)
        else {
            setVarData({
                ...varData,
                [e.target.name]: e.target.value,
            })
        }
    }

    const selectProperty = (value) => {
        setVarData({
            ...varData,
            responsePath: value,
        })
    }

    const editSubmitHandler = async () => {
        try {
            varData.isMapped = Boolean(varData.responsePath)
            const { data } = await ApiService.updateVar(editVar, varData)
            if (status !== "add") {
                showAlert({
                    type: data.status,
                    msg: data.message,
                })
            }
            else {
                showAlert({
                    type: data.status,
                    msg: "Variable added successfully",
                })
            }
            getTsVars(tsId)
            setEditVar(null)
            setStatus("update")
            // setAddInp(0)
        } catch (err) {
            const obj = {}
            console.log(err.response.data)
            const data = err.response.data.message.substring(19).split(",").map(e => e.split(":"))
            data.map(e => obj[e[0]] = e[1])
            showAlert({
                type: "error",
                msg: err.response?.data?.message,
            })
            setErrorData(obj)
            setTimeout(() => {
                setErrorData({})
            }, resource.TIMEOUT.ERRS)
        }
    }

    return (
        <Fragment>
            <TableRow>
                <TableCell align="center" >
                    <TextField
                        fullWidth
                        id={`key-${index}`}
                        name="name"
                        size="small"
                        autoComplete='off'
                        variant="outlined"
                        defaultValue={status !== "add" ? obj?.name : ""}
                        // sx={{ "& fieldset": { border: 'none' } }}
                        inputProps={{ style: inputBoxStyle, }}
                        onChange={onChangeHandler}
                        error={Boolean(errorData?.name)}
                        helperText={errorData?.name}
                    />
                </TableCell>
                <TableCell align="center" >
                    <TextField
                        fullWidth
                        name="value"
                        size="small"
                        autoComplete='off'
                        variant="outlined"
                        defaultValue={obj?.value}
                        inputProps={{ style: inputBoxStyle }}
                        onChange={onChangeHandler}
                        error={Boolean(errorData?.value)}
                        helperText={errorData?.value}
                    />
                </TableCell>

                <TableCell align="center" >
                    <Autocomplete
                        freeSolo
                        sx={{
                            zIndex: 0,
                            // transform: "translate(0%, 20%)",
                            "& .MuiAutocomplete-inputRoot": {
                                backgroundColor: "#ede8ff",
                                height: "30px"
                            }
                        }}
                        value={varData?.responsePath}
                        onChange={(e, v) => {
                            setVarData({
                                ...varData,
                                responsePath: v,
                            })
                        }}
                        options={req?.responsePaths.length ? req?.responsePaths.map(option => option) : resProps.map(option => option)}
                        renderOption={(props, option) => (
                            <MenuItem {...props}>
                                <ListItem><ListItemText primary={option} /></ListItem>
                            </MenuItem>
                        )}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                required
                                fullWidth
                                size="small"
                                autoComplete='off'
                                onChange={(e) => setVarData({
                                    ...varData,
                                    responsePath: e.target.value,
                                })}
                                label="Search Properties"
                            />
                        }
                    />
                    <Box sx={{
                        textDecoration: "underline",
                        color: "#54577d",
                        float: "right",
                        mt: 0.5,
                        transform: "translate(0%, 25%)",
                    }}>
                        <Typography sx={{ fontStyle: "oblique", cursor: "pointer" }}
                            onClick={() => setOpen(true)}>
                            Advanced Property Selection
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell>
                    <Box className='autListIcons'>
                        <Chip size="small" variant="outlined"
                            sx={{ color: "#611EB6", border: "1px solid #611EB6" }}
                            onClick={editSubmitHandler}
                            label={status !== "add" ? resource.UPDATE : resource.ADD
                            } />

                        {status !== "add" && <IconButton
                            size="small"
                            className="accordianActionIcon"
                            onClick={() => obj?.endpointId === edit && deleteHandler(obj?._id)}>
                            <Tooltip
                                title={resource.DELETE}
                                placement={"top"}
                                arrow >
                                <DeleteIcon />
                            </Tooltip>
                        </IconButton>}
                    </Box>
                </TableCell>
            </TableRow>
            {open && <JsonViewer open={open} setOpen={setOpen} onChangeHandler={selectProperty} />}
        </Fragment>
    )
}

EditVariable.propTypes = {
    obj: PropTypes.shape({
        _id: PropTypes.string,
        endpointId: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
        responsePath: PropTypes.string,
    }),
    index: PropTypes.number,
    varData: PropTypes.shape({
        responsePath: PropTypes.string,
        isMapped: PropTypes.bool
    }),
    setVarData: PropTypes.func,
    addInp: PropTypes.number,
    deleteHandler: PropTypes.func,
    status: PropTypes.string,
    setStatus: PropTypes.func,
    onAddInput: PropTypes.func
}