import { useState, useContext } from "react"
import {
  Accordion, AccordionSummary, AccordionDetails,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { resource } from "data/resource"
import { AUTheadCells } from "data/variables"
import CustomTabless from "components/Tables/CustomTabless"

import VarContext from "contexts/Variables/VarContext"
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext"

export default function AUTVariablesList() {
  const varContext = useContext(VarContext);
  const testSuiteContext = useContext(TestSuiteContext);

  const { testSuite } = testSuiteContext;
  const { env, envName, autVars } = varContext;
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState([]);

  return (
    <Box sx={{ width: "100%" }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
        >
          <Typography>
            Environment: <b>{envName}</b> | {resource.VARS.COUNT}: <b>{env?.length}</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomTabless
            category={"environment"}
            headCells={AUTheadCells}
            rows={env}
          />
        </AccordionDetails>
      </Accordion>
      <Box>
        <Box className="tsHeader" px="16px">
          <Grid container alignItems="center">
            <Grid sm={6} item>
              <Typography>
                {resource.AUT.NAME}: <b>{testSuite?.aut?.name}</b> | {resource.VARS.COUNT}: <b>{autVars?.length}</b>
              </Typography>
            </Grid>
            <Grid sm={6} textAlign="right" item></Grid>
          </Grid>
        </Box>
        <Box
          sx={{ width: "100%", mb: 2, "& .refreshBtn": { display: "none" } }}
        >
          <CustomTabless
            category={"AUT"}
            headCells={AUTheadCells}
            rows={autVars}
          />
        </Box>
      </Box>
    </Box>
  );
}
