import { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import {
  Alert,
  Box, Button,
  CircularProgress,
  Drawer,
  FormControl,
  IconButton,
  Grid,
  MenuItem,
  Paper,
  Select, Snackbar,
  TextField, Tooltip, Typography,
} from "@mui/material"
import TuneRoundedIcon from "@mui/icons-material/TuneRounded"
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined"

import AutContext from "contexts/Aut/AutContext.js"
import APIsContext from "contexts/APIs/APIsContext.js"
import UtilsContext from "contexts/Utils/UtilsContext.js"
import VarContext from "contexts/Variables/VarContext.js"
import TestCaseContext from "contexts/TestCase/TestCaseContext.js"
import TestSuiteContext from "contexts/TestSuite/TestSuiteContext.js"
import ExecutionContext from "contexts/Execution/ExecutionContext.js"
import TestResultContext from "contexts/TestResult/TestResultContext.js"

import addAPIIcon from "assets/images/noapiicon.png"

import AddRequestTabs from "./Tabs.js"
import { resource } from "data/resource.js"
import { toggleDrawer } from "hooks/index.js"
import ApiService from "services/app.service.js"
import NotFound from "components/Utils/NotFound.js"
import InnerHeader from "components/Utils/InnerHeader.js"
import DeleteDialog from "components/Utils/DeleteDialog.js"
import DefaultConfig from "components/Utils/DefaultConfig.js"
import Response from "components/AddRequest/Response/index.js"
import SideApiList from "components/AddRequest/SideApiList.js"
import APISuiteResult from "components/Utils/APISuiteResult.js"
import TextFieldWithDropdown from "components/Utils/AssignVarList.js"
import VariableTabs from "components/AddRequest/ViewVariables/index.js"
import UnsavedChangesDialog from "components/Utils/UnsavedChangesDialog.js"

export default function AddRequest() {

  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors } } = useForm()

  const tc = new URLSearchParams(document.location.search).get("tc")
  const autId = new URLSearchParams(document.location.search).get("aut")
  const testSuiteId = new URLSearchParams(document.location.search).get("testsuite")

  const autContext = useContext(AutContext)
  const varContext = useContext(VarContext)
  const apisContext = useContext(APIsContext)
  const utilsContext = useContext(UtilsContext)
  const testCaseContext = useContext(TestCaseContext)
  const executionContext = useContext(ExecutionContext)
  const testSuiteContext = useContext(TestSuiteContext)
  const testResultContext = useContext(TestResultContext)

  const { getAuts } = autContext
  const { getConfigs } = executionContext
  const { testSuite, getTestSuite, getTestSuites } = testSuiteContext
  const { setTestResults, getExes, getExeResults2 } = testResultContext
  const { tcs, testcase, testcases, setTCs, setResProps, getTestCases, setTestCase } = testCaseContext

  const { add, curr, reqs, req, response, requestData,
    getReq, getReqs, setAdd, setCurr, setSaved, setReq, setRequestData, setRes, setResponse } = apisContext

  const { tsVars, envName, envNames,
    getTsVars, getAutVars, getGlobalVars, getEnv, getEnvNames, setEnvName, setEnv, setNewVar, setDelVar, setEditVar } = varContext

  const { alert, loading, deletes, edit, changes, deleteAll, delValues, opens, exeLoading1, testData, visible,
    showAlert, removeAlert, setLoading, removeLoading, setDelete, setEdit, setChanges, setVisible, setDeleteAll, setDelValues, setOpens } = utilsContext

  // const [revert, setRevert] = useState({})
  const [, setIsEdit] = useState(null)
  const [execute, setExecute] = useState(null)
  const [isValid, setIsValid] = useState(true)
  const [showDetails, setShowDetails] = useState(false)

  const onclickCard = () => setShowDetails(true)

  const onCloseHandle = () => {
    setShowDetails(false)
    setDelVar(null)
    setEditVar(null)
  }
  const handleOpen = () => {
    setOpens(true)
    deleteAll & setDeleteAll(false)
    delValues.length && setDelValues([])
    getConfigs(testSuiteId)
  }

  const validate = async () => {
    const auts = await getAuts()
    if (auts.error === "jwtExpired")
      return
    if (auts?.find(e => e._id === autId)) {
      const suites = await getTestSuites(autId) ?? []
      if (suites?.testSuites.find(e => e._id === testSuiteId))
        setIsValid(true)
      else setIsValid(false)
    }
    else
      setIsValid(false)
  }

  useEffect(() => {
    validate()
    if (!testSuite) {
      getReqs(testSuiteId)
      getTestSuite(testSuiteId)
      getGlobalVars()
      getAutVars(autId)
      getTsVars(testSuiteId)
      getConfigs(testSuiteId)
      setEnv([])
      getEnvNames(autId)
      deleteAll & setDeleteAll(false)
      delValues.length && setDelValues([])
    }
    if (!req && edit)
      getReq(edit)

    else if (edit && req) {
      setRequestData({
        name: req.name,
        nameId: req.nameId,
        method: req.method,
        baseURL: req.baseURL,
        value: req.value,
        path: req.path,
        query: req.query,
        headers: req.headers,
        authenticationType: req.authenticationType,
        basicAuth: {
          username: req.basicAuth?.username,
          password: req.basicAuth?.password,
        },
        bearerToken: req.bearerToken,
        apiToken: {
          key: req.apiToken?.key,
          value: req.apiToken?.value,
          where: "header",
        },
        bodyType: req.bodyType,
        rawType: req.rawType,
        form: {
          encrypt: req.form?.encrypt,
          fields: req.form?.fields,
        },
        rawData: req.rawData || (req.rawType === "json" ? "{}" : ""),
        responseJsonSchema: req.responseJsonSchema,
        responsePaths: req.responsePaths,
        testData: req.testData,
        serialNumber: req.serialNumber,
        testSuiteId,
        autId
      })
      getTestCases(edit)
    }
  }, [testSuite, req, edit])

  const onChangeHandler = (e) => {
    !changes && setChanges(true)
    setRequestData({
      ...requestData,
      [e.target.name]: e.target.value,
    })
  }

  const submitHandler = async () => {
    try {
      requestData.name = requestData.name.trim()
      if (requestData.authenticationType === "none" || requestData.authenticationType === "inheritFromParent" || requestData.authenticationType === "bearerToken") {
        delete requestData.basicAuth
        delete requestData.apiToken
      }

      if (requestData.authenticationType === "basicAuth") {
        delete requestData.apiToken
        delete requestData.bearerToken
      }
      if (requestData.authenticationType === "apiToken") {
        delete requestData.basicAuth
        delete requestData.bearerToken
      }
      const { data } = await ApiService.updateEndpoint(edit, requestData)
      // eslint-disable-next-line no-unused-vars
      await ApiService.createTest(tcs.map(({ _id, ...e }) => ({ ...e, endpointId: data.endpoint._id })))
      showAlert({
        type: data.status,
        msg: data.message,
      })
      setSaved(true)
      setChanges(false)

      setTimeout(() => {
        setCurr(requestData.nameId)
        getReqs(testSuiteId)
        setTCs([])
        getTestCases(edit)
      }, resource.TIMEOUT.SUCCESS)
    } catch (err) {
      showAlert({
        type: "error",
        msg: err.response.data.message
          ? err.response.data.message
          : resource.ERR,
      })
    }
  }

  const addRequestHandler = async () => {
    try {
      const { data } = await ApiService.createEndpoint(requestData)
      showAlert({
        type: data.status,
        msg: data.message,
      })
      // eslint-disable-next-line no-unused-vars
      await ApiService.createTest(tcs.map(({ _id, ...e }) => ({ ...e, endpointId: data.endpoint._id })))

      const found = tsVars.filter(e => !e.endpointId)?.map(e => ({ ...e, endpointId: data.endpoint._id }))
      if (found.length) found.forEach(async e => await ApiService.updateVar(e._id, e))

      setNewVar(true)
      setAdd(null)
      setSaved(true)
      setChanges(false)
      getReqs(testSuiteId)
      setCurr(requestData.nameId)
      setEdit(data.endpoint._id)
      setReq(null)
      setTCs([])
      getTestCases(data.endpoint._id)
    } catch (err) {
      console.log(err.response.data)
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      })
    }

  }

  const sendHandler = async () => {
    try {
      setLoading()
      if (edit) requestData._id = edit
      requestData.testCases = testcases.filter(e => e.isSelected)
      if (testData) {
        const { data } = await ApiService.sendRequestTD(requestData, envName)
        getExeResults2(data.data)
        setExecute(data)
      }
      else {
        const { data } = await ApiService.sendRequest(requestData, envName)
        setRes(data.response)
        setResProps(data.paths)
        setTestResults(data.results.testCases)
        setResponse(true)
      }
      getTsVars(testSuiteId)
      edit && getExes(edit)
      removeLoading()
    } catch (err) {
      removeLoading()
      console.log(err.response?.data)
      if (err.code === "ECONNABORTED")
        showAlert({
          type: "error",
          msg: `Request ${err.message}`,
        })
      else
        showAlert({
          type: "error",
          msg: err.response.data.message
            ? err.response.data.message
            : resource.ERR,
        })
    }
  }

  const deleteHandler = async () => {
    try {
      let res
      const index = reqs.findIndex(e => e._id === edit)
      const index1 = reqs.findIndex(e => e._id === visible)
      if (deletes.length) { res = await ApiService.deleteEndpoint2(deletes) }
      else { res = await ApiService.deleteEndpoint(delValues) }
      showAlert({
        type: res.data.status,
        msg: res.data.message,
      })
      setDelete(null)
      setVisible(false)
      setDelValues([])
      setDeleteAll(false)
      getReqs(testSuiteId)
      getTsVars(testSuiteId)
      getConfigs(testSuiteId)
      if (index === reqs.length - 1 || index === 0 || index === index1) {
        setTimeout(() => {
          add && setAdd(null)
          tc && navigate(`/addrequest?aut=${autId}&testsuite=${testSuiteId}`)
          testcase && setTestCase(null)
          setReq(null)
          reqs.length > 1 && setEdit(reqs[index ? index - 1 : index + 1]._id)
          reqs.length > 1 && setCurr(reqs[index ? index - 1 : index + 1].nameId)
          setRes(null)
          setTCs([])
          setResProps([])
          setTestResults([])
        }, 1)
      }
    } catch (err) {
      console.log(err.response.data)
      showAlert({
        type: "error",
        msg: err.response.data.message ? err.response.data.message : resource.ERR,
      })
    }
  }

  if (isValid) {
    return (
      <Box className="fullBox2">
        {/* Title Header */}
        <InnerHeader>
          <Grid container alignItems="center">
            <Grid sm={8} item textAlign="left">
              <Typography display="inline-block" variant="h5">
                {testSuite?.name}
              </Typography>
            </Grid>
            <Grid sm={4} item textAlign="right">
              <Box className="tsHeader">
                <Typography display="inline-block" variant="h5" fontWeight={"normal"}>
                  {resource.AUT.NAME} - &nbsp;
                </Typography>
                <Typography display="inline-block" variant="h5"  >
                  {testSuite?.aut?.name}{" "}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </InnerHeader>

        <Box pt={8}>
          <Grid container>
            {/* Side API list */}
            <Grid item xs={2.5} lg={2.3} xl={2}>
              <SideApiList setExecute={setExecute} />
            </Grid>

            <Grid className="scroll-api scrollbarStyle" item xs={9.5} lg={9.7} xl={10}>
              <Box position="relative">
                <Box
                  display="flex"
                  sx={{
                    right: 15,
                    zIndex: 1,
                    top: 5,
                    position: "absolute",
                    width: "70%",
                    justifyContent: "end",
                  }}
                  gap={1}
                >
                  <FormControl>
                    {/* <InputLabel>Select Environment</InputLabel> */}
                    <Select
                      required
                      displayEmpty
                      // label={"placeholder text"}
                      // placeholder="Select  brooo"
                      value={envName || ""}
                      onChange={(e) => {
                        setEnvName(e.target.value)
                        getEnv(autId, e.target.value)
                      }}
                      sx={{ width: 200, marginTop: "5px" }}
                      size="small"
                      renderValue={(selected) => {
                        if (!selected) {
                          return <em>Select Environment</em>;
                        }
                        return selected;
                      }}
                    >
                      {envNames.map((e) => (
                        <MenuItem value={e} key={e}>
                          {e}{" "}
                        </MenuItem>
                      ))}
                      <MenuItem onClick={() => navigate("/variables")}
                        sx={{ backgroundColor: "#eee", py: "10px" }}>Create Environment &nbsp;&nbsp;⮞</MenuItem>
                    </Select>
                  </FormControl>

                  <Tooltip title="Manage Configs." placement="top">
                    <span>
                      <IconButton
                        size="small"
                        sx={{ top: 7 }}
                        disabled={!reqs?.length && true}
                        onClick={() => handleOpen()}
                      >
                        <TuneRoundedIcon sx={{ color: "#6580e0" }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="View Variables" placement="top">
                    <span>
                      <IconButton
                        size="small"
                        className="viewRes"
                        onClick={() => onclickCard()}
                      >
                        <RemoveRedEyeOutlinedIcon sx={{ color: "#6580e0" }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Response" placement="top">
                    <span>
                      <IconButton
                        size="small"
                        className="viewRes"
                        onClick={() => setResponse(true)}
                      >
                        <ArticleOutlinedIcon sx={{ color: "#6580e0" }} />
                      </IconButton>
                    </span>
                  </Tooltip>

                  {showDetails && (
                    <Paper className="customTabs viewVar">
                      <VariableTabs onCloseHandle={onCloseHandle} />
                    </Paper>
                  )}
                </Box>
              </Box>
              {!reqs.length && !add ?
                <Box>
                  <center>
                    <img src={addAPIIcon} />
                    <Typography variant="h6">{resource.REQ.CREATE}</Typography>
                  </center>
                </Box>
                :
                !add &&
                reqs.map(
                  (ele, i) =>
                    ele.nameId === curr && (
                      <Box
                        key={i}
                        noValidate
                        component="form"
                        onSubmit={handleSubmit(submitHandler)}
                      >
                        {/* Edit Request name */}
                        <Box className="resLogo" position="relative">
                          <TextField
                            autoFocus
                            autoComplete='off'
                            hiddenLabel
                            size="small"
                            variant="outlined"
                            name="name"
                            value={
                              Object.keys(requestData).length ? requestData?.name : ""
                            }
                            {...register("name", {
                              onChange: (e) => onChangeHandler(e),
                              required: "name is required.",
                              maxLength: {
                                value: 30,
                                message: "name must have at max. 30 characters.",
                              },
                              validate: (v) => {
                                if (!v.trim()) return "name is required."

                              },
                            })}
                            error={Boolean(errors.name)}
                            helperText={errors?.name?.message}
                            onMouseOver={() => setIsEdit(true)}
                            onMouseOutCapture={() => setIsEdit(false)}
                            // sx={{ "& fieldset": { border: !isEdit && 'none' } }}
                            inputProps={{
                              style: {
                                height: "20px",
                                fontSize: "14px",
                                fontWeight: 600,
                                color: "#54577d",
                              },
                            }}
                          />
                        </Box>

                        <Box className="createRequest">
                          {/* Request URL */}
                          <Box className="apiUrl" gap={1.2}>
                            <Box className="apiDetailsContainer1">
                              <Grid container>
                                <Grid md={2} item>
                                  <Select
                                    required
                                    name="method"
                                    defaultValue={ele.method}
                                    onChange={onChangeHandler}
                                    fullWidth
                                    size="small"
                                  >
                                    <MenuItem value={resource.CRUD[0]}>
                                      {resource.CRUD[0]}
                                    </MenuItem>
                                    <MenuItem value={resource.CRUD[1]}>
                                      {resource.CRUD[1]}
                                    </MenuItem>
                                    <MenuItem value={resource.CRUD[2]}>
                                      {resource.CRUD[2]}
                                    </MenuItem>
                                    <MenuItem value={resource.CRUD[3]}>
                                      {resource.CRUD[3]}
                                    </MenuItem>
                                    <MenuItem value={resource.CRUD[4]}>
                                      {resource.CRUD[4]}
                                    </MenuItem>
                                  </Select>
                                </Grid>
                                <Grid md={4} item>
                                  <TextFieldWithDropdown
                                    index={"00"}
                                    errors={errors}
                                    register={register}
                                    name={"baseURL"}
                                    defaults={requestData?.baseURL || ele.baseURL}
                                  />
                                </Grid>
                                <Grid md={6} item>
                                  <TextFieldWithDropdown
                                    index={"11"}
                                    errors={errors}
                                    register={register}
                                    name={"value"}
                                    defaults={requestData?.value || ele.value}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <Box display="flex" gap={1.5} width="20%">
                              <Button
                                className="save-button"
                                variant="contained"
                                size="small"
                                onClick={sendHandler}
                                fullWidth
                                sx={{
                                  backgroundColor: "#6580e0",
                                  "&:hover": {
                                    backgroundColor: "#6580e0",
                                  },
                                }}
                              >
                                {loading &&
                                  <CircularProgress
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      color: "#fff",
                                      marginRight: "8px",
                                    }} />
                                }
                                {resource.SEND}
                              </Button>
                              <Button
                                fullWidth
                                size="small"
                                type="submit"
                                variant="contained"
                                className="save-button"
                                disabled={!changes && true}
                                sx={{
                                  backgroundColor: "#611EB6",
                                  "&:hover": {
                                    backgroundColor: "#611EB6",
                                  },
                                }}
                              >
                                {resource.SAVE}
                              </Button>

                              <UnsavedChangesDialog
                                submitHandler={submitHandler}
                                name={ele.name}
                              />
                            </Box>
                          </Box>
                          {/* here it was */}
                          {deletes && !opens && (
                            <DeleteDialog
                              deleteHandler={deleteHandler}
                              name={"Request"}
                            // revert={revert}
                            // setRevert={setRevert}
                            />
                          )}
                          {/* Request URL */}
                          <AddRequestTabs
                            register={register}
                            errors={errors}
                          />
                        </Box>
                      </Box>
                    )
                )}

              {add &&
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit(addRequestHandler)}
                >
                  {/* Edit Request name */}
                  <Box className="resLogo" position="relative">
                    <TextField
                      autoFocus
                      hiddenLabel
                      name="name"
                      size="small"
                      autoComplete='off'
                      variant="outlined"
                      placeholder="New Request"
                      value={requestData.name}
                      {...register("name", {
                        onChange: (e) => onChangeHandler(e),
                        required: "name is required.",
                        maxLength: {
                          value: 30,
                          message: "name must have at max. 30 characters.",
                        },
                        validate: (v) => {
                          if (!v.trim()) { return "name is required." }
                        },
                      })}
                      error={Boolean(errors.name)}
                      helperText={errors?.name?.message}
                    />
                  </Box>

                  <Box className="createRequest">
                    <Box className="apiUrl" gap={1.2}>
                      <Box className="apiDetailsContainer1">
                        <Grid container>
                          <Grid md={2} item>
                            <Select
                              required
                              fullWidth
                              size="small"
                              name="method"
                              defaultValue={requestData.method}
                              onChange={onChangeHandler}
                            >
                              <MenuItem value={resource.CRUD[0]}>
                                {resource.CRUD[0]}
                              </MenuItem>
                              <MenuItem value={resource.CRUD[1]}>
                                {resource.CRUD[1]}
                              </MenuItem>
                              <MenuItem value={resource.CRUD[2]}>
                                {resource.CRUD[2]}
                              </MenuItem>
                              <MenuItem value={resource.CRUD[3]}>
                                {resource.CRUD[3]}
                              </MenuItem>
                              <MenuItem value={resource.CRUD[4]}>
                                {resource.CRUD[4]}
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid md={4} item>
                            <TextFieldWithDropdown
                              index={"00"}
                              errors={errors}
                              register={register}
                              name={"baseURL"}
                              defaults={requestData.baseURL}
                            />
                          </Grid>
                          <Grid md={6} item>
                            <TextFieldWithDropdown
                              index={"11"}
                              errors={errors}
                              register={register}
                              name={"value"}
                              defaults={requestData.value}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box display="flex" gap={1.5} width="20%">
                        <Button
                          className="save-button"
                          variant="contained"
                          size="small"
                          onClick={sendHandler}
                          fullWidth
                          sx={{
                            backgroundColor: "#6580e0",
                            "&:hover": {
                              backgroundColor: "#6580e0",
                            },
                          }}
                        >
                          {loading && <CircularProgress
                            style={{
                              color: "#fff",
                              width: "20px",
                              height: "20px",
                              marginRight: "8px",
                            }} />}
                          {resource.SEND}
                        </Button>
                        <Button
                          fullWidth
                          size="small"
                          type="submit"
                          variant="contained"
                          className="save-button"
                          sx={{
                            backgroundColor: "#611EB6",
                            "&:hover": {
                              backgroundColor: "#611EB6",
                            },
                          }}
                        >
                          {resource.SAVE}
                        </Button>
                      </Box>
                    </Box>
                    {deletes && !opens &&
                      <DeleteDialog
                        deleteHandler={deleteHandler}
                        name={"Request"}
                      // revert={revert}
                      // setRevert={setRevert}
                      />}

                    <UnsavedChangesDialog
                      submitHandler={addRequestHandler}
                      name={"New Request"}
                    />
                    {/* Request URL */}
                    <AddRequestTabs />
                  </Box>
                </Box>
              }
            </Grid>

            {response && (
              <Drawer
                anchor="right"
                open={response}
                onClose={(e) => toggleDrawer(e, setResponse)}
              >
                <Box role="presentation" className="resDrawer">
                  <Response />
                </Box>
              </Drawer>
            )}

            {alert !== null && (
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={true}
                className="snackbar"
              >
                <Alert severity={alert.type} onClose={() => removeAlert()}>{alert.msg}</Alert>
              </Snackbar>
            )}

            {execute && <APISuiteResult executes={execute} setExecute={setExecute} />}
            {exeLoading1 && <DefaultConfig />}

          </Grid>
        </Box>
      </Box>
    )
  }

  return <NotFound />;

}
