import { SET_ALERT, REMOVE_ALERT, SET_ERROR, SET_EDIT, SET_VISIBLE, SET_DELETE, SET_CHANGES, SET_LOADING, REMOVE_LOADING, SET_LOADING_EXE, REMOVE_LOADING_EXE, SET_DELETE_ALL, SET_DEL_VALUES, SET_OPENS, SET_LOADING_EXE1, REMOVE_LOADING_EXE1, SET_SNACK, REMOVE_SNACK, SET_TESTDATA } from "../types";

const UtilsReducer = (state, action) => {
    switch (action.type) {
        case SET_ALERT:
            return {
                ...state,
                alert: action.payload
            };
        case SET_SNACK:
            return {
                ...state,
                snack: action.payload
            };
        case REMOVE_SNACK:
            return {
                ...state,
                snack: null
            };
        case REMOVE_ALERT:
            return {
                ...state,
                alert: null
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        case SET_LOADING_EXE:
            return {
                ...state,
                exeLoading: true
            };
        case SET_LOADING_EXE1:
            return {
                ...state,
                exeLoading1: true
            };
        case REMOVE_LOADING_EXE:
            return {
                ...state,
                exeLoading: false
            };
        case REMOVE_LOADING_EXE1:
            return {
                ...state,
                exeLoading1: false
            };
        case REMOVE_LOADING:
            return {
                ...state,
                loading: false
            };
        case SET_ERROR:
            return {
                ...state,
                errorData: action.payload
            };
        case SET_EDIT:
            return {
                ...state,
                edit: action.payload
            };
        case SET_DELETE:
            return {
                ...state,
                deletes: action.payload
            };
        case SET_DELETE_ALL:
            return {
                ...state,
                deleteAll: action.payload
            };
        case SET_DEL_VALUES:
            return {
                ...state,
                delValues: action.payload
            };
        case SET_VISIBLE:
            return {
                ...state,
                visible: action.payload
            };
        case SET_CHANGES:
            return {
                ...state,
                changes: action.payload
            };
        case SET_OPENS:
            return {
                ...state,
                opens: action.payload
            };
        case SET_TESTDATA:
            return {
                ...state,
                testData: action.payload
            };
        default:
            return state;
    }
};

export default UtilsReducer;